import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Tag, Checkbox, Grid, Divider, Dropdown, Menu } from "antd";
import { LiaReadme } from "react-icons/lia";
import { DataSourceService } from "../../../services/DataSourceService.js";
import NoData from "../../../assets/img/no_data.js";
import "./requestAPI.css";
import { UploadOutlined } from "@ant-design/icons";
import { DeleteDialog } from "../../../components/index.js";
import { UserService } from "../../../services/UserService.js";
import { UserDataSourcePermissionService } from "../../../services/UserDataSourcePermissionService.js";

const { useBreakpoint } = Grid;

const RequestAPI = () => {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

    const fetchDataSource = async () => {
        try {
            const responseUserMe = sessionStorage.getItem("userMe") ? JSON.parse(sessionStorage.getItem("userMe")) : await UserService.getMe();
            const params = {
                TagIds:[],
                IsActivated: true,
                UserId:responseUserMe?.id
            };
            const response = await DataSourceService.searchPublic(params);
            setDataSource(response.items);
        } catch (error) {
            console.error("Error fetching data sources:", error);
        }
    };


    useEffect(() => {
        fetchDataSource();
    }, []);



    const toggleCheckbox = (id) => {
        setSelectedItems((prev) =>
            prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
        );
    };

    const handleDelete = () => {
        setIsModalDeleteOpen(true); // เปิด Modal
        //console.log("handleDelete >> selectedItems >> ",selectedItems)
    };


    const handleConfirmDelete = async () => {
        try {
            let data ={
                DataSourceIds: selectedItems
            }
            const response = await UserDataSourcePermissionService.revoke(data);
            if (response) {
                //setIsModalSuccessOpen(true);
                setDataSource((prev) => prev.filter((item) => !selectedItems.includes(item.id)));
                setSelectedItems([]); // Clear selected items after deletion
            } else {
                setIsModalDeleteOpen(false);
            }
        } catch (error) {
            console.error('เกิดข้อผิดพลาดในการลบข้อมูล:', error);
        }
    };

    const handleClickOpenRequestApiPage = async () => {
        if (sessionStorage.getItem('accessToken') === null) {
            localStorage.setItem('pageState', '/request-api');
            navigate('/login');
            return;
          }else{
            navigate(`/request-api`);
          }     
      };
    
    const handleClickDownload = async (urlSlug,type) => {
        try {
        const response = await DataSourceService.getDownloadByUrlSlug(urlSlug,type);
        //console.log("handleClickDownload >> response >> ",response)
        const blob = new Blob([response], { type: type }); 
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = `${urlSlug}.${type}`; 
        document.body.appendChild(a); 
        a.click(); 
        document.body.removeChild(a); 
        URL.revokeObjectURL(downloadUrl); 
    
    
        } catch (error) {
        console.error("Error fetching getDownloadByUrlSlug:", error);
        } finally {
        }
    };

    return (
        <div className="request-api-user-body main-body" >
            <Row justify="space-between" align="middle" style={{ paddingBottom: 20 }}>
                <p style={{ fontSize: "20px", fontWeight: "600" }}>ขอใช้ API</p>
                <div>
                    {selectedItems.length > 0 && (
                        <Button type="danger" onClick={handleDelete} style={{ marginRight: 10, background: '#fff', borderColor: '#FF4D4F', color: '#FF4D4F' }}>
                            ลบ API
                        </Button>
                    )}
                    {dataSource.length > 0 &&(
                        <Button style={{fontWeight:500}} type="primary" onClick={() => handleClickOpenRequestApiPage()}>
                            <LiaReadme /> ข้อมูล API
                        </Button>
                    )}
                    
                </div>
            </Row>
            <Divider style={{borderColor:"#D0D5DD", marginBottom:30,marginTop:0}}/>
            <div className="datacatalog-item">
                <Row gutter={[16, 8]}>
                    {dataSource.map((item) => (
                        <Col xs={24} key={item.id}>
                            <div className="datacatalog-item-data">
                                <Row justify="space-between" align="top" style={{ gap: "10px" }}>
                                    <div style={{ display: "flex", alignItems: "flex-start", flex: 1 }}>
                                        <Checkbox
                                            checked={selectedItems.includes(item.id)}
                                            onChange={() => toggleCheckbox(item.id)}
                                            style={{ marginRight: "10px" }}
                                        />
                                        <div>
                                            <Row>
                                                <span
                                                    className="link"
                                                    onClick={() => navigate(`/data-catalog/${item.urlSlug}`)}
                                                    style={{ fontSize: "16px", fontWeight: "500" }}
                                                >
                                                    {item.name}
                                                </span>
                                            </Row>
                                            <Row style={{ paddingTop: 5 }}>
                                                {item.dataSourceTags.slice(0, 3).map((tag) => (
                                                    <Tag key={tag.id} color={tag.tagColor}>
                                                        {tag.tagName}
                                                    </Tag>
                                                ))}
                                            </Row>
                                            <Row>
                                                <span style={{ color: "#667085" }}>
                                                    อัพเดทล่าสุด : {item.formattedUpdatedDate} <br />
                                                    <span
                                                        style={{ color: "#000000" }}
                                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                                    />
                                                </span>
                                            </Row>
                                        </div>
                                    </div>
                                    <Col xs={24}
                                        sm={8}
                                        md={8}
                                        lg={6}
                                        xl={4}
                                        xxl={5}
                                        style={{ alignSelf: "flex-start", textAlign: "right" }}
                                    >
                                         <Dropdown overlay={
                                            <Menu>
                                                <Menu.Item key="1"><div onClick={(e)=>{handleClickDownload(item?.urlSlug,"json")}}>ดาวน์โหลด JSON</div></Menu.Item>
                                                <Menu.Item key="2"><div onClick={(e)=>{handleClickDownload(item?.urlSlug,"csv")}}>ดาวน์โหลด CSV</div></Menu.Item>
                                            </Menu>} 
                                            
                                            //trigger={['click']}
                                            >
                                            <Button
                                                type="default"
                                                className=""
                                                style={{
                                                border: "1px solid #1677FF",
                                                color: "#1677FF",
                                                borderRadius: "10px",
                                                width: "100%"
                                                }}
                                            >
                                                <UploadOutlined />
                                                <span className="">ดาวน์โหลดข้อมูล</span>
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                            <Divider style={{borderColor:"#D0D5DD",marginBottom:20,marginTop:20}}/>
                        </Col>
                    ))}
                    {dataSource.length === 0 && (
                        <Col xs={24}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "50vh",
                                    flexDirection: "column",
                                }}
                            >
                                <NoData />
                                <p style={{ fontSize: "14px", fontWeight: "600", color: "#667085" }}>
                                    ไม่มีข้อมูล
                                </p>
                                <span>คลิกที่ปุ่มด้านล่างเพื่อสร้างชีทใหม่</span>
                                <Button
                                    type="primary"
                                    onClick={() => navigate(`/data-catalog`)}
                                    style={{ marginTop: 20, borderRadius: "10px", height: "40px", fontSize: "16px", fontWeight: "500", background: "#63E1E1", color: "#000" }}
                                >
                                    ดูดาแค็ตตาล็อค
                                </Button>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>

            <DeleteDialog
                isModalOpen={isModalDeleteOpen}
                closable={false}
                title="ยืนยันการลบ"
                cancelText="ยกเลิก"
                confirmText="ลบ"
                onCancel={() => {
                    setIsModalDeleteOpen(false);
                }}
                onConfirm={() => {
                    handleConfirmDelete();
                }}
                description="คุณต้องการลบใช่หรือไม่?"
            />
        </div>
    );
};

export default RequestAPI;