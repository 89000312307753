import React, { useEffect, useState } from "react";
import "./Survey.css";
import { SurveyService } from "../../../services/SurveyService";
import { Button, Col, Divider, Form, Radio, Row, Space } from "antd";
import { FailedDialog, LoadingDialog, SuccessDialog } from "../../../components";
import TextArea from "antd/es/input/TextArea";
import IconFeedbackButtton from "../../../assets/icon/icon_feedback_button.js";
import { useLocation } from "react-router-dom";

const SurveyPage = ({modalOpenState}) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [isValidateError, setIsValidateError] = useState(false);
  const [errorValidateMessage, setErrorValidateMessage] = useState({});
  const [formValue, setFormValue] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try{
      const response = await SurveyService.getCurrent();
      setData(response);
    }catch(e){

    }finally{
      setLoading(false);
    }

  };

  const onChange = async (e) => {
    formValue[e.target.name] = e.target.value;
    setFormValue(formValue);
  };
  const onSubmit = async () => {
    form.validateFields().then(async (results)=>{
      var answers = [];
      var questions = data.surveyFormQuestions;
      Object.keys(results).map(key=>{
        if(!results[key]) {
          return;
        }
        if(results[key].id === undefined) {
          answers.push({
            'surveyFormQuestionId': key,
            'value': results[key]
          });
        }else{
          answers.push({
            'surveyFormQuestionId': key,
            'surveyFormAnswerId': results[key].id,
            'value': results[key].value
          });
        }
        
      });
      if(answers.length !== questions.length) {
        setIsValidateError(true);
        setErrorValidateMessage({
          title: "ไม่สามารถบันทึกข้อมูลได้",
          detail: "กรุณาระบุคำตอบให้ครบถ้วน"
        });
      }else{
        setLoading(true)
        try{
          const ans = {
            "surveyFormId": data.id,
            "sessionId": window.sessionId,
            "answers": answers,
            "path": location.pathname
          }
          var response = await SurveyService.submit(ans);
          if(response.status === 200) {
            form.resetFields();
            setIsSuccess(true);
            setTimeout(()=>{
              setIsSuccess(false);
              modalOpenState();
            }, 3000);
          }
        }catch(e){
          
          setIsError(true);
          setErrorMessage({
            title: "ไม่สามารถบันทึกข้อมูลได้",
            detail: e.response.data.messageTh
          });
          console.log(e);
          
        }finally{
          setLoading(false);
        }
        
      }
      
    }).catch((e)=>{
      console.log('error', e);
      
    })
    
  };

  const onResetForm = async () => {
    setFormValue({});
    form.resetFields();
    
  };

  return (
    <div className="survey-form">
              
      <IconFeedbackButtton
        style={{ 
          position: "absolute", 
          left: "-87px", 
          top: "-16px",
          zIndex: 1000
          }}
      />
      <Form
          form={form} // เชื่อมโยง form instance
          onFinish={onSubmit}
          name="survey_form"
          initialValues={{ remember: true }}
      >
      <h1>สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)</h1>
      <p className="description">
      ขอความร่วมมือในการแสดงความคิดเห็นที่มีต่อการใช้งานเว็บไซด์ เพื่อนำข้อคิดเห็นที่ได้ไปพัฒนาให้ตอบโจทย์ผู้ใช้งานให้ดียิ่งขึ้น
      </p>
      <div className="survey-container">
      
      {
        data && (
          <>
            {data.surveyFormQuestions && (
              data.surveyFormQuestions.map((item, index) => (
                <div key={index} className="survey-item">
                  <h3>{index+1}. {item.name}</h3>
                  {item.surveyFormAnswers && (
                    <div className="survey-choices">
                      <Form.Item name={item.id} required={true}>
                      {item.questionType === "textarea" ? (
                        <TextArea name={item.id} onChange={onChange} rows={5} placeholder="กรุณาระบุคำตอบ" />
                      ) : (
                        <Radio.Group name={item.id} onChange={onChange} initialValues={null}>
                          <Space direction="vertical">
                            {item.surveyFormAnswers.map((choice, index) => (
                                <div key={index} className="survey-choice">
                                  <Radio value={choice}>{choice.name}</Radio>
                                </div>
                              ))
                            }
                          </Space>
                        </Radio.Group>
                      )}
                      </Form.Item>
                    </div>
                  )}
                  <Divider className="divider"/> 
                </div>
              ))
            )}
          </>
        )
      }
      
      </div>
      <Row className="button-row">
        <Col span={12} className="first-button">
            <Button type="primary" htmlType="submit" className="ant-btn-primary">ส่งคำตอบ</Button>
        </Col>
        <Col span={12} className="last-button">
            <a onClick={()=> {onResetForm();}}>ล้างข้อมูล</a>
        </Col>
      </Row>
      </Form>
      <FailedDialog
        title={errorMessage.title}
        detail={errorMessage.detail}
        closable={true}
        isModalOpen={isError}
        closeButtonTitle={"ตกลง"}
        onCancel={()=>{setIsError(false); setErrorMessage({});}}
        onAfterClose={()=>{setIsError(false); setErrorMessage({});}}
        onConfirm={()=>{}}
      />
      <FailedDialog
        title={errorValidateMessage.title}
        detail={errorValidateMessage.detail}
        closable={true}
        isModalOpen={isValidateError}
        onCancel={()=>{setIsValidateError(false); setErrorValidateMessage({});}}
        onAfterClose={()=>{setIsValidateError(false); setErrorValidateMessage({});}}
        onConfirm={()=>{}}
        closeButtonTitle={"ตกลง"}
      />
      <SuccessDialog
        title={"บันทึกข้อมูลสำเร็จ"}
        detail={"ขอบคุณที่ให้ความร่วมมือในการแสดงความคิดเห็น"}
        closable={true}
        isModalOpen={isSuccess}
        onCancel={()=>{}}
        onAfterClose={()=>{setIsSuccess(false);}}
        onConfirm={()=>{modalOpenState();}}
        closeButtonTitle={"ตกลง"}
      />
      <LoadingDialog isLoading={loading}/>
    </div>
  );
};

export default SurveyPage;
