const HTTP_STATUS_CODES = [
  { httpCode: 100, description: "Continue" },
  { httpCode: 101, description: "Switching Protocols" },
  { httpCode: 102, description: "Processing" },
  { httpCode: 103, description: "Early Hints" },
  { httpCode: 200, description: "OK" },
  { httpCode: 201, description: "Created" },
  { httpCode: 202, description: "Accepted" },
  { httpCode: 203, description: "Non-Authoritative Information" },
  { httpCode: 204, description: "No Content" },
  { httpCode: 205, description: "Reset Content" },
  { httpCode: 206, description: "Partial Content" },
  { httpCode: 207, description: "Multi-Status" },
  { httpCode: 208, description: "Already Reported" },
  { httpCode: 226, description: "IM Used" },
  { httpCode: 300, description: "Multiple Choices" },
  { httpCode: 301, description: "Moved Permanently" },
  { httpCode: 302, description: "Found" },
  { httpCode: 303, description: "See Other" },
  { httpCode: 304, description: "Not Modified" },
  { httpCode: 305, description: "Use Proxy" },
  { httpCode: 307, description: "Temporary Redirect" },
  { httpCode: 308, description: "Permanent Redirect" },
  { httpCode: 400, description: "Bad Request" },
  { httpCode: 401, description: "Unauthorized" },
  { httpCode: 402, description: "Payment Required" },
  { httpCode: 403, description: "Forbidden" },
  { httpCode: 404, description: "Not Found" },
  { httpCode: 405, description: "Method Not Allowed" },
  { httpCode: 406, description: "Not Acceptable" },
  { httpCode: 407, description: "Proxy Authentication Required" },
  { httpCode: 408, description: "Request Timeout" },
  { httpCode: 409, description: "Conflict" },
  { httpCode: 410, description: "Gone" },
  { httpCode: 411, description: "Length Required" },
  { httpCode: 412, description: "Precondition Failed" },
  { httpCode: 413, description: "Payload Too Large" },
  { httpCode: 414, description: "URI Too Long" },
  { httpCode: 415, description: "Unsupported Media Type" },
  { httpCode: 416, description: "Range Not Satisfiable" },
  { httpCode: 417, description: "Expectation Failed" },
  { httpCode: 418, description: "I'm a Teapot" },
  { httpCode: 421, description: "Misdirected Request" },
  { httpCode: 422, description: "Unprocessable Entity" },
  { httpCode: 423, description: "Locked" },
  { httpCode: 424, description: "Failed Dependency" },
  { httpCode: 425, description: "Too Early" },
  { httpCode: 426, description: "Upgrade Required" },
  { httpCode: 428, description: "Precondition Required" },
  { httpCode: 429, description: "Too Many Requests" },
  { httpCode: 431, description: "Request Header Fields Too Large" },
  { httpCode: 451, description: "Unavailable For Legal Reasons" },
  { httpCode: 500, description: "Internal Server Error" },
  { httpCode: 501, description: "Not Implemented" },
  { httpCode: 502, description: "Bad Gateway" },
  { httpCode: 503, description: "Service Unavailable" },
  { httpCode: 504, description: "Gateway Timeout" },
  { httpCode: 505, description: "HTTP Version Not Supported" },
  { httpCode: 506, description: "Variant Also Negotiates" },
  { httpCode: 507, description: "Insufficient Storage" },
  { httpCode: 508, description: "Loop Detected" },
  { httpCode: 510, description: "Not Extended" },
  { httpCode: 511, description: "Network Authentication Required" },
];

export default HTTP_STATUS_CODES;
