import Icon from "@ant-design/icons";

const icon = () => (
    <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="78" height="78" rx="20" fill="#F8F8F9"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.2654 11.4375H31.1248C36.5613 11.4375 40.9685 15.8447 40.9685 21.2812V26.2031C40.9685 28.9214 43.1721 31.125 45.8904 31.125H50.8123C56.2488 31.125 60.656 35.5322 60.656 40.9687V61.6406C60.656 64.3589 58.4524 66.5625 55.7341 66.5625H22.2654C19.5471 66.5625 17.3435 64.3589 17.3435 61.6406V16.3594C17.3435 13.6411 19.5471 11.4375 22.2654 11.4375ZM40.3919 37.6079C40.0227 37.2387 39.5219 37.0312 38.9998 37.0312C38.4776 37.0312 37.9769 37.2387 37.6076 37.6079L29.7326 45.4829C28.9638 46.2517 28.9638 47.4983 29.7326 48.2671C30.5015 49.036 31.748 49.036 32.5169 48.2671L37.031 43.753L37.031 54.75C37.031 55.8373 37.9124 56.7187 38.9998 56.7187C40.0871 56.7187 40.9685 55.8373 40.9685 54.75L40.9685 43.753L45.4826 48.2671C46.2515 49.036 47.498 49.036 48.2669 48.2671C49.0357 47.4983 49.0357 46.2517 48.2669 45.4829L40.3919 37.6079Z" fill="#475467"/>
    <path d="M44.906 21.2812C44.906 17.8345 43.6407 14.6833 41.5491 12.2668C50.4713 14.5974 57.4961 21.6222 59.8268 30.5444C57.4102 28.4528 54.259 27.1875 50.8123 27.1875H45.8904C45.3467 27.1875 44.906 26.7468 44.906 26.2031V21.2812Z" fill="#475467"/>
    </svg>    
);

const icon_confirm_request_api = (props) => <Icon component={icon} {...props} />;

export default icon_confirm_request_api;
