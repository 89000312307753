import { useLocation } from "react-router-dom";
import { handleLogin } from "../keycloak/KeycloakAuth";
import CryptoJS from "crypto-js";

const PrivateRoute = ({ children, allowedRoles = [] }) => {
  const location = useLocation();
  const accessToken = sessionStorage.getItem("accessToken");
  window.pathname = location.pathname;
  if (!accessToken) {
    
    localStorage.setItem("pageState", location.pathname);
    return handleLogin();
  }
  if(window.permissions === undefined && sessionStorage.getItem('permissions') != null){
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    let secSpec = CryptoJS.enc.Utf8.parse(secretKey);    
    let ivSpec = CryptoJS.enc.Utf8.parse(secretKey);
    secSpec = CryptoJS.lib.WordArray.create(secSpec.words.slice(0, 16/4));
    ivSpec = CryptoJS.lib.WordArray.create(ivSpec.words.slice(0, 16/4));
    const permissionString = CryptoJS.AES.decrypt(sessionStorage.getItem('permissions'), secSpec, { iv: ivSpec }).toString(CryptoJS.enc.Utf8);
    const permssion = JSON.parse(permissionString);
    window.permissions = permssion;
  }
  
  var hasAccess = (window.permissions||[]).find((p) => (p.routePath === location.pathname || (p.routePath + '-detail' === location.pathname && p.isEditable === true)));
  var userMe = JSON.parse(sessionStorage.getItem("userMe"));
  if(userMe && userMe.idp === process.env.REACT_APP_NAME){
    hasAccess = hasAccess || location.pathname === '/management/account-management' || location.pathname === '/management/change-password' || location.pathname === '/management/consent-setting' || location.pathname === '/management/request-api' || location.pathname === 'dashboardTag/:title' || location.pathname === 'data-catalogTag' || location.pathname === 'data-catalog-filter';
  }else{
    hasAccess = hasAccess || location.pathname === '/management/account-management' || location.pathname === '/management/consent-setting' || location.pathname === '/management/request-api';
  }
  if (!hasAccess) {
    return (window.location.href = "/not-allowed");
  }

  return children;
};

export default PrivateRoute;
