import "./DataCatalog.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Button, Dropdown, Menu } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { DataSourceService } from "../../../services/DataSourceService.js";
import { DataSourceCategoryService } from "../../../services/DataSourceCategoryService.js";
import { DataSourceTagService } from "../../../services/DataSourceTagService";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { LoadingDialog } from "../../../components";

const DataCatalogFilter = () => {
  const navigate = useNavigate();
  const [dataTag, setDataTag] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceCategory, setDataSourceCategory] = useState([{ id: "-", name: "ไม่มีข้อมูล" }]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [cookies, setCookie] = useCookies(["data-catalog-condition"]);
  const [dataSourceIds, setDataSourceIds] = useState([]);
  const location = useLocation();
  const [currentDataSourceIds, setCurrentDataSourceIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  var existParams = null;
  console.log("currentDataSourceIds", currentDataSourceIds);

  useEffect(() => {
    const storedDataSourceIds = sessionStorage.getItem('dataSourceIds');
    if (storedDataSourceIds) {
      setDataSourceIds(JSON.parse(storedDataSourceIds));
    }
  }, []);




  useEffect(() => {
    if (location?.state?.dataSourceIds) {
      setDataSourceIds(location.state.dataSourceIds);
    } else {
      console.warn("No dataSourceIds available in location state");
    }
  }, [location?.state]);

  useEffect(() => {
    if (dataSourceIds && dataSourceIds.length > 0) {
      setCurrentDataSourceIds(dataSourceIds);
    } else {
      setCurrentDataSourceIds([]);
    }
  }, [dataSourceIds]);

  useEffect(() => {
    fetchDataTags();
    fetchDataSourceCategory();
  }, []);

  useEffect(() => {
    fetchDataSource();
  }, [currentDataSourceIds, selectedTags, selectedDate, keywords]);

  const fetchDataTags = async () => {
    try {
      const response = await DataSourceTagService.get();
      setDataTag(response);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const fetchDataSource = async () => {
    setIsLoading(true);  // ตั้งค่าการโหลดเป็น true
    try {
      const params = {
        TagIds: selectedTags,
        LastCreatedDate: selectedDate,
        Keywords: keywords,
        IsActivated: true,
      };
      if (localStorage.getItem('cookie') === 'true') {
        setCookie('data-catalog-condition', JSON.stringify(params), { path: '/' });
      }
      if (existParams === JSON.stringify(params)) {
        return;
      }
      existParams = JSON.stringify(params);

      // ทำการ fetch ข้อมูลจาก API
      const data = await DataSourceService.searchPublic(params);
      if (data && data.items) {
        let filteredData = data.items;
        if (dataSourceIds && dataSourceIds.length > 0) {
          // กรองข้อมูลตาม dataSourceIds ที่มี
          filteredData = data.items.filter(item => dataSourceIds.includes(item.id));
        }
        // ตั้งค่าผลลัพธ์ที่กรองแล้ว
        setDataSource(filteredData);
        setCurrentDataSourceIds(undefined);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };



  const fetchDataSourceCategory = async () => {
    try {
      const response = await DataSourceCategoryService.search({});
      setDataSourceCategory(response.items || [{ id: "-", name: "ไม่มีข้อมูล" }]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickDownload = async (urlSlug, type) => {
    try {
      const response = await DataSourceService.getDownloadByUrlSlug(urlSlug, type);
      const blob = new Blob([response], { type });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `${urlSlug}.${type}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const toggleButtonTag = (key) => {
    const updatedTags = selectedTags.includes(key)
      ? selectedTags.filter(tag => tag !== key)
      : [...selectedTags, key];
    setSelectedTags(updatedTags);
  };

  return (
    <div className="dashboard-detail">
      <div className="dashboard-detail-title">
        <span className="content-card-title">
          <ArrowLeftOutlined onClick={handleBackClick} style={{ marginRight: "10px" }} />
          แสดงแหล่งข้อมูลของแดชบอร์ด
        </span>
      </div>

      <div className="dashboard-detail-body">
        <div className="card">
          <div className="content-dashboard-detail">
            <h2>ดาต้าแค็ตตาล๊อค</h2>
            <hr />
            <Row gutter={[16, 8]}>
              {dataSource.map((item, index) => (
                <Col xs={24} key={item.id}>
                  <div className="datacatalog-item-data">
                    <Row justify="space-between">
                      <Col xs={24} sm={12} md={14}>
                        <Row style={{ paddingBottom: 10 }}>
                          <span
                            className="link"
                            onClick={() => navigate(`/data-catalog/${item?.urlSlug}`)}
                          >
                            {item?.name}
                          </span>
                        </Row>
                        <Row style={{ paddingBottom: 5 }}>
                          {item?.dataSourceTags.slice(0, 3).map(tag => (
                            <Tag
                              key={tag.id}
                              color={tag.tagColor}
                              onClick={() => toggleButtonTag(tag.tagId)}
                            >
                              {tag.tagName}
                            </Tag>
                          ))}
                        </Row>
                        <Row>
                          <span style={{ color: "#667085", marginBottom: 5 }}>
                            อัพเดทล่าสุด : {item?.formattedUpdatedDate}
                          </span>
                        </Row>
                      </Col>
                      <Col xs={24} sm={8} md={8} lg={6} xl={5} xxl={5} style={{ textAlign: "end" }}>
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="1" onClick={() => handleClickDownload(item?.urlSlug, "json")}>
                                ดาวน์โหลด JSON
                              </Menu.Item>
                              <Menu.Item key="2" onClick={() => handleClickDownload(item?.urlSlug, "csv")}>
                                ดาวน์โหลด CSV
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Button
                            type="default"
                            style={{ border: "1px solid #1677FF", color: "#1677FF", borderRadius: "10px" }}
                          >
                            <UploadOutlined /> ดาวน์โหลดข้อมูล
                          </Button>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                      </Col>
                    </Row>
                  </div>

                  {/* แสดง <hr /> ถ้าไม่ใช่ข้อมูลสุดท้าย */}
                  {index < dataSource.length - 1 && <hr />}
                </Col>
              ))}

            </Row>
            <LoadingDialog isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCatalogFilter;
