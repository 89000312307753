import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const DataSourceCategoryService = {
  search: async function (params, cancel = false) {
    const response = await api.request({
      url: "/mst-datasource-category",
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/mst-datasource-category`,
      method: "POST",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  update: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/mst-datasource-category?id=${id}`,
      method: "PUT",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/mst-datasource-category?id=${id}`,
      method: "DELETE",
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(DataSourceCategoryService);
