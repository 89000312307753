import React, { useEffect, useState, useRef } from "react";
import {
  Space,
  Flex,
  Button,
  Col,
  Row,
  Tag,
  Select,
  Radio,
  Grid,
  List,
  Divider,
  Tooltip,
} from "antd";
import "./Dashboard.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { DashboardCategoryService } from "../../../services/DashboardCategoryService";
import { useCookies } from "react-cookie";
import { DashboardService } from "../../../services/DashboardService";
import { debounce } from "lodash";
import { AiOutlineAppstore, AiOutlineBars } from "react-icons/ai";
import NoImg from "../../../assets/img/no_img.png";
import NoData from "../../../assets/img/no_data.js";
import IconRightArrowViewDashboard from "../../../assets/icon/icon_right_arrow_view_dashboard.js";
import AffilateButton from "../../../components/Button/AffilateButton.js";
import NoAuthDialog from "../../../components/Dialog/NoAuthDialog.js";
import { LoadingDialog } from "../../../components/index.js";
import { DashboardTagService } from "../../../services/DashboardTagService";
import ErrorMessageHandler from "../../../helpers/ErrorMessageHandler.js";

const { useBreakpoint } = Grid;

const Dashboard = () => {
  var tagLoading = false;
  const location = useLocation();
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const [dataDashboardType, setDataDashboardType] = useState(null);
  const [selectedDashboardType, setSelectedDashboardType] = useState(["all"]);
  const [dataDashboard, setDataDashboard] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [cookies, setCookie] = useCookies(["dashboard-condition"]);
  const [viewMode, setViewMode] = useState("card");
  const [isNotAllow, setIsNotAllow] = useState(false);
  const [dataTag, setDataTag] = useState([]);
  const prevSelectedDashboardType = useRef(selectedDashboardType);
  const prevSelectedTags = useRef(selectedTags);
  const config = cookies["dashboard-condition"];
  const view = cookies["dashboard-view"];
  var localDataTag, localDataDashboardType;

  /*useEffect(() => {
    if (location.state?.cate) {
      try {
        // ตรวจสอบว่า cate เป็นอาร์เรย์หรือไม่
        const cate = Array.isArray(location.state.cate)
          ? location.state.cate
          : [location.state.cate]; // แปลงให้เป็นอาร์เรย์หากไม่ใช่

        console.log("location.state?.cate", cate);
        setSelectedDashboardType(cate); // ตั้งค่า selectedDashboardType
      } catch (error) {
        console.error("Error processing state data:", error);
      }
    }
  }, [location.state]);*/

  const fetchDataTags = async () => {
    try {
      setLoading(true);
      const response = await DashboardTagService.get();
      localDataTag = response;
      //console.log("fetchDataTags >> response", response);
      setDataTag(response);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataDashboardType = async () => {
    try {
      setIsLoading(true);
      const response = await DashboardCategoryService.search();
      const options = response.items.map((item) => ({
        value: item.id,
        label: item.name,
        color: "default",
      }));
      options.unshift({
        value: "all",
        label: "ประเภททั้งหมด",
        color: "default",
      });
      localDataDashboardType = options;
      setDataDashboardType(options);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const tagRender = (props) => {
    const { label, value, color, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    );
  };

  const handleChangeType = (selected) => {
    if (!Array.isArray(selected)) {
      console.error("Selected must be an array");
      return;
    }

    if (
      (selected.includes("all") && !selectedDashboardType.includes("all")) ||
      selected.length === 0
    ) {
      setSelectedDashboardType(["all"]);
      const url = new URL(window.location.href);
      url.searchParams.delete("cate");
      window.history.pushState({}, "", url);
    } else {
      const filteredValues = selected.filter((val) => val !== "all");
      setSelectedDashboardType(filteredValues);
      const url = new URL(window.location.href);
      const cate = dataDashboardType
        .filter((type) => filteredValues.includes(type.value))
        .map((type) => type.label)
        .join("|");
      url.searchParams.set("cate", cate);
      window.history.pushState({}, "", url);
    }
  };

  const fetchDataDashboard = async () => {
    try {
      if (localDataDashboardType === null) return;
      if (localDataTag === null) return;

      var params = {
        DashboardCategoryIds: selectedDashboardType || [],
        TagIds: selectedTags || [],
        IsActivated: true,
      };
      const urlSearchParam = new URLSearchParams(window.location.search);
      const search = urlSearchParam.get("search");
      if (search && search !== "") {
        params.keywords = search;
      }

      setCookiesValue("dashboard-condition", JSON.stringify(params));
      const response = await DashboardService.searchPublic(params);
      setDataDashboard(response.items);
    } catch (error) {
      console.error(error);
      setDataDashboard([]);
    }
  };

  useEffect(() => {
    fetchDataDashboard();
  }, [location.search]);

  // Debounced function
  const debouncedFetch = debounce(() => {
    if (
      JSON.stringify(prevSelectedDashboardType.current) !==
        JSON.stringify(selectedDashboardType) ||
      JSON.stringify(prevSelectedTags.current) !== JSON.stringify(selectedTags)
    ) {
      prevSelectedDashboardType.current = selectedDashboardType;
      prevSelectedTags.current = selectedTags;
      fetchDataDashboard();
    }
  }, 300);

  useEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, [selectedDashboardType, selectedTags]);

  useEffect(() => {
    //prevent multiple call
    if (tagLoading) return;
    tagLoading = true;

    if (view) {
      setViewMode(view ?? "card");
    } else {
      setViewMode("card");
    }

    const param = new URLSearchParams(window.location.search);
    var tag = param.get("tag");
    var cate = param.get("cate");
    fetchDataTags().then(() => {
      fetchDataDashboardType().then(() => {
        if (config) {
          if (
            config.DashboardCategoryIds &&
            config.DashboardCategoryIds.length > 0 &&
            config.DashboardCategoryIds[0] !== "all"
          ) {
            const cateNames = localDataDashboardType
              .filter(
                (type) =>
                  config.DashboardCategoryIds.includes(type.value) &&
                  type.value !== "all"
              )
              .map((type) => type.label)
              .join("|");
            const url = new URL(window.location.href);
            if (cateNames === "") {
              url.searchParams.delete("cate");
            } else {
              url.searchParams.set("cate", cateNames);
              cate = cateNames;
            }
            window.history.pushState({}, "", url);
          }
          if (config.TagIds && config.TagIds.length > 0) {
            const tags = localDataTag
              .filter((tag) => config.TagIds.includes(tag.tagId))
              .map((tag) => tag.tagName)
              .join("|");
            const url = new URL(window.location.href);
            if (tags === "") {
              url.searchParams.delete("tag");
            } else {
              url.searchParams.set("tag", tags);
              tag = tags;
            }
            window.history.pushState({}, "", url);
          }
        }

        if (location.search || window.location.search) {
          if (cate) {
            const cates = cate.includes("|") ? cate.split("|") : [cate];
            const cateIds = localDataDashboardType
              .filter((type) => cates.includes(type.label))
              .map((type) => type.value);
            setSelectedDashboardType(cateIds);
          }
          if (tag) {
            const tags = tag.includes("|") ? tag.split("|") : [tag];
            const tagIds = [];
            localDataTag
              .filter((tag) => tags.includes(tag.tagName))
              .map((tag) => {
                tagIds.push(tag.tagId);
              });
            setSelectedTags(tagIds);
          } else {
            const params = new URLSearchParams(location.search);
            const cate = params.get("cate");
            if (!cate || cate === "") {
              fetchDataDashboard();
            }
          }
        } else {
          fetchDataDashboard();
        }
        //}
      });
    });
  }, []);

  const isSelectedTag = (key) => selectedTags.includes(key);
  const toggleButtonTag = (key, tag) => {
    if (selectedTags.includes(key)) {
      //setSelectedTags([]);
      selectedTags.splice(selectedTags.indexOf(key), 1);
      const url = new URL(window.location.href);
      if (selectedTags.length === 0) {
        url.searchParams.delete("tag");
        window.history.pushState({}, "", url);

        setSelectedTags([]);
      } else {
        var tags = dataTag
          .filter((tag) => selectedTags.includes(tag.tagId))
          .map((tag) => tag.tagName)
          .join("|");
        url.searchParams.set("tag", tags);
        window.history.pushState({}, "", url);

        setSelectedTags([...selectedTags]);
      }
      fetchDataDashboard();
    } else {
      selectedTags.push(key);

      const url = new URL(window.location.href);
      var tags = dataTag
        .filter((tag) => selectedTags.includes(tag.tagId))
        .map((tag) => tag.tagName)
        .join("|");
      url.searchParams.set("tag", tags);
      window.history.pushState({}, "", url);

      setSelectedTags([...selectedTags]);
      fetchDataDashboard();
    }
  };

  const toggleButtonTag2 = (key, tag) => {
    if (selectedTags.length === 1 && selectedTags[0] === key) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    setSelectedTags([key]);
    const url = new URL(window.location.href);
    if (key) {
      url.searchParams.set("tag", tag);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      url.searchParams.delete("tag");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    window.history.pushState({}, "", url);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const setLoading = (visible) => {
    if (visible) {
      setIsLoading(isLoading + 1);
    } else {
      setIsLoading(isLoading - 1);
    }
  };

  const handleClick = (id, urlSlug) => {
    setLoading(true);
    DashboardService.validatePermission(urlSlug)
      .then((allow) => {
        if (allow) {
          navigate(`/dashboard/${encodeURIComponent(urlSlug)}`);
          window.scrollTo(0, 0);
        } else {
          if (sessionStorage.getItem("accessToken") === null) {
            localStorage.setItem(
              "pageState",
              `/dashboard/${encodeURIComponent(urlSlug)}`
            );
            navigate("/login");
            return;
          }
          setIsNotAllow(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          if (sessionStorage.getItem("accessToken") === null) {
            localStorage.setItem(
              "pageState",
              `/dashboard/${encodeURIComponent(urlSlug)}`
            );
            navigate("/login");
            return;
          }
          setIsNotAllow(true);
        } else {
          ErrorMessageHandler(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setCookiesValue = (key, value) => {
    if (localStorage.getItem("cookie") === "true") {
      setCookie(key, value, { path: "/" });
    }
  };

  const tagButtons = (item) => {
    const maxTag = 2;
    const tagCount = item?.dashboardTags.length;
    var renderTiems = item?.dashboardTags
      .sort((a, b) => {
        if (a.tagName === "เฉพาะสมาชิก" && b.tagName !== "เฉพาะสมาชิก") {
          return -1;
        }
        return a.sequence - b.sequence;
      })
      .slice(0, maxTag)
      .map((tag) => (
        <Tag
          key={item.id + tag.id}
          bordered={false}
          color={tag.tagColor}
          onClick={() => toggleButtonTag2(tag.tagId, tag.tagName)}
          className="tag"
        >
          {tag.tagName}
        </Tag>
      ));
    if (tagCount > maxTag) {
      renderTiems.push(
        <Tooltip
          placement="bottom"
          title={item?.dashboardTags
            .slice(maxTag)
            .map((tag) => tag.tagName)
            .join(", ")}
        >
          <Tag key={item.id + "more"} bordered={false} className="tag tag-more">
            {`+${tagCount - maxTag}`}
          </Tag>
        </Tooltip>
      );
    }
    return renderTiems;
  };

  return (
    <>
      <div className="dashboard-body">
        <div
          className="dashboard-container"
          style={{ padding: !screens.md ? "0px 20px 0px 20px" : "" }}
        >
          <h2>แดชบอร์ด</h2>
          <div className="dashboard-tag">
            <Row>
              <Col xs={4} sm={3} md={2} lg={2} xl={2}>
                <h3>Tag : </h3>
              </Col>
              <Col
                sm={20}
                md={22}
                lg={22}
                xl={20}
                // flex={"20"}
                className="group-btn-tag"
              >
                <Flex wrap>
                  {dataTag
                    .sort((a, b) => {
                      if (
                        a.tagName === "เฉพาะสมาชิก" &&
                        b.tagName !== "เฉพาะสมาชิก"
                      ) {
                        return -1;
                      }
                      return a.sequence - b.sequence;
                    })
                    .map((tag) => (
                      <Button
                        key={tag.tagId}
                        onClick={() => toggleButtonTag(tag.tagId, tag.tagName)}
                        shape="round"
                        className={
                          isSelectedTag(tag.tagId)
                            ? "btn-tag-selected"
                            : "btn-tag"
                        }
                      >
                        {tag.tagName}
                      </Button>
                    ))}
                </Flex>
              </Col>
            </Row>
          </div>
          <Divider className="dashboard-divider" />

          <div className="dashboard-type">
            <Row className="insight-condition">
              <Col span={24} className="group-type">
                <h3>ประเภท : </h3>
                <Select
                  size="large"
                  mode="multiple"
                  tagRender={tagRender}
                  defaultValue={["all"]}
                  onChange={handleChangeType}
                  value={selectedDashboardType}
                  options={dataDashboardType}
                  className="select-insight-type"
                />
              </Col>
            </Row>
          </div>
          <Divider className="dashboard-divider" />

          {dataDashboardType
            ?.filter(
              (item) =>
                item.value != "all" &&
                dataDashboard.some(
                  (data) => data.dashboardCategoryId === item.value
                )
            )
            .map((itemType, index) => (
              <div key={index}>
                <div className="dashboard-content-head">
                  <Row justify={"space-between"} align={"middle"}>
                    <Col xs={14} sm={9} md={7} lg={14} xl={8}>
                      <h3 style={{ fontSize: 17 }}>{itemType.label}</h3>
                    </Col>
                    <Col
                      xs={10}
                      sm={15}
                      md={16}
                      lg={10}
                      xl={8}
                      className="btn-viewpage"
                      style={{ textAlign: "right" }}
                    >
                      {index == 0 && (
                        <Radio.Group
                          defaultValue={viewMode}
                          buttonStyle="solid"
                          size="large"
                          optionType="button"
                          onChange={(e) => {
                            setCookiesValue("dashboard-view", e.target.value);
                            setViewMode(e.target.value);
                          }}
                        >
                          <Radio.Button value="card">
                            <AiOutlineAppstore />
                          </Radio.Button>
                          <Radio.Button value="list">
                            <AiOutlineBars />
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </Col>
                  </Row>
                </div>

                {viewMode === "card" && (
                  <div className="dashboard-content-content">
                    <Row justify="start" gutter={[16, 16]}>
                      {dataDashboard
                        ?.filter(
                          (item) => item.dashboardCategoryId === itemType.value
                        )
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((item) => (
                          <Col sm={24} md={12} lg={8} xl={6} key={item.id}>
                            <div className="content-card-dashboard2">
                              <div>
                                <div className="content-image-dashboard">
                                  <img
                                    alt="Dashboard Image"
                                    src={item?.thumbnailSrc ?? `${NoImg}`}
                                    onError={(e) => {
                                      if (e.target.alt === "error") {
                                        e.target.src = NoImg;
                                      } else {
                                        e.target.src = NoImg;
                                      }
                                    }}
                                  />
                                </div>
                                <div>
                                  <Flex wrap gap="small">
                                    {tagButtons(item)}
                                  </Flex>
                                </div>
                                <div>
                                  <p className="content-card-title-dashboard">
                                    {item.name}
                                  </p>
                                  <p className="content-card-detail-dashboard">
                                    {/* {item.shortDescription} */}
                                    {item.shortDescription}
                                  </p>
                                </div>
                              </div>

                              <Flex
                                justify="center"
                                align="center"
                                className="dashboard-detail-button-flex"
                                onClick={() =>
                                  handleClick(item.id, item.urlSlug)
                                }
                              >
                                <p>
                                  <span>ดูรายละเอียด</span>
                                  <IconRightArrowViewDashboard />
                                </p>
                              </Flex>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                )}

                {viewMode === "list" && (
                  <div
                    className="dashboard-hover-scroll"
                    style={{ overflowX: "auto", overflowY: "hidden" }}
                  >
                    <Space>
                      <List
                        itemLayout="vertical"
                        dataSource={dataDashboard
                          .filter(
                            (item) =>
                              item.dashboardCategoryId === itemType.value
                          )
                          .sort((a, b) => a.sequence - b.sequence)}
                        renderItem={(item, index) => (
                          <>
                            <List.Item>
                              <Row
                                style={{
                                  alignItems: "center",
                                  // display: "flex",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  style={{ height: "120px", width: "150px" }}
                                >
                                  <div className="dashboard-list-content">
                                    <Flex
                                      justify="center"
                                      align="center"
                                      className="dashboard-list-content-flex"
                                    >
                                      <img
                                        alt="Dashboard Image"
                                        src={item?.thumbnailSrc ?? `${NoImg}`}
                                        onError={(e) => {
                                          if (e.target.alt === "error") {
                                            e.target.src = NoImg;
                                          } else {
                                            e.target.src = NoImg;
                                          }
                                        }}
                                        className="dashboard-image"
                                        onClick={() =>
                                          handleClick(item.id, item.urlSlug)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Flex>
                                  </div>
                                </Col>
                                <Col style={{ height: "auto", width: "340px" }}>
                                  <div className="dashboard-list-tag-container">
                                    <Flex wrap gap="small">
                                      {tagButtons(item)}
                                    </Flex>
                                    <p
                                      style={{
                                        color: "#1677FF",
                                        textDecoration: "underline",
                                        cursor: "pointer"
                                      }}
                                      onClick={() =>
                                        handleClick(item.id, item.urlSlug)
                                      }
                                    >
                                      {item.name}
                                    </p>
                                  </div>
                                </Col>
                                <Col style={{ height: "auto", width: "500px" }}>
                                  <div className="dashboard-list-descripition-container">
                                    <p className="title">รายละเอียด</p>
                                    <p>{item.shortDescription}</p>
                                  </div>
                                </Col>
                                <Col style={{ height: "auto", width: "200px" }}>
                                  <div className="dashboard-list-button-container">
                                    <div
                                      className="button"
                                      onClick={() =>
                                        handleClick(item.id, item.urlSlug)
                                      }
                                    >
                                      <Flex justify="center">
                                        <p className="flex">ดูรายละเอียด</p>
                                        <IconRightArrowViewDashboard />
                                      </Flex>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </List.Item>
                          </>
                        )}
                      />
                    </Space>
                  </div>
                )}
              </div>
            ))}

          {dataDashboard.length == 0 && (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ height: "50vh" }}
            >
              <NoData />
              <p className="dashboard-list-empty">ไม่มีข้อมูล</p>
            </Flex>
          )}
        </div>
      </div>
      {process.env.REACT_APP_AFFILIATE_URL &&
        process.env.REACT_APP_AFFILIATE_URL !== "" && (
          <AffilateButton
            screenPosition="80vh"
            onClick={() => {
              window.open(process.env.REACT_APP_AFFILIATE_URL);
            }}
          />
        )}
      <NoAuthDialog
        isModalOpen={isNotAllow}
        onCancel={() => {
          setIsNotAllow(false);
        }}
        onAfterClose={() => {
          setIsNotAllow(false);
        }}
        onConfirm={() => {
          setIsNotAllow(false);
        }}
      />
      <LoadingDialog isLoading={isLoading > 0} />
    </>
  );
};

export default Dashboard;
