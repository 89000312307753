import React from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { Layout, Row, Col, Space, Flex, Divider } from "antd";
import { HiOutlineArrowRight } from "react-icons/hi";
import "./Footer.css";
import trade_association_bg from "../../assets/img/trade_association_bg.jpg";
import TCEB_Logo_white from "../../assets/img/TCEB_Logo_white.png";
import logo_fb from "../../assets/img/logo_fb.png";
import logo_ig from "../../assets/img/logo_ig.png";
import logo_x from "../../assets/img/logo_x.png";

import packageJson from "../../../package.json";

const { Footer } = Layout;

const CustomFooter = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const CheckPath = (currentPath) => {
    const pathsToHideFooter = ["/"];

    return pathsToHideFooter.includes(currentPath);
  };
  return (
    <>
      {CheckPath(currentPath) && (
        <Footer className="footer-trade-association">
          <div className="trade-association-image">
            <img src={trade_association_bg} alt="Trade Association" />
          </div>
          <div className="trade-association-container">
            <div className="ta-content-card">
              <Row>
                <Space direction="vertical">
                  <p className="text-title">{"สมาคมการค้า"}</p>
                  <p className="text-des">
                    สถาบันที่บุคคลหลายคนซึ่งเป็นผู้ประกอบธุรกิจทางการค้าหรือ
                    อุตสาหกรรม หรือการเงิน หรือทางการประมง หรือทางเกษตรกรรม
                    หรือธุรกิจอื่นใดในทางเศรษฐกิจร่วมกันจัดตั้งขึ้นเพื่อทำการส่งเสริมการประกอบวิสาหกิจ
                    อันมิใช่เป็นการหาผลกำไรหรือรายได้แบ่งปันกัน
                  </p>
                  <a>
                    <Space>
                      <p className="text-link" onClick={()=>navigate(`/trade-association`)}>
                        {"ดูสมาคมการค้าทั้งหมด"}
                        <HiOutlineArrowRight className="view-icon" />
                      </p>
                    </Space>
                  </a>
                </Space>
              </Row>
            </div>
          </div>
        </Footer>
      )}
      <Footer className="footer-contact">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={6}>
            <Link to={"/"} key={"1"}>
              <img src={TCEB_Logo_white} alt="tceb" width={104} />
            </Link>
            <br />
            <Space style={{ marginTop: "15px" }}>
              {process.env.REACT_APP_FB_URL && process.env.REACT_APP_FB_URL !== '' && (
                <a href={process.env.REACT_APP_FB_URL} target="_blank" rel="noreferrer">
                  <img src={logo_fb} alt="facebook" width={33} />
                </a>
              )}
              {process.env.REACT_APP_IG_URL && process.env.REACT_APP_IG_URL !== '' && (
                <a href={process.env.REACT_APP_IG_URL} target="_blank" rel="noreferrer">
                  <img src={logo_ig} alt="instagram" width={33} />
                </a>
              )}
              {process.env.REACT_APP_X_URL && process.env.REACT_APP_X_URL !== '' && (
                <a href={process.env.REACT_APP_X_URL} target="_blank" rel="noreferrer">
                  <img src={logo_x} alt="x twitter" width={33} />
                </a>
              )}
            </Space>
          </Col>
          <Col xs={24} sm={18}>
            <p className="contact-title" style={{ marginTop: "0px" }}>
              {"ติดต่อ"}
            </p>
            <p>{"สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)"}</p>
            <p>
              {
                "อาคารสยามพิวรรธน์ทาวเวอร์ ชั้น 25 และ 26 ยูนิต A2 B1 และ B2 เลขที่ 989 ถนนพระรามที่ 1 แขวงปทุมวัน เขตปทุมวัน กรุงเทพฯ 10330"
              }
            </p>
            <p>
              +66 2 694 6000 <br />
              info@tceb.or.th
            </p>
            <p className="contact-title">{"แผนผังเว็บไซต์"}</p>

            <Flex wrap gap="middle">
              <Link to="/">
                <u>{"หน้าแรก"}</u>
              </Link>
              <Link to="/dashboard">
                <u>{"แดชบอร์ด"}</u>
              </Link>
              <Link to="/insight-report">
                <u>{"รายงานวิเคราะห์"}</u>
              </Link>
              <Link to="/data-catalog">
                <u>{"ดาต้าแค็ตตาล๊อค"}</u>
              </Link>
              <Link to="/trade-association">
                <u>{"สมาคมการค้า"}</u>
              </Link>
              <Link to="/terms-and-condition">
                <u>{"ข้อกำหนดและเงื่อนไขการให้บริการ"}</u>
              </Link>
              <Link to="/privacy">
                <u>{"นโยบายความเป็นส่วนตัว "}</u>
              </Link>
            </Flex>
          </Col>

          <Divider
            style={{ border: "1px solid #D4D2E34D", marginBottom: "0px" }}
          />
          <div style={{ width: "100%" }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <p>
                  © 2567{" "}
                  {"สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)"}
                </p>
              </Col>
              <Col xs={24} sm={12}>
                <div className="footer-version">
                  <p>version {packageJson.version} </p>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Footer>
    </>
  );
};

export default CustomFooter;
