import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const SurveyService = {
  getCurrent: async function (cancel = false) {
    const response = await api.request({
      url: `/survey-forms/current`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  submit: async function (data, cancel = false) {
    const response = await api.request({
      url: `/survey-form-transactions/save`,
      method: "POST",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },
  summary: async function (data, cancel = false) {
    const response = await api.request({
      url: `/survey-form-transactions/summary`,
      method: "GET",
      params : data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  exportResult: async function (data, cancel = false) {
    const response = await api.request({
      url: `/survey-form-transactions/summary/export`,
      method: "GET",
      responseType: "blob",
      params : data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(SurveyService);
