
import React, { useEffect, useState } from "react";
import { Space, Col, Row, Table, Input, Button, Modal, message, Flex, Switch, Badge, Pagination, Spin, notification, Select, Checkbox } from "antd";
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'; // Correct path for Heroicons v2
import { DeleteOutlined, EditOutlined, MailOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
// import { ConfirmDialog } from "../../../../../components/index.js";
// import SuccessDialog from "../../../../../components/Dialog/SuccessDialog.js";
// import FailedDialog from "../../../../../components/Dialog/FailedDialog.js";
import "./ManageUser.css";
import { UserService } from "../../../../../services/UserService.js";
import { RoleService } from "../../../../../services/RoleService.js";
// import DeleteDialog from "../../../../../components/Dialog/DeleteDialog.js";
import NoData from "../../../../../assets/img/no_data.js";
import LoadingDialog from "../../../../../components/Dialog/LoadingDialog.js";
import ErrorMessageHandler from "../../../../../helpers/ErrorMessageHandler.js";
import {
    CustomButton,
    DeleteButton,
    EditButton,
    MailButton,
    PinButton,
    ConfirmButton,
    FilterButton,
    HistoryButton,
    EyeButton,
    ConfirmDialog,
    DeleteDialog,
    DiscardDialog,
    FailedDialog,
    SuccessDialog,
    SuccessNotification,
    CustomSearch,
    CustomCard,
    CustomIFrame,
    DatePickerLocale,
    RangePickerLocale,
} from "../../../../../components";

const { Option } = Select;
const ManageUserPage = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);
    const [isModalSuccessOpenSave, setIsModalSuccessOpenSave] = useState(false);
    const [usersData, setUsersData] = useState([]);  // ข้อมูลผู้ใช้
    const [totalCount, setTotalCount] = useState(0);  // จำนวนข้อมูลทั้งหมด
    const [allUsersCount, setAllUsersCount] = useState(0);  // จำนวนข้อมูลทั้งหมด
    const [totalNotVerifyEmail, setTotalNotVerifyEmail] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);  // หน้าเริ่มต้น
    const [pageSize, setPageSize] = useState(10);  // จำนวนรายการต่อหน้า
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        IsVerifiedEmail: null, // สถานะอีเมล
        roles: [], // บทบาทหน้าที่
        type: null, // ประเภท
    });
    const [selectedCount1, setSelectedCount1] = useState(0); // นับสำหรับ filter อันแรก
    const [selectedCount2, setSelectedCount2] = useState(0);
    const [selectedCount3, setSelectedCount3] = useState(0);
    const [keywords, setKeywords] = useState("");
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalDeleteMutiOpen, setIsModalDeleteMutiOpen] = useState(false);
    const [isModalEmailMutiOpen, setIsModalEmailMutiOpen] = useState(false);
    const [isModalEmailMutiOpen2, setIsModalEmailMutiOpen2] = useState(false);

    const [selectedId, setSelectedId] = useState(null);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [isModalSuccessOpen3, setIsModalSuccessOpen3] = useState(false);
    const [tab, setTab] = useState('all'); // ค่าเริ่มต้นคือ 'all'
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
    const [isModalFailedOpen2, setIsModalFailedOpen2] = useState(false);

    const [errorDetail, setErrorDetail] = useState("");
    const [isModalSuccessOpen2, setIsModalSuccessOpen2] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [cancelFetch, setCancelFetch] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(false);
    let searchTimeout;
    const [roles, setRoles] = useState([]);
    const [selectAll, setSelectAll] = useState(false);


    const handleSelectAll = async (isChecked) => {
        setLoading(true);
        if (isChecked) {
            setSelectAll(true);
            try {
                const allUserIds = await UserService.getAllUsers();
                if (allUserIds.items && Array.isArray(allUserIds.items)) {
                    const userIds = allUserIds.items.map(user => user.id);
                    setSelectedRecords(userIds);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching all user ids", error);
            }
        } else {
            setSelectAll(false);
            setSelectedRecords([]);
            setLoading(false);
        }
    };

    const handleSelectRecord = (id, isChecked) => {
        if (isChecked) {
            setSelectedRecords((prevSelected) => [...prevSelected, id]);
        } else {
            setSelectedRecords((prevSelected) => prevSelected.filter((recordId) => recordId !== id));
        }
        if (selectedRecords.length === usersData.length - 1) {
            setSelectAll(true);
        } else if (selectedRecords.length !== usersData.length) {
            setSelectAll(false);
        }
    };

    const fetchRoleFunctions = async () => {
        try {
            const response = await RoleService.search();
            if (response && response.items) {
                const filteredRoles = response.items.filter(
                    (role) => role.nameTh !== 'ไม่ระบุตัวตน' && role.nameEn !== 'ไม่ระบุตัวตน'
                );
                setRoles(filteredRoles);
            }
        } catch (error) {
            console.error('Error fetching job functions:', error);
        }
    };

    useEffect(() => {
        fetchRoleFunctions();
    }, []);

    const handleCheckboxChange2 = (e, roleId) => {
        const roleIdString = String(roleId);

        setFilters((prevFilters) => {
            // ใช้ prevFilters เพื่อลดการอัพเดตซ้ำ
            const newRoles = e.target.checked ? [roleIdString] : [];

            // เช็คว่าค่า roles เปลี่ยนไปหรือไม่
            if (prevFilters.roles[0] === newRoles[0]) {
                return prevFilters;  // ถ้า roles ไม่เปลี่ยน ไม่ต้องอัพเดต
            }

            return {
                ...prevFilters,
                roles: newRoles, // อัพเดต roles เพียงครั้งเดียว
            };
        });
        const selectedCount = e.target.checked ? 1 : 0;
        setSelectedCount2(selectedCount);
    };





    useEffect(() => {
        return () => {
            setFilters({
                IsVerifiedEmail: null,
                roles: [],
                type: null,
            });
        };
    }, []);
    const handleSelectChange = (event) => {
        // อัปเดตค่า type ใน filters โดยไม่ลบค่าของคีย์อื่นๆ
        setFilters(prevFilters => ({
            ...prevFilters,
            type: event.target.value, // อัปเดตเฉพาะ type
        }));
        setSelectedCount3(1); // เพิ่มจำนวนขึ้น 1
    };


    const handleSearchChange = (e) => {
        const value = e.target.value;
        setKeywords(value);
        // ล้าง timeout เดิมก่อนจะตั้งใหม่
        if (searchTimeout) {
            clearTimeout(searchTimeout);
            setCancelFetch(true);
        }
        searchTimeout = setTimeout(() => {
            setCancelFetch(false);
            if (loading) return;
            fetchData(undefined, undefined, { Keywords: value });
        }, 1500);
    };


    const fetchData = async (currentPageNext = currentPage, pageSizeNext = pageSize, filters = {}) => {
        setLoading(true);
        try {
            const response = await UserService.getAllUsers(currentPageNext, pageSizeNext, filters, cancelFetch);
            // ตรวจสอบว่ามี response.items ก่อนทำการกรอง
            if (response && response.items) {

                setUsersData(response.items);
                setTotalCount(response.pagination.totalCount);
                setAllUsersCount(response.usersTotalCount);
                setTotalNotVerifyEmail(response.emailUnverifiedTotalCount);
            }
        } catch (error) {
            console.error("Error fetching data", error);
            //ErrorMessageHandler(error);
        } finally {
            setLoading(false); // หยุดโหลดข้อมูล

        }
    };

    useEffect(() => {
        fetchData();
        UserService.isEditable().then((res) => {
            setIsEditable(res);
        });
    }, []);


    const handleDelete = (id) => {
        // console.log(id);
        setSelectedId(id); // เก็บ id ที่เลือกไว้
        setIsModalDeleteOpen(true); // เปิด Modal
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await UserService.deleteUser(selectedId);
            if (response.status === 204) {
                setIsModalSuccessOpen(true);
                fetchData();
            } else {
                setIsModalDeleteOpen(false);
            }
        } catch (error) {
            console.error('เกิดข้อผิดพลาดในการลบข้อมูล:', error);
        }
    };

    const handleConfirmDeleteMuti = async () => {
        // console.log("Selected records to delete:", selectedRecords);
        try {
            const response = await UserService.deleteUserMuti(selectedRecords);
            if (response.status === 204) {
                setIsModalSuccessOpen(true); // แสดง modal success
                fetchData(); // ดึงข้อมูลใหม่
                setSelectedRecords([]); // ล้าง selected records หลังจากลบสำเร็จ
            } else {
                setIsModalDeleteMutiOpen(false); // ปิด modal ถ้าลบไม่สำเร็จ
            }
        } catch (error) {
            console.error("เกิดข้อผิดพลาดในการลบข้อมูล:", error);

            // ตั้งค่าข้อความ error ที่จะส่งไปแสดงใน modal
            setErrorDetail(error.message || "เกิดข้อผิดพลาดไม่ทราบสาเหตุ"); // ตั้งค่าข้อความ error
            setIsModalFailedOpen(true); // เปิด modal ที่แสดงข้อผิดพลาด
        }
    };

    const handleEdit = (id) => {
        //console.log(id);
        // /management/account-management
        navigate('/management/manage-user-detail', { state: { id } });
        window.scrollTo(0, 0);
    };

    const handleSwitchChange = async (checked, record) => {
        console.log(checked);
        setUsersData(prevData =>
            prevData.map(item =>
                item.id === record.id ? { ...item, isEnabled: checked } : item
            )
        );
        const payload = {
            id: record.id,
            enabled: checked, // ตั้งค่าสถานะ enabled เป็น true/false ตามที่กด
        };
        try {
            const response = await UserService.updateUser(record.id, payload);
            setIsModalSuccessOpen3(true);
        } catch (error) {
            console.error("Error updating user", error);
            message.error('อัปเดตสถานะไม่สำเร็จ');
        } finally {
            // ปิด modal ทุกครั้ง
            setIsModalOpen(false);
            setIsModalOpen2(false);
        }
    };

    const TabnotverifyEmail = (e, filterKey) => {
        const newFilters = { ...filters, [filterKey]: false }; // ตั้งค่า IsVerifiedEmail เป็น false
        setFilters(newFilters);
        fetchData("", "", newFilters); // เรียก fetchData พร้อม filters ใหม่
    };


    const TabshowAll = (e, filterKey) => {
        fetchData();
    };


    const handleDeleteMuti = () => {
        // console.log("Delete selected records:", selectedRecords);
        setIsModalDeleteMutiOpen(true);
    };


    const handleSendEmailMuti = () => {
        setIsModalEmailMutiOpen2(true);
    };

    // const handleConfirmSendEmail = async () => {
    //     try {
    //         // ดึงข้อมูลทั้งหมดจาก UserService
    //         const userResponse = await UserService.getalluser();
    //         console.log("userResponse", userResponse);

    //         if (userResponse && userResponse.items) {
    //             const allRecords = userResponse.items;

    //             // กรองรายการที่ยังไม่ได้ยืนยันอีเมล
    //             const unverifiedRecords = allRecords.filter(record =>
    //                 selectedRecords.includes(record.id) && !record.isVerifiedEmail
    //             );

    //             console.log("Unverified records:", unverifiedRecords);

    //             // กรองรายการที่ยืนยันอีเมลแล้ว
    //             const verifiedRecords = allRecords.filter(record =>
    //                 selectedRecords.includes(record.id) && record.isVerifiedEmail
    //             );

    //             if (verifiedRecords.length > 0) {
    //                 setIsModalFailedOpen2(true);
    //                 return;  // หยุดการทำงานต่อ
    //             }


    //             const unverifiedIds = unverifiedRecords.map(record => record.id);
    //             console.log("Unverified IDs:", unverifiedIds);
    //             if (unverifiedIds.length > 0) {
    //                 const sendEmailResponse = await UserService.sendEmailMuti(unverifiedIds, false);
    //                 console.log("Send email successfully", sendEmailResponse);
    //                 setIsModalSuccessOpen2(true);  // เปิด modal success
    //                 fetchData();
    //             } else {
    //                 console.log("ไม่มีผู้ใช้ที่ยังไม่ได้ยืนยันอีเมล");
    //             }
    //         } else {
    //             console.log("ไม่พบข้อมูลผู้ใช้");
    //         }
    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // };

    const handleConfirmSendEmail = async (userId) => {
        setLoading(true);
        const userIdArray = [userId.toString()];
        const response = await UserService.sendEmailMuti(userIdArray, false);
        console.log("Send email successfully", response);
        setIsModalSuccessOpen2(true);
        fetchData();
    };

    const handleConfirmSendEmail2 = async () => {
        setLoading(true);
        if (!Array.isArray(selectedRecords) || selectedRecords.length === 0) {
            return;
        }
        try {
            for (const id of selectedRecords) {
                // console.log("Sending email to ID:", id);
                const response = await UserService.sendEmailMuti([id], false);
                // console.log(`Email sent successfully for ID: ${id}`, response);
            }
            setLoading(false);
            setIsModalSuccessOpen2(true);
            setIsModalEmailMutiOpen2(false);
            fetchData(); // รีเฟรชข้อมูล
        } catch (error) {
            console.error("Error sending emails:", error);
        }
    };


    // const handleSelectRecord = (record, isChecked) => {
    //     setSelectedRecords((prevSelected) => {
    //         const updatedRecords = isChecked
    //             ? [...prevSelected, record] // เพิ่ม record ถ้ายังไม่มี
    //             : prevSelected.filter((selectedRecord) => selectedRecord.id !== record.id); // ลบ record ตาม id
    //         return updatedRecords;
    //     });
    // };

    const isAllNotVerified = selectedRecords.every((id) => {
        const user = usersData.find((user) => user.id === id); // ค้นหาผู้ใช้จาก id
        return user && !user.isVerifiedEmail; // ตรวจสอบว่า isVerifiedEmail เป็น false
    });

    const columns = [
        {
            title: (
                <Checkbox
                    className="role-management-checkbox"
                    checked={selectAll || selectedRecords.length === usersData.length}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                />
            ),
            dataIndex: "select",
            key: "select",
            render: (_, record) => (
                <Checkbox
                    className="role-management-checkbox"
                    checked={selectedRecords.includes(record.id)}
                    onChange={(e) => handleSelectRecord(record.id, e.target.checked)}
                />
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>คำสั่ง</span>,
            key: 'action',
            width: '10%',
            render: (_, record) => (
                <Space size={2}> {/* ลดขนาด spacing */}
                    <EditButton
                        type="default"
                        icon={isEditable ? <EditOutlined /> : <ZoomInOutlined />}
                        style={{ padding: '2px 8px' }} // ลด padding
                        onClick={() => handleEdit(record.id)}
                        title={isEditable ? "แก้ไข" : "ดูข้อมูล"}
                    />
                    {isEditable && (
                        <DeleteButton
                            type="default"
                            icon={<DeleteOutlined />}
                            style={{ padding: '2px 8px' }} // ลด padding
                            onClick={() => handleDelete(record.id)}
                            title="ลบ"
                        />
                    )}
                    {record.isVerifiedEmail === false && isEditable && (
                        <MailButton
                            type="default"
                            icon={<MailOutlined />}
                            style={{ padding: '2px 8px' }} // ลด padding
                            onClick={() => {
                                setCurrentRecord(record);
                                setSelectedUserId(record.id);
                                handleSendEmailMuti();
                            }}
                            title="ส่งอีเมลยืนยัน"
                        />
                    )}
                </Space>
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>#</span>,
            key: 'index',
            render: (_, __, index) => (
                <span>{(currentPage - 1) * pageSize + index + 1}</span> // คำนวณลำดับใหม่ตามหน้า
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>ชื่อ - นามสกุล</span>,
            dataIndex: "fullName",
            key: "fullName",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>อีเมล</span>,
            dataIndex: "email",
            key: "email",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>สถานะ</span>,
            dataIndex: "isVerifiedEmail",
            key: "isVerifiedEmail",
            render: (isActivated, record) => (
                <button
                    className={isActivated ? "verified-badge" : "not-verified-badge"}
                >
                    {isActivated ? "ตรวจสอบแล้ว" : "ยังไม่ได้ยืนยันผู้ใช้งาน"}
                </button>
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>เบอร์โทรศัพท์</span>,
            dataIndex: "tel",
            key: "tel",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>ตำแหน่ง</span>,
            dataIndex: "position",
            key: "position",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>ประเภทงาน</span>,
            dataIndex: "jobFunction",
            key: "jobFunction",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>แหล่งที่มาของ ผู้ใช้งาน</span>,
            dataIndex: "userSources",
            key: "userSources",
            render: (text) => Array.isArray(text) ? text.map(o => o.name).join(', ') : (text || '-'),
        },
        {
            title: <span style={{ color: '#475467' }}>บทบาทหน้าที่</span>,
            dataIndex: "roleNameTh",
            key: "roleNameTh",
            render: (role) => (
                <button
                    className="role-badge"
                >
                    {role || 'ไม่ได้ระบุ'}
                </button>
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>การให้คำยินยอม</span>,
            dataIndex: "consentDate",
            key: "consentDate",
            render: (consentDate) => (
                <Button
                    type="default"
                    icon={
                        consentDate !== null ? (
                            <Badge status="success" />
                        ) : (
                            <Badge status="error" />
                        )
                    }
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderColor: "#d9d9d9",
                        borderRadius: "30px"
                    }}
                >
                    {consentDate !== null ? 'ให้คำยินยอมแล้ว' : 'ยังไม่ได้ให้คำยินยอม'}
                </Button>
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>Inactive/Active</span>,
            dataIndex: "isEnabled",
            key: "isEnabled",
            render: (isActive, record) => (
                <Switch
                    checked={isActive}
                    onChange={(checked) => {
                        setCurrentRecord(record); // เก็บ record ปัจจุบันใน state
                        if (checked) {
                            setIsModalOpen2(true);
                        } else {
                            setIsModalOpen(true);
                        }
                    }}
                />
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>สร้างล่าสุด</span>,
            dataIndex: "createdDateText",
            key: "createdDateText",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>เข้าใช้งานล่าสุด</span>,
            dataIndex: "lastAccessDateText",
            key: "lastAccessDateText",
            render: (text) => text || '-',
        }
    ];


    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const handleCheckboxChange = (event, filterKey) => {
        const value = event.target.value === 'true';
        const isChecked = event.target.checked;
        setFilters((prev) => {
            let updatedFilters = { ...prev };
            if (isChecked) {
                updatedFilters[filterKey] = value;
            } else {
                updatedFilters[filterKey] = null;
            }
            setSelectedCount1(isChecked ? 1 : 0);

            return updatedFilters;
        });
    };

    // const handleCheckboxChange2 = (event, filterKey) => {
    //     const isChecked = event.target.checked;
    //     const value = event.target.value;

    //     setFilters((prev) => {
    //         let updatedFilters = { ...prev };
    //         if (!updatedFilters.roles) {
    //             updatedFilters.roles = [];
    //         }
    //         if (isChecked) {
    //             if (!updatedFilters.roles.includes(value)) {
    //                 updatedFilters.roles.push(value);
    //             }
    //         } else {
    //             updatedFilters.roles = updatedFilters.roles.filter((role) => role !== value);
    //         }
    //         const selectedCount = updatedFilters.roles.length;
    //         setSelectedCount2(selectedCount);
    //         return updatedFilters;
    //     });
    // };


    const totalSelectedCount = selectedCount1 + selectedCount2 + selectedCount3;



    const handleChangeStatus = async () => {
        setIsModalSuccessOpenSave(true);
        setTimeout(() => {
            setIsModalSuccessOpenSave(false);
        }, 2000);
    }

    const handleSearchFilter = () => {
        setCurrentPage(1);
        fetchData("", "", filters)
    }

    return (
        <div className="page-container">
            <div className="tag-content main-body">
                <p style={{ fontSize: "20px", fontWeight: "600" }}>จัดการข้อมูลผู้ใช้งาน</p>
                <div>
                    {/* แท็บ */}
                    <div style={{ display: 'flex', borderBottom: '2px solid #E5E5E5', marginBottom: '16px' }}>
                        <div
                            onClick={(e) => {
                                setTab('all');
                                TabshowAll(); // ตั้งค่า filters และ fetchData
                            }}
                            style={{
                                padding: '8px 16px',
                                cursor: 'pointer',
                                fontWeight: tab === 'all' ? 'bold' : 'normal',
                                color: '#683091',
                                borderBottom: tab === 'all' ? '4px solid #683091' : '4px solid transparent',
                                transition: 'border-color 0.3s',
                            }}
                        >
                            ผู้ใช้งานทั้งหมด ({allUsersCount})
                        </div>
                        <div
                            onClick={(e) => {
                                setTab('pending');
                                TabnotverifyEmail(e, 'IsVerifiedEmail'); // ตั้งค่า filters และ fetchData
                            }}
                            style={{
                                padding: '8px 16px',
                                cursor: 'pointer',
                                fontWeight: tab === 'pending' ? 'bold' : 'normal',
                                color: '#683091',
                                borderBottom: tab === 'pending' ? '4px solid #683091' : '4px solid transparent',
                                transition: 'border-color 0.3s',
                            }}
                        >
                            ยืนยันอีเมลผู้ใช้งาน ({totalNotVerifyEmail})
                        </div>
                    </div>
                </div>

                <Row gutter={16} align="middle" style={{ justifyContent: 'space-between' }}>
                    <Col span={12}>
                        <Input
                            placeholder="ค้นหา ชื่อ-นามสกุล, อีเมล, ตำแหน่ง..."
                            value={keywords}
                            onChange={handleSearchChange}
                            style={{
                                width: '100%',
                                height: '40px',
                                borderRadius: '10px',
                                borderColor: '#D1D5DB',
                                transition: 'border-color 0.3s',
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.borderColor = 'rgb(104, 48, 145)';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.borderColor = '#D1D5DB';
                            }}
                        />
                    </Col>
                    <Col
                        span={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: '16px', // ระยะห่างระหว่าง Button และ Filter
                        }}
                    >
                        {selectedRecords.length > 0 && isEditable && (
                            <>
                                <Button
                                    type="default"
                                    icon={<DeleteOutlined />}
                                    style={{ padding: '5px 10px' }}
                                    onClick={() => handleDeleteMuti()} // ส่ง id ไป
                                />
                                {isAllNotVerified && (
                                    <>
                                        <Button
                                            type="default"
                                            icon={<MailOutlined />} // ใช้ไอคอนจดหมาย
                                            style={{ padding: '5px 10px' }}
                                            onClick={() => handleSendEmailMuti()} // ส่ง id ไป
                                        />
                                    </>
                                )}

                            </>
                        )}
                        <div style={{ position: 'relative' }}>
                            <Button
                                style={{
                                    height: '40px',
                                    borderRadius: '10px',
                                    fontSize: '16px',
                                    fontWeight: '300',
                                    borderColor: '#f8f8f9',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
                                }}
                                onClick={toggleFilter}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.borderColor = 'rgb(104, 48, 145)'; 
                                    e.currentTarget.style.color = 'rgb(104, 48, 145)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.borderColor = '#f8f8f9'; 
                                    e.currentTarget.style.color = '#000000';
                                }}
                            >
                                <AdjustmentsHorizontalIcon style={{ width: '20px', height: '20px' }} />
                                ตัวกรอง
                                {totalSelectedCount > 0 && (
                                    <Badge
                                        count={totalSelectedCount}
                                        style={{
                                            backgroundColor: '#FF0000',
                                            color: '#FFFFFF',
                                        }}
                                    />
                                )}
                            </Button>


                            {/* เมนูตัวกรอง */}
                            {showFilter && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50px',
                                        left: '-200px',
                                        backgroundColor: 'white',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '12px',
                                        padding: '16px',
                                        zIndex: 1050,
                                        width: '300px',
                                        overflow: 'auto',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        <h4 style={{ margin: 0, color: '#000000' }}>
                                            ตัวกรอง
                                            {totalSelectedCount > 0 && (
                                                <span
                                                    style={{
                                                        backgroundColor: '#FF0000',
                                                        color: '#FFFFFF',
                                                        borderRadius: '50%',
                                                        padding: '4px 8px',
                                                        marginLeft: '8px',
                                                        fontSize: '9px',
                                                        fontWeight: 'bold',
                                                        minWidth: '20px',
                                                        textAlign: 'center',
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    {totalSelectedCount}
                                                </span>
                                            )}
                                        </h4>
                                        <button
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: '#3B82F6',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                            }}
                                            onClick={() => {
                                                setFilters({
                                                    roles: [],
                                                });
                                                setSelectedCount(0);
                                                setSelectedCount1(0);
                                                setSelectedCount2(0);
                                                setSelectedCount3(0);
                                                document
                                                    .querySelectorAll('input[type="checkbox"]')
                                                    .forEach((checkbox) => {
                                                        checkbox.checked = false;
                                                    });
                                            }}
                                        >
                                            ล้างข้อมูล
                                        </button>
                                    </div>

                                    <div>
                                        <label>ประเภท</label>
                                        <select
                                            value={filters.type || ''}
                                            onChange={handleSelectChange}
                                            style={{
                                                width: '100%',
                                                padding: '8px',
                                                border: '1px solid #D1D5DB',
                                                borderRadius: '8px',
                                                marginBottom: '16px',
                                            }}
                                        >
                                            <option value="">ทุกประเภท</option>
                                            <option value="MICE Data Platform">${process.env.REACT_APP_NAME}</option>
                                            <option value="TCEB SSO">TCEB SSO</option>
                                            <option value="Google">Google</option>
                                        </select>

                                        <div>
                                            <label style={{ marginBottom: '10px', display: 'block' }}>บทบาทหน้าที่</label>
                                            <div>
                                                <Space direction="vertical" size="small">
                                                    {roles.map((role) => (
                                                        <Checkbox
                                                            key={role.id}
                                                            value={role.id}
                                                            checked={filters.roles && filters.roles.includes(role.id.toString())}
                                                            onChange={(e) => handleCheckboxChange2(e, role.id)}
                                                        >
                                                            {role.nameTh || role.nameEn}
                                                        </Checkbox>
                                                    ))}
                                                </Space>
                                            </div>
                                        </div>
                                        <br />
                                        <label style={{ marginBottom: '10px', display: 'block' }}>สถานะอีเมล</label>
                                        <div>
                                            <Space direction="vertical" size="small">
                                                <label>
                                                    <Checkbox
                                                        name="IsVerifiedEmail"
                                                        checked={filters.IsVerifiedEmail === false}
                                                        onChange={(e) => handleCheckboxChange(e, 'IsVerifiedEmail')}
                                                    />{' '}
                                                    ยังไม่ได้ยืนยันอีเมล
                                                </label>
                                                <label>
                                                    <Checkbox
                                                        name="IsVerifiedEmail"
                                                        value="true"
                                                        checked={filters.IsVerifiedEmail === true}
                                                        onChange={(e) => handleCheckboxChange(e, 'IsVerifiedEmail')}
                                                    />{' '}
                                                    ตรวจสอบเรียบร้อย
                                                </label>
                                            </Space>
                                        </div>

                                        <button
                                            style={{
                                                backgroundColor: '#63e1e1',
                                                border: 'none',
                                                borderRadius: '8px',
                                                padding: '10px',
                                                marginTop: '16px',
                                                width: '100%',
                                                cursor: 'pointer',
                                                color: 'black'
                                            }}
                                            onClick={handleSearchFilter}
                                        >
                                            ค้นหา
                                        </button>

                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>

                </Row>
                <div className="table-scroll-container">
                    {usersData && usersData.length > 0 ? (
                        <Table
                            columns={columns}  // คอลัมน์ของคุณ
                            dataSource={usersData}
                            rowKey="id"
                            pagination={false}  // ปิด pagination ที่ Table
                        />
                    ) : (
                        <Flex vertical justify="center" align="center" style={{ height: '50vh', width: '70vw' }}>
                            <NoData />
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#667085",
                                }}
                            >
                                ไม่มีข้อมูล
                            </p>
                        </Flex>
                    )}
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                    <span
                        style={{
                            color: "#2E323A",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        ทั้งหมด {totalCount} รายการ
                    </span>
                    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                        <Pagination
                            className="custom-pagination"
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalCount}
                            onChange={(page, pageSize) => {
                                //console.log("Current Page:", page);
                                setCurrentPage(page);
                                fetchData(page, pageSize);
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: '150px' }}>
                        <Select
                            value={pageSize}
                            onChange={(value) => {
                                const newPageSize = value;
                                setPageSize(newPageSize);
                                setCurrentPage(1);
                                fetchData(1, newPageSize);
                            }}
                            style={{
                                width: "108px",
                                height: "40px",
                                borderRadius: "10px",
                            }}
                        >
                            <Option value={10}>
                                <span
                                    style={{
                                        color: "#2E323A",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                    }}
                                >
                                    10 / หน้า
                                </span>
                            </Option>
                            <Option value={25}>
                                <span
                                    style={{
                                        color: "#2E323A",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                    }}
                                >
                                    25 / หน้า
                                </span>
                            </Option>
                            <Option value={50}>
                                <span
                                    style={{
                                        color: "#2E323A",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                    }}
                                >
                                    50 / หน้า
                                </span>
                            </Option>
                            <Option value={100}>
                                <span
                                    style={{
                                        color: "#2E323A",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                    }}
                                >
                                    100 / หน้า
                                </span>
                            </Option>
                        </Select>
                    </div>
                </div>
            </div>



            <ConfirmDialog
                isModalOpen={isModalOpen}
                closable={false}
                title="ยืนยันการเปลี่ยนแปลงสถานะ"
                description="เปลี่ยนสถานะเป็น 'ไม่ใช้งาน' หรือไม่?"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                onConfirm={() => handleSwitchChange(false, currentRecord)} // ใช้ currentRecord เพื่อเก็บข้อมูล
            />

            <ConfirmDialog
                isModalOpen={isModalOpen2}
                closable={false}
                title="ยืนยันการเปลี่ยนแปลงสถานะ"
                description="เปลี่ยนสถานะเป็น 'ใช้งาน' หรือไม่?"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                    setIsModalOpen2(false);
                }}
                onConfirm={() => handleSwitchChange(true, currentRecord)} // ใช้ currentRecord เพื่อเก็บข้อมูล
            />


            <SuccessDialog
                isModalOpen={isModalSuccessOpenSave}
                closable={false}
                title="สำเร็จ"
                detail="บันทึกข้อมูลสำเร็จ"
                onCancel={() => {
                    setIsModalSuccessOpenSave(false); // Corrected line here
                }}
                onAfterClose={() => {
                    // Handle after close if needed
                }}
            />

            <DeleteDialog
                isModalOpen={isModalDeleteOpen}
                closable={false}
                title="ยืนยันการลบ"
                cancelText="ยกเลิก"
                confirmText="ลบ"
                onCancel={() => {
                    setIsModalDeleteOpen(false);
                }}
                onConfirm={() => {
                    handleConfirmDelete();
                }}
                description="คุณต้องการลบใช่หรือไม่?"
            />
            <DeleteDialog
                isModalOpen={isModalDeleteMutiOpen}
                closable={false}
                title="ยืนยันการลบ"
                cancelText="ยกเลิก"
                confirmText="ลบ"
                onCancel={() => {
                    setIsModalDeleteMutiOpen(false);
                }}
                onConfirm={() => {
                    handleConfirmDeleteMuti();
                }}
                description="คุณต้องการลบใช่หรือไม่?"
            />

            <ConfirmDialog
                isModalOpen={isModalEmailMutiOpen}
                closable={false}
                type="mail"
                title="ส่งอีเมล"
                description="คุณต้องการส่งอีเมลยืนยันผู้ใช้งานนี้ ใช่หรือไม่ ?"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                    setIsModalEmailMutiOpen(false);
                }}
                onConfirm={() => handleConfirmSendEmail(selectedUserId)} // ส่ง selectedUserId เข้าไปใน handleConfirm
            />

            <ConfirmDialog
                isModalOpen={isModalEmailMutiOpen2}
                closable={false}
                type="mail"
                title="ส่งอีเมล"
                description="คุณต้องการส่งอีเมลยืนยันผู้ใช้งานนี้ ใช่หรือไม่ ?"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                    setIsModalEmailMutiOpen2(false);
                }}
                onConfirm={() => handleConfirmSendEmail2(selectedUserId)} // ส่ง selectedUserId เข้าไปใน handleConfirm
            />


            <SuccessDialog
                isModalOpen={isModalSuccessOpen3}
                closable={false}
                title="สำเร็จ"
                detail="อัพเดทสถานะสำเร็จ"
                onCancel={() => {
                    setIsModalSuccessOpen3(false);
                }}
                onAfterClose={() => {
                    setTimeout(() => {
                        setIsModalSuccessOpen3(false);
                    }, 3000);
                }}
            />


            <SuccessDialog
                isModalOpen={isModalSuccessOpen}
                closable={false}
                type="bin"
                title="สำเร็จ"
                detail="ลบข้อมูลสำเร็จ"
                onCancel={() => {
                    setIsModalSuccessOpen(false);
                }}
                onAfterClose={() => {
                    setTimeout(() => {
                        setIsModalSuccessOpen(false);
                    }, 3000);
                }}
            />

            <FailedDialog
                isModalOpen={isModalFailedOpen}
                closable={false}
                title="ล้มเหลว"
                detail={errorDetail}  // ส่ง error message ไปแสดงที่นี่
                onCancel={() => {
                    setIsModalFailedOpen(false);
                }}
                onAfterClose={() => {
                    setIsModalFailedOpen(false);
                }}
                onConfirm={() => {
                    setIsModalFailedOpen(false);
                }}
            />

            <FailedDialog
                isModalOpen={isModalFailedOpen2}
                closable={false}
                title="ล้มเหลว"
                detail="มีผู้ใช้ที่ยืนยันอีเมลแล้ว"
                onCancel={() => {
                    setIsModalFailedOpen2(false);
                }}
                onAfterClose={() => {
                    setIsModalFailedOpen2(false);
                }}
                onConfirm={() => {
                    setIsModalFailedOpen2(false);
                }}
            />


            <SuccessDialog
                isModalOpen={isModalSuccessOpen2}
                closable={false}
                title="สำเร็จ"
                detail="ส่งอีเมลยืนยันตัวตนสำเร็จแล้ว"
                onCancel={() => {
                    setIsModalSuccessOpen2(false);
                }}
                onAfterClose={() => {
                    setIsModalSuccessOpen2(false);
                }}
            />
            <LoadingDialog isLoading={loading} />



        </div>
    );
};

export default ManageUserPage;

