import React from "react";
import { Button } from "antd";
import { AiOutlinePushpin } from "react-icons/ai";

const PinButton = ({ onClick, isPinned, loading, isEditable }) => {
  return (
    <Button
      className="custom-button-action"
      loading={loading}
      icon={
        <AiOutlinePushpin
          style={{
            fontSize: "20px",
          }}
        />
      }
      disabled={isEditable === false}
      onClick={onClick}
      style={{
        width: "130px",
        height: "32px",
        borderRadius: "8px",
        background: isPinned ? "#E7F1FE" : "",
        color: isPinned ? "#0958D9" : "",
        border: isPinned ? "1px solid #0958D9" : "",
        transition: "all 0.3s ease",
        fontSize: "12px",
        fontWeight: "500",
      }}
    >
      ตั้งค่าไว้หน้าแรก
    </Button>
  );
};

export default PinButton;
