import React, { useState, useEffect } from 'react';
import { Tabs, Typography, Card, notification, Button, Divider, Grid } from 'antd';
import { ConsentService } from '../../../../../services/ConsentService';
import { handleLogout } from '../../../../../keycloak/KeycloakAuth';
import { ConfirmDialog, LoadingDialog } from '../../../../../components';
import { UserService } from '../../../../../services/UserService';
import './consent-setting.css'; // อ้างอิงไฟล์ CSS
import ErrorMessageHandler from '../../../../../helpers/ErrorMessageHandler';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;


const ConsentSettings = () => {
    const screens = useBreakpoint();
    const [activeTab, setActiveTab] = useState('1');
    const [currentConsent, setCurrentConsent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [consentDataList, setConsentDataList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const data = await ConsentService.acceptedConsent();

            const current = await ConsentService.getCurrent();
            
            setCurrentConsent(current);
            setConsentDataList(data);
            if(data.length > 0) {
                setActiveTab(current.id);
            }
        } catch (error) {
            ErrorMessageHandler(error);
        } finally {
            setIsLoading(false);
        }
    };

    const disagree = async () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        setIsModalVisible(false); // ปิด modal เมื่อกดปุ่ม
         try {
             setIsLoading(true);
             await ConsentService.withdraw(activeTab); // ถอนคำยินยอม
             handleLogout(); // ออกจากระบบ
         } catch (error) {
             console.error("Failed to withdraw consent:", error);
             ErrorMessageHandler(error);
         } finally {
             setIsLoading(false);
         }
    };

    return (
        <div className='consent-settings-body main-body' style={{ background: '#FFFFFF', }}>
            <p style={{ fontSize: "20px", fontWeight: "600" }}>ตั้งค่าความยินยอมของคุณ</p>
            <Divider style={{ margin: "0", borderColor: "#D0D5DD" }} />
            <br />
            <Tabs
                tabPosition={screens.xs ? 'top' : 'left'}
                style={{ height: '100%' }}
                activeKey={activeTab}
                onChange={setActiveTab}
            >
                {consentDataList.map((consentData, index) => (
                    <TabPane
                        key={consentData.id}
                        tab={
                            <div>
                                <span style={{ color: '#673AB7' }}>{consentData.name}</span>
                                <div style={{ color: 'black', fontSize: '12px', marginTop: '4px', textAlign: 'left' }}>
                                    {consentData.version}
                                </div>
                            </div>
                        }
                    >
                        <Card style={{ padding: 16, background: '#FAFAFA', borderColor: '#BCC2CB' }}>
                            
                            <div
                                dangerouslySetInnerHTML={{ __html: consentData.content }}
                                style={screens.xs ? {} : { maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
                            />
                        </Card>

                        <br />
                        <Paragraph>
                            <strong>อัปเดทล่าสุดวันที่ :</strong> {consentData.updatedDateText}
                        </Paragraph>
                        <Divider style={{ margin: '0' }} />
                        <br />

                        {/* เงื่อนไขสำหรับเวอร์ชั่นล่าสุด */}
                        {consentData.version === currentConsent.version ? (
                            <>
                                <Button type="default" style={{ marginRight: '10px', background: '#EEEEEE' }} onClick={disagree}>
                                    ไม่ยอมรับคำยินยอม
                                </Button>
                                <span style={{ color: '#00B6A9' }}>
                                    ยอมรับเมื่อวันที่ {consentData.consentDateText}
                                </span>
                            </>
                        ) : (
                            // ถ้าไม่ใช่เวอร์ชั่นล่าสุด แสดงแค่วันที่
                            <span style={{ color: '#00B6A9' }}>
                                ยอมรับเมื่อวันที่ {consentData.consentDateText}
                            </span>
                        )}
                    </TabPane>
                ))}
            </Tabs>

            <LoadingDialog isLoading={isLoading} />
            <ConfirmDialog
                cancelText={"ยกเลิก"}
                confirmText={"ยืนยัน"}
                closable={false}
                type={"warning"}
                title={"ความยินยอมนี้มีความจำเป็นต่อระบบ ถ้าคุณไม่ให้ความยินยอม จะไม่สามารถใช้งานในระบบได้"}
                isModalOpen={isModalVisible}
                onConfirm={handleOk}
                onCancel={() => setIsModalVisible(false)}
            />
        </div >
    );
};

export default ConsentSettings;
