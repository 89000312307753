import "./NotFound.css";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Layout, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Footer } from "../../components";
import { Content } from "antd/es/layout/layout";

const NotFound = () => {
  const [footerHeight, setFooterHeight] = useState(0);
  useEffect(() => {

  },[]);
  return (
    <div>
      <Layout className="layout">
        <Content className="content">
          <Layout>
            <Content>
              <div className="not-found-body" style={{minHeight:`calc(100vh - ${footerHeight}px)`}}>
                <div className="not-found-container" style={{minHeight:`calc(100vh - ${footerHeight}px)`}}>
                <h1 className="extra-large">404</h1>
                <h1>ไม่พบหน้าที่คุณกำลังมองหา</h1>
                <p>หน้าที่คุณกำลังมองหาอาจถูกย้าย หรือไม่มีอยู่อีกต่อไป</p>
                <Row style={{width:"100%"}}>
                  <Col span={24}  style={{textAlign:"center"}}>
                    {window.history.length > 0 && (
                      <Button type="default"  className="primary-button" onClick={() => {
                        window.history.back();
                      }}>
                        <ArrowLeftOutlined />
                        กลับไปก่อนหน้า
                      </Button>
                    )}
                    <Link to="/" style={{display:"inline-block"}}>
                      <Button type="default"  className="main-button">หน้าหลัก</Button>
                    </Link>
                  </Col>
                  
                </Row>
                </div>
             </div>
            </Content>
          </Layout>
        </Content>

        <Footer />
      </Layout>
    </div>
  );
};

export default NotFound;
