import React, { useEffect, useState } from "react";
import { Button, Divider, Space, DatePicker, Flex, Grid } from "antd";
import "./feedback.css";
import { SurveyService } from "../../../../../services/SurveyService";
import { DownloadOutlined } from "@ant-design/icons";
import { LoadingDialog, RangePickerLocale } from "../../../../../components";
import dayjs from "dayjs";

const { useBreakpoint } = Grid;

const FeedbackPage = () => {
  const screen = useBreakpoint();
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [selectedDateRange]);

  const fetchData = async () => {
    setLoading(true);
    try {
      var result = await SurveyService.summary(
        selectedDateRange.length > 0
          ? {
              startDate: selectedDateRange[0],
              endDate: selectedDateRange[1],
            }
          : {}
      );
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeDateRange = (dates) => {
    let formattedDates = [];
    if (dates != null) {
      formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));
    }
    setSelectedDateRange(formattedDates);
  };
  const handleExport = async () => {
    const response = await SurveyService.exportResult(
      selectedDateRange.length > 0
        ? {
            startDate: selectedDateRange[0],
            endDate: selectedDateRange[1],
          }
        : {}
    );

    //const blob = await response.blob(); // แปลงเนื้อหาของไฟล์เป็น Blob
    const link = document.createElement("a"); // สร้างลิงก์ชั่วคราว
    link.href = URL.createObjectURL(response); // สร้าง URL จาก Blob
    link.download = `feedback_result_${dayjs().format("YYYYMMDD")}.xlsx`; // ตั้งชื่อไฟล์จาก URL หรือกำหนดชื่อที่คุณต้องการ
    link.click(); // คลิกลิงก์เพื่อดาวน์โหลด
  };

  return (
    <div className="page-container main-body">
      <div className="feedback-content">
        <LoadingDialog isLoading={loading} />
        <Flex
          justify="space-between"
          align="center"
          wrap
          style={{ marginBottom: "10px" }}
        >
          <p style={{ fontSize: "20px", fontWeight: "600" }}>
            จัดการข้อมูล Feedback
          </p>
          <Flex
            justify="space-between"
            align="center"
            wrap
            gap="small"
            style={{ marginTop: !screen.sm ? "10px" : "" }}
          >
            <RangePickerLocale
              onChange={handleChangeDateRange}
              value={
                selectedDateRange.length > 0
                  ? [dayjs(selectedDateRange[0]), dayjs(selectedDateRange[1])]
                  : null
              }
              style={{
                borderRadius: "10px",
                fontSize: "14px",
              }}
            />
            <Button
              className="export-button"
              type="primary"
              onClick={handleExport}
            >
              <DownloadOutlined /> Export
            </Button>
          </Flex>
        </Flex>
        {data && (
          <>
            <h1 className="summary-title">
              ระดับความพึงพอใจ {data.averageScore} จาก 5 คะแนน
            </h1>
            <h3 className="sub-title">
              จำนวนผู้ตอบแบบสอบถามทั้งสิัน {data.totalSurvey} คน
              <small>
                ช่วงเวลาการเก็บข้อมูล{" "}
                {data.startDate
                  ? `${data.startDate} - ${data.endDate}`
                  : "(ยังไม่มีผู้ตอบแบบสอบถาม)"}
              </small>
            </h3>

            <Divider className="divider" style={{ borderColor: "#D0D5DD" }} />
            {data.surveyFormQuestions &&
              data.surveyFormQuestions.map((item, index) => (
                <div key={index} className="survey-item">
                  <h3>
                    {index + 1}. {item.name} ({item.averageScore} จาก 5)
                  </h3>
                  {item.surveyFormAnswers && (
                    <div className="survey-choices">
                      {item.questionType === "textarea" ? (
                        <></>
                      ) : (
                        <Space direction="vertical">
                          {item.surveyFormAnswers.map((choice, index) => (
                            <div key={choice.id} className="survey-choice">
                              {choice.name} : {choice.percentage}%
                            </div>
                          ))}
                        </Space>
                      )}
                    </div>
                  )}
                  <Divider style={{ borderColor: "#D0D5DD" }} />
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackPage;
