import React, { useState } from 'react';
import { Form, Input, Button , Modal, Row, Col, Grid } from 'antd';
import { ArrowLeftOutlined , CloseCircleOutlined } from '@ant-design/icons';
import logo from '../Login/logo 1.png';
import key from '../register/key.png';
import { useNavigate } from 'react-router-dom';
import '../Login/login.css';
import '../forgot/forgot.css';
import { KeycloakService } from '../../../services/KeycloakService';
import { LoadingDialog, FailedDialog } from '../../../components';

const { useBreakpoint } = Grid;
    
const ForgotPage = () => {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [requestSent, setRequestSent] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง");
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);

    const onFinish = async (values) => {
        //console.log('Received values of form: ', values);
        setIsLoading(true);
        try {
            const responseData = await KeycloakService.sendEmailForgot(values.email);
            //console.log(responseData);
            setRequestSent(true);
        } catch (error) {
            console.error();
            console.error();
        
            setErrorMessage(
                error.response?.data?.messageTh
            );
            setIsModalFailedOpen(true);
            form.setFields([
                {
                    name: 'email',
                    errors: ['อีเมลไม่ถูกต้อง โปรดตรวจสอบอีเมลอีกครั้ง'],
                },
            ]);
        }finally{
            setIsLoading(false);
        }
    };

    const handleOk = () => {
        setIsModalVisible(false); // ปิด modal เมื่อกดปุ่ม
    };

    const goBack = () => {
        navigate('/login');
    };

    const onValuesChange = (changedValues) => {
        const emailValue = form.getFieldValue('email');
        setIsButtonDisabled(!emailValue); // Enable button only if email has value
    };

    return (
        <div className="login-container1">
            <Row>
                <Col span={18} md={18} xs={24}>
                
                <Row>
                    <Col span={24} md={24} xs={0}>
                    <div className="login-bg"></div>
                    <div className='login-left'>
                        <div className="logo">
                        <img
                            src={logo}
                            alt="TCEB Logo"
                            className="logo-image"
                            onClick={() => navigate('/')}
                            style={{ cursor: 'pointer' }}
                        />
                        </div>
                    </div>
                    <div className="copyright">
                        © 2567 สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)
                    </div>
                    </Col>
                    <Col span={24} xs={24} md={0}>
                    <div className="login-bg" style={{position:'fixed'}}></div>
                    <div className="logo" style={{textAlign:"center", marginTop:"15px"}}>
                        <img
                        src={logo}
                        alt="TCEB Logo"
                        className="logo-image"
                        onClick={() => navigate('/')}
                        style={{ cursor: 'pointer' }}
                        />
                    </div>
                    </Col>
                </Row>
                </Col>
                <Col span={6} md={6} xs={24}>
                    <div className="center-v">
                    {!requestSent ? (
                        <div className="login-card" style={screens.xs || document.body.clientWidth <= 767 ? {marginLeft: "auto", marginRight: "auto", marginTop: "15px"} : { marginLeft: "-230px" }}>
                            <div className="header">
                                <ArrowLeftOutlined
                                    style={{ marginRight: '8px', cursor: 'pointer' }}
                                    onClick={goBack}
                                />
                            </div>
                            <p
                                style={{
                                    margin: '20px 0 0 0',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                }}
                            >
                                คุณลืมรหัสผ่าน ?
                            </p>
                            <span style={{ display: 'block', margin: '10px 0' }}>
                                กรุณากรอกอีเมลที่คุณใช้ในการสมัครสมาชิกเพื่อกู้คืนรหัสผ่าน <br />
                                คุณจะได้รับลิงก์สำหรับรีเซ็ตรหัสผ่าน
                            </span>

                            <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
                                <Form.Item
                                    name="email"
                                    label={
                                        <span style={{ fontWeight: 'bold' }}>
                                            อีเมล
                                        </span>
                                    }
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    required={false}
                                    style={{ textAlign: 'left' }} // Aligns the error message to the left
                                >
                                    <Input placeholder="อีเมล" />
                                </Form.Item>
                                <Button type="primary" htmlType="submit" className="register-form-button" disabled={isButtonDisabled}>
                                    ยืนยัน
                                </Button>
                            </Form>
                        </div>
                    ) : (
                        <div
                        className="register-card2"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <img
                            src={key}
                            alt="key"
                            className="logo-image"
                            style={{ width: '100px', height: 'auto' }}
                        />
                        <p
                            style={{
                                margin: '20px 0 0 0',
                                textAlign: 'center',
                                fontSize: '18px',
                                fontWeight: 'bold',
                            }}
                        >
                            ส่งคำขอในการตั้งค่ารหัสผ่านใหม่
                        </p>
                        <span style={{ display: 'block', margin: '10px 0' }}>
                            ส่งคำขอเปลี่ยนรหัสผ่านในอีเมลของคุณเรียบร้อยแล้ว <br />
                            กรุณาตรวจสอบอีเมลเพื่อดำเนินการต่อ
                        </span>
                        <Button
                            type="primary"
                            className="register-form-button"
                            onClick={goBack}
                        >
                            รับทราบ
                        </Button>
                    </div>
                    
                    )}
                    </div>
                </Col>
            </Row>          
            <LoadingDialog isLoading={isLoading} />

            <FailedDialog
                isModalOpen={isModalFailedOpen}
                closable={false}
                title=""
                detail={errorMessage}
                onCancel={() => {
                    setIsModalFailedOpen(false);
                    setErrorMessage("");
                }}
                onAfterClose={() => {}}
                onConfirm={() => {
                    setIsModalFailedOpen(false);
                    setErrorMessage("");
                }}
            />

            {/* <Modal
                open={isModalVisible}
                onOk={handleOk}
                footer={null}
                closable={false}
                centered
                width={500}
            >
                <div style={{ textAlign: 'center' }}>
                    <CloseCircleOutlined style={{ fontSize: '70px', color: '#E03A3A' }} />
                    <h2 style={{ marginTop: '16px' }}>ไม่สำเร็จ</h2>
                    <p style={{ margin: '16px 0', fontSize: '16px' }}>{errorMessage}</p>
                    <Button type="primary" onClick={handleOk} style={{ backgroundColor: '#E03A3A', borderColor: '#E03A3A' }}>
                        ลองอีกครั้ง
                    </Button>
                </div>
            </Modal> */}
        </div>
    );
};

export default ForgotPage;
