import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./DashboardManagement.css";
import {
  Layout,
  Flex,
  Button,
  Divider,
  Input,
  Menu,
  Switch,
  Space,
  Row,
  Col,
  Tag,
  Grid,
  Dropdown,
} from "antd";
import {
  DeleteButton,
  PinButton,
  SuccessDialog,
  DeleteDialog,
  FailedDialog,
  LoadingDialog,
} from "../../../../../components";
import { AiOutlineSearch } from "react-icons/ai";
import { MenuFoldOutlined } from "@ant-design/icons";
import NoData from "../../../../../assets/img/no_data.js";
import NoImg from "../../../../../assets/img/no_img.png";
import { DashboardService } from "../../../../../services/DashboardService";
import { DashboardCategoryService } from "../../../../../services/DashboardCategoryService";
import { UserService } from "../../../../../services/UserService.js";
import { DataSourceService } from "../../../../../services/DataSourceService.js";

const { useBreakpoint } = Grid;
const { Header, Content, Sider } = Layout;
const DashboardManagement = () => {
  const screens = useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const [select, setSelect] = useState();
  const [selectedMenu, setSelectedMenu] = useState();
  const [dashboardList, setDashboardList] = useState([]);
  const [dashboardCategory, setDashboardCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectDelete, setSelectDelete] = useState();
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [dataSources, setDataSources] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const passData = useLocation();
  let categoryId = "";
  if (passData.state !== null && passData.state.categoryId !== null) {
    categoryId = passData.state.categoryId;
  }
  let keywords = "";
  if (passData.state !== null && passData.state.keyword !== null) {
    keywords = passData.state.keyword;
  }
  let tmpSelectedMenu = null;
  if (passData.state !== null && passData.state.selectedMenu !== null) {
    tmpSelectedMenu = passData.state.selectedMenu;
  }
  const [prevId, setPrevId] = useState(categoryId);

  useEffect(() => {
    const fetchDataSources = async () => {
      try {
        const result = await DataSourceService.getIdName();
        if (Array.isArray(result.items)) {
          setDataSources(result.items);
        } else {
          console.error();
        }
      } catch (error) {
        console.error();
      }
    };
    fetchDataSources();
  }, []);

  useEffect(() => {
    setSelect(categoryId);
    setPrevId(categoryId);
    setKeyword(keywords);
    setSelectedMenu(tmpSelectedMenu);
    UserService.isEditable().then((isEditable) => {
      setIsEditable(isEditable);
    });
  }, []);

  useEffect(() => {
    if (select) {
      fetchData();
    }
  }, [select]);

  useEffect(() => {
    if (!dashboardCategory) {
      fetchDashboardCategory();
    }
  }, [dashboardCategory]);

  useEffect(() => {
    if (keyword) {
      const delayDebounce = setTimeout(() => {
        if (select) {
          fetchData();
        }
      }, 1000);

      return () => clearTimeout(delayDebounce);
    } else if (select) {
      fetchData();
    }
  }, [keyword]);

  const fetchData = async () => {
    try {
      if (select === undefined) {
        return;
      }
      setLoading(true);
      const params = {
        dashboardCategoryIds: select,
        Keywords: keyword,
      };

      const response = await DashboardService.search(params);
      setDashboardList(response);

      const delayPerItem = 500;
      const totalDelay = response?.items?.length * delayPerItem;
      
      setTimeout(() => {
        setLoading(false);
      }, totalDelay);

    } catch (error) {
      setLoading(false);
      console.error();
    } finally {
      setLoading(false);
    }
  };

  const fetchDashboardCategory = async () => {
    try {
      setLoading(true);

      const params = {
        // Keywords: ,
      };

      const response = await DashboardCategoryService.search(params);
      setDashboardCategory(response);

      if (!categoryId) {
        if (response?.items?.length !== 0) {
          setSelect(response?.items[0].id);
          setPrevId(response?.items[0].id);
          setSelectedMenu(response?.items[0]);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error();
    }
  };

  const handleUpdateActivate = async (checked, item) => {
    try {
      setLoading(true);
      const data = {
        id: item.id,
        isActivated: checked,
      };

      await DashboardService.updateActivated(item.id, data);
      fetchData();
    } catch (error) {
      console.error();
      setLoading(false);
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    }
  };

  const handleUpdatePinned = async (checked, item) => {
    setLoading(true);
    setPinLoading(item.id);
    try {
      const data = {
        id: item.id,
        isPinned: checked,
      };

      await DashboardService.updatePinned(item.id, data);
      setPinLoading(false);
      fetchData();
    } catch (error) {
      setPinLoading(false);
      console.error();
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    }
  };

  const handleDeleteDashboardById = async () => {
    try {
      setLoading(true);
      await DashboardService.delete(selectDelete);
      setSelectDelete();
      setIsModalDeleteOpen(false);
      setIsModalSuccessOpen(true);
      setLoading(false);
      setTimeout(() => {
        setIsModalSuccessOpen(false);
      }, 2000);
      fetchDashboardCategory();
      fetchData();
    } catch (error) {
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
      console.error();
      setLoading(false);
    }
  };

  const handleSearchDashboard = () => {
    fetchData();
  };

  return (
    <div className="dashboard-management-body">
      <Layout>
        <Header
          style={{
            height: "70px",
            background: "#FFF",
            borderBottom: "1px solid #D0D5DD",
          }}
        >
          <Flex
            justify="space-between"
            align="center"
            style={{ height: "100%" }}
          >
            <p
              style={{
                fontSize: screens.xs ? "16px" : "20px",
                fontWeight: "600",
              }}
            >
              จัดการข้อมูลแดซบอร์ด
            </p>
            {isEditable && (
              <Button
                type="primary"
                onClick={() =>
                  navigate(
                    {
                      pathname: `/management/dashboard-management-detail`,
                    },
                    {
                      state: {
                        categoryId: select,
                        keyword: keyword,
                        selectedMenu: selectedMenu,
                      },
                    }
                  )
                }
                style={{
                  width: screens.xs ? "100px" : "140px",
                  height: "40px",
                  fontSize: screens.xs ? "14px" : "16px",
                  fontWeight: "500",
                  background: "#63E1E1",
                  borderRadius: "10px",
                }}
              >
                + เพิ่ม/แก้ไข
              </Button>
            )}
          </Flex>
        </Header>
        <Layout>
          <Sider
            width={!screens.xl ? 0 : 302}
            style={{
              margin: !screens.xl ? "0px 14px 0px 0px" : "14px 14px 0px 14px",
              background: "#FFF",
              borderRadius: "8px",
              height: "calc(100vh - 200px)",
              overflowY: "hidden",
            }}
          >
            <div style={{ padding: "14px" }}>
              <p style={{ margin: "0", fontSize: "16px", fontWeight: "600" }}>
                ประเภทแดซบอร์ด ({dashboardCategory?.pagination?.totalCount || 0}
                )
              </p>
              <Input
                placeholder="ค้นหา ชื่อแดซบอร์ด..."
                allowClear
                suffix={
                  <AiOutlineSearch
                    onClick={() => handleSearchDashboard()}
                    style={{
                      color: "rgba(0,0,0,.45)",
                      cursor: "pointer",
                    }}
                  />
                }
                id="dashboard-search"
                onChange={(e) => setKeyword(e.target.value)}
                onPressEnter={() => handleSearchDashboard()}
                size="large"
                style={{ marginTop: "14px", borderRadius: "8px" }}
              />
            </div>
            <Divider style={{ margin: "0", borderColor: "#D0D5DD" }} />
            {dashboardCategory && dashboardCategory?.items?.length !== 0 ? (
              <div
                style={{
                  padding: "14px",
                  height: "calc(100% - 110px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#999 #f5f5f5",
                  scrollbarTrackColor: "#f5f5f5",
                }}
              >
                <Menu
                  // mode="inline"
                  defaultSelectedKeys={["1"]}
                  selectedKeys={prevId}
                  style={{
                    height: "100%",
                  }}
                  items={
                    dashboardCategory &&
                    dashboardCategory?.items?.length !== 0 &&
                    dashboardCategory?.items
                      ?.sort((a, b) => a.sequence - b.sequence)
                      .map((item, index) => ({
                        key: item.id,
                        onClick: () => {
                          setSelect(item.id);
                          setPrevId(item.id);
                          setSelectedMenu(item);
                        },
                        style: {
                          paddingLeft: "14px",
                        },
                        label: (
                          <span style={{ fontSize: "14px", fontWeight: "500" }}>
                            {item.name} ({item?.count ?? 0})
                          </span>
                        ),
                      }))
                  }
                ></Menu>
              </div>
            ) : (
              <Flex
                vertical
                justify="center"
                align="center"
                style={{ height: "70%" }}
              >
                <NoData />
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#667085",
                  }}
                >
                  ไม่มีข้อมูล
                </p>
              </Flex>
            )}
          </Sider>

          <Content
            style={{
              margin: "14px",
              marginLeft: "0",
              overflowY: "auto",
              marginTop: "14px",
              borderRadius: "8px",
            }}
          >
            {!screens.xl && (
              <div
                className="custom-dropdown"
                style={{
                  // position: "absolute",
                  // left: "15px",
                  // marginTop: "10px",
                  // width: "calc(100vw - 28px)",
                  // zIndex: 2000,
                  marginBottom: "10px",
                }}
              >
                <Dropdown
                  placement="bottomRight"
                  trigger={["click"]}
                  // overlayStyle={{ zIndex: 2000 }}
                  dropdownRender={(menu) => (
                    <Menu
                      className="dropdown-menu"
                      theme="light"
                      mode="vertical"
                      style={{
                        width: 262,
                        minHeight: 100,
                        padding: "23px 12px 23px 12px",
                        // marginTop: "10px",
                        // marginRight: "-10px",
                        fontSize: "16px",
                      }}
                      triggerSubMenuAction="click"
                      items={
                        dashboardCategory &&
                        dashboardCategory?.items?.length !== 0 &&
                        dashboardCategory?.items
                          ?.sort((a, b) => a.sequence - b.sequence)
                          .map((item, index) => ({
                            key: item.id,
                            onClick: () => {
                              setSelect(item.id);
                              setPrevId(item.id);
                              setSelectedMenu(item);
                            },
                            style: {
                              paddingLeft: "14px",
                            },
                            label: (
                              <span
                                style={{ fontSize: "14px", fontWeight: "500" }}
                              >
                                {item.name} ({item?.count ?? 0})
                              </span>
                            ),
                          }))
                      }
                    ></Menu>
                  )}
                >
                  <Button
                    type="default"
                    htmlType="button"
                    className="dropdown-button"
                    style={{
                      width: "100%",
                      textAlign: "left",
                      fontWeight: 500,
                      fontSize: "16px",
                      height: "40px",
                    }}
                  >
                    <MenuFoldOutlined />

                    {selectedMenu
                      ? `${selectedMenu.name} (${selectedMenu.count})`
                      : `ประเภทแดซบอร์ด (${
                          dashboardCategory?.items?.length ?? 0
                        })`}
                  </Button>
                </Dropdown>
              </div>
            )}
            {select ? (
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "calc(100vh - 200px)",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#999 #f5f5f5",
                  scrollbarTrackColor: "#f5f5f5",
                  borderRadius: "8px",
                }}
              >
                {dashboardList?.items?.length !== 0 ? (
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{
                      display: "flex",
                    }}
                  >
                    {dashboardList?.items
                      ?.sort((a, b) => a.sequence - b.sequence)
                      .map((item) => (
                        <div
                          key={item.id}
                          style={{
                            padding: "14px",
                            background: "#FFF",
                            borderRadius: "8px",
                          }}
                        >
                          <Flex justify="space-between" align="center">
                            <span
                              style={{ fontSize: "14px", fontWeight: "600" }}
                            >
                              ใช้งาน :
                              <span style={{ margin: "10px" }}>
                                <Switch
                                  // defaultChecked={item?.isPinned}
                                  disabled={isEditable === false}
                                  checked={item?.isActivated}
                                  onChange={(e) =>
                                    handleUpdateActivate(e, item)
                                  }
                                />
                              </span>
                            </span>
                            <Space>
                              <PinButton
                                loading={pinLoading === item.id}
                                isEditable={isEditable}
                                onClick={() =>
                                  handleUpdatePinned(!item.isPinned, item)
                                }
                                isPinned={item?.isPinned}
                              />
                              {isEditable && !item?.isPreserved && (
                                <DeleteButton
                                  isEditable={isEditable}
                                  onClick={() => {
                                    setSelectDelete(item.id);
                                    setIsModalDeleteOpen(true);
                                  }}
                                />
                              )}
                            </Space>
                          </Flex>
                          <Divider style={{ borderColor: "#D0D5DD" }} />
                          <p style={{ fontSize: "16px", fontWeight: "600" }}>
                            ชื่อแดซบอร์ด
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#667085",
                              marginTop: "-14px",
                            }}
                          >
                            {item?.name || "-"}
                          </p>
                          <p style={{ fontSize: "16px", fontWeight: "600" }}>
                            URL แดซบอร์ด
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#667085",
                              marginTop: "-14px",
                            }}
                          >
                            {`${window.location.origin}/dashboard/${
                              item?.urlSlug || "-"
                            }`}
                          </p>
                          <p style={{ fontSize: "16px", fontWeight: "600" }}>
                            รายละเอียดโดยย่อ
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#667085",
                              marginTop: "-14px",
                            }}
                          >
                            {item?.shortDescription || "-"}
                          </p>
                          <p style={{ fontSize: "16px", fontWeight: "600" }}>
                            รายละเอียด
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#667085",
                              marginTop: "-14px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item?.description || "-",
                            }}
                          ></p>
                          <p style={{ fontSize: "16px", fontWeight: "600" }}>
                            อัปโหลดภาพ
                          </p>
                          {item?.thumbnailSrc ? (
                            <img
                              alt="example"
                              src={item?.thumbnailSrc}
                              onError={(e) => {
                                e.target.src = NoImg;
                              }}
                              width={204}
                              style={{
                                padding: "6px",
                                border: "1px solid #D9D9D9",
                                borderRadius: "2px",
                                marginTop: "-14px",
                              }}
                            />
                          ) : (
                            "-"
                          )}
                          <p style={{ fontSize: "16px", fontWeight: "600" }}>
                            URL แดชบอร์ดต้นทาง
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#667085",
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                              lineBreak: "anywhere",
                              marginTop: "-14px",
                            }}
                          >
                            {item?.embedSrc || "-"}
                          </p>
                          <Row gutter={16}>
                            <Col span={12}>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                เลือก Role ที่ต้องการแสดง
                              </p>
                              <Flex
                                wrap
                                gap="small"
                                style={{ marginTop: "-14px" }}
                              >
                                {item?.dashboardPermissions &&
                                item?.dashboardPermissions?.length !== 0
                                  ? item?.dashboardPermissions?.map((role) => (
                                      <Tag
                                        key={item.id + role.id}
                                        style={{
                                          borderRadius: "8px",
                                          padding: "6px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {role.roleName}
                                      </Tag>
                                    ))
                                  : "-"}
                              </Flex>
                            </Col>

                            <Col span={12}>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                Tag
                              </p>
                              <Flex
                                wrap
                                gap="small"
                                style={{ marginTop: "-14px" }}
                              >
                                {item?.dashboardTags &&
                                item?.dashboardTags?.length !== 0
                                  ? item?.dashboardTags.map((tag) => (
                                      <Tag
                                        key={tag.id + item.id}
                                        bordered={false}
                                        color={tag?.tagColor}
                                        style={{
                                          borderRadius: "8px",
                                          padding: "6px",
                                        }}
                                      >
                                        {tag?.tagName}
                                      </Tag>
                                    ))
                                  : "-"}
                              </Flex>
                            </Col>
                          </Row>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            เลือกดาต้าแคดตาล็อคที่ต้องการเชื่อมกับแดซบอร์ด
                          </p>
                          <Flex wrap gap="small" style={{ marginTop: "-14px" }}>
                            {item?.dashboardDataSources &&
                            item?.dashboardDataSources.length !== 0
                              ? item?.dashboardDataSources?.map((source) => {
                                  const matchedDataSource = dataSources.find(
                                    (ds) => ds.id === source.dataSourceId
                                  );
                                  return matchedDataSource ? (
                                    <Tag
                                      key={source.id}
                                      style={{
                                        borderRadius: "8px",
                                        padding: "6px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {matchedDataSource.name}
                                    </Tag>
                                  ) : null;
                                })
                              : "-"}
                          </Flex>
                        </div>
                      ))}
                  </Space>
                ) : (
                  <Flex
                    vertical
                    justify="center"
                    align="center"
                    style={{
                      height: "calc(100vh - 200px)",
                      background: "#fff",
                      borderRadius: "8px",
                    }}
                  >
                    <NoData />
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#667085",
                      }}
                    >
                      ไม่มีข้อมูล
                    </p>
                  </Flex>
                )}
              </div>
            ) : (
              <Flex
                vertical
                justify="center"
                align="center"
                style={{
                  height: "calc(100vh - 200px)",
                  background: "#fff",
                  borderRadius: "8px",
                }}
              >
                <NoData />
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#667085",
                  }}
                >
                  {!keyword
                    ? "กรุณาเลือกประเภทก่อนเพิ่มข้อมูล"
                    : !select
                    ? "กรุณาเลือกประเภทก่อนเพิ่มข้อมูล"
                    : "ไม่มีข้อมูลที่ค้นหา"}
                </p>
              </Flex>
            )}
          </Content>
        </Layout>
      </Layout>

      <LoadingDialog isLoading={loading} />

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        type="bin"
        title="สำเร็จ"
        detail="ลบข้อมูลสำเร็จแล้ว"
        onCancel={() => {
          setIsModalSuccessOpen(false);
        }}
        onAfterClose={() => {
          setIsModalSuccessOpen(false);
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title=""
        detail={errorMessage?.messageTh}
        onCancel={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
        onAfterClose={() => {}}
        onConfirm={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
      />

      <DeleteDialog
        isModalOpen={isModalDeleteOpen}
        closable={false}
        title="ยืนยันการลบ"
        description="คุณต้องการลบใช่หรือไม่?"
        cancelText="ยกเลิก"
        confirmText="ลบ"
        onCancel={() => {
          setSelectDelete();
          setIsModalDeleteOpen(false);
        }}
        onConfirm={() => {
          handleDeleteDashboardById();
        }}
      />
    </div>
  );
};

export default DashboardManagement;
