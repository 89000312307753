import "./connect-api.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Tabs, Card, Input, Tooltip, Radio, Space} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CopyOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;
const ConnectAPI = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("1"); // สำหรับเก็บ tab ปัจจุบัน
    const [consentData, setConsentData] = useState(null); // เก็บข้อมูลการยินยอม
    const [consentDate, setConsentDate] = useState("ไม่ระบุ"); // วันที่ยินยอมล่าสุด
    const [inputValue, setInputValue] = useState("");
    const [tabPosition, setTabPosition] = useState('left');
    const changeTabPosition = (e) => {
      setTabPosition(e.target.value);
    };

    useEffect(() => {
        // Mock การโหลดข้อมูลการยินยอม
        setTimeout(() => {
            setConsentData({
                name: "หัวข้อคำยินยอม",
                content: "เนื้อหาคำยินยอมที่ผู้ใช้งานต้องยอมรับ",
            });
            setConsentDate("20/12/2024");
        }, 1000); // โหลดข้อมูลหลังจาก 1 วินาที
    }, []);

    const disagree = () => {
        console.log("ไม่ยอมรับคำยินยอม");
        // เพิ่มการทำงานเมื่อผู้ใช้ไม่ยอมรับ
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(inputValue).then(() => {
            // // Optionally, you can show a tooltip or message to the user that the text was copied
            // alert('คัดลอกข้อมูลสำเร็จ!');
        }).catch((error) => {
            console.error('Error copying text: ', error);
        });
    };


    return (
        <>
            <div className="requestapinew-body">
                <div className="heading">
                    <div className="content-card-title">
                        <ArrowLeftOutlined className="arrow-back" onClick={() => navigate(`/management/request-api`)} />
                        <span style={{ marginLeft: 10 }}>ขอใช้ API</span>
                    </div>
                </div>

                {/* การ์ดครอบแท็บ */}
                <Card className="tabs-card">
                    <Tabs
                        activeKey={activeTab}
                        onChange={setActiveTab}
                        style={{ marginBottom: '12px' }}
                    >
                        <TabPane tab={<span className="tab-title">ตั้งค่าความยินยอมของคุณ</span>} key="1" />
                        <TabPane tab={<span className="tab-title">คู่มือการใช้งาน</span>} key="2" />
                    </Tabs>
                </Card>

                <Card className="selected-tab-card">
                    <h2>การเข้าถึง API</h2>
                    <Card className="inner-card" style={{ border: '1px solid #D0D5DD' }}>
                        <p className="selected-tab-text">
                            {activeTab === "1" ? (
                                <div>
                                    <span>ค่าเริ่มต้น</span>
                                    <hr />
                                    <br />
                                    <div className="api-input-group">
                                        <label htmlFor="client-id">รหัสผู้ใช้งาน (Client ID)</label>
                                        <Input
                                            id="client-id"
                                            placeholder="กรอกรหัสผู้ใช้งาน"
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            suffix={
                                                <Tooltip title="คัดลอก">
                                                    <CopyOutlined
                                                        onClick={copyToClipboard}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </Tooltip>
                                            }
                                            className="api-input"
                                        />
                                    </div>
                                    <div className="api-input-group">
                                        <label htmlFor="client-secret">รหัสลับ (Client Secret)</label>
                                        <Input.Password id="client-secret" placeholder="กรอกรหัสลับ" className="api-input" />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    Top this page
                                    <Tabs
                                        tabPosition={tabPosition}
                                        items={new Array(3).fill(null).map((_, i) => {
                                            const id = String(i + 1);
                                            return {
                                                label: `Tab ${id}`,
                                                key: id,
                                                children: `Content of Tab ${id}`,
                                            };
                                        })}
                                    />
                                </div>
                            )}
                        </p>
                    </Card>
                </Card>

            </div>
        </>
    );
};

export default ConnectAPI;
