import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./helpers/PrivateRoute";
import Callback from "./pages/OAuth/Callback";
import NotFound from "./pages/NotFound/NotFound";
import NetworkError from "./pages/NetworkError/NetWorkError";

// Main
import Layout from "./pages/Main/Layout/Layout";
import Home from "./pages/Main/Home/Home";
import About from "./pages/Main/About/About";
import Dashboard from "./pages/Main/Dashboard/Dashboard";
import InsightReport from "./pages/Main/InsightReport/InsightReport";
import Contact from "./pages/Main/Contact/Contact";

// Management
import ManagementLayout from "./pages/Main/Layout/ManagementLayout";
import AccountManagement from "./pages/Main/Management/User/AccountManagement/AccountManagement";
import RoleManagement from "./pages/Main/Management/Admin/RoleManagement/RoleManagement";
import DashboardManagement from "./pages/Main/Management/Admin/DashboardManagement/DashboardManagement";
import DashboardManagementDetail from "./pages/Main/Management/Admin/DashboardManagement/DashboardManagementDetail";
import InsightReportManagementNav from "./pages/Main/Management/Admin/InsightReportManagement/InsightReportManagementNav";
import InsightReportManagementDetail from "./pages/Main/Management/Admin/InsightReportManagement/InsightReportManagementDetail";
import DataSourceManagement from "./pages/Main/Management/Admin/DataSourceManagement/DataSourceManagement";
import DataSourceManagementDetail from "./pages/Main/Management/Admin/DataSourceManagement/DataSourceManagementDetail";
import RequestApiManagement from "./pages/Main/Management/Admin/RequestApiManagement/RequestApiManagement";
import TagManagement from "./pages/Main/Management/Admin/TagManagement/TagManagement";

// Ocelot-Config
// import GlobalConfigure from "./pages/OcelotConfigure/GlobalConfigure/GlobalConfigure";
// import AuthenticationProvidersList from "./pages/OcelotConfigure/AuthenticationProviderOcelot/AuthenticationProvidersList";
// import RoutesOcelot from "./pages/OcelotConfigure/RoutesOcelot/RoutesOcelot";
// import SwaggerDoc from "./pages/OcelotConfigure/SwaggerDoc/SwaggerDoc";

import Template from "./pages/Template/Template";
import LoginPage from "./pages/Main/Login/login";
import RegisterPage from "./pages/Main/register/register";
import ForgotPage from "./pages/Main/forgot/forgot";
import VerifyEmailPage from "./pages/Main/register/verify_email/verify_email";
import VerifyEmailTimeoutPage from "./pages/Main/register/verify_timeout/verify_timeout";
import EditPasswordPage from "./pages/Main/EditPassword/edit_password";
import RegisterGooglePage from "./pages/Main/register/regisgoogle/regis_google";
import DetailDashboard from "./pages/Main/Dashboard/DetailDashBoard/DetailDashBoard";
import InsightReportPage from "./pages/Main/InsightReport/InsightReportDetail/InsightReportDetail";
import ChangePassword from "./pages/Main/Management/User/ChangePassword/ChangePassword";
import ManageUserPage from "./pages/Main/Management/Admin/ManageUser/ManageUser";
import ManageUserDetail from "./pages/Main/Management/Admin/ManageUser/ManageUserDetail/ManageUserDetail";
import DataCatalog from "./pages/Main/DataCatalog/DataCatalog";
import DataCatalogDetail from "./pages/Main/DataCatalog/DataCatalogDetail/DataCatalogDetail";
import TradeAssociation from "./pages/Main/TradeAssociation/TradeAssociation";
import MangeConsent from "./pages/Main/Management/Admin/MangeConsent/ManageConsent";
import MangeConsentDetail from "./pages/Main/Management/Admin/MangeConsent/mangeconsent-detail/MangeconsentDetail";
import FeedbackPage from "./pages/Main/Management/Admin/Feedback/feedback";
import NotAllow from "./pages/NotAllow/NotAllow";
import PageStateRoute from "./helpers/PageStateRoute";
import SurveyPage from "./pages/Main/Feedback/Survey";
import DefaultRoute from "./helpers/DefaultRoute";
import ScrollToTop from "./helpers/ScrollToTop";
import RequestApiNew from "./pages/Main/DataCatalog/RequestApi/RequestApiNew";
import RequestApiMain from "./pages/Main/DataCatalog/RequestApi/RequestApiMain";
import FileUploadCard from "./pages/Main/Management/Admin/TermsAndConditions/terms-and-conditions";

import ConfirmConsent from "./pages/Main/Login/confirm_consent";
import ConsentSettings from "./pages/Main/Management/User/ConsentSetting/consent-setting";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from "dayjs/plugin/buddhistEra";
import RequestAPI from "./pages/Main/RequestAPI/requestAPI";
import ConnectAPI from "./pages/Main/RequestAPI/connectAPI/connect-api";
import ViewConsent from "./pages/Main/Management/Admin/MangeConsent/ViewConsent";
import InsightReportTag from "./pages/Main/InsightReport/InsightReportTag";
import DashboardCategory from "./pages/Main/Dashboard/DashboardCategory";
import Privacy from "./pages/Main/Home/Privacy-Terms/Privacy";
import Terms from "./pages/Main/Home/Privacy-Terms/Terms";
import TermsPrivacyManagement from "./pages/Main/Management/Admin/TermsAndConditions/terms-privacy";
import { notification } from "antd";
import DataCatalogFilter from "./pages/Main/DataCatalog/DataCatalogFilter";

function App() {

  sessionStorage.removeItem("isChanged");

  dayjs.extend(buddhistEra);
  dayjs.locale('th');


  notification.useNotification({
    maxCount: 1,
    stack: { limit: 1 },
  })

  return (
    <BrowserRouter>
      <ScrollToTop />
      <DefaultRoute>
        <Routes>
          <Route path="/oauth/callback" element={<Callback />} />
          <Route path="*" element={<NotFound />}></Route>
          <Route path="/network-error" element={<NetworkError />}></Route>
          <Route path="/not-allowed" element={<NotAllow />}></Route>

          {/* <Route path="/template" element={<Template />} /> */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot" element={<ForgotPage />} />
          <Route path="/verifyemail" element={<VerifyEmailPage />} />
          <Route path="survey" element={<SurveyPage />} />
          <Route
            path="/verifyemailtimeout"
            element={<VerifyEmailTimeoutPage />}
          />
          <Route path="/editpassword" element={<EditPasswordPage />} />
          <Route path="/registergoogle" element={<RegisterGooglePage />} />
          <Route path="/confirm-consent" element={<ConfirmConsent />} />


          <Route path="/" element={
            <PageStateRoute>
              <Layout />
            </PageStateRoute>
          }>

            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms-and-condition" element={<Terms />} />
            <Route
              path="dashboard"
              element={
                // <PrivateRoute>
                <Dashboard />
                // </PrivateRoute>
              }
            />
            <Route
              path="dashboard/:title"
              element={
                // <PrivateRoute>
                <DetailDashboard />
                // </PrivateRoute>
              }
            />
            <Route
              path="DashboardCategory"
              element={
                // <PrivateRoute>
                <DashboardCategory />
                // </PrivateRoute>
              }
            />
            <Route
              path="connect-api"
              element={
                // <PrivateRoute>
                <ConnectAPI />
                // </PrivateRoute>
              }
            />
            <Route
              path="insight-report"
              element={
                // <PrivateRoute>
                <InsightReport />
                // </PrivateRoute>
              }
            />
            <Route
              path="data-catalog"
              element={
                // <PrivateRoute>
                <DataCatalog />
                // </PrivateRoute>
              }
            />
            <Route
              path="data-catalog-filter"
              element={<DataCatalogFilter />}
            />
            <Route
              path="data-catalog/:title"
              element={
                // <PrivateRoute>
                <DataCatalogDetail />
                // </PrivateRoute>
              }
            />
            <Route
              path="insight-report/:title"
              element={
                // <PrivateRoute>
                <InsightReportPage />
                // </PrivateRoute>
              }
            />
            <Route
              path="insight-reportTag"
              element={
                // <PrivateRoute>
                <InsightReportTag />
                // </PrivateRoute>
              }
            />
            <Route
              path="trade-association"
              element={
                // <PrivateRoute>
                <TradeAssociation />
                // </PrivateRoute>
              }
            />
            <Route
              path="new-request-api"
              element={
                // <PrivateRoute>
                <RequestApiNew />
                // </PrivateRoute>
              }
            />
            <Route
              path="request-api"
              element={
                // <PrivateRoute>
                <RequestApiMain />
                // </PrivateRoute>
              }
            />

            <Route path="management" element={<ManagementLayout />}>
              <Route
                index
                element={
                  <PrivateRoute>
                    <AccountManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="account-management"
                element={
                  <PrivateRoute>
                    <AccountManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="consent-setting"
                element={
                  <PrivateRoute>
                    <ConsentSettings />
                  </PrivateRoute>
                }
              />
              <Route
                path="change-password"
                element={
                  <PrivateRoute>
                    <ChangePassword />
                  </PrivateRoute>
                }
              />
              <Route
                path="role-management"
                element={
                  <PrivateRoute>
                    <RoleManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="dashboard-management"
                element={
                  <PrivateRoute>
                    <DashboardManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="dashboard-management-detail"
                element={
                  <PrivateRoute>
                    <DashboardManagementDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="insight-report-management"
                element={
                  <PrivateRoute>
                    <InsightReportManagementNav />
                  </PrivateRoute>
                }
              />
              <Route
                path="insight-report-management-detail"
                element={
                  <PrivateRoute>
                    <InsightReportManagementDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="datasource-management"
                element={
                  <PrivateRoute>
                    <DataSourceManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="datasource-management-detail"
                element={
                  <PrivateRoute>
                    <DataSourceManagementDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="datasource-request"
                element={
                  <PrivateRoute>
                    <RequestApiManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-tag"
                element={
                  <PrivateRoute>
                    <TagManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="manage-user"
                element={
                  <PrivateRoute>
                    <ManageUserPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="manage-user-detail"
                element={
                  <PrivateRoute>
                    <ManageUserDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="manage-consent"
                element={
                  <PrivateRoute>
                    <MangeConsent />
                  </PrivateRoute>
                }
              />
              <Route
                path="manage-consent-detail"
                element={
                  <PrivateRoute>
                    <MangeConsentDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="view-consent"
                element={
                  <PrivateRoute>
                    <ViewConsent />
                  </PrivateRoute>
                }
              />
              <Route
                path="feedback"
                element={
                  <PrivateRoute>
                    <FeedbackPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="terms-and-conditions"
                element={
                  <PrivateRoute>
                    <TermsPrivacyManagement />
                  </PrivateRoute>
                }
              />
              
              <Route
                path="request-api"
                element={
                  <PrivateRoute>
                    <RequestApiMain />
                  </PrivateRoute>
                }
              />

            </Route>
          </Route>
        </Routes>
      </DefaultRoute>
    </BrowserRouter>
  );
}

export default App;
