import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ArrowLeftOutlined, ShareAltOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Tag, Modal, Row, Col, Input } from 'antd';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import IconRightArrowViewDashboard from "../../../../assets/icon/icon_right_arrow_view_dashboard.js";
import NoImg from "../../../../assets/img/no_img.png";
import CustomFooter from "../../../../components/Footer/Footer.js";
import "./DetailDashBoard.css";
import { DashboardService } from "../../../../services/DashboardService.js";
import { InsightReportService } from "../../../../services/InsightReportService.js";
import { useLocation } from 'react-router-dom';
import { FacebookShareButton, LineShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import icon_fb from "../../../../assets/icon/svg/icon_fb.svg";
import icon_linkedin from "../../../../assets/icon/svg/icon_linkedin.svg";
import icon_x from "../../../../assets/icon/svg/icon_x.svg";
import icon_line from "../../../../assets/icon/svg/icon_line.svg";
import { FailedDialog, LoadingDialog } from '../../../../components';
import AffilateButton from "../../../../components/Button/AffilateButton.js";
import ErrorMessageHandler from "../../../../helpers/ErrorMessageHandler.js";
import { useCookies } from "react-cookie";


const DetailDashboard = ({ id }) => {

    const [cookieInsightReport, setCookieInsightReport] = useCookies(["insight-report-condition"]);
    const [cookieDashboard, setCookieDashboard] = useCookies(["dashboard-condition"]);
    const location = useLocation();
    const navigate = useNavigate();
    const { id: urlId } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNotAllow, setIsNotAllow] = useState(false);
    const [dashboardData, setDashboardData] = useState({})
    const [dashboardDataList, setDashboardDataList] = useState([]);
    const [insightDataList, setInsightDataList] = useState([]);
    const [isCopied, setIsCopied] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0); // index ปัจจุบั
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const itemsPerPage = 4;
    const itemsPerInsightPage = 4;
    const [zoomScale, setZoomScale] = useState(1);
    const [dashboardDataSources, setDashboardDataSources] = useState([]);

    function isValidURL(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }
    function isLocalhost(url) {
        return url.includes("localhost");
    }

    useEffect(() => {

        const handleWheel = (event) => {
            if (event.ctrlKey) {
                const newScale = Math.max(0.5, Math.min(2, 1 / window.devicePixelRatio));
                setZoomScale(newScale);
            }
        };
        window.addEventListener("wheel", handleWheel);

        return () => {
            window.removeEventListener("wheel", handleWheel);
        };
    }, []);




    const handleNext = () => {
        if (currentIndex + itemsPerPage < dashboardDataList.length) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    };

    const handlePrev = () => {
        if (currentIndex - itemsPerPage >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };

    const handleNext2 = () => {
        if (currentIndex2 + itemsPerInsightPage < insightDataList.length) {
            setCurrentIndex2(currentIndex2 + itemsPerInsightPage);
        }
    };

    const handlePrev2 = () => {
        if (currentIndex2 - itemsPerInsightPage >= 0) {
            setCurrentIndex2(currentIndex2 - itemsPerInsightPage);
        }
    };

    const displayedData = dashboardDataList.slice(currentIndex, currentIndex + itemsPerPage);
    const displayedData2 = insightDataList.slice(currentIndex2, currentIndex2 + itemsPerInsightPage);


    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };


    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const title = decodeURI(window.location.pathname.split("/").pop());
                if (title && title !== "dashboard") {
                    const response = await DashboardService.get(title); // ยิง API

                    // Set thumbnail images for social media sharing
                    const ogImage = document.querySelector(`meta[property="og:image"]`);
                    if (ogImage) {
                        ogImage.setAttribute("content", response.thumbnailSrc || NoImg);
                    }
                    const ogUrl = document.querySelector(`meta[property="og:url"]`);
                    if (ogUrl) {
                        ogUrl.setAttribute("content", window.location.href);
                    }
                    const metaImage = document.querySelector(`meta[name="twitter:image"]`);
                    if (metaImage) {
                        metaImage.setAttribute("content", response.thumbnailSrc || NoImg);
                    }
                    setDashboardData(response);
                    // Set dashboardDataSources if available
                    if (Array.isArray(response.dashboardDataSources)) {
                        setDashboardDataSources(response.dashboardDataSources); // Set dashboardDataSources
                    } else {
                        console.error("dashboardDataSources is not an array or is undefined");
                        setDashboardDataSources([]); // Set an empty array if no valid data
                    }

                    const tagIdObjects = response.dashboardTags.map((tag) => ({ tagId: tag.tagId }));
                    const tagIds = tagIdObjects.map((obj) => obj.tagId);
                    const responseList = await DashboardService.getDataListTag(tagIds);
                    if (Array.isArray(responseList.items)) {
                        setDashboardDataList(responseList.items);
                    } else {
                        console.error("responseList.items is not an array");
                        setDashboardDataList([]);
                    }

                    const idDashboard = response.id;
                    const responseList2 = await InsightReportService.getDataList(idDashboard);
                    if (Array.isArray(responseList2.items)) {
                        setInsightDataList(responseList2.items);
                    } else {
                        console.error("responseList.items is not an array");
                        setInsightDataList([]);
                    }
                } else {
                    console.error("No ID found in sessionStorage or URL");
                }
            } catch (err) {
                console.error("Error fetching dashboard data:", err);
                if (err.response?.status === 404) {
                    navigate("/not-found");
                }
            } finally {
                setIsLoading(false); // ปิดสถานะโหลด
            }
        };

        const title = decodeURI(window.location.pathname.split("/").pop());
        DashboardService.validatePermission(title).then((allow) => {
            if (allow) {
                fetchDashboardData(); // Call the fetch function if permission is granted
            } else {
                var hasAccessToken = sessionStorage.getItem('accessToken') !== null;
                if (!hasAccessToken) {
                    localStorage.setItem('pageState', location.pathname);
                    navigate('/login');
                } else {
                    navigate('/not-allowed');
                }
            }
        }).catch((error) => {
            if (error.response?.status === 404) {
                if (sessionStorage.getItem('accessToken') === null) {
                    localStorage.setItem('pageState', `/dashboard/${encodeURIComponent(title)}`);
                    navigate('/login');
                    return;
                }
                navigate('/not-allowed');
            } else {
                ErrorMessageHandler(error);
            }
        });
    }, [location.pathname, urlId]);

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const title = decodeURI(window.location.pathname.split("/").pop());
                if (title) {
                    const response = await DashboardService.get(title); // ยิง API

                    // Set thumbnail images for social media sharing
                    const ogImage = document.querySelector(`meta[property="og:image"]`);
                    if (ogImage) {
                        ogImage.setAttribute("content", response.thumbnailSrc || NoImg);
                    }
                    const metaImage = document.querySelector(`meta[name="twitter:image"]`);
                    if (metaImage) {
                        metaImage.setAttribute("content", response.thumbnailSrc || NoImg);
                    }
                    setDashboardData(response);

                    // Check if response.dashboardDataSources is valid
                    // console.log("Received dashboardDataSources:", response.dashboardDataSources);

                    // Set dashboardDataSources if available
                    if (Array.isArray(response.dashboardDataSources)) {
                        setDashboardDataSources(response.dashboardDataSources); // Set dashboardDataSources
                    } else {
                        console.error("dashboardDataSources is not an array or is undefined");
                        setDashboardDataSources([]); // Set an empty array if no valid data
                    }
                } else {
                    console.error("No ID found in sessionStorage or URL");
                }
            } catch (err) {
                console.error("Error fetching dashboard data:", err);
                if (err.response?.status === 404) {
                    navigate("/not-found");
                }
            } finally {
                setIsLoading(false); // ปิดสถานะโหลด
            }
        };

        fetchDashboardData();
    }, []);



    const dataSourceIds = dashboardData?.dashboardDataSources?.map((source) => source.dataSourceId) || [];
    const handleBackClick = () => {
        navigate('/dashboard');  // Navigate back to the /dashboard page
    }

    const handleClickInsight = (id, urlSlug) => {
        navigate(`/insight-report/${encodeURIComponent(urlSlug)}`);
        window.scrollTo(0, 0);
    };

    const handleClickDashbord = (id, urlSlug) => {
        const Name = encodeURIComponent(urlSlug);
        DashboardService.validatePermission(urlSlug).then((allow) => {
            if (allow) {
                navigate(`/dashboard/${Name}`);
                window.scrollTo(0, 0);
            } else {
                var hasAccessToken = sessionStorage.getItem('accessToken') !== null;
                if (!hasAccessToken) {
                    localStorage.setItem('pageState', `/dashboard/${Name}`);
                    navigate('/login');
                } else {
                    setIsNotAllow(true);
                }
            }
        }).catch((error) => {
            if (error.response.status === 404) {
                if (sessionStorage.getItem('accessToken') === null) {
                    localStorage.setItem('pageState', `/dashboard/${encodeURIComponent(urlSlug)}`);
                    navigate('/login');
                    return;
                }
                setIsNotAllow(true);
            } else {
                ErrorMessageHandler(error);
            }
        });
    };

    const handleTagClick = (tagId, tagName) => {
        if (localStorage.getItem('cookie') === 'true') {
            if (cookieDashboard['dashboard-condition'] !== undefined) {
                var condition = cookieDashboard['dashboard-condition'];
                condition = {};
                condition.TagIds = tagId;
                setCookieDashboard('dashboard-condition', condition);
            }
        }
        navigate(`/dashboard?tag=${encodeURIComponent(tagName)}`, {
            state: { tagId },
        });
    };

    // const handleClickType = () => {
    //     const cate = dashboardData?.dashboardCategoryId || "";
    //     const encodedType = encodeBase64(cate); // เข้ารหัส Base64
    //     const url = `/Dashboard?cate=${encodeURIComponent(encodedType)}`;
    //     console.log(url); // ตรวจสอบ URL ที่จะถูกส่ง
    //     navigate(url);
    // };
    const handleClickType = () => {
        const cate = dashboardData?.dashboardCategoryId || "";
        const state = { cate }; // เก็บค่า cate ใน state
        const url = new URL(window.location.href);
        url.searchParams.set("cate", dashboardData?.dashboardCategoryName); // เพิ่มค่า cate ลงใน URL
        //navigate(url.toString(), { state }); // ส่ง state ไปยังหน้า /Dashboard
        navigate(`/dashboard?cate=${encodeURI(dashboardData?.dashboardCategoryName)}`, { state }); // ส่ง state ไปยังหน้า /Dashboard
    };

    // const handleNavigate = () => {
    //     if (dataSourceIds) {
    //         // ตรวจสอบว่า dataSourceIds เป็น string หรือไม่
    //         let dataSourceIdsString = dataSourceIds;
            
    //         // หาก dataSourceIds เป็น array, ให้แปลงเป็น string
    //         if (Array.isArray(dataSourceIds)) {
    //             dataSourceIdsString = dataSourceIds.join(',');
    //         }
    
    //         // ตรวจสอบว่ามีค่าเป็น string ก่อนที่จะใช้ split()
    //         if (typeof dataSourceIdsString === 'string') {
    //             // แยก dataSourceIds โดยใช้ ',' แล้วแปลงเป็น query parameters ที่คั่นด้วย '&'
    //             const dataSourceIdsArray = dataSourceIdsString.split(',');
    //             const queryParams = dataSourceIdsArray.map(id => `dataSourceIds=${id}`).join('&');
                
    //             // นำ queryParams ที่ได้ไปสร้าง URL ใหม่
    //             window.location.href = `/data-catalog-filter?${queryParams}`;
    //         } else {
    //             console.error('dataSourceIds is not a string or array');
    //         }
    //     } else {
    //         console.error('dataSourceIds is undefined or null');
    //     }
    // };
    

    const handleNavigate = () => {
        if (dataSourceIds) {
            // เก็บข้อมูลใน sessionStorage
            sessionStorage.setItem('dataSourceIds', JSON.stringify(dataSourceIds));
    
            // ใช้ URL ที่ไม่มี dataSourceIds ใน query string
            window.location.href = '/data-catalog-filter';
        } else {
            console.error('dataSourceIds is undefined or null');
        }
    };
    
    
    

    const tagInsightButtons = (item) => {
        const maxTag = 2;
        const tagCount = item?.insightReportTags.length;
        var renderTiems = item?.insightReportTags
            .sort((a, b) => {
                if (a.tagName === 'เฉพาะสมาชิก' && b.tagName !== 'เฉพาะสมาชิก') {
                    return -1;
                }
                return a.sequence - b.sequence
            })
            .slice(0, maxTag)
            .map((tag) => (
                <Tag
                    key={item.id + tag.id}
                    bordered={false}
                    color={tag.tagColor}
                    onClick={() => goInsight(tag.tagId, tag.tagName)}
                    className="tag"
                >
                    {tag.tagName}
                </Tag>
            ));
        if (tagCount > maxTag) {
            renderTiems.push(<Tag
                key={item.id + 'more'}
                bordered={false}
                className="tag tag-more"
            >
                {`+${(tagCount - maxTag)}`}
            </Tag>);
        }
        return renderTiems
    };
    const tagDashboardButtons = (item) => {
        const maxTag = 2;
        const tagCount = item?.dashboardTags.length;
        var renderTiems = item?.dashboardTags
            .sort((a, b) => {
                if (a.tagName === 'เฉพาะสมาชิก' && b.tagName !== 'เฉพาะสมาชิก') {
                    return -1;
                }
                return a.sequence - b.sequence
            })
            .slice(0, maxTag)
            .map((tag) => (
                <Tag
                    key={item.id + tag.id}
                    bordered={false}
                    color={tag.tagColor}
                    onClick={() => goDashboard(tag.tagId, tag.tagName)}
                    className="tag"
                >
                    {tag.tagName}
                </Tag>
            ));
        if (tagCount > maxTag) {
            renderTiems.push(
                <Tag
                    key={item.id + 'more'}
                    bordered={false}
                    className="tag tag-more"
                >
                    {`+${(tagCount - maxTag)}`}
                </Tag>);
        }
        return renderTiems
    };

    const goDashboard = (tagId, tagName) => {
        if (localStorage.getItem('cookie') === 'true') {
            if (cookieDashboard['dashboard-condition'] !== undefined) {
                var condition = cookieDashboard['dashboard-condition'];
                condition = {};
                condition.TagIds = tagId;
                setCookiesValue('dashboard', 'dashboard-condition', condition);
            }
        }
        navigate(`/dashboard?tag=${encodeURIComponent(tagName)}`, {
            state: { tagId },
        });
    };
    const goInsight = (tagId, tagName) => {
        if (localStorage.getItem('cookie') === 'true') {
            if (cookieInsightReport['insight-report-condition'] !== undefined) {
                var condition = cookieInsightReport['insight-report-condition'];
                condition = {};
                condition.TagIds = tagId;
                setCookiesValue('insight-report', 'insight-report-condition', condition);
            }
        }
        navigate(`/insight-report?tag=${encodeURIComponent(tagName)}`, {
            state: { tagId },
        });
    };
    const setCookiesValue = (group, key, value) => {
        if (localStorage.getItem('cookie') === 'true') {
            if (group === 'dashboard') {
                setCookieDashboard(key, value, { path: '/' });
            } else if (group === 'insight-report') {
                setCookieInsightReport(key, value, { path: '/' });
            }
        }
    };

    return (
        <>
            <div className="dashboard-detail">
                <div className="dashboard-detail-title">
                    <span className="content-card-title">
                        <ArrowLeftOutlined onClick={handleBackClick} style={{
                            marginRight: "10px",
                        }} /> {dashboardData?.name || ""}
                    </span>
                </div>

                <div className="dashboard-detail-body">
                    <div className="card" style={{ transform: `scale(${1 / zoomScale})` }}>
                        <div className="content-dashboard-detail">
                            <div className="flex-container">
                                <div>
                                    <div className="text-left">
                                        <span className="content-card-title-detail"> {dashboardData?.name || ""}</span>
                                        <div className="tag-container">
                                            {dashboardData?.dashboardTags?.map((tagItem, tagIndex) => (
                                                <Tag
                                                    key={tagItem.id}
                                                    bordered={false}
                                                    color={tagItem.tagColor}
                                                    onClick={() => handleTagClick(tagItem.tagId, tagItem.tagName)}
                                                    className="tag"
                                                >
                                                    {tagItem.tagName}
                                                </Tag>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    className="share-button"
                                    onClick={showModal}
                                >
                                    <ShareAltOutlined /> แชร์
                                </Button>

                                <Modal
                                    title="แชร์ข้อมูลของคุณ"
                                    className="share-modal"
                                    open={isModalVisible}
                                    onCancel={handleCancel}
                                    footer={null} // ถ้าไม่ต้องการปุ่มใน footer ของ Modal
                                    width={500} // ความกว้างของ Modal
                                >
                                    <FacebookShareButton url={window.location.href}>
                                        <img src={icon_fb} alt="FB" width={30} height={30} title="แชร์ไปยัง Facebook" />
                                    </FacebookShareButton>
                                    <TwitterShareButton url={window.location.href}>
                                        <img src={icon_x} alt="X" width={30} height={30} title="แชร์ไปยัง X" />
                                    </TwitterShareButton>
                                    <LinkedinShareButton url={window.location.href}>
                                        <img src={icon_linkedin} alt="Linkedin" width={30} height={30} title="แชร์ไปยัง Linkedin" />
                                    </LinkedinShareButton>
                                    <LineShareButton url={window.location.href}>
                                        <img src={icon_line} alt="Line" width={30} height={30} title="แชร์ไปยัง Line" />
                                    </LineShareButton>
                                    <h4 style={{ margin: "0px" }}>คัดลอก URL <small className="copied-text">{isCopied}</small></h4>
                                    <Space.Compact className="copy-url-container">
                                        <Input defaultValue={decodeURI(window.location.href)} readOnly={true} />
                                        <CopyToClipboard text={decodeURI(window.location.href)}
                                            onCopy={() => {
                                                setIsCopied("คัดลอกแล้ว");
                                                setTimeout(() => {
                                                    setIsCopied("");
                                                }, 3000);
                                            }}>
                                            <Button type="default" className="copy-url-button">คัดลอก</Button>
                                        </CopyToClipboard>
                                    </Space.Compact>

                                </Modal>
                            </div>
                            <p className="dashboard-description" dangerouslySetInnerHTML={{ __html: dashboardData?.description || "" }} />
                            <br />
                            {dashboardData?.embedSrc && isValidURL(dashboardData.embedSrc) && !isLocalhost(dashboardData.embedSrc) ? (
                                <div className="pbi">
                                    <iframe
                                        loading="lazy"
                                        src={dashboardData.embedSrc}
                                        height="100%"
                                        width="100%"
                                        allowFullScreen={true}
                                    ></iframe>
                                </div>
                            ) : null}



                            <div className="flex-space-between">
                                {/* Left Section */}
                                <div style={{ flex: 1 }}>
                                    <h3>ประเภท</h3>
                                    <p className="link-style" onClick={handleClickType}>
                                        {dashboardData?.dashboardCategoryName || ""}
                                    </p>
                                </div>

                                {
                                    dashboardDataSources && dashboardDataSources.length > 0 ? (
                                        <>
                                            {/* Vertical Divider */}
                                            <div className="vertical-line"></div>

                                            {/* Data Sources Section */}
                                            <div className="flex-text-left">
                                                <h3>แหล่งข้อมูล</h3>
                                                <Button onClick={handleNavigate} type="primary" className="responsive-button">
                                                    <CopyOutlined />
                                                    <span className="button-text">แสดงแหล่งข้อมูลของแดชบอร์ด</span>
                                                </Button>
                                            </div>
                                        </>
                                    ) : null
                                }



                            </div>



                        </div>
                    </div>
                </div>

                {displayedData2?.length > 0 ? (
                    <div style={{ padding: '20px' }}>
                        <div style={{
                            transform: `scale(${1 / zoomScale})`
                        }}>

                            <div className="container-dashboard-detail">
                                <div className="content-dashboard-detail">
                                    <Flex justify="space-between" align="center">
                                        <span className="content-card-title-detail">รายงานวิเคราะห์ข้อมูลเชิงลึกที่เกี่ยวข้อง (Insight Report)</span>
                                        <Space>
                                            <Button className="arrow-left-home" onClick={handlePrev2}>
                                                <HiOutlineArrowLeft />
                                            </Button>
                                            <Button className="arrow-right-home" onClick={handleNext2}>
                                                <HiOutlineArrowRight />
                                            </Button>
                                        </Space>
                                    </Flex>
                                </div>
                                <div className="container-card-insight-report-detail">
                                    <Space size="middle">
                                        <Row style={{ width: "calc(100vw - 80px)" }}>
                                            {displayedData2?.map((item) => (
                                                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                                    <div className="card-container">
                                                        <div key={item.id} className="content-card-item">
                                                            <div className="content-image-container">
                                                                <img
                                                                    className="content-image-insight-report-detail-2"
                                                                    alt="error"
                                                                    src={item.thumbnailSrc ? item.thumbnailSrc : NoImg}
                                                                    onError={(e) => {
                                                                        if (e.target.alt === 'error') {
                                                                            //console.log("No img")
                                                                            e.target.src = NoImg;
                                                                        } else {
                                                                            e.target.src = NoImg;
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="card-detail-container">
                                                                <Space>
                                                                    {tagInsightButtons(item)}
                                                                </Space>
                                                                <p className="content-card-title-insight-report-detail">{item.name}</p>
                                                                <p className="content-card-detail-insight-report-detail">{item.shortDescription}</p>
                                                                <p className="content-card-detail-dashboard-detail">{item.createdDateFrom}</p>
                                                                <Flex
                                                                    className="detail-button-footer"
                                                                    justify="center"
                                                                    align="center"
                                                                    onClick={() => handleClickInsight(item.id, item.urlSlug)}
                                                                >
                                                                    <p>
                                                                        <span>อ่านต่อ</span>
                                                                        <IconRightArrowViewDashboard />
                                                                    </p>
                                                                </Flex>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Space>
                                </div>
                            </div>
                        </div>

                    </div>
                ) : null}

                {displayedData?.length > 0 ? (
                    <div style={{ padding: '20px' }}>
                        <div style={{
                            transform: `scale(${1 / zoomScale})`
                        }}>
                            <div className="container-dashboard-detail">
                                <div className="content-dashboard-detail">
                                    <Flex justify="space-between" align="center">
                                        <span className="content-card-title-detail">แดชบอร์ดที่เกี่ยวข้อง</span>
                                        <Space>
                                            <Button className="arrow-left-home" onClick={handlePrev}>
                                                <HiOutlineArrowLeft />
                                            </Button>
                                            <Button className="arrow-right-home" onClick={handleNext}>
                                                <HiOutlineArrowRight />
                                            </Button>
                                        </Space>
                                    </Flex>
                                </div>
                                {/* 
                                <div className="container-card-insight-report-detail">
                                    <Space size="middle">
                                        <Row style={{ width: "calc(100vw - 80px)" }}>
                                            {displayedData.map((item, index) => (
                                                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                                    <div className="card-container">
                                                        <div key={index} className="content-card-item">
                                                            <div>
                                                                <img
                                                                    className="content-image-insight-report-detail-2"
                                                                    alt="error"
                                                                    src={item.thumbnailSrc ? item.thumbnailSrc : NoImg}
                                                                    onError={(e) => {
                                                                        if (e.target.alt === 'error') {
                                                                            //console.log("No img");
                                                                            e.target.src = NoImg;
                                                                        } else {
                                                                            e.target.src = NoImg;
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="card-detail-container">
                                                                <Space>
                                                                    {tagDashboardButtons(item)}
                                                                </Space>
                                                                <p className="content-card-title-insight-report-detail">{item.name}</p>
                                                                <p className="content-card-detail-insight-report-detail">
                                                                    {item.shortDescription}
                                                                </p>
                                                                <p className="dashboard-date">
                                                                </p>

                                                                <Flex
                                                                    className="detail-button-footer"
                                                                    justify="center"
                                                                    align="center"
                                                                    onClick={() => handleClickDashbord(item.id, item.name)}
                                                                >
                                                                    <p>
                                                                        <span>ดูรายละเอียด</span>
                                                                        <IconRightArrowViewDashboard />
                                                                    </p>
                                                                </Flex>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Space>
                                </div> */}


                                <div className="dashboard-content-content">
                                    <Row justify="start" gutter={[16, 16]}>
                                        {Array.isArray(displayedData) && displayedData.map((item, index) => (
                                            <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                                <div className="card-container">
                                                    <div key={index} className="content-card-item2">
                                                        <div className="tag-container">
                                                            {Array.isArray(item.tags) && item.tags.map((tag, idx) => (
                                                                <span key={idx} className={`tag ${tag.type}`}>
                                                                    {tag.label}
                                                                </span>
                                                            ))}
                                                        </div>
                                                        <div className="image-placeholder-container">
                                                            <img
                                                                className="content-image-insight-report-detail-2"
                                                                alt="error"
                                                                src={item.thumbnailSrc || NoImg}
                                                                onError={(e) => {
                                                                    e.target.src = NoImg;
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="card-detail-container">
                                                            <Space>
                                                                {tagDashboardButtons(item)}
                                                            </Space>
                                                            <p className="content-card-title-insight-report-detail">
                                                                {item.name}
                                                            </p>
                                                            <p className="content-card-detail-insight-report-detail">
                                                                {item.shortDescription}
                                                            </p>
                                                            <Flex
                                                                className="detail-button-footer"
                                                                justify="center"
                                                                align="center"
                                                                onClick={() => handleClickDashbord(item.id, item.urlSlug)}
                                                            >
                                                                <p>
                                                                    <span>ดูรายละเอียด</span>
                                                                    <IconRightArrowViewDashboard />
                                                                </p>
                                                            </Flex>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>




                            </div>
                        </div>
                    </div >
                ) : null}


                <CustomFooter />
            </div >
            <LoadingDialog isLoading={isLoading} />
            {process.env.REACT_APP_AFFILIATE_URL !== "" && (
                <AffilateButton screenPosition="80vh" onClick={
                    () => { window.open(process.env.REACT_APP_AFFILIATE_URL) }
                } />
            )}
            <FailedDialog
                isModalOpen={isNotAllow}
                closable={false}
                title={"ไม่สามารถเข้าถึงหน้านี้ได้"}
                detail={"คุณไม่มีสิทธิ์เข้าถึงหน้านี้ หากต้องการเข้าถึงกรุณาติดต่อผู้ดูแลระบบ"}
                onCancel={() => {
                    setIsNotAllow(false);
                }}
                onAfterClose={() => {
                    setIsNotAllow(false);
                }}
                onConfirm={() => {
                    setIsNotAllow(false);
                }}
            />
        </>
    );
};

export default DetailDashboard;



