import React from "react";
import { Button, Modal, Flex, Space } from "antd";
import IconDelete from "../../assets/icon/icon_delete_dialog.js";

const DeleteDialog = ({
  isModalOpen,
  closable,
  title,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  description,
}) => {
  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        footer={null}
        closable={closable}
        centered
        width={400}
        style={{
          borderRadius: "16px",
          overflow: "auto",
        }}
      >
        <Flex justify="center" align="center" vertical>
          <IconDelete  style={{
              width: 86,
              height: 86,
            }}/>
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 20,
            }}
          >
            {title}
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 0,
              color: "#475467",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            {description}
          </span>
          <Space style={{ marginTop: 20 }}>
            <Button
              type="primary"
              ghost={true}
              style={{
                width: 135,
                height: 40,
                border: "1px solid #D0D5DD",
                color: "#1D273C",
                backgroundColor: "#FFF",
                fontWeight: 500,
                fontSize: 16,
              }}
              onClick={() => {
                onCancel();
              }}
            >
              {cancelText}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onCancel();
                onConfirm();
              }}
              style={{
                width: 135,
                height: 40,
                backgroundColor: "#FF4D4F",
                color: "#ffffff",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              {confirmText}
            </Button>
          </Space>
        </Flex>
      </Modal>
    </>
  );
};

export default DeleteDialog;
