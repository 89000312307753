import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const InsightReportService = {


  getDataList: async function (idDashbord, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/by-dashboard?DashboardIds=${idDashbord}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getDataList.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },

  getDataListTag: async function (tagIds, cancel = false) {
    const queryString = tagIds.map((id) => `TagIds=${id}`).join("&");
    const response = await api.request({
      url: `/insight-reports/by-tag?${queryString}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getDataList.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },

  // getDataList: async function (params, cancel = false) {
  //   const response = await api.request({
  //     url: `/insight-reports/public`,
  //     method: "GET",
  //     params: params,
  //     signal: cancel
  //       ? cancelApiObject[this.getDataList.name].handleRequestCancellation()
  //           .signal
  //       : undefined,
  //   });

  //   return response.data;
  // },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/by-url-slug?urlSlug=${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getPublic: async function (params, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/public`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  search: async function (params, cancel = false) {
    const response = await api.request({
      url: `/insight-reports`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  searchPublic: async function (params, cancel = false) {
    const query = new URLSearchParams();
    const queryTag = new URLSearchParams();
    if(params.InsightReportCategoryIds){
      params.InsightReportCategoryIds.forEach((id) => {
        if (id !== "all") query.append("insightReportCategoryIds", id);
      });
    }
    if(params.TagIds){
      params.TagIds.forEach((id) => {
        query.append("TagIds", id);
      });
    }
    delete params.InsightReportCategoryIds;
    delete params.TagIds;

    const response = await api.request({
      url: `/insight-reports/public?${query.toString() + queryTag.toString()}`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.searchPublic.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },
  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/insight-reports`,
      method: "POST",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  saveBulk: async function (data, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/bulk`,
      method: "POST",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },
  update: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/${id}`,
      method: "PUT",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  updatePinned: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/${id}/is-pinned`,
      method: "PATCH",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.updatePinned.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },
  updateActivated: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/${id}/is-activated`,
      method: "PATCH",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.updateActivated.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/${id}`,
      method: "DELETE",
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  downloadedStats: async function (dateFrom, dateTo, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/download-stats`,
      method: "GET",
      params: {
        DownloadDateFrom: dateFrom,
        DownloadDateTo: dateTo,
      },
      signal: cancel
        ? cancelApiObject[this.downloadedStats.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },
  validatePermission: async function (id, cancel = false) {
    const response = await api.request({
      url: `/insight-reports/validate-permission`,
      method: "GET",
      params:{
        id: id
      },
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(InsightReportService);
