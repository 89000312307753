import axios from "axios";
import { notification } from "antd";
import { OAuthService } from "../OAuthService";
import { handleLogin } from "../../keycloak/KeycloakAuth";
import ErrorMessageHandler from "../../helpers/ErrorMessageHandler";

export const api = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_BASE_URI,
});


export const ocelotConfigApi = axios.create({
  baseURL: process.env.REACT_APP_OCELOT_CONFIG_BASE_URI,
});

export const api2 = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_WEB_BASE_URI,
});

// defining a custom error handler for all APIs
const errorHandler = async (error) => {
  
  const statusCode = error.response?.status;
  const isSelfErrorHandler = error.config?.isSelfErrorHandler ?? false;
  if (error.code === "ERR_CANCELED") {
    if (!isSelfErrorHandler) ErrorMessageHandler({response: {data: {errorCode: 500, messageTh: "Request canceled"}}});
    return Promise.resolve();
  } else if(error.code === "ERR_NETWORK"){
    if (!isSelfErrorHandler) ErrorMessageHandler({response: {data: {errorCode: 500, messageTh: "Network error! Please try again later"}}});
    window.location.href = "/network-error";
    return Promise.reject(error);
  }

  const originalRequest = error.config;

  if (statusCode === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const refreshToken = sessionStorage.getItem("refreshToken");
      if(!refreshToken){
        if (!isSelfErrorHandler) ErrorMessageHandler({response: {data: {errorCode: 401, messageTh: "Session expired! Please login again"}}});
        sessionStorage.clear();
        handleLogin();
        setTimeout(function(){
          window.location.reload();
        }, 2400);
        return Promise.reject(error);
      }
      const response = await OAuthService.refreshToken(refreshToken);
      if(!response){
        sessionStorage.clear();
        handleLogin();
        setTimeout(function(){
          window.location.reload();
        }, 2400);
      }

      sessionStorage.setItem("accessToken", response.accessToken);
      sessionStorage.setItem("refreshToken", response.refreshToken);
      originalRequest.headers.Authorization = `Bearer ${response.accessToken}`;
      return axios(originalRequest);
    } catch (error) {
      // console.error();
      if (!isSelfErrorHandler) ErrorMessageHandler(error);
      sessionStorage.clear();
      handleLogin();
      setTimeout(function(){
        window.location.reload();
      }, 2400);
     
    }
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    //console.error(error);
    console.log("error", error);
    if (!isSelfErrorHandler) ErrorMessageHandler(error);
  }

  return Promise.reject(error);
};

api.interceptors.request.use(
  (config) => {
    
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      //config.headers["Self-Error-Handler"] = "application/json";
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
