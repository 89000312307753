import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Button, Grid } from 'antd';
import logo from '../../Login/logo 1.png';
import '../../Login/login.css';
import '../register.css';
import { KeycloakService } from '../../../../services/KeycloakService';
import { LoadingDialog } from '../../../../components';
import { UserService } from '../../../../services/UserService';
import { ConsentService } from '../../../../services/ConsentService';

const { useBreakpoint } = Grid;

const VerifyEmailPage = () => {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useState('');
    const [code, setCode] = useState('');
    const [issuer, setIssuer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [isVerifyingEmail, setVerifyingEmail] = useState(true);
    const [isVerifyingEmailSuccess, setVerifyingEmailSuccess] = useState(false);
    const [isVerifyingEmailFailed, setVerifyingEmailFailed] = useState(false);
    const ssoIssuer = process.env.REACT_APP_LOGIN_SSO_ISSUER;

    useEffect(() => {

        // ดึงค่า token จาก query parameters
        const tokenFromUrl = new URLSearchParams(window.location.search);
        const token = tokenFromUrl.get('token');
        if (token) {
            setToken(token);
        }

        // // ดึงค่า code จาก hash parameters
        const hashParams = new URLSearchParams(location.hash.substring(1)); // ลบเครื่องหมาย # ออก
        const codeFromUrl = hashParams.get('code');
        if (codeFromUrl) {
            setCode(codeFromUrl);
        }
        const issuerFromUrl = hashParams.get('iss');
        if (issuerFromUrl) {
            setIssuer(issuerFromUrl);
        }
    }, [location]);


    useEffect(() => {
        setVerifyingEmailSuccess(false);
        setVerifyingEmailFailed(false);

        const verifyEmailWithToken = async () => {

            if (token) {
                
                setIsLoading(true);
                try {
                    setVerifyingEmail(true);
                    var verifyEmailResponse = await KeycloakService.verifyEmail(token);
                    if (verifyEmailResponse) {
                        setVerifyingEmailSuccess(true);
                        setEmail(verifyEmailResponse.email);
                    }
                } catch (error) {
                    console.error('Error verifying email:', error);
                    //Token expired
                    if(error.response.data.errorCode === 'err901') {
                        navigate('/verifyemailtimeout?token=' + token);
                    }
                    setVerifyingEmailFailed(true);
                } finally {
                    setIsLoading(false);
                }
            } else if (code) {
                const codejson = { code: code };
                setIsLoading(true);
                setVerifyingEmail(true);
                try {
                    const responsCodegoogle = await KeycloakService.loginVerifyCodeGoogle(codejson);
                    if(responsCodegoogle && responsCodegoogle.accessToken){
                        sessionStorage.setItem("accessToken", responsCodegoogle.accessToken);
                        sessionStorage.setItem("refreshToken", responsCodegoogle.refreshToken);
                        await UserService.getPermissions();
                    }
                    if(issuer === ssoIssuer){
                        var hasConsent = await ConsentService.validate();
                        if (hasConsent) {
                            if(localStorage.getItem('pageState')){
                                var pageState = localStorage.getItem('pageState');
                                localStorage.removeItem('pageState');
                                navigate(pageState);
                                return;
                            }
                            navigate('/');
                        } else {
                            navigate('/confirm-consent');
                        }

                    }else{
                        const userResponse = await KeycloakService.loginVerifyCodeGoogleGetme(responsCodegoogle.accessToken);
                        //const { attributes } = responsCodegooglegetme;
                        if (
                            !userResponse.position ||
                            userResponse.position === '' ||
                            !userResponse.jobFunction ||
                            userResponse.jobFunction === ''
                        ) {
                            // navigate('/registergoogle', { state: { userData: responsCodegooglegetme } });
                            navigate('/registergoogle', {
                                state: {
                                    userData: userResponse,
                                    responsCodegoogle: responsCodegoogle 
                                }
                            });
                        } else {
                            await UserService.getPermissions();
                            var hasConsent = await ConsentService.validate();
                            if (hasConsent) {
                                if(localStorage.getItem('pageState')){
                                    var pageState = localStorage.getItem('pageState');
                                    localStorage.removeItem('pageState');
                                    navigate(pageState);
                                    return;
                                }
                                navigate('/');
                            } else {
                                navigate('/confirm-consent');
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('Error verifying email:', error);
                    setVerifyingEmailFailed(true);
                }finally {
                    setIsLoading(false);
                }
            }
        };
        verifyEmailWithToken();
    }, [token, code]);
 
    const siteKey = process.env.REACT_APP_SITE_KEY_RECAPCHA;

    // โหลดสคริปต์ reCAPTCHA v3
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        document.head.appendChild(script);
    }, [siteKey]);


    const goBack = () => {
        navigate('/login');
    };
    return (
        <div className="login-container1">

            {isVerifyingEmail && isVerifyingEmailFailed ? (
                <Row>
                    <Col span={24} md={24} xs={24}>
                        <div className="gray-bg"></div>
                        <div className='center-v'>
                            <div className="error-card" style={{textAlign:"center"}}>
                                <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="78" height="78" rx="20" fill="#FFE2E2"/>
                                    <g clipPath="url(#clip0_7882_101808)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M22.6742 22.6742C23.4064 21.9419 24.5936 21.9419 25.3258 22.6742L39 36.3483L52.6742 22.6742C53.4064 21.9419 54.5936 21.9419 55.3258 22.6742C56.0581 23.4064 56.0581 24.5936 55.3258 25.3258L41.6517 39L55.3258 52.6742C56.0581 53.4064 56.0581 54.5936 55.3258 55.3258C54.5936 56.0581 53.4064 56.0581 52.6742 55.3258L39 41.6517L25.3258 55.3258C24.5936 56.0581 23.4064 56.0581 22.6742 55.3258C21.9419 54.5936 21.9419 53.4064 22.6742 52.6742L36.3483 39L22.6742 25.3258C21.9419 24.5936 21.9419 23.4064 22.6742 22.6742Z" fill="#FF4D4F"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_7882_101808">
                                    <rect width="60" height="60" fill="white" transform="translate(9 9)"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                                <p
                                    style={{
                                        
                                        textAlign: 'center',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    ยืนยันตัวตนไม่สำเร็จ
                                </p>
                                <span style={{ display: 'block', margin: '10px 0' }}> {/* ใช้ margin เพื่อเพิ่มระยะห่าง */}
                                    เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง
                                </span>
                                <Button htmlType="button" className="error-button" onClick={goBack} >
                                    ลองอีกครั้ง
                                </Button>
                                
                            </div>
                            
                            <Row>
                                <Col span={0} md={0} xs={24}><div style={{padding:"10vh"}}></div></Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            ):(
                isVerifyingEmail && isVerifyingEmailSuccess  && (
                    <Row>
                        <Col span={18} md={18} xs={24}>
                            <Row>
                                <Col span={24} md={24} xs={0}>
                                <div className="login-bg"></div>
                                <div className='login-left'>
                                    <div className="logo">
                                    <img
                                        src={logo}
                                        alt="TCEB Logo"
                                        className="logo-image"
                                        onClick={() => {
                                            ConsentService.validate().then((hasConsent) => {
                                                if (hasConsent) {
                                                    if(localStorage.getItem('pageState')){
                                                        var pageState = localStorage.getItem('pageState');
                                                        localStorage.removeItem('pageState');
                                                        navigate(pageState);
                                                        return;
                                                    }
                                                    navigate('/');
                                                } else {
                                                    navigate('/confirm-consent');
                                                }
                                            });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    </div>
                                </div>
                                <div className="copyright">
                                    © 2567 สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)
                                </div>
                                </Col>
                                <Col span={24} xs={24} md={0}>
                                <div className="login-bg" style={{position:'fixed'}}></div>
                                <div className="logo" style={{textAlign:"center", marginTop:"15px"}}>
                                    <img
                                    src={logo}
                                    alt="TCEB Logo"
                                    className="logo-image"
                                    onClick={() => navigate('/')}
                                    style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6} md={6} xs={24}>
                            <div className="center-v">
                                
                                <div>
                                    <div className="login-card" style={screens.xs || document.body.clientWidth <= 767 ? {textAlign:"center", marginLeft: "auto", marginRight: "auto", marginTop: "15px"} : { textAlign:"center", marginLeft: "-230px" }}>
                                        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="78" height="78" rx="20" fill="#E3FFF0"/>
                                        <g clipPath="url(#clip0_7804_23684)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.625 39C14.625 25.5381 25.5381 14.625 39 14.625C52.4619 14.625 63.375 25.5381 63.375 39C63.375 52.4619 52.4619 63.375 39 63.375C25.5381 63.375 14.625 52.4619 14.625 39ZM48.0257 34.4648C48.6276 33.6222 48.4325 32.4511 47.5898 31.8493C46.7472 31.2474 45.5761 31.4425 44.9742 32.2852L36.8857 43.6091L32.8258 39.5492C32.0936 38.8169 30.9064 38.8169 30.1742 39.5492C29.4419 40.2814 29.4419 41.4686 30.1742 42.2008L35.7992 47.8258C36.1889 48.2156 36.7304 48.4141 37.2797 48.3686C37.8289 48.3232 38.3304 48.0383 38.6508 47.5898L48.0257 34.4648Z" fill="#1AC487"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_7804_23684">
                                        <rect width="60" height="60" fill="white" transform="translate(9 9)"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                        <p
                                            style={{
                                                margin: '20px 0 0 0', // กำหนดระยะห่างด้านบนให้กับพารากราฟ
                                                textAlign: 'center',
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            ยืนยันตัวตนเรียบร้อย
                                        </p>
                                        <span style={{ display: 'block', margin: '10px 0' }}> {/* ใช้ margin เพื่อเพิ่มระยะห่าง */}
                                            คุณได้ยืนยันตัวตนด้วยอีเมล {email} เรียบร้อยแล้ว
                                        </span>
                                        <Button type="primary" htmlType="submit" className="register-form-button" onClick={goBack} >
                                            กลับหน้าหลัก
                                        </Button>
                                        
                                    </div>
                                    <Row>
                                        <Col span={0} md={0} xs={24}><div style={{padding:"10vh"}}></div></Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            )}
            <LoadingDialog isLoading={isLoading || (!isVerifyingEmailFailed && !isVerifyingEmailSuccess)} />
        </div>
    );
};

export default VerifyEmailPage;

