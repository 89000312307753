import "./NetworkError.css";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Layout, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Footer } from "../../components";
import { Content } from "antd/es/layout/layout";

const NetWorkError = () => {
  const [footerHeight, setFooterHeight] = useState(0);
  useEffect(() => {

  },[]);
  return (
    <div>
      
      

      <Layout className="layout">
        
        <Content className="content">
          <Layout>
            <Content>
              <div className="network-error-body" style={{minHeight:`calc(100vh - ${footerHeight}px)`}}>
                <div className="network-error-container" style={{minHeight:`calc(100vh - ${footerHeight}px)`}}>
                <h1 className="extra-large">Network Error</h1>
                <h1>ระบบเครือข่ายเนทเวิร์คผิดพลาด</h1>
                <p>ขออภัย หน้าที่คุณกำลังมองหา ไม่สามารถเชื่อมต่อกับระบบเครือข่ายเนทเวิร์คได้
                  <small>กรุณาตรวจสอบระบบเครือข่ายเนทเวิร์คของท่านและลองใช้งานอีกครั้ง</small>
                </p>
                <Row style={{width:"100%"}}>
                  <Col span={24}  style={{textAlign:"center"}}>
                    <Button type="default"  className="primary-button" onClick={() => {
                      window.history.back();
                    }}>
                      <ArrowLeftOutlined />
                      กลับไปก่อนหน้า
                    </Button>
                    <Link to="/" style={{display:"inline-block"}}>
                      <Button type="default"  className="main-button">หน้าหลัก</Button>
                    </Link>
                  </Col>
                  
                </Row>
                </div>
             </div>
            </Content>
          </Layout>
        </Content>

        <Footer />
      </Layout>
    </div>
  );
};

export default NetWorkError;
