import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ConsentService = {
  getAll: async function (data, cancel = false) {
    const response = await api.request({
      url: `/consent/lists`,
      method: "GET",
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/consent/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/consent`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  update: async function (data, cancel = false) {
    const response = await api.request({
      url: `/consent/${data.id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  updateisPrimary: async function (data, cancel = false) {
    const response = await api.request({
      url: `/consent/${data.id}/is-primary`,
      method: "PATCH",
      data: data,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/consent/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getCurrent: async function (cancel = false) {
    const response = await api.request({
      url: `/consent/current`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  accept: async function (id, cancel = false) {
    const response = await api.request({
      url: `/consent/accept/${id}`,
      method: "PATCH",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  withdraw: async function (id, cancel = false) {
    const response = await api.request({
      url: `/consent/withdraw/${id}`,
      method: "PATCH",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  validate: async function (cancel = false) {
    const response = await api.request({
      url: `/consent/validate-current`,
      method: "GET",
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  userLists: async function (id, query, cancel = false) {
    const response = await api.request({
      url: `/consent/users-list/${id}`,
      method: "GET",
      params: query,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  acceptedConsent: async function (cancel = false) {
    const response = await api.request({
      url: `/consent/accepted-consent`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  
};

const cancelApiObject = defineCancelApiObject(ConsentService);
