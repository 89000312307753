import React from "react";

const LoadingDialog = ({
  isLoading
}) => {
  return (
    isLoading && (
      <div className="modal-overlay">
          <div className="modal-content">
              <div className="spinner"></div>
              <p>กรุณารอสักครู่...</p>
          </div>
      </div>
    )
  );
};

export default LoadingDialog;
