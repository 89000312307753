import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Layout,
  Space,
  Dropdown,
  Badge,
  Divider,
  Menu,
  Carousel,
  Button,
  Row,
  Col,
  Grid,
  Drawer,
  Modal,
} from "antd";
import { CodeOutlined, ControlOutlined, DownOutlined, LockOutlined, LogoutOutlined, MenuOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import Icon_user_filled from "../../assets/icon/icon_user_filled";
import TCEB_LogoSvg from "../../assets/img/TCEB_Logo.js";
import { DiscardDialog, CustomButton, LoadingDialog } from "../../components";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services/UserService";
import { handleLogin, handleLogout } from "../../keycloak/KeycloakAuth";
import Banner01 from "../../assets/img/Banner01.jpg";
import Banner02 from "../../assets/img/Banner02.jpg";
import Banner03 from "../../assets/img/Banner03.jpg";
import NavDashboardInsight from "../Nav/NavDashboardInsight.js";
import { useUnsavedChangesWarning } from "../../hooks";
import { useUnsavedChangesContext } from "../../contexts";
import IconFeedbackButtton from "../../assets/icon/icon_feedback_button.js";
import SurveyPage from "../../pages/Main/Feedback/Survey.js";

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const Nav = ({}) => {
  const { isDataModified, setIsDataModified } = useUnsavedChangesContext();
  const {
    handleNavigation,
    handleUnsavedConfirm,
    handleUnsavedCancel,
    setIsUnsavedModalOpen,
    isUnsavedModalOpen,
  } = useUnsavedChangesWarning();
  const [isLoading, setIsLoading] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const { i18n, t } = useTranslation();
  const [userMe, setUserMe] = useState();
  const [opacity, setOpacity] = useState(0);
  const [backgroundStr, setBackgroundStr] = useState("");
  const [navPosition, setNavPosition] = useState("");
  const [permission, setPermission] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const screens = useBreakpoint();
  const location = useLocation();
  const currentPath = location.pathname;
  const [userMenu, setUserMenu] = useState([]);
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);

  useEffect(() => {
    window.pathname = location.pathname;
    i18n.changeLanguage("th");
    if (!userMe && sessionStorage.getItem("accessToken") !== null) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    fetchPermission();
    if (userMe) window.sessionId = userMe.id;
  }, [userMe]);

  const fetchData = async () => {
    try {
      let response = await UserService.getMe(true)
      setUserMe(response);
      sessionStorage.setItem("userMe", JSON.stringify(response));
      await fetchPermission();
    } catch (error) {
      console.error("error", error);
      if(error.response.status === 401){
        localStorage.setItem("pageState", location.pathname);
        window.permissions = null;
        sessionStorage.clear();
        window.location.href = "/";
      }
    }
  };

  const fetchPermission = async () => {
    if (sessionStorage.getItem("accessToken") !== null) {
      if (window.permissions === undefined || window.permissions.length === 0) {
        if (sessionStorage.getItem("permissions") === null) {
          window.permissions = await UserService.getPermissions();
        } else {
          window.permissions = UserService.getPermissionFromSession();
        }
      }
      setPermission(window.permissions);
    } else {
      setPermission([]);
    }
    buildUserMenu();
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    const newOpacity = Math.min(offset / 300, 1); // Adjust the divisor for speed of opacity change
    const bgStr = `linear-gradient(to right, rgb(69, 46, 100,${newOpacity}), rgb(9, 20, 33,${newOpacity}), rgb(4, 18, 27,${newOpacity}), rgb(12, 91, 92,${newOpacity}))`;
    setOpacity(newOpacity);
    setBackgroundStr(bgStr);
  };

  useEffect(() => {
    if (CheckPath(currentPath)) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [currentPath]);

  useEffect(() => {
    if (CheckPath(currentPath)) {
      setNavPosition("fixed");
      const bgStr = `linear-gradient(to right, rgb(69, 46, 100, 0), rgb(9, 20, 33, 0), rgb(4, 18, 27, 0), rgb(12, 91, 92, 0))`;
      setBackgroundStr(bgStr);
    } else {
      const bgStr = `linear-gradient(to right, rgb(69, 46, 100, 1), rgb(9, 20, 33, 1), rgb(4, 18, 27, 1), rgb(12, 91, 92, 1))`;
      setBackgroundStr(bgStr);
      setNavPosition("inherit");
    }
  }, [currentPath]);

  // Toggle Drawer visibility (sidebar for mobile)
  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);

  // Menu items for the sidebar and navbar
  const menuItems = [
    { label: "หน้าแรก", key: "/", to: "/" },
    { label: "แดชบอร์ด", key: "/dashboard", to: "/dashboard" },
    { label: "รายงานวิเคราะห์", key: "/insight-report", to: "/insight-report" },
    { label: "ดาต้าแค็ตตาล๊อค", key: "/data-catalog", to: "/data-catalog" },
  ];

  const CheckPath = (currentPath) => {
    const pathsToHide = ["/"];
    return pathsToHide.includes(currentPath);
  };

  const CheckPathDashboardInsight = (currentPath) => {
    const pathsToHide = ["/dashboard", "/insight-report"];
    return pathsToHide.includes(currentPath);
  };

  const CheckPathFeedback = (currentPath) => {
    const pathsToShow = ["/dashboard", "/insight-report", "/data-catalog"];
    return pathsToShow.filter((path) => currentPath.includes(path)).length > 0;
  };

  const getPathforActive = (pathname) => {
    if (pathname == "/dashboard/detail") return "/dashboard";
    else return pathname;
  };

  const buildUserMenu = () => {
    var items = [];
    if (
      !window.permissions ||
      sessionStorage.getItem("userMe") === null ||
      !window.permissions.map
    ) {
      return;
    }
    var userMe = JSON.parse(sessionStorage.getItem("userMe"));
    const menuLabel = {};
    window.permissions.map((p) => {
      menuLabel[p.routePath] = p.pageName;
    });
    menuLabel["/management/account-management"] = "บัญชีของคุณ";
    menuLabel["/management/request-api"] = "ขอใช้ API";
    menuLabel["/management/consent-setting"] = "ตั้งค่าความยินยอมของคุณ";

    if (userMe && userMe.idp === process.env.REACT_APP_NAME) {
      menuLabel["/management/change-password"] = "เปลี่ยนรหัสผ่าน";
    }
    items.push({
      key: "user",
      label: (
        <div className="user-info">
          <h4 style={{ margin: 0 }}>{userMe?.fullName}</h4>
          <small className="role-badge">{userMe?.roleName}</small>
        </div>
      ),
      style: {
        marginLeft: "-15px",
      },
      disabled: true,
    });
    items.push({
      key: "user-1",
      label: <Divider className="dropdown-divider" />,
      style: {
        margin: "0px 0px 0px 0px",
      },
      disabled: true,
    });
   

    items.push({
      key: "/management/account-management",
      label: (
        <Space>
          <UserOutlined />
          {t("my-profile")}
        </Space>
      ),
    });

    items.push({
      key: "/management/request-api",
      label: (
        <Space>
          <CodeOutlined />
          {t("request-api")}
        </Space>
      ),
    });
    items.push({
      key: "/management/consent-setting",
      label: (
        <Space>
          
          <ControlOutlined />
          {t("consent-setting")}
        </Space>
      ),
    });

    if (userMe && userMe.idp === process.env.REACT_APP_NAME) {
      items.push({
        key: "/management/change-password",
        label: (
          <Space>
            <LockOutlined /> 
            {t("change-password")}
          </Space>
        ),
      });
    }
    items.push({
      key: "user-2",
      label: <Divider className="dropdown-divider" />,
      disabled: true,
      style: {
        margin: "0px 0px 0px 0px",
      },
    });
    if (permission.length > 0) {
      items.push({
        key: "admin-system",
        label: (
          <Space>

            <TeamOutlined />

            {t("admin-system")}{" "}
            <Badge count={permission.length} color="#667085" />
          </Space>
        ),
        popupStyle: !screens.md
          ? {
              position: "absolute",
              width: "260px",
              left: "calc(50% - 130px)",
              top: "110px",
              marginTop: "102px",
              background: "#e5e5e5e",
            }
          : {
              width: "auto",
            },
        children: permission.map((p) => ({
          key: p.routePath,
          label: p.pageName,
        })),
      });
    }
    items.push({
      key: "user-3",
      label: <Divider className="dropdown-divider" />,
      style: {
        margin: "0px 0px 0px 0px",
      },
      disabled: true,
    });
    items.push({
      key: "logout",
      label: (
        <Space>
          <LogoutOutlined />
          {t("logout")}
        </Space>
      ),
    });
    setUserMenu(items);
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      await handleLogout();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header
        className="main-header"
        style={{
          background: backgroundStr,
          transition: "background-color 0.3s ease",
          position:
            CheckPathDashboardInsight(currentPath) && !screens.xl
              ? "inherit"
              : navPosition,

          height: screens.xs ? "70px" : "100px",
          paddingTop: screens.xs ? "0px" : "20px",
        }}
      >
        {" "}
        <Row>
          <Col span={24}>
            <Row>
              {
                //Main Header Left
              }
              <Col span={5} lg={5} md={8} sm={8} xs={8}>
                {screens.xl ? (
                  <div
                    onClick={() => {
                      handleNavigation("/");
                    }}
                  >
                    <Link key={"1"}>
                      <div className="main-header-logo">
                        <TCEB_LogoSvg
                          className="TCEB_LogoSvg"
                          style={{ height: "70px", marginTop: "-15px" }}
                        />
                      </div>
                    </Link>
                  </div>
                ) : (
                  <Row>
                    <Col span={4} align="end">
                      {!screens.xl && (
                        <div
                          className="main-header-logo ant-menu-horizontal"
                          onClick={showDrawer}
                          style={
                            screens.xl
                              ? { float: "left", cursor: "pointer" }
                              : { marginLeft: "0px", cursor: "pointer" }
                          }
                        >
                          <MenuOutlined className="MenuOutlined" />
                        </div>
                      )}
                    </Col>
                    <Col
                      span={18}
                      style={
                        !screens.xl
                          ? { marginLeft: "0px" }
                          : { marginLeft: "100px" }
                      }
                    >
                      <div
                        onClick={() => {
                          handleNavigation("/");
                        }}
                      >
                        <Link key={"1"}>
                          <div
                            className="main-header-logo"
                            style={{ paddingTop: !screens.md ? "5px" : "0px" }}
                          >
                            <TCEB_LogoSvg
                              className="TCEB_LogoSvg"
                              style={{
                                height: "60px",
                                width: "60px",
                                top: "0px",
                              }}
                            />
                          </div>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              {
                //Main Header Center
              }
              <Col span={14} lg={14} md={8} xs={0}>
                <div style={{ marginTop: "15px" }}>
                  {screens.xl && (
                    <Menu
                      onClick={(e) => {
                        handleNavigation(e.key);
                      }}
                      mode="horizontal"
                      selectedKeys={getPathforActive(currentPath)}
                      style={{
                        backgroundColor: "transparent",
                        justifyContent: "center",
                        alignItems: "center",
                        borderBottom: "0px",
                        //display:"inline"
                      }}
                      items={menuItems.map((item) => ({
                        label: (
                          <Link
                            key={item.label}
                            // to={item.to}
                          >
                            {item.label}
                          </Link>
                        ),
                        key: item.key,
                      }))}
                    />
                  )}
                </div>
              </Col>
              {
                //Main Header Right
              }
              <Col span={5} lg={5} md={8} sm={16} xs={16}>
                <Space
                  size="middle"
                  style={{ float: "right", marginTop: "0px" }}
                >
                  {!sessionStorage.getItem("accessToken") && (
                    <CustomButton
                      text={"เข้าสู่ระบบ/ลงทะเบียน"}
                      type="primary"
                      onClick={handleLogin}
                      width={170}
                      height={32}
                      margin={0}
                    />
                  )}

                  {!!sessionStorage.getItem("accessToken") && (
                    <div className="custom-dropdown ">
                      <Dropdown
                        placement="bottomRight"
                        trigger={["click"]}
                        overlayStyle={{ zIndex: 2000 }}
                        dropdownRender={(menu) => (
                          <Menu
                            className="dropdown-menu list-dropdown-menu"
                            theme="light"
                            style={{
                              width: 262,
                              minHeight: 100,
                              padding: "23px 12px 23px 12px",
                              marginTop: "10px",
                              marginRight: "-10px",
                              fontSize: "16px",
                            }}
                            onClick={(e) => {
                              if (e.key === "logout") {
                                if (isDataModified) {
                                  setIsLogout(true);
                                  setIsUnsavedModalOpen(true);
                                } else {
                                  logout();
                                }
                              } else {
                                handleNavigation(e.key);
                              }
                            }}
                            triggerSubMenuAction="click"
                            items={userMenu}
                          ></Menu>
                        )}
                      >
                        <Space>
                          <Icon_user_filled />
                          <p>{userMe?.firstName}</p>
                          <DownOutlined />
                        </Space>
                      </Dropdown>
                    </div>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>

      {/* Drawer for mobile navigation */}
      {screens.sm && (
        <Drawer
          //title="เมนู"
          placement="top"
          closable={false}
          onClose={closeDrawer}
          open={drawerVisible}
          //width={250}
          height={280}
          className="drawer-top"
          style={{
            // backgroundColor:"transparent",
            background:
              "linear-gradient(to right, rgb(69, 46, 100,1), rgb(9, 20, 33,1), rgb(4, 18, 27,1), rgb(12, 91, 92,1))",
            borderRadius: "0 0 15px 15px",
            height: "100px",
            paddingTop: "10px",
          }}
          zIndex={2000}
        >
          <Menu
            onClick={(e) => {
              closeDrawer();
              handleNavigation(e.key);
            }}
            style={{
              backgroundColor: "transparent",
              lineHeight: 3,
              borderInlineEnd: 0,
              justifyItems: "center",
              placeContent: "center",
              borderBottom: "0px",
            }}
            className="list-menu-top"
            mode="horizontal"
            selectedKeys={currentPath}
            items={menuItems.map((item) => ({
              label: (
                <Link
                  key={item.label}
                  // to={item.to}
                >
                  {item.label}
                </Link>
              ),
              key: item.key,
              className: "item-menu-top",
            }))}
          />
        </Drawer>
      )}

      {screens.xs && (
        <Drawer
          //title="เมนู"
          placement="top"
          closable={false}
          onClose={closeDrawer}
          open={drawerVisible}
          //width={250}
          height={280}
          className="drawer-top"
          style={{
            // backgroundColor:"transparent",
            background:
              "linear-gradient(to right, rgb(69, 46, 100,1), rgb(9, 20, 33,1), rgb(4, 18, 27,1), rgb(12, 91, 92,1))",
            borderRadius: "0 0 15px 15px",
          }}
          zIndex={2000}
        >
          <div className="main-menu-header-mobile">
            <Menu
              onClick={(e) => {
                closeDrawer();
                handleNavigation(e.key);
              }}
              style={{
                backgroundColor: "transparent",
                lineHeight: 3,
                borderInlineEnd: 0,
                justifyItems: "center",
              }}
              className="list-menu-top"
              mode="inline"
              selectedKeys={currentPath}
              items={menuItems.map((item) => ({
                label: (
                  <Link
                    key={item.label}
                    // to={item.to}
                  >
                    {item.label}
                  </Link>
                ),
                key: item.key,
                className: "item-menu-top",
              }))}
            />
          </div>
        </Drawer>
      )}

      {CheckPath(currentPath) && (
        <Content>
          <Carousel autoplay autoplaySpeed={7000}>
            <div
              style={{ backgroundColor: "#5d5a88" }}
              className="carousel-slide"
            >
              <div className="image-crop">
                <img src={Banner01} className="carousel-image" />
              </div>
              <div className="carousel-title">
                
                <p className="p-title">ศูนย์รวมข้อมูลดิจิทัลเพื่ออุตสาหกรรมไมซ์</p>
                <p>
                  แพลตฟอร์มที่รวบรวมข้อมูลเชิงลึก บทวิเคราะห์ และเครื่องมือที่จำเป็นสำหรับการพัฒนาธุรกิจไมซ์ของคุณ พร้อมระบบวิเคราะห์เชิงลึกที่ช่วยให้คุณตัดสินใจได้อย่างแม่นยำ
                </p>
                <Link to="/dashboard">
                  <Button type="primary" size="large">
                    เริ่มต้นใช้งาน
                  </Button>
                </Link>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#5d5a88" }}
              className="carousel-slide"
            >
              <div className="image-crop">
                <img src={Banner02} className="carousel-image" />
              </div>
              <div className="carousel-title">
                
                <p className="p-title">เจาะลึกอุตสาหกรรมไมซ์ด้วยข้อมูลเชิงลึก</p>
                <p>
                รายงานวิเคราะห์ที่ครอบคลุมทุกมิติของอุตสาหกรรมไมซ์ พร้อมข้อเสนอแนะจากผู้เชี่ยวชาญ เพื่อให้คุณเข้าใจตลาดและแนวโน้มธุรกิจได้อย่างลึกซึ้ง
                </p>
                <Link to="/insight-report">
                  <Button type="primary" size="large">
                    เริ่มต้นใช้งาน
                  </Button>
                </Link>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#5d5a88" }}
              className="carousel-slide"
            >
              <div className="image-crop">
                <img src={Banner03} className="carousel-image" />
              </div>
              <div className="carousel-title">
                
                <p className="p-title">
                ฐานข้อมูลที่ครอบคลุมทุกความต้องการ
                </p>
                <p>
                ศูนย์รวมข้อมูลอุตสาหกรรมไมซ์ที่ครอบคลุมและเป็นระบบ พร้อมระบบ Open API ที่ช่วยให้คุณเข้าถึงและใช้ประโยชน์จากข้อมูลได้อย่างสะดวกและปลอดภัย
                </p>
                <Link to="/data-catalog">
                  <Button type="primary" size="large">
                    เริ่มต้นใช้งาน
                  </Button>
                </Link>
              </div>
            </div>
          </Carousel>
        </Content>
      )}

      {CheckPathDashboardInsight(currentPath) && <NavDashboardInsight />}

      <LoadingDialog isLoading={isLoading} />

      <DiscardDialog
        isModalOpen={isUnsavedModalOpen}
        closable={false}
        title="ยกเลิกการเปลี่ยนแปลง"
        cancelText="ยกเลิก"
        confirmText={isLogout ? "ออกจากระบบ" : "ยืนยัน"}
        onCancel={() => {
          handleUnsavedCancel();
          setIsLogout(false);
        }}
        onConfirm={(e) => {
          handleUnsavedConfirm();
          setIsLogout(false);
          if (isLogout) {
            logout();
          }
        }}
      />

      {CheckPathFeedback(currentPath) && (
        <>
          <IconFeedbackButtton
            
            className="home-feedback"
            onClick={() => setIsSurveyModalOpen(true)}
          />

          <Modal
            title={<p>Feedback</p>}
            open={isSurveyModalOpen}
            footer={null}
            closable={true}
            destroyOnClose={true}
            style={{ top: 20, minWidth: '60%' }}
            onCancel={() => setIsSurveyModalOpen(false)}
            zIndex={2000}
            className="feedback-modal"
          >
            <SurveyPage modalOpenState={()=>{setIsSurveyModalOpen(false)}} />
          </Modal>
        
        </>
      )}
    </>
  );
};

export default Nav;
