import React, { useEffect, useState, useRef } from "react";
import {
    Space,
    Flex,
    Button,
    Col,
    Row,
    Tag,
    Select,
    Radio,
    Grid,
    List,
    Divider,
    notification
} from "antd";
import "./Dashboard.css";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { DashboardCategoryService } from "../../../services/DashboardCategoryService";
import { useCookies } from "react-cookie";
import { DashboardService } from "../../../services/DashboardService";
import { debounce } from "lodash";
import { AiOutlineAppstore, AiOutlineBars } from "react-icons/ai";
import NoImg from "../../../assets/img/no_img.png";
import NoData from "../../../assets/img/no_data.js";
import IconRightArrowViewDashboard from "../../../assets/icon/icon_right_arrow_view_dashboard.js";
import AffilateButton from "../../../components/Button/AffilateButton.js";
import NoAuthDialog from "../../../components/Dialog/NoAuthDialog.js";
import { FailedDialog, LoadingDialog } from "../../../components/index.js";
import { DashboardTagService } from "../../../services/DashboardTagService";
import ErrorMessageHandler from "../../../helpers/ErrorMessageHandler.js";

const { useBreakpoint } = Grid;

const DashboardCategory = () => {
    const location = useLocation();
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [dataDashboardType, setDataDashboardType] = useState([]);
    const [selectedDashboardType, setSelectedDashboardType] = useState(["all"]);
    const [dataDashboard, setDataDashboard] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [cookies, setCookie] = useCookies(['dashboard-condition']);
    const [viewMode, setViewMode] = useState("card");
    const [isNotAllow, setIsNotAllow] = useState(false);
    const [dataTag, setDataTag] = useState([]);
    const prevSelectedDashboardType = useRef(selectedDashboardType);
    const prevSelectedTags = useRef(selectedTags);
    const config = cookies['dashboard-condition'];

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const selectedType = params.get("selectedType");

        if (selectedType) {
            const selectedArray = selectedType.split(","); // Assuming it's comma-separated
            setSelectedDashboardType(selectedArray);
        }
    }, [location.search]);

    const fetchDataDashboardType = async () => {
        try {
            setIsLoading(true);

            const response = await DashboardCategoryService.search();
            const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
                color: "default",
            }));
            options.unshift({
                value: "all",
                label: "ประเภททั้งหมด",
                color: "default",
            });
            setDataDashboardType(options);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDataDashboardType();
    }, []);

    const tagRender = (props) => {
        const { label, value, color, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={color}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginInlineEnd: 4 }}
            >
                {label}
            </Tag>
        );
    };

    const handleChangeType = (selected) => {
        if (!Array.isArray(selected)) {
            console.error("Selected must be an array");
            return;
        }

        if (
            (selected.includes("all") && !selectedDashboardType.includes("all")) ||
            selected.length === 0
        ) {
            setSelectedDashboardType(["all"]);
        } else {
            const filteredValues = selected.filter((val) => val !== "all");
            setSelectedDashboardType(filteredValues);
        }
    };

    const fetchDataDashboard = async () => {
        try {
            setIsLoading(true);
            const params = {
                DashboardCategoryIds: selectedDashboardType || [],
                TagIds: selectedTags || [],
                IsActivated: true,
            };
            if (localStorage.getItem('cookie') === 'true') {
                setCookie('dashboard-condition', JSON.stringify(params), { path: '/' });
            }
            setTimeout(async () => {
                const response = await DashboardService.searchPublic(params);
                console.log("fetchDataDashboard --> response", response);
                setDataDashboard(response.items);
            }, 100);
        } catch (error) {
            console.error(error);
            setDataDashboard([]); // fallback กรณีเกิดข้อผิดพลาด
        } finally {
            setIsLoading(false);
        }
    };


    // Debounced function
    const debouncedFetch = debounce(() => {
        if (
            JSON.stringify(prevSelectedDashboardType.current) !==
            JSON.stringify(selectedDashboardType) ||
            JSON.stringify(prevSelectedTags.current) !== JSON.stringify(selectedTags)
        ) {
            prevSelectedDashboardType.current = selectedDashboardType;
            prevSelectedTags.current = selectedTags;
            fetchDataDashboard();
        }
    }, 300);

    useEffect(() => {
        debouncedFetch();
        return () => {
            debouncedFetch.cancel(); // ยกเลิก debounce เมื่อ component ถูก unmount
        };
    }, [selectedDashboardType, selectedTags]);

    // เรียก fetchDataDashboard ครั้งแรกเมื่อหน้าแรกโหลด
    useEffect(() => {
        fetchDataTags();
        fetchDataDashboard();
    }, []);

    const isSelectedTag = (key) => selectedTags.includes(key);
    const toggleButtonTag = (key) => {
        if (selectedTags.includes(key)) {
            setSelectedTags([]);
        } else {
            setSelectedTags([key]);
        }
    };

    const toggleButtonTag2 = (key) => {
        // ตรวจสอบว่า key ที่กดเป็น key เดียวกับใน selectedTags หรือไม่
        if (selectedTags.length === 1 && selectedTags[0] === key) {
            // กรณีแท็กเดิม ให้คงค่าเดิม (ไม่ทำอะไร)
            return;
        }
        // กรณีแท็กใหม่ ให้เปลี่ยนค่าเป็นแท็กที่เลือก
        setSelectedTags([key]);
    };

    const setLoading = (visible) => {
        if (visible) {
          setIsLoading(isLoading + 1)
        } else {
          setIsLoading(isLoading - 1)
        }
      }


    const handleClick = (id, name) => {
        DashboardService.validatePermission(name).then((allow) => {
            if (allow) {
                navigate(`/dashboard/${encodeURIComponent(name)}`);
                window.scrollTo(0, 0);
            } else {
                if (sessionStorage.getItem('accessToken') === null) {
                    localStorage.setItem('pageState', `/dashboard/${encodeURIComponent(name)}`);
                    navigate('/login');
                    return;
                }
                console.log("isNotAllow", isNotAllow);
                setIsNotAllow(true);
            }
        }).catch((error) => {
            console.error("Error:", error);
            if (error.response.status === 404) {
                if (sessionStorage.getItem('accessToken') === null) {
                    localStorage.setItem('pageState', `/dashboard/${encodeURIComponent(name)}`);
                    navigate('/login');
                    return;
                }
                console.log("isNotAllow", isNotAllow);
                setIsNotAllow(true);
            } else {
                ErrorMessageHandler(error);
            }
        });
    };

    const fetchDataTags = async () => {
        try {
            setLoading(true);
            const response = await DashboardTagService.get();

            //console.log("fetchDataTags >> response", response);
            setDataTag(response);
            if (config && config.TagIds) {
                setSelectedTags(config.TagIds);
            }
        } catch (error) {
            console.error("Error fetching tags:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="dashboard-body">
                <div
                    className="dashboard-container"
                    style={{ padding: !screens.md ? "0px 20px 0px 20px" : "" }}
                >
                    <h2>แดชบอร์ด</h2>
                    <div className="dashboard-tag">
                        <Row>
                            <Col xs={4} sm={3} md={2} lg={2} xl={2}>
                                <h3>Tag : </h3>
                            </Col>
                            <Col
                                sm={20}
                                md={22}
                                lg={22}
                                xl={20}
                                // flex={"20"}
                                className="group-btn-tag"
                            >
                                <Flex wrap>
                                    {dataTag?.map((tag) => (
                                        <Button
                                            key={tag.tagId}
                                            onClick={() => toggleButtonTag(tag.tagId)}
                                            shape="round" className={isSelectedTag(tag.tagId) ? "btn-tag-selected" : "btn-tag"}>
                                            {tag.tagName}
                                        </Button>
                                    ))}
                                </Flex>
                            </Col>
                        </Row>
                    </div>
                    <Divider className="dashboard-divider" />

                    <div className="dashboard-type">
                        <Row className="insight-condition">
                            <Col span={24} className="group-type">
                                <h3>ประเภท : </h3>
                                <Select
                                    size="large"
                                    mode="multiple"
                                    tagRender={tagRender}
                                    defaultValue={["all"]}
                                    onChange={handleChangeType}
                                    value={selectedDashboardType}
                                    options={dataDashboardType}
                                    className="select-insight-type"
                                />
                            </Col>
                        </Row>
                    </div>
                    <Divider className="dashboard-divider" />

                    {dataDashboardType
                        ?.filter(
                            (item) =>
                                item.value != "all" &&
                                dataDashboard.some(
                                    (data) => data.dashboardCategoryId === item.value
                                )
                        )
                        .map((itemType, index) => (
                            <div key={index}>
                                <div className="dashboard-content-head">
                                    <Row justify={"space-between"} align={"middle"}>
                                        <Col xs={14} sm={9} md={7} lg={14} xl={8}>
                                            <h3 style={{ fontSize: 17 }}>{itemType.label}</h3>
                                        </Col>
                                        <Col
                                            xs={10}
                                            sm={15}
                                            md={16}
                                            lg={10}
                                            xl={8}
                                            className="btn-viewpage"
                                            style={{ textAlign: "right" }}
                                        >
                                            {index == 0 && (
                                                <Radio.Group
                                                    defaultValue="card"
                                                    buttonStyle="solid"
                                                    size="large"
                                                    optionType="button"
                                                    onChange={(e) => setViewMode(e.target.value)}
                                                >
                                                    <Radio.Button value="card">
                                                        <AiOutlineAppstore />
                                                    </Radio.Button>
                                                    <Radio.Button value="list">
                                                        <AiOutlineBars />
                                                    </Radio.Button>
                                                </Radio.Group>
                                            )}
                                        </Col>
                                    </Row>
                                </div>

                                {viewMode === "card" && (
                                    <div className="dashboard-content-content">
                                        <Row justify="start" gutter={[16, 16]}>
                                            {dataDashboard
                                                ?.filter(
                                                    (item) => item.dashboardCategoryId === itemType.value
                                                )
                                                .sort((a, b) => a.sequence - b.sequence)
                                                .map((item) => (
                                                    <Col sm={24} md={12} lg={8} xl={6} key={item.id}>
                                                        <div className="content-card-dashboard2">
                                                            <div>
                                                                <div className="content-image-dashboard">
                                                                    <img
                                                                        alt="Dashboard Image"
                                                                        src={
                                                                            item?.thumbnailSrc ??
                                                                            `${NoImg}`
                                                                        }
                                                                        onError={(e) => {
                                                                            if (e.target.alt === 'error') {
                                                                                e.target.src = NoImg;
                                                                            } else {
                                                                                e.target.src = NoImg;
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <Flex wrap gap="small">
                                                                        {item?.dashboardTags
                                                                            .slice(0, 3)
                                                                            .map((tag) => (
                                                                                <Tag
                                                                                    key={tag.id}
                                                                                    bordered={false}
                                                                                    color={tag.tagColor}
                                                                                    onClick={() => toggleButtonTag2(tag.tagId)}
                                                                                    className="tag"
                                                                                >
                                                                                    {tag.tagName}
                                                                                </Tag>
                                                                            ))}
                                                                    </Flex>
                                                                </div>
                                                                <div>
                                                                    <p className="content-card-title-dashboard">
                                                                        {item.name}
                                                                    </p>
                                                                    <p className="content-card-detail-dashboard">
                                                                        {/* {item.shortDescription} */}
                                                                        {item.shortDescription}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <Flex
                                                                justify="center"
                                                                align="center"
                                                                className="dashboard-detail-button-flex"
                                                                onClick={() => handleClick(item.id, item.name)}
                                                            >
                                                                <p>
                                                                    <span>
                                                                        ดูรายละเอียด
                                                                    </span>
                                                                    <IconRightArrowViewDashboard />
                                                                </p>
                                                            </Flex>
                                                        </div>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </div>
                                )}

                                {viewMode === "list" && (
                                    <div
                                        className="dashboard-hover-scroll"
                                        style={{ overflowX: "auto", overflowY: "hidden" }}
                                    >
                                        <Space>
                                            <List
                                                itemLayout="vertical"
                                                dataSource={dataDashboard
                                                    .filter(
                                                        (item) =>
                                                            item.dashboardCategoryId === itemType.value
                                                    )
                                                    .sort((a, b) => a.sequence - b.sequence)}
                                                renderItem={(item, index) => (
                                                    <>
                                                        <List.Item>
                                                            <Row
                                                                style={{
                                                                    alignItems: "center",
                                                                    // display: "flex",
                                                                    flexWrap: "nowrap",
                                                                }}
                                                            >
                                                                <Col
                                                                    style={{ height: "120px", width: "150px" }}
                                                                >
                                                                    <div
                                                                        className="dashboard-list-content"
                                                                    >
                                                                        <Flex
                                                                            justify="center"
                                                                            align="center"
                                                                            className="dashboard-list-content-flex"
                                                                        >
                                                                            <img
                                                                                alt="Dashboard Image"
                                                                                src={
                                                                                    item?.thumbnailSrc ??
                                                                                    `${NoImg}`
                                                                                }
                                                                                onError={(e) => {
                                                                                    if (e.target.alt === 'error') {
                                                                                        e.target.src = NoImg;
                                                                                    } else {
                                                                                        e.target.src = NoImg;
                                                                                    }
                                                                                }}
                                                                                className="dashboard-image"
                                                                            />
                                                                        </Flex>
                                                                    </div>
                                                                </Col>
                                                                <Col style={{ height: "auto", width: "340px" }}>
                                                                    <div
                                                                        className="dashboard-list-tag-container"
                                                                    >
                                                                        <Flex wrap gap="small">
                                                                            {item?.dashboardTags
                                                                                .slice(0, 3)
                                                                                .map((tag) => (
                                                                                    <Tag
                                                                                        key={tag.id}
                                                                                        bordered={false}
                                                                                        color={tag.tagColor}  // ใช้ tagColor ที่กำหนดให้เป็นสีตลอด
                                                                                        onClick={() => toggleButtonTag2(tag.tagId)} // เรียกฟังก์ชันเมื่อคลิก
                                                                                        className="tag" // ใช้คลาสเดียวกันตลอด
                                                                                    >
                                                                                        {tag.tagName}
                                                                                    </Tag>
                                                                                ))}
                                                                        </Flex>
                                                                        <p>
                                                                            {item.name}
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                                <Col style={{ height: "auto", width: "500px" }}>
                                                                    <div className="dashboard-list-descripition-container">
                                                                        <p className="title">
                                                                            รายละเอียด
                                                                        </p>
                                                                        <p>
                                                                            {item.shortDescription}
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                                <Col style={{ height: "auto", width: "200px" }}>
                                                                    <div className="dashboard-list-button-container">
                                                                        <div
                                                                            className="button"
                                                                            onClick={() =>
                                                                                handleClick(item.id, item.name)
                                                                            }
                                                                        >
                                                                            <Flex justify="center">
                                                                                <p className="flex">
                                                                                    ดูรายละเอียด
                                                                                </p>
                                                                                <IconRightArrowViewDashboard />
                                                                            </Flex>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </List.Item>
                                                    </>
                                                )}
                                            />
                                        </Space>
                                    </div>
                                )}
                            </div>
                        ))}

                    {dataDashboard.length == 0 && (
                        <Flex
                            vertical
                            justify="center"
                            align="center"
                            style={{ height: "50vh" }}
                        >
                            <NoData />
                            <p className="dashboard-list-empty">
                                ไม่มีข้อมูล
                            </p>
                        </Flex>
                    )}
                </div>
            </div>
            <AffilateButton screenPosition="80vh" onClick={
                () => { window.open(process.env.REACT_APP_AFFILIATE_URL) }
            } />
            <NoAuthDialog
                isModalOpen={isNotAllow}
                onCancel={() => {
                    setIsNotAllow(false);
                }}
                onAfterClose={() => {
                    setIsNotAllow(false);
                }}
                onConfirm={() => {
                    setIsNotAllow(false);
                }}
            />
            <LoadingDialog isLoading={isLoading > 0} />
        </>
    );
};

export default DashboardCategory;
