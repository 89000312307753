import keycloakConfig from "./KeycloakConfig";
import { OAuthService } from "../services/OAuthService";

export const handleLogin = () => {
  // const { url, realm, clientId, redirectUri } = keycloakConfig;
  // let test = `${url}/realms/${realm}/protocol/openid-connect/auth?response_type=code&scope=openid&client_id=${clientId}&redirect_uri=${encodeURIComponent(
  //   `${redirectUri}/oauth/callback`
  // )}`;
  // window.location.href = `${url}/realms/${realm}/protocol/openid-connect/auth?response_type=code&scope=openid&client_id=${clientId}&redirect_uri=${encodeURIComponent(
  //   `${redirectUri}/oauth/callback`
  // )}`;
  window.location.href = "/login";
};

export const handleRegister = () => {
  // const { url, realm, clientId, redirectUri } = keycloakConfig;
  // window.location.href = `${url}/realms/${realm}/protocol/openid-connect/registrations?response_type=code&scope=openid&client_id=${clientId}&redirect_uri=${encodeURIComponent(
  //   `${redirectUri}/oauth/callback`
  // )}`;
  window.location.href = "/register";
};

export const handleLogout = async () => {
  try {
    const refreshToken = sessionStorage.getItem("refreshToken");
    await OAuthService.logout(refreshToken);
    window.permissions = null;
    sessionStorage.clear();
    window.location.href = "/";
  } catch (error) {
    console.error();
  }
};
