const PageStateRoute = ({ children = [] }) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const pageState = localStorage.getItem('pageState');

  if (accessToken && pageState) {
    localStorage.removeItem('pageState');
    return (window.location.href = pageState);
  }
  return children;
};

export default PageStateRoute;
