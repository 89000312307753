import { ArrowLeftOutlined, CopyOutlined } from "@ant-design/icons";
import "./RequestApiMain.css";
import { ConfirmDialog, CustomButton, FailedDialog, LoadingDialog } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Col, Divider, Flex, Form, Input, message, Row, Select, Space, Table, Tabs, Tag, Tooltip } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { UserService } from "../../../../services/UserService";
import TextArea from "antd/es/input/TextArea";
import { AiOutlineLink } from "react-icons/ai";
import { DataSourceService } from "../../../../services/DataSourceService";
import { LiaReadme } from "react-icons/lia";
import { TbApi } from "react-icons/tb";

const { Option } = Select;

const RequestApiMain = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [dataUserMe, setDataUserMe] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isModalComfirmOpen, setIsModalComfirmOpen] = useState(false);
    const [isModalFailOpen, setIsModalFailOpen] = useState(false);
    const [formUserApi] = Form.useForm();

    const [formData, setFormData] = useState({
            companyName: "",
            address1: "",
            address2: "",
            provinceId: "",
            districtId: "",
            subdistrictId: "",
            requisitionReason: "",
            domain: "",
            zipcode:"",
            requisitionDetails: [{
                dataSourceId: ""
            }],
    });
    const [provinceList, setProvinceList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [subDistrictList, setSubDistrictList] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [requisitionsStatus, setRequisitionsStatus] = useState({});
    const [dataApiUser, setDataApiUser] = useState({
            companyName: "",
            address1: "",
            address2: "",
            provinceId: "",
            districtId: "",
            subdistrictId: "",
            domain: "",
            zipcode:"",
    });  

    useEffect(() => {
        fetchRequestAPIState();
    }, []);

    const fetchRequestAPIState = async () => {
        
        const responseUserMe = await UserService.getMe();
        //console.log("responseUserMe >> ",responseUserMe)
        UserService.getRequisitionsStatusByUserId(responseUserMe?.id).then((data)=>{
            console.log("fetchRequestAPIState >> data",data)
            setRequisitionsStatus(data);
            if(data?.hasRequisition === false && data?.isApproved === false){
                navigate(`/new-request-api`);
            }
        });
    };


// #region function for Tabs รายละเอียดข้อมูลการขอใช้ API 
    const handleChange = (e) => {
            const { name, value } = e.target;
            //console.log("name = ",name,"value = ",value,)
            // formUserApi.setFieldsValue({
            //     ...dataApiUser,
            //     [name]: value
            // });
        
        };
    const fetchProvinces = async () => {
            try {
                const response = await UserService.getProvinces();
                //console.log('fetchProvinces >>  response:', response);
                setProvinceList(response)
               
            } catch (error) {
                console.error('Error fetching job functions:', error);
            }
        };
    const handleChangeProvince = async (id) => {
            if(id){
                try {
                    const response = await UserService.getDistrictsByProvinceId(id);
                    console.log('getDistrictsByProvinceId >>  response:', response);
                    setDistrictList(response)
                    setSubDistrictList([])
    
                    formUserApi.setFieldsValue({
                        provinceId:id,
                        districtId:"",
                        subdistrictId:"",
                        zipcode:""
                    });
    
                } catch (error) {
                    console.error('Error fetching job functions:', error);
                }
            }
           
        };
    const handleChangeDistrict = async (id) => {
            if(id){
                try {
                    const response = await UserService.getSubDistrictsByDistrictsId(id);
                    setSubDistrictList(response)
                    formUserApi.setFieldsValue({
                        districtId:id,
                        subdistrictId:"",
                        zipcode:""
                    });
                
                } catch (error) {
                    console.error('Error fetching job functions:', error);
                }
            }
        };
    const handleChangeSubDistrict = async (id) => {
        if(id){
            try {
                let valObject = subDistrictList.filter((item) => item.id == id)
                formUserApi.setFieldsValue({
                    subdistrictId:id,
                    zipcode: valObject[0].zipCode
                });
               
            } catch (error) {
                console.error('Error fetching job functions:', error);
            }
        }
           
        };
    const handleClickSave = () => {
            setIsModalComfirmOpen(true);
        };
    
    const handleSave = async () => {
            try {
                let dataForm = formUserApi.getFieldsValue()
                let handleSavedata = {
                    companyName: dataForm.companyName,
                    address1: dataForm.address1,
                    //address2: formData.address2,
                    provinceId: dataForm.provinceId == "" ? null : formData.provinceId,
                    districtId: dataForm.districtId == "" ? null : formData.districtId,
                    subdistrictId: dataForm.subdistrictId == "" ? null : formData.subdistrictId,
                    domain: dataForm.domain,
                    zipcode:dataForm.zipcode.toString(),
                    
                }

                const response = await UserService.updateApiUserByUserId(dataApiUser.id,handleSavedata);
                console.log("response handleSave >> ",response)
                if(response){
                     setDataApiUser(response);
                     setIsEditMode(false);
                }
                
              
            } catch (error) {
                //const errorMessage = error?.response?.data?.messageTh || "Something went wrong. Please try again.";
                setIsModalFailOpen(true);
            }
            
        };
    const handleCancel = () => {
            setIsEditMode(false); // Turn off edit mode
        };
    
    const handleEdit = () => {
            setIsEditMode(true); // Turn on edit mode
        };
// #endregion Tabs รายละเอียดข้อมูลการขอใช้ API

// #region function for Tabs รายการ API 
    const [selectionType, setSelectionType] = useState('checkbox');
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        //setSelectedDataSourceList(selectedRowKeys);
        setFormData({
            ...formData,
            requisitionDetails: selectedRows?.map((item, index) => ({
                                    dataSourceId: item.key, 
                                }))
            });
        },
        getCheckboxProps: (record) => ({
        name: record.name,
        }),
    };
    const columns = [
        {
            title: 'รายการ API',
            //dataIndex: 'name',
            render: (record) => 
            <>
                <div className="requestapinew-item-data">
                    <Row justify={"space-between"} >
                        <Col xs={24} sm={12} md={14} style={{alignContent:"center"}}>
                            <Row style={{paddingBottom:10}}>
                                <span className="name" onClick={() => navigate(`/data-catalog/${record.urlSlug}`)}>{record.name}</span>
                            </Row>
                            
                            <Row style={{paddingBottom:5}}>
                            
                            </Row>
                            <Row>
                            <Flex wrap gap="small">
                                <span style={{color:"#667085" ,marginBottom:5}}>อัพเดทล่าสุด : {record.formattedUpdatedDate}</span>
                            </Flex>
                            </Row>
                            
                        
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={5} xxl={5} style={{alignContent:"start", textAlign:"end"}}>
                        
                        </Col>
                    </Row>
                    <Row justify={"space-between"} >
                        <Col xs={24}  style={{alignContent:"start", textAlign:"left"}}>
                                {/* <p>{dataCatalogData?.description}</p> */}
                                <div dangerouslySetInnerHTML={{ __html: record.description }} />
                        </Col>
                    </Row>

                </div>
            </>,
        },
        {
            title: 'วันที่ขอใช้ API',
            //dataIndex: 'name',
            width: 200,
            render: (record) => 
            <>
                <div style={{color:"#667085",verticalAlign:"top",minHeight:100}}>   
                    {record.formattedUpdatedDate}
                </div>
            </>,
        },
        ]

    const fetchDataSource = async () => {
            try {
                const responseUserMe = await UserService.getMe();
                const params = {
                TagIds:[],
                // LastCreatedDate: selectedDate,
                // Keywords: keywords,
                IsActivated: true,
                UserId:responseUserMe?.id
                };
                setTimeout(async () => {
                const response = await DataSourceService.searchPublic(params);
                //setDataSource(response.items);
                setDataSource(
                    response.items.map((item, index) => ({
                        key: item.id, 
                        name: item.name, 
                        description: item.description, 
                        urlSlug: item.urlSlug,
                        formattedUpdatedDate: item.formattedUpdatedDate, 
                      }))
                )
                //console.log("fetchDataSource >> response.items",response.items)
                }, 150); 
            } catch (error) {
               
                console.error(error);
            }
        };

// #endregion Tabs รายการ API 


    

    useEffect(() => {
            fetchProvinces();
            
    }, []);

    useEffect(() => {
        fetchData();
            
    }, []);

    const fetchData = async () => {

        try {
            setIsLoading(true);
            const responseUserMe = await UserService.getMe();
            setDataUserMe(responseUserMe);
            UserService.getRequisitionsStatusByUserId(responseUserMe?.id).then((data)=>{
                setRequisitionsStatus(data);
                UserService.getApiUserByUserId(responseUserMe?.id).then((dataApi)=>{
                    setDataApiUser(dataApi);
                    if(dataApi?.provinceId){
                        UserService.getDistrictsByProvinceId(dataApi?.provinceId).then((responseDistricts)=>{
                            setDistrictList(responseDistricts);
                        });
                    }
                    if(dataApi?.districtId){
                        UserService.getSubDistrictsByDistrictsId(dataApi?.districtId).then((responseSubDistricts)=>{
                            setSubDistrictList(responseSubDistricts);
                        });
                   }
                    
                    fetchDataSource();
                });
            });
        } catch (error) {
            console.error(error);
        }finally{
            setIsLoading(false);
        }
    };

    const setLoading = (visible) => {
        if (visible) {
          setIsLoading(isLoading + 1)
        } else {
          setIsLoading(isLoading - 1)
        }
      }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(dataApiUser?.clientId).then((xx) => {
            copiedMessage()
        }).catch((error) => {
            console.error('Error copying text: ', error);
        });
        
    };

    const [messageApi, contextHolder] = message.useMessage();
    const copiedMessage = () => {
        messageApi.open({
        type: 'success',
        content: 'copied',
        });
    };
    return (
        <>
           <div className="requestapi-main-body" >
            <div className="heading">
                <div className="content-card-title-top">
                
                    <Row>
                        <Col xs={12}>
                            <ArrowLeftOutlined className="arrow-back" onClick={()=>navigate(`/data-catalog`)}  />  <span style={{marginLeft:10,fontSize:18,fontWeight:600}}>ขอใช้ API</span>
                        </Col>
                        <Col xs={12} style={{textAlign:"right"}}>
                            {(!requisitionsStatus?.hasRequisition) &&(
                                <Button type={"primary"} style={{fontWeight:500}} onClick={()=>{navigate(`/new-request-api`);}}><LiaReadme />ขอใช้ API เพิ่มเติม</Button>
                            )}
                        </Col>
                    </Row>
                    
                    
                </div>
            </div>

            <div className="requestapi-main-container">
                <div className="detail">
                    <Tabs
                        defaultActiveKey="1"
                        tabPosition={"top"}
                        style={{
                        height: "max",
                        }}
                        >

                        {/* No approve */}
                        {(!requisitionsStatus?.isApproved || (requisitionsStatus?.latestRequisitionStatusId && requisitionsStatus?.latestRequisitionStatusId == "1")) &&(
                            <TabPane tab={"การดำเนินการ"} key={"1"}>
                            <>
                                <Space
                                    direction="vertical"
                                    size="middle"
                                    style={{
                                        display: "flex",
                                        
                                    }}
                                >
                                
                                    <div className="requestapinew-success-container">
                                        <div className="detail">
                                            <Row>
                                                <Col xs={24} sm={24} style={{paddingTop:10,paddingBottom:10}}>
                                                    <div style={{fontSize:20,fontWeight:600}}>ขอใช้ API  
                                                        <Tag style={{marginLeft:20}} color="volcano">อยู่ระหว่างพิจารณาอนุมัติการใช้งาน</Tag>
                                                    </div>
                                                </Col>
                                                
                                            </Row>
    
                                            <Divider style={{marginTop:15,marginBottom:20}}/> 
                                            
                                            <Row gutter={[16,8]}>
                                                <Col xs={24} md={24} lg={15} xl={14}>
                                                    <div className="form-requestapinew">
                                                        <div className="box-name">
                                                            <div className="name">
                                                                {dataUserMe?.fullName}
                                                            </div>
                                                            <div className="email">
                                                                {dataUserMe?.email}
                                                            </div>
                                                            <div className="email">
                                                                    {/* <span>{dataAfterSave?.companyName} , {dataAfterSave?.domain}</span> */}
                                                            </div>
                                                        </div>
                                                        <Divider style={{marginTop:20,marginBottom:20}}/> 
                                                        {requisitionsStatus?.isApproved ? (
                                                            <div className="box-color">
                                                               <p className="name">
                                                                   คุณได้ลงทะเบียนสำเร็จแล้ว
                                                               </p>
                                                               <div className="email">
                                                                   คุณได้ลงทะเบียนสำเร็จแล้ว เราได้รับข้อมูลของคุณแล้ว หากได้รับการอนุมัติ 
                                                                   ระบบจะแจ้งให้คุณทราบ ผ่านทางอีเมลของคุณ
                                                               </div>
                                                               <div className="email" style={{marginTop:20}}>
                                                                   อีเมลของคุณคือ  "{dataUserMe?.email}"
                                                               </div>
                                                           </div>
                                                        ):(
                                                            <div className="box-color">
                                                                <p className="name">
                                                                    คุณได้ลงทะเบียนสำเร็จแล้ว
                                                                </p>
                                                                <div className="email">
                                                                    คุณได้ลงทะเบียนสำเร็จแล้ว เราได้รับข้อมูลของคุณแล้ว หากได้รับการอนุมัติ 
                                                                    รหัสผู้ใช้งาน (Client ID) และรหัสลับ (Client Secret) จะถูกส่งไปยังอีเมลของคุณ และจะถูกแสดง อยู่ใน บัญชีของคุณ
                                                                </div>
                                                                <div className="email" style={{marginTop:20}}>
                                                                    อีเมลของคุณคือ  "{dataUserMe?.email}"
                                                                </div>
                                                            </div>
                                                        )}
    
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Space>
                            
                            </>
                            </TabPane>
                        )}

                        {requisitionsStatus?.isApproved &&(
                            <TabPane tab={"การเข้าถึง API"} key={"2"}>
                            <>
                                <Space
                                    direction="vertical"
                                    size="middle"
                                    style={{
                                        display: "flex",
                                        
                                    }}
                                >
                                
                                    <div className="api-client-container">
                                        <Row>
                                            <Col xs={24}>
                                                <div style={{fontSize:22,fontWeight:600,marginBottom:20}}>การเข้าถึง API</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={24}>
                                                <div className="box">
                                                {contextHolder}
                                                    <div style={{fontSize:18,fontWeight:600}}>ค่าเริ่มต้น</div>
                                                    <Divider style={{borderColor:"#D0D5DD"}}/> 
                                                    <Row>
                                                        <Col lg={9}>
                                                            <div className="label">รหัสผู้ใช้งาน (Client ID)</div>
                                                            <Input
                                                                readOnly
                                                                className="input"
                                                                type="text"
                                                                name="lastName"
                                                                value={dataApiUser?.clientId}
                                                                suffix={
                                                                    <Tooltip title="คัดลอก">
                                                                        <CopyOutlined
                                                                            onClick={copyToClipboard}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                            />
                                                            <div className="label">รหัสลับ (Client Secret)</div>
                                                            <Input.Password
                                                                readOnly
                                                                className="input"
                                                                //type="text"
                                                                name="lastName"
                                                                value={dataApiUser?.clientSecret}
                                                                suffix={
                                                                    <Tooltip title="คัดลอก">
                                                                        <CopyOutlined
                                                                            //onClick={copyToClipboard}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    
                                                </div>
                                            </Col>
                                            <Col lg={15}>
                                                
                                            </Col>
                                        </Row>
                                    </div>
                                   
                                </Space>
                            
                            </>
                            </TabPane>
                        )}

                        {requisitionsStatus?.isApproved &&(
                        <TabPane tab={"รายละเอียดข้อมูลการขอใช้ API"} key={"3"}>
                        <>
                            <Space
                            direction="vertical"
                            size="middle"
                            style={{
                                display: "flex",
                                
                            }}
                            >
                            
                                <div className="input-container">
                                    <div className="detail">
                                        <Row>
                                            <Col xs={24} md={20} lg={15} xl={14}>
                                                <Row>
                                                    <Col span={12} style={{paddingTop:10,paddingBottom:10}}>
                                                        <div style={{fontSize:18,fontWeight:600}}>รายละเอียดข้อมูลการขอใช้ API</div>
                                                    </Col>
                                                    <Col span={12} style={{paddingTop:10,paddingBottom:10,paddingRight:10,textAlign:"right"}}>
                                                    {isEditMode ? (
                                                        <>
                                                         <CustomButton text={"ยกเลิก"} onClick={handleCancel}/>
                                                         <CustomButton type={"primary"} text={"บันทึก"} onClick={handleClickSave} />   
                                                        </>
                               
                                                    ) : (
                                                        <CustomButton text={"แก้ไข"} onClick={handleEdit}/>
                                                    )}
                                                        
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Divider style={{marginTop:10,marginBottom:10}}/> 
                                        
                                        <Row gutter={[16,8]}>
                                            <Col xs={24} md={20} lg={15} xl={14}>
                                                <div className="form-requestapinew">
                                                    <div className="box-color">
                                                        <p className="name">
                                                            {dataUserMe?.fullName}
                                                        </p>
                                                        <span className="email">
                                                            {dataUserMe?.email}
                                                        </span>
                                                    </div>
                                                    <div className="box-input">
                                                        <Form 
                                                            layout="vertical"
                                                            onFinish={handleClickSave}
                                                            labelCol={{ span: 8 }}
                                                            wrapperCol={{ span: 16 }}
                                                            initialValues={dataApiUser}
                                                            form={formUserApi}
                                                            >
                                                            <Row >
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="ชื่อบริษัท" name="companyName" 
                                                                    rules={[{ required: true, message: "กรุณากรอกชื่อบริษัท" }]}
                                                                    >
                                                                         {isEditMode ? (
                                                                            <Input
                                                                                placeholder="ชื่อบริษัท"
                                                                                name="companyName"
                                                                                value={dataApiUser?.companyName}
                                                                                onChange={handleChange}
                                                                            />
                                                                        ) : (
                                                                            <div className="label-value">{dataApiUser?.companyName}</div>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={24}>
                                                                <p style={{fontSize:16,fontWeight:600}}>ที่อยู่บริษัท</p>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label="จังหวัด" name="provinceId">
                                                                        {isEditMode ? (
                                                                            <Select 
                                                                            placeholder="Select"
                                                                            value={dataApiUser?.provinceId}
                                                                            name="provinceId"
                                                                            onChange={(value) => {
                                                                                handleChange({ target: { name: 'provinceId', value } })
                                                                                handleChangeProvince(value);
                                                                            }}
                                                                            >
                                                                                <Option value="" disabled>{"Select"}</Option>
                                                                                {provinceList?.map((item) => (
                                                                                    <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                        ) : (
                                                                            <div className="label-value">{dataApiUser?.provinceNameTh}</div>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label="อำเภอ/เขต" name="districtId">
                                                                        {isEditMode ? (
                                                                             <Select 
                                                                                placeholder="Select"
                                                                                value={dataApiUser?.districtId}
                                                                                name="districtId"
                                                                                onChange={(value) => {
                                                                                    handleChange({ target: { name: 'districtId', value } })
                                                                                    handleChangeDistrict(value);
                                                                                }}
                                                                                >
                                                                                    <Option value="" disabled>{"Select"}</Option>
                                                                                    {districtList?.map((item) => (
                                                                                        <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                                                                    ))}
                                                                             </Select>
                                                                        ) : (
                                                                            <div className="label-value">{dataApiUser?.districtNameTh}</div>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label="ตำบล/แขวง" name="subdistrictId">
                                                                        {isEditMode ? (
                                                                             <Select 
                                                                                value={dataApiUser?.subdistrictId}
                                                                                name="subdistrictId"
                                                                                onChange={(value) => {
                                                                                    //handleChange({ target: { name: 'subdistrictId', value } })
                                                                                    handleChangeSubDistrict(value);
                                                                                }}
                                                                                >
                                                                                    <Option value="" disabled>{"Select"}</Option>
                                                                                    {subDistrictList?.map((item) => (
                                                                                        <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                                                                    ))}
                                                                             </Select>
                                                                        ) : (
                                                                            <div className="label-value">{dataApiUser?.subdistrictNameTh}</div>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label="รหัสไปรณีย์" name="zipcode">
                                                                        {isEditMode ? (
                                                                             <Input placeholder="รหัสไปรณีย์" name="zipcode" value={dataApiUser?.zipcode} onChange={handleChange}/>
                                                                        ) : (
                                                                            <div className="label-value">{dataApiUser?.zipcode}</div>
                                                                        )}
                                                                        
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="รายละเอียดที่อยู่" name="address1">
                                                                        {isEditMode ? (
                                                                             <TextArea placeholder="รายละเอียด" name="address1" value={dataApiUser?.address1} onChange={handleChange} autoSize={{ minRows: 3, maxRows: 5 }} />
                                                                        ) : (
                                                                            <div className="label-value">{dataApiUser?.address1}</div>
                                                                        )}
                                                                        
                                                                    </Form.Item>
                                                                </Col>
                                                               
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="โดเมน ที่เอาไปใช้งาน" name="domain" rules={[{ required: true, message: "กรุณากรอกที่อยู่โดเมน" }]}>
                                                                        {isEditMode ? (
                                                                             <Input placeholder="https://tceb.com..." name="domain" value={dataApiUser?.domain} onChange={handleChange} prefix={<AiOutlineLink />}/>
                                                                        ) : (
                                                                            <div className="label-value">{dataApiUser?.domain}</div>
                                                                        )}
                                                                        
                                                                    </Form.Item>
                                                                </Col>
                                                                
                                                            </Row>

                                                            
                                                        </Form>
                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Space>
                        
                        </>
                        </TabPane>
                        )}

                        {requisitionsStatus?.isApproved &&(
                        <TabPane tab={"รายการ API"} key={"4"}>
                        <>
                            <Space
                            direction="vertical"
                            size="middle"
                            style={{
                                display: "flex",
                                
                            }}
                            >
                            
                                 <div className="api-list-container">
                                    <div className="detail">
                                        <Row>
                                            <Col xs={12} sm={12} style={{paddingTop:10,paddingBottom:10}}>
                                                <div style={{fontSize:18,fontWeight:600}}>รายการขอใช้ API</div>
                                            </Col>
                                           
                                        </Row>
                                        <Divider style={{marginTop:10,marginBottom:10}}/> 
                                        <Row gutter={[16,8]}>
                                            <Col xs={24}>
                                                <div>
                                                    <Table
                                                       
                                                        columns={columns}
                                                        dataSource={dataSource}
                                                        pagination={false}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Space>
                        
                        </>
                        </TabPane>
                        )}

                        <TabPane tab={"คู่มือการใช้งาน"} key={"5"}>
                        <>
                            <Space
                            direction="vertical"
                            size="middle"
                            style={{
                                display: "flex",
                                
                            }}
                            >
                            <div className="manual-container">
                                <div className="detail">
                                    <Row>
                                        <Col xs={12} sm={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                            <div style={{ fontSize: 18, fontWeight: 600 }}>คู่มือการใช้งาน</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>
                                            <Form layout="vertical">
                                            <div className="manual-content" style={{lineHeight:2}}>
                                                <ul>
                                                    {/*<li>สมัครสมาชิกเพื่อขอใช้งาน APIs&nbsp;</li>
                                                    <li>เลือกเมนูที่ชื่อว่า “ดาต้าแคตตาล๊อก”</li>
                                                    <li>กดปุ่ม "ขอใช้ API"</li>
                                                    <li>เลือกดาต้าแค๊ตตาล๊อกที่ต้องการ</li>
                                                    <li>ทำการกรอกข้อมูลที่อยู่ขององค์กร และกดปุ่ม "ขอใช้ API"</li>
                                                    <li>ทำการกรอกรายละเอียดของท่านเพื่อประกอบการพิจารณาใช้งาน API</li>
                                                    <li>เมื่อระบบอนุมัติท่าจะได้รับ Client ID กับ Client Secret เพื่อใช้ในการออก Access Token ก่อนเรียกใช้งาน API</li>
                                                    */}
                                                    <li>วิธีใช้งาน API&nbsp;
                                                        <ol>
                                                            <li>เรียกใช่งาน Login API เพื่อขอ Access Token ดังนี้<br/>
                                                            <Form.Item
                                                                label="API สำหรับการขอ Access Token"
                                                                
                                                                style={{width: '100%' }}
                                                            >
                                                                <Input
                                                                    prefix={<div className="http-prefix">POST</div>}
                                                                    readOnly
                                                                    value={window.location.origin + "/api/auth/login"}
                                                                    placeholder="API สำหรับการขอ Access Token"
                                                                />
                                                            </Form.Item>
                                                                <Form.Item
                                                                    label="Request Body"
                                                                    style={{width: '100%' }}
                                                                >
                                                                    <TextArea 
                                                                        rows={4}
                                                                        value={JSON.stringify(
                                                                        {
                                                                            clientId: "your clientId",
                                                                            clientSecret: "your clientSecret",
                                                                        }, null, 2)}
                                                                        readOnly
                                                                        style={{color:"#000"}}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Response"
                                                                    style={{width: '100%' }}
                                                                >
                                                                    <TextArea 
                                                                        rows={9}
                                                                        value={JSON.stringify({
                                                                            "access_token": "string",
                                                                            "expires_in": "integer",
                                                                            "token_type": "string",
                                                                            "id_token": "string",
                                                                            "not-before-policy": "integer",
                                                                            "session_state": "string",
                                                                            "scope": "string"
                                                                        }, null, 2)}
                                                                        readOnly
                                                                        style={{color:"#000"}}
                                                                    />
                                                                </Form.Item>
                                                                
                                                            </li>
                                                            <li>ในการใช้งาน API ต่างๆ จำเป็นต้องแนบ Access Token ผ่าน Headers เพื่อเป็นการตรวจสอบสิทธิ์การใช้งาน และข้อจำกัดต่างๆที่ท่านสามารถใช้งาน API ได้ ตัวอย่างเช่น
                                                                <Row>
                                                                    <Col sm={24} md={18} lg={18} xl={16} xxl={12}>
                                                                    <Form.Item
                                                                    label="Request Header"
                                                                    style={{width: '100%' }}
                                                                >
                                                                    <TextArea 
                                                                        rows={3}
                                                                        value={JSON.stringify({
                                                                            "Authorizattion": "Bearer <your token>"
                                                                        }, null, 2)}
                                                                        disabled
                                                                        style={{color:"#000"}}
                                                                    />
                                                                </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li>
                                                                โดยท่านสามารถดูรายละเอียดเพิ่มเติมได้ที่ <Button type={""} style={{ marginLeft: 10, fontWeight: 500, width: "auto", borderRadius: 10 }} onClick={() => window.open(process.env.REACT_APP_API_DOC_URL, "_blank")}><TbApi />API Doc</Button>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                </ul>
                                            </div>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            </Space>
                        
                        </>
                        </TabPane>

                    </Tabs>
                </div>
              
            </div>

        
           
            <ConfirmDialog
                isModalOpen={isModalComfirmOpen}
                closable={false}
                title="ยืนยันการขอใช้ API"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                type={"requestApi"}
                onCancel={() => {
                    setIsModalComfirmOpen(false);
                }}
                onConfirm={() => {
                    handleSave();
                }}
                description="คุณต้องการขอใช้ API ใช่หรือไม่"
            />

            <FailedDialog
                isModalOpen={isModalFailOpen}
                closable={false}
                title="ไม่สำเร็จ"
                //detail={errorMessage}  
                onCancel={() => {
                    setIsModalFailOpen(false);
                }}
                onAfterClose={() => {
                    setIsModalFailOpen(false);
                }}
                onConfirm={() => {
                    setIsModalFailOpen(false);
                }}
            />
            <LoadingDialog isLoading={isLoading} />
        </div>
        </>
        )
   
    
}

export default RequestApiMain;