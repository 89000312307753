import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUnsavedChangesContext } from "../../../../../../contexts";
import { useUnsavedChangesWarning } from "../../../../../../hooks";
import {
  Layout,
  Button,
  Divider,
  Input,
  Row,
  Col,
  Card,
  notification,
  Grid,
  Flex,
  message,
} from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./MangeconsentDetail.css";
import dayjs from "dayjs";
import { ConsentService } from "../../../../../../services/ConsentService";
import { LoadingDialog, DiscardDialog } from "../../../../../../components";
import ErrorMessageHandler from "../../../../../../helpers/ErrorMessageHandler";

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const MangecontentDetail = () => {
  const { isDataModified, setIsDataModified } = useUnsavedChangesContext();
  const {
    handleNavigation,
    handleUnsavedConfirm,
    handleUnsavedCancel,
    setIsUnsavedModalOpen,
    isUnsavedModalOpen,
  } = useUnsavedChangesWarning();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const quillRef = useRef(null);
  const [detailError, setDetailError] = useState(null);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { selectedData } = location.state || {};
  const { latestVersion = "1.0.0" } = location.state || {};
  const incrementVersion = (version) => {
    const versionParts = version.split(".");
    const major = parseInt(versionParts[0], 10) || 0;
    const minor = parseInt(versionParts[1], 10) || 0;
    const patch = parseInt(versionParts[2], 10) || 0;

    return `${major}.${minor}.${patch + 1}`;
  };
  const [data, setData] = useState({
    content: "",
    name: "",
    version: incrementVersion(latestVersion),
  });

  // useEffect(() => {
  //   console.log("Initial Data:", data);
  // }, [data]);

  useEffect(() => {
    if (selectedData) {
      setData({
        name: selectedData.name || "",
        content: selectedData.content || "",
        version: selectedData.version || "1.0.0",
      });
    }
  }, [selectedData]);

  const handleOnEditTextChange = (value) => {
    // setIsDataModified(true);
    const cleanedValue = value.trim() === "<p><br></p>" ? "" : value;

    if (cleanedValue.length <= 15000) {
      setData((prevData) => ({ ...prevData, content: cleanedValue }));
      setDetailError(null);
    } else {
      setDetailError("ข้อความยาวเกินไป (สูงสุด 15000 ตัวอักษร)");
    }
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setData((prevData) => ({ ...prevData, name: value }));
  };

  const handleSaveDraft = async () => {
    setLoading(true);
    var cleanedValue = data.content.replace(/<p>\\*/g, "");
    cleanedValue = cleanedValue.replace(/<\/p>\\*/g, "<br />");
    try {
      if (selectedData === undefined) {
        var result = await ConsentService.create({
          name: data.name,
          content: cleanedValue,
          isPublished: false,
        });
        if (result.length > 0) {
          notification.success({
            message: "บันทึกฉบับร่างสำเร็จ",
          });
          handleUnsavedConfirm();
          navigate("/management/manage-consent");
        }
      } else {
        var result = await ConsentService.update({
          id: selectedData.id,
          name: data.name,
          content: cleanedValue,
        });
        if (result > 0) {
          notification.success({
            message: "บันทึกฉบับร่างสำเร็จ",
          });
          handleUnsavedConfirm();
          navigate("/management/manage-consent");
        }
      }
    } catch (error) {
      ErrorMessageHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAndPublish = async () => {
    setLoading(true);
    var cleanedValue = data.content.replace(/<p>\\*/g, "");
    cleanedValue = cleanedValue.replace(/<\/p>\\*/g, "<br />");

    try {
      if (selectedData === undefined) {
        var result = await ConsentService.create({
          name: data.name,
          content: cleanedValue,
          isPublished: true,
        });
        if (result.length > 0) {
          notification.success({
            message: "บันทึกและเผยแพร่สำเร็จ",
          });
          handleUnsavedConfirm();
          navigate("/management/manage-consent");
        }
      } else {
        var result = await ConsentService.update({
          id: selectedData.id,
          name: data.name,
          content: cleanedValue,
          isPublished: true,
        });
        if (result > 0) {
          notification.success({
            message: "บันทึกและเผยแพร่สำเร็จ",
          });
          handleUnsavedConfirm();
          navigate("/management/manage-consent");
        }
      }
    } catch (error) {
      ErrorMessageHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const getDetailLength = () => data.content.length;

  const modules = {
    toolbar: [
      { header: [1, 2, 3, 4, 5, 6, false] },
      "bold",
      "italic",
      "underline",
      "link",
      // { list: "bullet" },
      // { list: "ordered" },
    ],
    htmlEditButton: {
      debug: true,
      msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
      okText: "Ok", // Text to display in the OK button, default: Ok,
      cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
      buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
      buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
      syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
      prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
      editorModules: {}, // The default mod
    },
  };

  return (
    <div className="consent-management-body">
      <Layout>
        <Header
          style={{
            height: "100%",
            background: "#FFF",
            borderBottom: "1px solid #D0D5DD",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <AiOutlineArrowLeft
              style={{ fontSize: "24px", cursor: "pointer" }}
              onClick={() => {
                handleNavigation("/management/manage-consent", {
                  state: selectedData ? { consentId: selectedData.id } : {},
                });
              }}
            />
            <p
              style={{
                margin: "0",
                fontSize: !screens.lg ? "14px" : "18px",
                fontWeight: "500",
              }}
            >
              {screens.xs ? "" : selectedData ? "แก้ไขฟอร์ม" : "สร้างฟอร์ม"}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: !screens.lg ? "5px" : "10px",
            }}
          >
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: !screens.lg ? "14px" : "16px",
              }}
              onClick={() => {
                if (data?.name) {
                  handleSaveDraft();
                } else {
                  message.error("กรุณากรอกชื่อ!");
                }
              }}
            >
              บันทึกฉบับร่าง
            </span>
            |
            <Button
              type="primary"
              onClick={() => handleNavigation("/management/manage-consent")}
              style={{
                background: "#EBEBEB",
                height: !screens.sm ? "30px" : "40px",
                width: !screens.sm ? "100px" : "140px",
                borderRadius: "10px",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                ยกเลิก
              </span>
            </Button>
            <Button
              type="primary"
              style={{
                height: !screens.sm ? "30px" : "40px",
                width: !screens.sm ? "100px" : "140px",
                borderRadius: "10px",
              }}
              onClick={() => {
                if (data?.name) {
                  handleSaveAndPublish();
                } else {
                  message.error("กรุณากรอกชื่อ!");
                }
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                บันทึกและเผยแพร่
              </span>
            </Button>
          </div>
        </Header>
        <Layout>
          <Content style={{ padding: "20px 0px" }}>
            <Row justify="center" gutter={[16, 16]}>
              <Col span={24}>
                <div
                  style={{
                    margin: "0 14px",
                    padding: "14px",
                    background: "#FFF",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      อัปเดตล่าสุด :{" "}
                      <span style={{ fontSize: "14px", fontWeight: "300" }}>
                        {dayjs(data.updatetedDate ?? data.createdDate).format(
                          "DD/MM/BBBB HH:mm:ss"
                        )}
                      </span>
                    </span>
                    เวอร์ชั่น : {data.version}
                  </div>
                  <Divider />
                  <div style={{ marginBottom: "16px" }}>
                    <label style={{ fontSize: "14px", fontWeight: "600" }}>
                      ชื่อ:
                    </label>
                    <Input
                      placeholder="กรอกชื่อ"
                      style={{ marginTop: "8px" }}
                      value={data.name}
                      onChange={handleNameChange}
                    />
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <Card
                  bordered={true}
                  style={{
                    margin: "0 14px",
                    backgroundColor: "#FFFFFF",
                    textAlign: "left",

                    borderTop: "8px solid #000000",
                  }}
                >
                  <p
                    style={{ margin: "0", fontSize: "18px", fontWeight: "600" }}
                  >
                    หัวข้อ / คำอธิบาย <span style={{ color: "red" }}>*</span>
                  </p>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-end",
                    }}
                  >
                    <ReactQuill
                      ref={quillRef}
                      theme="snow"
                      value={data.content}
                      modules={modules}
                      style={{ height: 600, width: "100%" }}
                      onChange={handleOnEditTextChange}
                    />
                    {detailError && (
                      <span style={{ color: "red" }}>{detailError}</span>
                    )}
                    <span
                      style={{
                        color: "#9ca0a6",
                        marginTop: detailError ? 0 : 40,
                      }}
                    >
                      {getDetailLength()}/15000
                    </span>
                  </div>
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>

      <LoadingDialog loading={loading} />

      <DiscardDialog
        isModalOpen={isUnsavedModalOpen}
        closable={false}
        title="ยกเลิกการเปลี่ยนแปลง"
        cancelText="ยกเลิก"
        confirmText="ยืนยัน"
        onCancel={() => {
          handleUnsavedCancel();
        }}
        onConfirm={() => {
          handleUnsavedConfirm();
        }}
      />
    </div>
  );
};

export default MangecontentDetail;
