import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const DataSourceService = {
    getIdName: async function (params, cancel = false) {
      const response = await api.request({
        url: `/datasource/id-name`,
        method: "GET",
        params: params,
        signal: cancel
          ? cancelApiObject[this.getIdName.name].handleRequestCancellation().signal
          : undefined,
      });

      return response.data;
    },
  search: async function (params, cancel = false) {
    const response = await api.request({
      url: `/datasource`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/datasource`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  saveBulk: async function (data, cancel = false) {
    const response = await api.request({
      url: `/datasource/bulk`,
      method: "POST",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.saveBulk.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  update: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/datasource/${id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  updateActivated: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/datasource/${id}/is-activated`,
      method: "PATCH",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.updateActivated.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/datasource/${id}`,
      method: "DELETE",
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  searchPublic: async function (params, cancel = false) {
    const query = new URLSearchParams();
    if (params.TagIds && Array.isArray(params.TagIds)) {
      params.TagIds.forEach((id) => {
        query.append("TagIds", id);
      });
      delete params.TagIds; 
    }
    if (params.Ids && Array.isArray(params.Ids)) {
      params.Ids.forEach((id) => {
        query.append("Ids", id);
      });
      delete params.Ids;
    }
    const response = await api.request({
      url: `/datasource/public?${query.toString()}`,
      method: "GET",
      params: params, 
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getByUrlSlug: async function (urlSlug, cancel = false) {
    const response = await api.request({
      url: `/datasource/by-url-slug?urlSlug=${urlSlug}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getByUrlSlug.name].handleRequestCancellation()
          .signal
        : undefined,
    });
    return response;
  },
  getDownloadByUrlSlug: async function (urlSlug, type, cancel = false) {
    const response = await api.request({
      url: `/datasource/by-url-slug/download?urlSlug=${urlSlug}&type=${type}`,
      method: "GET",
      responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.getDownloadByUrlSlug.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getAssociationList: async function (cancel = false) {
    const response = await api.request({
      url: `/datasource/association-list`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[
          this.getAssociationList.name
        ].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
};

const cancelApiObject = defineCancelApiObject(DataSourceService);
