import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./InsightReportManagement.css";
import {
  Layout,
  Flex,
  Button,
  Divider,
  Input,
  Menu,
  Switch,
  Space,
  Row,
  Col,
  Tag,
  Grid,
  Dropdown,
} from "antd";
import {
  DeleteButton,
  PinButton,
  SuccessDialog,
  DeleteDialog,
  FailedDialog,
  LoadingDialog,
} from "../../../../../components";
import { AiOutlineSearch } from "react-icons/ai";
import { MenuFoldOutlined } from "@ant-design/icons";
import NoData from "../../../../../assets/img/no_data.js";
import NoImg from "../../../../../assets/img/no_img.png";
import { InsightReportService } from "../../../../../services/InsightReportService.js";
import { InsightReportCategoryService } from "../../../../../services/InsightReportCategoryService.js";
import { FileService } from "../../../../../services/FileService.js";
import { UserService } from "../../../../../services/UserService.js";

const { Header, Content, Sider } = Layout;
const { useBreakpoint } = Grid;
const InsightReportManagement = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [select, setSelect] = useState();
  const [selectedMenu, setSelectedMenu] = useState();
  const [insightList, setInsightList] = useState([]);
  const [insightCategory, setInsightCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectDelete, setSelectDelete] = useState();
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const passData = useLocation();
  let categoryId = "";
  if (passData.state !== null && passData.state.categoryId !== null) {
    categoryId = passData.state.categoryId;
  }
  let tmpSelectedMenu = null;
  if (passData.state !== null && passData.state.selectedMenu !== null) {
    tmpSelectedMenu = passData.state.selectedMenu;
  }
  const [prevId, setPrevId] = useState(categoryId);

  useEffect(() => {
    setSelect(categoryId);
    setPrevId(categoryId);
    setSelectedMenu(tmpSelectedMenu);
    UserService.isEditable().then((res) => {
      setIsEditable(res);
    });
  }, []);

  useEffect(() => {
    if (select) {
      fetchData();
    }
  }, [select]);

  useEffect(() => {
    if (!insightCategory) {
      fetchInsightCategory();
    }
  }, [insightCategory]);

  useEffect(() => {
    if (keyword) {
      const delayDebounce = setTimeout(() => {
        if (select) {
          fetchData();
        }
      }, 1000);

      return () => clearTimeout(delayDebounce);
    } else if (select) {
      fetchData();
    }
  }, [keyword]);

  const fetchData = async () => {
    try {
      if (select === undefined) {
        return;
      }
      setLoading(true);
      const params = {
        InsightReportCategoryIds: select,
        Keywords: keyword,
      };
      const response = await InsightReportService.search(params);
      setInsightList(response);

      const delayPerItem = 500;
      const totalDelay = response?.items?.length * delayPerItem;
      
      setTimeout(() => {
        setLoading(false);
      }, totalDelay);

    } catch (error) {
      setLoading(false);
      console.error();
    } finally {
      setLoading(false);
    }
  };

  const fetchInsightCategory = async () => {
    try {
      setLoading(true);

      const params = {
        // Keywords: ,
      };

      const response = await InsightReportCategoryService.search(params);
      setInsightCategory(response);

      if (!categoryId) {
        if (response?.items?.length !== 0) {
          setSelect(response?.items[0].id);
          setPrevId(response?.items[0].id);
          setSelectedMenu(response?.items[0].name);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateActivated = async (checked, item) => {
    try {
      setLoading(true);
      const data = {
        id: item.id,
        isActivated: checked,
      };

      await InsightReportService.updateActivated(item.id, data);
      fetchData();
    } catch (error) {
      console.error();
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    }
  };

  const handleUpdatePinned = async (checked, item) => {
    setLoading(true);
    setPinLoading(item.id);
    try {
      const data = {
        id: item.id,
        isPinned: checked,
      };

      await InsightReportService.updatePinned(item.id, data);
      setPinLoading(false);
      fetchData();
    } catch (error) {
      setPinLoading(false);
      console.error();
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    }
  };

  const handleDeleteInsightReportById = async () => {
    try {
      setLoading(true);
      await InsightReportService.delete(selectDelete);
      setSelectDelete();
      setIsModalDeleteOpen(false);
      setIsModalSuccessOpen(true);
      setLoading(false);
      setTimeout(() => {
        setIsModalSuccessOpen(false);
      }, 2000);
      fetchInsightCategory();
      fetchData();
    } catch (error) {
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
      console.error();
      setLoading(false);
    }
  };

  const handleSearchInsightReport = () => {
    fetchData();
  };

  const handleOpenFile = (fileRawName, item) => {
    const file = item.insightReportFiles.find(
      (f) => f.fileRawName === fileRawName
    );
    if (file && file.fileSrc) {
      window.open(file.fileSrc, "_blank");
    }
  };

  // const downloadFile = async (item) => {
  //   let file = item?.insightReportFiles.find(
  //     (item) => item?.fileType === "insight_report_file"
  //   );

  //   try {
  //     const response = await FileService.get(file?.fileId);

  //     const url = window.URL.createObjectURL(response);

  //     const link = document.createElement("a");
  //     link.href = url;
  //     // link.href = file?.fileSrc;
  //     link.download = file?.fileRawName;
  //     document.body.appendChild(link);

  //     link.click();

  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error(error);
  //     setIsModalFailedOpen(true);
  //   }
  // };

  return (
    <>
      <Header
        style={{
          height: "70px",
          background: "#FFF",
          borderBottom: "1px solid #D0D5DD",
        }}
      >
        <Flex justify="space-between" align="center" style={{ height: "100%" }}>
          <p
            style={{
              fontSize: screens.xs ? "16px" : "20px",
              fontWeight: "600",
            }}
          >
            จัดการข้อมูลรายงานวิเคราะห์
          </p>
          {isEditable && (
            <Button
              type="primary"
              onClick={() =>
                navigate(
                  {
                    pathname: `/management/insight-report-management-detail`,
                  },
                  {
                    state: {
                      categoryId: select,
                      keyword: keyword,
                      selectedMenu: selectedMenu,
                    },
                  }
                )
              }
              style={{
                float: "right",
                width: screens.xs ? "100px" : "140px",
                height: "40px",
                fontSize: screens.xs ? "14px" : "16px",
                fontWeight: "500",
                background: "#63E1E1",
                borderRadius: "10px",
              }}
            >
              + เพิ่ม/แก้ไข
            </Button>
          )}
        </Flex>
      </Header>
      <Layout>
        <Sider
          width={!screens.xl ? 0 : 302}
          style={{
            margin: !screens.xl ? "0px 14px 0px 0px" : "14px 14px 0px 14px",
            background: "#FFF",
            borderRadius: "8px",
            height: "calc(100vh - 270px)",
            overflowY: "hidden",
          }}
        >
          <div style={{ padding: "14px" }}>
            <p
              style={{
                margin: "0",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              ประเภทรายงานวิเคราะห์ (
              {insightCategory?.pagination?.totalCount || 0})
            </p>
            <Input
              placeholder="ค้นหา ชื่อรายงานวิเคราะห์..."
              allowClear
              suffix={
                <AiOutlineSearch
                  onClick={() => handleSearchInsightReport()}
                  style={{
                    color: "rgba(0,0,0,.45)",
                    cursor: "pointer",
                  }}
                />
              }
              onChange={(e) => setKeyword(e.target.value)}
              onPressEnter={() => handleSearchInsightReport()}
              size="large"
              style={{ marginTop: "14px", borderRadius: "8px" }}
            />
          </div>
          <Divider style={{ margin: "0", borderColor: "#D0D5DD" }} />
          {insightCategory && insightCategory?.items?.length !== 0 ? (
            <div
              style={{
                padding: "14px",
                height: "calc(100% - 110px)",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                scrollbarColor: "#999 #f5f5f5",
                scrollbarTrackColor: "#f5f5f5",
              }}
            >
              <Menu
                // mode="inline"
                defaultSelectedKeys={["1"]}
                selectedKeys={prevId}
                style={{
                  height: "100%",
                }}
                items={insightCategory?.items
                  ?.sort((a, b) => a.sequence - b.sequence)
                  .map((item, index) => ({
                    key: item.id,
                    onClick: () => {
                      setSelect(item.id);
                      setPrevId(item.id);
                      setSelectedMenu(item);
                    },
                    style: {
                      paddingLeft: "14px",
                    },
                    label: (
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        {item.name} ({item?.count || 0})
                      </span>
                    ),
                  }))}
              ></Menu>
            </div>
          ) : (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ height: "70%" }}
            >
              <NoData />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#667085",
                }}
              >
                ไม่มีข้อมูล
              </p>
            </Flex>
          )}
        </Sider>

        <Content
          style={{
            margin: "14px",
            marginLeft: "0px",
            borderRadius: "8px",
            marginTop: "14px",
          }}
        >
          {!screens.xl && (
            <div
              className="custom-dropdown"
              style={{
                // position: "absolute",
                // left: "15px",
                // marginTop: "10px",
                // width: "calc(100vw - 28px)",
                // zIndex: 2000,
                marginBottom: "10px",
              }}
            >
              <Dropdown
                placement="bottomRight"
                trigger={["click"]}
                // overlayStyle={{ zIndex: 2000 }}
                dropdownRender={(menu) => (
                  <Menu
                    className="dropdown-menu"
                    theme="light"
                    mode="vertical"
                    style={{
                      width: 262,
                      minHeight: 100,
                      padding: "23px 12px 23px 12px",
                      // marginTop: "10px",
                      // marginRight: "-10px",
                      fontSize: "14px",
                    }}
                    triggerSubMenuAction="click"
                    items={
                      insightCategory &&
                      insightCategory?.items?.length !== 0 &&
                      insightCategory?.items
                        ?.sort((a, b) => a.sequence - b.sequence)
                        .map((item, index) => ({
                          key: item.id,
                          onClick: () => {
                            setSelect(item.id);
                            setPrevId(item.id);
                            setSelectedMenu(item);
                          },
                          style: {
                            paddingLeft: "14px",
                            // fontSize: "14px",
                          },
                          label: (
                            <span
                              style={{ fontSize: "14px", fontWeight: "500" }}
                            >
                              {item.name} ({item?.count ?? 0})
                            </span>
                          ),
                        }))
                    }
                  ></Menu>
                )}
              >
                <Button
                  type="default"
                  htmlType="button"
                  className="dropdown-button"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontWeight: 500,
                    fontSize: "16px",
                    height: "40px",
                  }}
                >
                  <MenuFoldOutlined />

                  {selectedMenu
                    ? `${selectedMenu.name} (${selectedMenu.count})`
                    : `ประเภทรายงานวิเคราห์ (${
                        insightCategory?.items?.length ?? 0
                      })`}
                </Button>
              </Dropdown>
            </div>
          )}
          {select ? (
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "calc(100vh - 270px)",
                scrollbarWidth: "thin",
                scrollbarColor: "#999 #f5f5f5",
                scrollbarTrackColor: "#f5f5f5",
                borderRadius: "8px",
              }}
            >
              {insightList?.items?.length !== 0 ? (
                <Space
                  direction="vertical"
                  size="middle"
                  style={{
                    display: "flex",
                  }}
                >
                  {insightList?.items
                    ?.sort((a, b) => a.sequence - b.sequence)
                    .map((item) => (
                      <div
                        key={item.id}
                        style={{
                          padding: "14px",
                          background: "#FFF",
                          borderRadius: "8px",
                        }}
                      >
                        <Flex justify="space-between" align="center">
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            ใช้งาน :
                            <span style={{ margin: "10px" }}>
                              <Switch
                                disabled={isEditable === false}
                                // defaultChecked={item?.isPinned}
                                checked={item?.isActivated}
                                onChange={(e) => handleUpdateActivated(e, item)}
                              />
                            </span>
                          </span>
                          <Space>
                            <PinButton
                              isEditable={isEditable}
                              loading={pinLoading === item.id}
                              onClick={() =>
                                handleUpdatePinned(!item.isPinned, item)
                              }
                              isPinned={item?.isPinned}
                            />
                            {isEditable && !item?.isPreserved && (
                              <DeleteButton
                                isEditable={isEditable}
                                onClick={() => {
                                  setSelectDelete(item.id);
                                  setIsModalDeleteOpen(true);
                                }}
                              />
                            )}
                          </Space>
                        </Flex>
                        <Divider style={{ borderColor: "#D0D5DD" }} />
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          ชื่อรายงานวิเคราะห์
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#667085",
                            marginTop: "-14px",
                          }}
                        >
                          {item?.name || "-"}
                        </p>
                        <p style={{ fontSize: "16px", fontWeight: "600" }}>
                          URL รายงานวิเคราะห์
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#667085",
                            marginTop: "-14px",
                          }}
                        >
                          {`${window.location.origin}/insight-report/${
                            item?.urlSlug || "-"
                          }`}
                        </p>

                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          รายละเอียดโดยย่อ
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#667085",
                            marginTop: "-14px",
                          }}
                        >
                          {item?.shortDescription || "-"}
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          รายละเอียด
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#667085",
                            marginTop: "-14px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: item?.description || "-",
                          }}
                        ></p>
                        <Row gutter={16}>
                          <Col span={12}>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              อัปโหลดภาพขนาดย่อ
                            </p>
                            {item?.thumbnailSrc ? (
                              <img
                                alt="example"
                                src={item?.thumbnailSrc}
                                onError={(e) => {
                                  e.target.src = NoImg;
                                }}
                                width={204}
                                style={{
                                  padding: "6px",
                                  border: "1px solid #D9D9D9",
                                  borderRadius: "2px",
                                  marginTop: "-14px",
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </Col>
                          <Col span={12}>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              อัปโหลดภาพในหน้า รายละเอียด
                            </p>
                            {item?.contentImageSrc ? (
                              <img
                                alt="example"
                                src={item?.contentImageSrc}
                                onError={(e) => {
                                  e.target.src = NoImg;
                                }}
                                width={204}
                                style={{
                                  padding: "6px",
                                  border: "1px solid #D9D9D9",
                                  borderRadius: "2px",
                                  marginTop: "-14px",
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </Col>
                        </Row>

                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Tag
                        </p>
                        <Flex wrap gap="small" style={{ marginTop: "-14px" }}>
                          {item?.insightReportTags &&
                          item?.insightReportTags?.length !== 0
                            ? item?.insightReportTags.map((tag) => (
                                <Tag
                                  key={item.id + tag.id}
                                  bordered={false}
                                  color={tag?.tagColor}
                                >
                                  {tag?.tagName}
                                </Tag>
                              ))
                            : "-"}
                        </Flex>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          เลือกแดซบอร์ดที่ต้องการเชื่อมกับรายงานวิเคราะห์
                        </p>
                        <Flex wrap gap="small" style={{ marginTop: "-14px" }}>
                          {item?.insightReportDashboards &&
                          item?.insightReportDashboards?.length !== 0
                            ? item?.insightReportDashboards?.map(
                                (dashboard) => (
                                  <Tag
                                    key={item.id + dashboard.id}
                                    style={{
                                      borderRadius: "8px",
                                      padding: "6px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {dashboard.dashboardName}
                                  </Tag>
                                )
                              )
                            : "-"}
                        </Flex>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          เลือก Role ที่ต้องการให้ดาวน์โหลดรายงานวิเคราะห์
                        </p>
                        <Flex wrap gap="small" style={{ marginTop: "-14px" }}>
                          {item?.insightReportPermissions &&
                          item?.insightReportPermissions?.length !== 0
                            ? item?.insightReportPermissions?.map((role) => (
                                <Tag
                                  key={item.id + role.id}
                                  style={{
                                    borderRadius: "8px",
                                    padding: "6px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {role.roleName}
                                </Tag>
                              ))
                            : "-"}
                        </Flex>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          อัปโหลดไฟล์ รายงานวิเคราะห์
                        </p>
                        {item?.insightReportFileRawName ? (
                          <a
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              marginTop: "-14px",
                            }}
                            onClick={() => {
                              // downloadFile(item);
                              handleOpenFile(
                                item?.insightReportFileRawName,
                                item
                              );
                            }}
                          >
                            {item?.insightReportFileRawName}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                    ))}
                </Space>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{
                    height: "calc(100vh - 270px)",
                    background: "#fff",
                    borderRadius: "8px",
                  }}
                >
                  <NoData />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#667085",
                    }}
                  >
                    ไม่มีข้อมูล
                  </p>
                </Flex>
              )}
            </div>
          ) : (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{
                height: "calc(100vh - 270px)",
                background: "#fff",
                borderRadius: "8px",
              }}
            >
              <NoData />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#667085",
                }}
              >
                {!keyword
                  ? "กรุณาเลือกประเภทก่อนเพิ่มข้อมูล"
                  : !select
                  ? "กรุณาเลือกประเภทก่อนเพิ่มข้อมูล"
                  : "ไม่มีข้อมูลที่ค้นหา"}
              </p>
            </Flex>
          )}
        </Content>
      </Layout>

      <LoadingDialog isLoading={loading} />

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        title="สำเร็จ"
        detail="ลบข้อมูลสำเร็จแล้ว"
        onCancel={() => {
          setIsModalSuccessOpen(false);
        }}
        onAfterClose={() => {
          setIsModalSuccessOpen(false);
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title=""
        detail={errorMessage?.messageTh}
        onCancel={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
        onAfterClose={() => {}}
        onConfirm={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
      />

      <DeleteDialog
        isModalOpen={isModalDeleteOpen}
        closable={false}
        title="ยืนยันการลบ"
        description="คุณต้องการลบใช่หรือไม่?"
        cancelText="ยกเลิก"
        confirmText="ลบ"
        onCancel={() => {
          setSelectDelete();
          setIsModalDeleteOpen(false);
        }}
        onConfirm={() => {
          handleDeleteInsightReportById();
        }}
      />
    </>
  );
};

export default InsightReportManagement;
