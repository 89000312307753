import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUnsavedChangesContext } from "../../../../../contexts";
import { useUnsavedChangesWarning } from "../../../../../hooks";
import "./DataSourceManagement.css";
import {
  Form,
  Input,
  Switch,
  Select,
  Row,
  Col,
  Flex,
  Tag,
  message,
  Button,
} from "antd";
import { RightOutlined, PlusOutlined } from "@ant-design/icons";
import { AppContext } from "./DataSourceManagementDetail";
import {
  SuccessDialog,
  FailedDialog,
  ConfirmDialog,
} from "../../../../../components";
import { DataSourceService } from "../../../../../services/DataSourceService";
import { TagService } from "../../../../../services/TagService";
import { UserService } from "../../../../../services/UserService";
import DataSourceFormRouteConfig from "./DataSourceFormRouteConfig";

import IconUpAdmin from "../../../../../assets/icon/icon_up_admin";
import IconDownAdmin from "../../../../../assets/icon/icon_down_admin";
import IconBinAdmin from "../../../../../assets/icon/icon_bin_admin";
import IconUpAdminDisable from "../../../../../assets/icon/icon_up_admin_disable";
import IconDownAdminDisable from "../../../../../assets/icon/icon_down_admin_disable";
import NoData from "../../../../../assets/img/no_data.js";

import htmlEditButton from "quill-html-edit-button";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

Quill.register({
  "modules/htmlEditButton": htmlEditButton,
});

const modules = {
  toolbar: [
    { header: [1, 2, 3, 4, 5, 6, false] },
    "bold",
    "italic",
    "underline",
    "link",
    // { list: "bullet" },
    // { list: "ordered" },
  ],
  htmlEditButton: {
    debug: true,
    msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
    okText: "Ok", // Text to display in the OK button, default: Ok,
    cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
    buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
    prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
    editorModules: {}, // The default mod
  },
};

const DataSourceFormDetail = ({ categoryId, keyword }) => {
  const { detailList, setDetailList, isSubmit, setIsSubmit, setLoading } =
    useContext(AppContext);

  const { setIsDataModified } = useUnsavedChangesContext();
  const { handleUnsavedConfirm } = useUnsavedChangesWarning();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [routeConfigJson, setRouteConfigJson] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const categoryIdRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    UserService.isEditable().then((res) => {
      setIsEditable(res);
      if (!res) {
        navigate(
          {
            pathname: `/management/datasource-management`,
          },
          { state: { categoryId: categoryId } }
        );
      }
    });
  }, []);

  useEffect(() => {
    if (detailList.length) {
      if (categoryIdRef.current !== categoryId) {
        fetchTags();
        categoryIdRef.current = categoryId;
      }
      initialValues(detailList);
    }
  }, [detailList]);

  useEffect(() => {
    if (isSubmit) {
      setIsModalConfirmOpen(true);
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const fetchTags = async () => {
    try {
      const response = await TagService.get();
      const sortedTags = response?.items.sort(
        (a, b) => a.sequence - b.sequence
      );
      setTags(sortedTags);
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = (data) => {
    const initialValues = data?.map((item) => ({
      ...item,
      dataSourceTags: item.dataSourceTags?.map((tag) => tag.tagId),
    }));

    handleUpdateForm(initialValues);
  };

  const handleUpdateForm = (data) => {
    form.setFieldsValue({ items: data });
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let data;
      if (!values || Object.keys(values).length === 0) {
        data = {
          dataSourceCategoryId: categoryId,
          items: [],
        };
      } else {
        data = {
          dataSourceCategoryId: categoryId,
          keywords: keyword || "",
          items: detailList.map((item) => {
            return {
              id: item.id,
              dataSourceCategoryId: item.dataSourceCategoryId,
              name: item.name,
              urlSlug: item.urlSlug,
              // apiName: item.apiName,
              apiName: item.name,
              description: item.description,
              upstreamPath: item.upstreamPath,
              upstreamHttpMethod: item.upstreamHttpMethod,
              sequence: item.sequence,
              isActivated: item.isActivated,
              routeConfigJson: item.routeConfigJson,
              swaggerConfigJson: item.swaggerConfigJson,
              apiVersion: item.apiVersion,
              dataSourceTags: item.dataSourceTags.map((tag) => {
                return {
                  id: tag.id,
                  dataSourceId: tag.dataSourceId,
                  tagId: tag.tagId,
                  sequence: tag.sequence,
                };
              }),
            };
          }),
        };
      }

      const response = await DataSourceService.saveBulk(data);
      setLoading(false);
      setIsModalSuccessOpen(true);
      handleUnsavedConfirm();
      setTimeout(() => {
        setIsModalSuccessOpen(false);
        navigate(
          {
            pathname: `/management/datasource-management`,
          },
          { state: { categoryId: categoryId } }
        );
      }, 1000);
    } catch (error) {
      console.error();
      setLoading(false);
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    }
  };

  const moveItemDetail = (id, direction) => {
    setDetailList((prev) => {
      const items = prev;
      const index = items.findIndex((item) => item.id === id);

      if (index < 0) return prev;
      const swapIndex = index + direction;

      if (swapIndex < 0 || swapIndex >= items.length) return prev;

      [items[index].sequence, items[swapIndex].sequence] = [
        items[swapIndex].sequence,
        items[index].sequence,
      ];

      items.sort((a, b) => a.sequence - b.sequence);

      initialValues(items);
      setIsDataModified(true);

      return items;
    });
  };

  const handleChangeIsActivated = (isActivated, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            isActivated: isActivated,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeTag = (selectedTagIds, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          const updatedTags = selectedTagIds.map((tagId) => {
            const tag = tags.find((tag) => tag.id === tagId);
            return {
              id:
                detail.dataSourceTags?.find((t) => t.tagId === tagId)?.id || "",
              dataSourceId: detail.id,
              tagId: tag.id,
              tagName: tag.name,
              tagColor: tag.color,
              sequence: tag.sequence,
            };
          });
          return {
            ...detail,
            dataSourceTags: updatedTags,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeName = (name, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            name: name,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeUrlSlug = (urlSlug, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            urlSlug: urlSlug,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeApiName = (apiName, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            apiName: apiName,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeDescription = (value, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            description: value,
          };
        }
        return detail;
      })
    );
  };

  const handleDelete = (item) => {
    setDetailList((prev) =>
      prev
        .filter((detail) => detail.id !== item.id)
        .map((item, index) => ({
          ...item,
          sequence: index + 1,
        }))
    );
    setIsDataModified(true);
  };

  const getDetailLength = (name) => {
    const currentValue =
      form.getFieldValue(["items", name, "description"]) || "";
    const plainText = currentValue?.replace(/<[^>]+>/g, "");
    return plainText.length;
  };

  const createTagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        icon={<PlusOutlined style={{ color: "#00000073" }} />}
        color={"#F8F8F9"}
        onMouseDown={onPreventMouseDown}
        closable={false}
        onClick={() => {
          navigate("/management/edit-tag");
        }}
        style={{
          marginInlineEnd: 4,
          color: "#000000D9",
          fontSize: 12,
          fontWeight: 600,
          borderColor: "#BCC2CB",
          borderStyle: "dashed",
          cursor: "pointer",
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
    setRouteConfigJson();
  };

  const validateUrlSlug = (_, value) => {
    if (value && /\s/.test(value)) {
      return Promise.reject(
        new Error(`กรุณาใช้ "-" หรือ "_" แทนช่องว่างระหว่างคำ!`)
      );
    } else if (value && !/^[a-zA-Z0-9-_!@#$%^&*()]+$/.test(value)) {
      return Promise.reject(new Error(`กรุณากรอกเฉพาะภาษาอังกฤษ!`));
    }
    return Promise.resolve();
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        name="dynamic_form_complex"
        onFinish={onFinish}
        initialValues={{ items: [{}] }}
        onValuesChange={(changedValues, allValues) => {
          setIsDataModified(true);
        }}
        onFinishFailed={({ errorFields }) => {
          messageApi.open({
            type: "error",
            content: "กรุณากรอกข้อมูลให้สมบูรณ์",
          });
        }}
        layout="vertical"
        style={{ height: "100%" }}
      >
        {detailList?.length !== 0 ? (
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className="content-card-main-datasource">
                    <Row
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginRight: "0px",
                      }}
                    >
                      <div style={{ width: "60px" }} span={2}>
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Row>
                            <Col>
                              <Row style={{ marginBottom: 15 }}>
                                {key === 0 || !isEditable ? (
                                  <IconUpAdminDisable />
                                ) : (
                                  <IconUpAdmin
                                    onClick={() => {
                                      setTimeout(() => {
                                        const item = form.getFieldValue([
                                          "items",
                                          name,
                                        ]);
                                        moveItemDetail(item.id, -1);
                                      }, 200);
                                    }}
                                  />
                                )}
                              </Row>
                              <Row>
                                {detailList.length === key + 1 ||
                                !isEditable ? (
                                  <IconDownAdminDisable />
                                ) : (
                                  <IconDownAdmin
                                    onClick={() => {
                                      setTimeout(() => {
                                        const item = form.getFieldValue([
                                          "items",
                                          name,
                                        ]);
                                        moveItemDetail(item.id, 1);
                                      }, 200);
                                    }}
                                  />
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div style={{ width: "90%" }} span={22}>
                        <div
                          style={{
                            width: "100%",
                            // height: "100%",
                            marginLeft: 0,
                          }}
                        >
                          <Col span={24}>
                            <Row style={{}}>
                              <Col span={1}>
                                <div className="border-left-datasource"></div>
                              </Col>
                              <Col span={23}>
                                {!form.getFieldValue([
                                  "items",
                                  name,
                                  "isPreserved",
                                ]) && (
                                  <>
                                    <Flex justify="end">
                                      <IconBinAdmin
                                        onClick={() => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleDelete(item);
                                        }}
                                        style={{
                                          marginRight: 10,
                                        }}
                                      />
                                    </Flex>
                                    <Row>
                                      <div className="border-bottom"></div>
                                    </Row>
                                  </>
                                )}

                                <Form.Item
                                  {...restField}
                                  name={[name, "id"]}
                                  noStyle
                                >
                                  <Input type="hidden" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "dataSourceCategoryId"]}
                                  noStyle
                                >
                                  <Input type="hidden" />
                                </Form.Item>

                                <Row style={{ marginLeft: 20 }}>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "isActivated"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">ใช้งาน</p>
                                      }
                                    >
                                      <Switch
                                        style={{
                                          marginTop: 10,
                                        }}
                                        defaultChecked
                                        onChange={(isActivated) => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleChangeIsActivated(
                                            isActivated,
                                            item
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "routeConfigJson"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          การตั้งค่า API
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            {" "}
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <Button
                                          type="link"
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            padding: "0px",
                                          }}
                                          onClick={() => {
                                            const item = form.getFieldValue([
                                              "items",
                                              name,
                                            ]);

                                            setLoading(true);
                                            setRouteConfigJson(item);
                                            setTimeout(() => {
                                              handleOpenModal();
                                            }, 200);
                                          }}
                                        >
                                          <u>ตั้งค่า</u>
                                          <RightOutlined />
                                        </Button>
                                      </div>
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={
                                        tags.length
                                          ? [name, "dataSourceTags"]
                                          : ""
                                      }
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          Tag{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Select
                                        mode="multiple"
                                        // allowClear
                                        style={{ width: "100%" }}
                                        tagRender={
                                          tags.length ? "" : createTagRender
                                        }
                                        defaultValue={
                                          tags.length ? [] : ["New Tag"]
                                        }
                                        onChange={(selectedTagIds) => {
                                          if (tags.length) {
                                            const item = form.getFieldValue([
                                              "items",
                                              name,
                                            ]);
                                            handleChangeTag(
                                              selectedTagIds,
                                              item
                                            );
                                          }
                                        }}
                                        options={tags?.map((tag) => ({
                                          label: tag.name,
                                          value: tag.id,
                                        }))}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "name"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          {/* ชื่อแหล่งที่มาของข้อมูล{" "} */}
                                          ชื่อระบบฐานข้อมูล (API){" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Input
                                        showCount
                                        maxLength={100}
                                        onBlur={(e) => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleChangeName(
                                            e.target.value,
                                            item
                                          );
                                        }}
                                        placeholder="ชื่อแหล่งที่มาของข้อมูล"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "urlSlug"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          URL ดาต้าแค็ตตาล็อก{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                        {
                                          validator: validateUrlSlug,
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Input
                                        addonBefore={
                                          window.location.origin +
                                          "/data-catalog/"
                                        }
                                        showCount
                                        maxLength={100}
                                        onBlur={(e) => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleChangeUrlSlug(
                                            e.target.value,
                                            item
                                          );
                                        }}
                                        placeholder="URL ดาต้าแค็ตตาล็อก"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                    display: "none",
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "apiName"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          ชื่อระบบฐานข้อมูล (API){" "}
                                          {/* <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span> */}
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: false,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Input
                                        showCount
                                        maxLength={100}
                                        onBlur={(e) => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleChangeApiName(
                                            e.target.value,
                                            item
                                          );
                                        }}
                                        placeholder="ชื่อระบบฐานข้อมูล (API)"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "description"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          รายละเอียด{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: (
                                            <p
                                              style={{
                                                position: "relative",
                                                top: -38,
                                              }}
                                            >
                                              จำเป็นต้องกรอกข้อมูล
                                            </p>
                                          ),
                                        },
                                        {
                                          validator: (_, value) => {
                                            const plainText = value
                                              ? value.replace(/<[^>]+>/g, "")
                                              : "";
                                            if (plainText.length > 1000) {
                                              return Promise.reject(
                                                <p
                                                  style={{
                                                    position: "relative",
                                                    top: -38,
                                                  }}
                                                >
                                                  ข้อความต้องไม่เกิน 1000
                                                  ตัวอักษร
                                                </p>
                                              );
                                            }
                                            if (
                                              value &&
                                              plainText.length === 0
                                            ) {
                                              return Promise.reject(
                                                <p
                                                  style={{
                                                    position: "relative",
                                                    top: -38,
                                                  }}
                                                >
                                                  จำเป็นต้องกรอกข้อมูล
                                                </p>
                                              );
                                            }
                                            return Promise.resolve();
                                          },
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Flex
                                        vertical={true}
                                        gap="small"
                                        justify="flex-end"
                                        align="flex-end"
                                      >
                                        <ReactQuill
                                          className="react-quill"
                                          theme="snow"
                                          value={form.getFieldValue([
                                            "items",
                                            name,
                                            "description",
                                          ])}
                                          modules={modules}
                                          style={{
                                            height: 145,
                                            width: "100%",
                                          }}
                                          onChange={(value) => {
                                            form.setFieldsValue({
                                              ["items"]: {
                                                [name]: {
                                                  description: value,
                                                },
                                              },
                                            });
                                            form.validateFields([
                                              ["items", name, "description"],
                                            ]);
                                            const item = form.getFieldValue([
                                              "items",
                                              name,
                                            ]);
                                            handleChangeDescription(
                                              value,
                                              item
                                            );
                                          }}
                                        />
                                        <span
                                          style={{
                                            color: "#9ca0a6",
                                            marginTop: 40,
                                            position: "relative",
                                          }}
                                        >
                                          {getDetailLength(name)}
                                          /1000
                                        </span>
                                      </Flex>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      </div>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        ) : (
          <Flex
            vertical
            justify="center"
            align="center"
            style={{
              height: "calc(100vh - 282px)",
              background: "#fff",
              borderRadius: "8px",
            }}
          >
            <NoData />
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#667085",
              }}
            >
              ไม่มีข้อมูล
            </p>
          </Flex>
        )}
      </Form>

      <DataSourceFormRouteConfig
        isModalVisible={isModalVisible}
        handleCancelModal={handleCancelModal}
        item={routeConfigJson}
        tags={tags}
        createTagRender={createTagRender}
        setLoading={setLoading}
      />

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        title="สำเร็จ"
        detail="บันทึกข้อมูลสำเร็จ"
        onCancel={() => {}}
        onAfterClose={() => {}}
      />

      <ConfirmDialog
        isModalOpen={isModalConfirmOpen}
        closable={false}
        title="ยืนยันการบันทึก"
        description="คุณต้องการบันทึกใช่หรือไม่?"
        cancelText="ยกเลิก"
        confirmText="บันทึก"
        onCancel={() => {
          setIsModalConfirmOpen(false);
        }}
        onConfirm={() => {
          form.submit();
          setIsModalConfirmOpen(false);
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title=""
        detail={errorMessage?.messageTh}
        onCancel={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
        onAfterClose={() => {}}
        onConfirm={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
      />
    </>
  );
};

export default DataSourceFormDetail;
