import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, ShareAltOutlined, DownCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Tag, Row, Col, Grid, Modal, Input } from 'antd';
import { InsightReportService } from "../../../../services/InsightReportService.js";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import IconRightArrowViewDashboard from "../../../../assets/icon/icon_right_arrow_view_dashboard.js";
import { DashboardService } from "../../../../services/DashboardService.js";
import { useLocation, useNavigate } from 'react-router-dom';
import NoImg from "../../../../assets/img/no_img.png";
import "./InsightReportDetail.css";
import { FacebookShareButton, LineShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import icon_fb from "../../../../assets/icon/svg/icon_fb.svg";
import icon_linkedin from "../../../../assets/icon/svg/icon_linkedin.svg";
import icon_x from "../../../../assets/icon/svg/icon_x.svg";
import icon_line from "../../../../assets/icon/svg/icon_line.svg";
import { LoadingDialog } from "../../../../components/index.js";
import NoAuthDialog from "../../../../components/Dialog/NoAuthDialog.js";
import { useCookies } from "react-cookie";


const InsightReportPage = ({ id }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const [InsightData, setInsightData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isNotAllow, setIsNotAllow] = useState(false);
    const [isCopied, setIsCopied] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [insightDataList, setInsightDataList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0); // index ปัจจุบั
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const [dashboardDataList, setDashboardDataList] = useState([]);
    const [cookieInsightReport, setCookieInsightReport] = useCookies(['insight-report-condition']);
    const [cookieDashboard, setCookieDashboard] = useCookies(["dashboard-condition"]);
    const itemsPerPage = 4;
    const itemsPerInsightPage = 4;

    const handleNext = () => {
        if (currentIndex + itemsPerPage < dashboardDataList.length) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    };

    const handlePrev = () => {
        if (currentIndex - itemsPerPage >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };

    const handleNext2 = () => {
        if (currentIndex2 + itemsPerInsightPage < insightDataList.length) {
            setCurrentIndex2(currentIndex2 + itemsPerInsightPage);
        }
    };

    const handlePrev2 = () => {
        if (currentIndex2 - itemsPerInsightPage >= 0) {
            setCurrentIndex2(currentIndex2 - itemsPerInsightPage);
        }
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const displayedData = dashboardDataList.slice(currentIndex, currentIndex + itemsPerPage);
    const displayedData2 = insightDataList.slice(currentIndex2, currentIndex2 + itemsPerInsightPage);

    const fetchInsightReportData = async () => {
        try {
            setIsLoading(true); // ตั้งค่าสถานะการโหลด
            // const dashboardId = id || "38dfccb3-9266-4fe9-9d03-3d9e02c3ce92";
            var title = decodeURI(window.location.pathname.split("/").pop());
            const response = await InsightReportService.get(title);
            if (response) {
                const ogImage = document.querySelector(`meta[property="og:image"]`);
                if (ogImage) {
                    ogImage.setAttribute("content", response.thumbnailSrc || NoImg);
                }
                const metaImage = document.querySelector(`meta[name="twitter:image"]`);
                if (metaImage) {
                    metaImage.setAttribute("content", response.thumbnailSrc || NoImg);
                }
                setInsightData(response); // ตั้งค่าข้อมูลที่ได้จาก API
                //console.log("Fetched Data:", response); // ตรวจสอบข้อมูล
                const tagIdObjects = response.insightReportTags.map((tag) => ({ tagId: tag.tagId }));
                //console.log(tagIdObjects);
                const tagIds = tagIdObjects.map((obj) => obj.tagId);
                const responseList = await InsightReportService.getDataListTag(tagIds);
                if (Array.isArray(responseList.items)) {
                    setInsightDataList(responseList.items); // เข้าถึง items และตั้งค่า
                } else {
                    console.error("responseList.items is not an array");
                    setInsightDataList([]); // ถ้าไม่ใช่ array ให้ตั้งค่าเป็น array ว่างๆ
                }
                const idInsight = response.id;
                const responseList2 = await DashboardService.getDataList(idInsight);
                if (Array.isArray(responseList2.items)) {
                    setDashboardDataList(responseList2.items);
                } else {
                    console.error("responseList.items is not an array");
                    setDashboardDataList([]);
                }
            } else {
                throw new Error("No data received from API");
            }
        } catch (err) {
            console.error("Error fetching insight report data:", err);

            if (err.response?.status === 404) {
                navigate("/not-found");
            }
        } finally {
            setIsLoading(false); // ยกเลิกสถานะการโหลด
        }
    };

    useEffect(() => {
        fetchInsightReportData();
    }, []); // ดึงข้อมูลเมื่อโหลดหน้านี้

    // ดึงข้อมูลจาก API
    const showModal = () => {
        setIsModalVisible(true);
        console.log("showModal");
    };

    // แสดงผลข้อมูล
    const { name, insightReportTags, description, formattedCreatedDate, contentImageSrc, srcUrl } = InsightData;
    // const srcUrlId = srcUrl.split('/').pop();


    const handleBackClick = () => {
        navigate('/insight-report');
    };

    const handleClickInsight = (id, urlSlug) => {
        const Name = encodeURIComponent(urlSlug);
        navigate(`/insight-report/${Name}`);
        window.scrollTo(0, 0);
        fetchInsightReportData();
    };

    const handleClickDashbord = (id, urlSlug) => {
        const Name = encodeURIComponent(urlSlug);
        sessionStorage.setItem("itemId", id);
        navigate(`/dashboard/${Name}`);
        window.scrollTo(0, 0);
    };


    const handleDownload = async () => {
        try {
            setIsLoading(true);
            var allow = await InsightReportService.validatePermission(InsightData.id);
            if (!allow) {
                if (sessionStorage.getItem("accessToken") === null) {
                    localStorage.setItem('pageState', location.pathname);
                    navigate("/login");
                } else {
                    setIsNotAllow(true);
                }
                return;
            }

            // ส่งคำขอเพื่อดึงไฟล์
            const response = await fetch(srcUrl);
            const blob = await response.blob(); // แปลงเนื้อหาของไฟล์เป็น Blob
            const link = document.createElement('a'); // สร้างลิงก์ชั่วคราว
            link.href = URL.createObjectURL(blob); // สร้าง URL จาก Blob
            link.download = `${decodeURI(location.pathname.split('/').pop())}.pdf`; // ตั้งชื่อไฟล์จาก URL หรือกำหนดชื่อที่คุณต้องการ
            link.click(); // คลิกลิงก์เพื่อดาวน์โหลด
        } catch (error) {
            console.error('Error downloading the file', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTagClick = (tagId, tagName) => {
        if (localStorage.getItem('cookie') === 'true') {
            if (cookieInsightReport['insight-report-condition'] !== undefined) {
                var condition = cookieInsightReport['insight-report-condition'];
                condition = {};
                condition.TagIds = tagId;
                setCookieInsightReport('insight-report-condition', condition);
            }
        }
        navigate(`/insight-report?tag=${encodeURIComponent(tagName)}`, {
            state: { tagId },
        });
    };

    const tagInsightButtons = (item) => {
        const maxTag = 2;
        const tagCount = item?.insightReportTags.length;
        var renderTiems = item?.insightReportTags
            .sort((a, b) => {
                if (a.tagName === 'เฉพาะสมาชิก' && b.tagName !== 'เฉพาะสมาชิก') {
                    return -1;
                }
                return a.sequence - b.sequence
            })
            .slice(0, maxTag)
            .map((tag) => (
                <Tag
                    key={tag.id}
                    bordered={false}
                    color={tag.tagColor}
                    onClick={() => goInsight(tag.tagId, tag.tagName)}
                    className="tag"
                >
                    {tag.tagName}
                </Tag>
            ));
        if (tagCount > maxTag) {
            renderTiems.push(<Tag
                bordered={false}
                className="tag tag-more"
            >
                {`+${(tagCount - maxTag)}`}
            </Tag>);
        }
        return renderTiems
    };
    const tagDashboardButtons = (item) => {
        const maxTag = 2;
        const tagCount = item?.dashboardTags.length;
        var renderTiems = item?.dashboardTags
            .sort((a, b) => {
                if (a.tagName === 'เฉพาะสมาชิก' && b.tagName !== 'เฉพาะสมาชิก') {
                    return -1;
                }
                return a.sequence - b.sequence
            })
            .slice(0, maxTag)
            .map((tag) => (
                <Tag
                    key={item.id + tag.id}
                    bordered={false}
                    color={tag.tagColor}
                    onClick={() => goDashboard(tag.tagId, tag.tagName)}
                    className="tag"
                >
                    {tag.tagName}
                </Tag>
            ));
        if (tagCount > maxTag) {
            renderTiems.push(<Tag
                key={item.id + 'more'}
                bordered={false}
                className="tag tag-more"
            >
                {`+${(tagCount - maxTag)}`}
            </Tag>);
        }
        return renderTiems
    };

    const goDashboard = (tagId, tagName) => {
        if (localStorage.getItem('cookie') === 'true') {
            if (cookieDashboard['dashboard-condition'] !== undefined) {
                var condition = cookieDashboard['dashboard-condition'];
                condition = {};
                condition.TagIds = tagId;
                setCookiesValue('dashboard', 'dashboard-condition', condition);
            }
        }
        navigate(`/dashboard?tag=${encodeURIComponent(tagName)}`, {
            state: { tagId },
        });
    };
    const goInsight = (tagId, tagName) => {
        if (localStorage.getItem('cookie') === 'true') {
            if (cookieInsightReport['insight-report-condition'] !== undefined) {
                var condition = cookieInsightReport['insight-report-condition'];
                condition = {};
                condition.TagIds = tagId;
                setCookiesValue('insight-report', 'insight-report-condition', condition);
            }
        }
        navigate(`/insight-report?tag=${encodeURIComponent(tagName)}`, {
            state: { tagId },
        });
    };
    const setCookiesValue = (group, key, value) => {
        if (localStorage.getItem('cookie') === 'true') {
            if (group === 'dashboard') {
                setCookieDashboard(key, value, { path: '/' });
            } else if (group === 'insight-report') {
                setCookieInsightReport(key, value, { path: '/' });
            }
        }
    };

    return (
        <div className="content-insight-report-detail">
            <div
                className="heading"
                style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "40px", 
                }}
            >
                <span className="content-card-title">
                    <ArrowLeftOutlined
                        onClick={handleBackClick}
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    {name || "ไม่มีข้อมูล"}
                </span>
            </div>

            <div>
                <div className="card-container">
                    <Row>
                        <Col span={12} md={12} sm={24} xs={24} className={screens.md ? "cover-image-container" : ""}>
                            <img
                                className={screens.md ? "cover-image" : "cover-image-mobile"}
                                src={contentImageSrc || NoImg}
                                alt="Background"
                                onError={(e) => {
                                    //console.log("No img");
                                    e.target.src = NoImg;
                                }}
                            />
                        </Col>
                        <Col span={12} md={12} sm={24} xs={24}>
                            <div className="center-v">
                                {/* การ์ดเนื้อหา */}
                                <div className={screens.md ? 'content' : 'content-mobile'}>
                                    {screens.md && (
                                        <div className="share-button-container">
                                            <Button className="share-button" onClick={showModal}>
                                                <ShareAltOutlined />แชร์
                                            </Button>


                                        </div>
                                    )}
                                    <Modal
                                        title="แชร์ข้อมูลของคุณ"
                                        className="share-modal"
                                        open={isModalVisible}
                                        onCancel={handleCancel}
                                        footer={null} // ถ้าไม่ต้องการปุ่มใน footer ของ Modal
                                        width={500} // ความกว้างของ Modal
                                        style={{ zIndex: 999999 }}
                                    >
                                        <FacebookShareButton url={window.location.href}>
                                            <img src={icon_fb} alt="FB" width={30} height={30} title="แชร์ไปยัง Facebook" />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={window.location.href}>
                                            <img src={icon_x} alt="X" width={30} height={30} title="แชร์ไปยัง X" />
                                        </TwitterShareButton>
                                        <LinkedinShareButton url={window.location.href}>
                                            <img src={icon_linkedin} alt="Linkedin" width={30} height={30} title="แชร์ไปยัง Linkedin" />
                                        </LinkedinShareButton>
                                        <LineShareButton url={window.location.href}>
                                            <img src={icon_line} alt="Line" width={30} height={30} title="แชร์ไปยัง Line" />
                                        </LineShareButton>
                                        <h4 >คัดลอก URL <small className="copied-text">{isCopied}</small></h4>
                                        <Space.Compact className="copy-url-container">
                                            <Input defaultValue={decodeURI(window.location.href)} readOnly={true} />
                                            <CopyToClipboard text={decodeURI(window.location.href)}
                                                onCopy={() => {
                                                    setIsCopied("คัดลอกแล้ว");
                                                    setTimeout(() => {
                                                        setIsCopied("");
                                                    }, 3000);
                                                }}>
                                                <Button type="default" className="copy-url-button">คัดลอก</Button>
                                            </CopyToClipboard>
                                        </Space.Compact>

                                    </Modal>
                                    {/* ชื่อและคำอธิบาย */}
                                    <div>
                                        <h3 className="title">{name || "Untitled Report"}</h3>
                                    </div>
                                    {/* ปุ่มแชร์ */}

                                    <div> {/* เพิ่ม gap ระหว่าง tags */}
                                        {insightReportTags && insightReportTags.length > 0 ? (
                                            insightReportTags.map((tag) => (
                                                // <Tag
                                                //     key={tag.id}
                                                //     className="tag"
                                                //     color={tag.tagColor}
                                                // >
                                                //     {tag.tagName}
                                                // </Tag>
                                                <Tag
                                                    key={tag.id}
                                                    bordered={false}
                                                    color={tag.tagColor}
                                                    onClick={() => handleTagClick(tag.tagId, tag.tagName)}
                                                    className="tag"
                                                >
                                                    {tag.tagName}
                                                </Tag>
                                            ))
                                        ) : (
                                            <p>No tags available.</p>
                                        )}
                                    </div>
                                    {!screens.md && (
                                        <div className="share-button-container-mobile">
                                            <Button className="share-button" onClick={showModal}>
                                                <ShareAltOutlined /> แชร์
                                            </Button>
                                        </div>
                                    )}
                                    <p
                                        className="description"
                                        dangerouslySetInnerHTML={{ __html: description || "" }}
                                    ></p>

                                    <div>
                                        {/* วันที่ */}
                                        <span className="date">
                                            {formattedCreatedDate}
                                        </span>
                                        <Button
                                            type="primary"
                                            className={screens.xs ? "download-button-mobile" : "download-button"}
                                            onClick={handleDownload}
                                        >
                                            <DownCircleOutlined />
                                            ดาวน์โหลดเอกสาร
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </div >

            <div>
                {displayedData?.length > 0 ? (
                    <div className={screens.md ? 'container-insight-report-detail' : 'container-insight-report-detail-mobile'}>
                        <div className="content-insight-report-detail">
                            <Flex justify="space-between" align="center">
                                <span className="content-card-title-detail">แดชบอร์ดที่เกี่ยวข้อง</span>
                                <Space>
                                    <Button className="arrow-left-home" onClick={handlePrev}>
                                        <HiOutlineArrowLeft />
                                    </Button>
                                    <Button className="arrow-right-home" onClick={handleNext}>
                                        <HiOutlineArrowRight />
                                    </Button>
                                </Space>
                            </Flex>
                        </div>
{/* 
                        <div className="container-card-insight-report-detail">
                            <Space size="middle">
                                <Row style={{ width: screens.md ? "calc(100vw - 220px)" : "100%" }}>
                                    {displayedData.map((item) => (
                                        <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                            <div className="card-container">
                                                <div className="content-card-item">

                                                    <img
                                                        className="content-image-insight-report-detail-2"
                                                        alt="error"
                                                        src={item.thumbnailSrc ? item.thumbnailSrc : NoImg}
                                                        onError={(e) => {
                                                            if (e.target.alt === 'error') {
                                                                //console.log("No img")
                                                                e.target.src = NoImg;
                                                            } else {
                                                                e.target.src = NoImg;
                                                            }
                                                        }}
                                                    />
                                                    <div className="card-detail-container">
                                                        <div>
                                                            {tagDashboardButtons(item)}

                                                        </div>

                                                        <p className="content-card-title-insight-report-detail">{item.name}</p>
                                                        <p className="content-card-detail-insight-report-detail">
                                                            {item.shortDescription}
                                                        </p>
                                                        <p className="insight-report-date">
                                                        </p>

                                                        <Flex
                                                            className="detail-button-footer"
                                                            justify="center"
                                                            align="center"
                                                            style={{

                                                            }}
                                                            onClick={() => handleClickDashbord(item.id, item.urlSlug)}
                                                        >
                                                            <p>
                                                                <span>ดูรายละเอียด</span>
                                                                <IconRightArrowViewDashboard />
                                                            </p>
                                                        </Flex>

                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Space>
                        </div> */}

<div className="dashboard-content-content">
                                    <Row justify="start" gutter={[16, 16]}>
                                        {Array.isArray(displayedData) && displayedData.map((item, index) => (
                                            <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                                <div className="card-container">
                                                    <div key={index} className="content-card-item2">
                                                        <div className="tag-container">
                                                            {Array.isArray(item.tags) && item.tags.map((tag, idx) => (
                                                                <span key={idx} className={`tag ${tag.type}`}>
                                                                    {tag.label}
                                                                </span>
                                                            ))}
                                                        </div>
                                                        <div className="image-placeholder-container">
                                                            <img
                                                                className="content-image-insight-report-detail-2"
                                                                alt="error"
                                                                src={item.thumbnailSrc || NoImg}
                                                                onError={(e) => {
                                                                    e.target.src = NoImg;
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="card-detail-container">
                                                            <Space>
                                                                {tagDashboardButtons(item)}
                                                            </Space>
                                                            <p className="content-card-title-insight-report-detail">
                                                                {item.name}
                                                            </p>
                                                            <p className="content-card-detail-insight-report-detail">
                                                                {item.shortDescription}
                                                            </p>
                                                            <Flex
                                                                className="detail-button-footer"
                                                                justify="center"
                                                                align="center"
                                                                onClick={() => handleClickDashbord(item.id, item.urlSlug)}
                                                            >
                                                                <p>
                                                                    <span>ดูรายละเอียด</span>
                                                                    <IconRightArrowViewDashboard />
                                                                </p>
                                                            </Flex>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>


                    </div>
                ) : null}

            </div>


            <div>
                {displayedData2?.length > 0 ? (
                    <div className={screens.md ? 'container-insight-report-detail' : 'container-insight-report-detail-mobile'}>
                        <div className="content-insight-report-detail">
                            <Flex justify="space-between" align="center">
                                <span className="content-card-title-detail">รายงานวิเคราะห์ข้อมูลเชิงลึกที่เกี่ยวข้อง (Insight Report)</span>
                                <Space>
                                    <Button className="arrow-left-home" onClick={handlePrev2}>
                                        <HiOutlineArrowLeft />
                                    </Button>
                                    <Button className="arrow-right-home" onClick={handleNext2}>
                                        <HiOutlineArrowRight />
                                    </Button>
                                </Space>
                            </Flex>
                        </div>

                        <div className="container-card-insight-report-detail">
                            <Space size="middle">
                                <Row style={{ width: screens.md ? "calc(100vw - 220px)" : "100%" }}>
                                    {displayedData2?.map((item) => (
                                        <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                            <div className="card-container">

                                                <div className="content-card-item" key={item.id}>
                                                    <div className="content-image-container">
                                                        <img
                                                            className="content-image-insight-report-detail-2"
                                                            alt="error"
                                                            src={item.thumbnailSrc ? item.thumbnailSrc : NoImg}
                                                            onError={(e) => {
                                                                if (e.target.alt === 'error') {
                                                                    //console.log("No img")
                                                                    e.target.src = NoImg;
                                                                } else {
                                                                    e.target.src = NoImg;
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="card-detail-container">
                                                        <Space>
                                                            {tagInsightButtons(item)}
                                                        </Space>
                                                        <br />
                                                        <p className="content-card-title-insight-report-detail">{item.name}</p>

                                                        <p className="content-card-detail-insight-report-detail">
                                                            {item.shortDescription}
                                                        </p>


                                                        <p className="content-card-detail-dashboard-detail">{item.createdDateFrom}</p>
                                                        <Flex
                                                            className="detail-button-footer"
                                                            justify="center"
                                                            align="center"
                                                            onClick={() => handleClickInsight(item.id, item.urlSlug)}
                                                        >

                                                            <p>
                                                                <span>อ่านต่อ</span>
                                                                <IconRightArrowViewDashboard />
                                                            </p>
                                                        </Flex>
                                                    </div>
                                                </div>

                                            </div>

                                        </Col>
                                    ))}
                                </Row>
                            </Space>
                        </div>


                    </div>
                ) : null}

            </div>
            <LoadingDialog isLoading={isLoading} />
            <NoAuthDialog
                isModalOpen={isNotAllow}
                onCancel={() => {
                    setIsNotAllow(false);
                }}
                onAfterClose={() => {
                    setIsNotAllow(false);
                }}
                onConfirm={() => {
                    setIsNotAllow(false);
                }}
            />


        </div>
    );
};

export default InsightReportPage;
