import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Layout,
  Flex,
  Divider,
  notification,
  Grid,
  Col,
  Row,
  Table,
  Pagination,
  Input,
  Select,
} from "antd";
import { LoadingDialog } from "../../../../../components/index.js";
import NoData from "../../../../../assets/img/no_data.js";
import { ConsentService } from "../../../../../services/ConsentService.js";
import "./ManageConsent.css";
import "./ViewConsent.css";
import ErrorMessageHandler from "../../../../../helpers/ErrorMessageHandler.js";
const { Header, Content, Sider } = Layout;
const { useBreakpoint } = Grid;
const { Option } = Select;

const ViewConsent = () => {
  const screens = useBreakpoint();
  // const [dashboardCategory, setDashboardCategory] = useState();
  const passData = useLocation();
  let consentId = "";
  if (passData.state !== null && passData.state.consentId !== null) {
    consentId = passData.state.consentId;
  }
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(null);
  const [consentList, setConsentList] = useState([]);
  const [defaultId, setDefaultId] = useState(null); // State สำหรับค่าเริ่มต้น

  useEffect(() => {
    fetchAllConsent();
  }, []);

  const fetchAllConsent = async () => {
    setLoading(true);
    try {
      const response = await ConsentService.getAll();
      setConsentList(response);
      response.map((item) => {
        if (item.isPrimary) {
          setDefaultId(item.id);
        }
      });
      if (response?.length !== 0) {
        setSelect(response[0]?.id);
      }
    } catch (error) {
      ErrorMessageHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main-body">
        <Layout>
          <Header
            style={{
              height: "70px",
              background: "#ffffff",
              padding: 0,
            }}
          >
            <Flex
              justify="space-between"
              align="center"
              style={{ height: "100%" }}
            >
              <h2>การให้ความยินยอมการใช้งานข้อมูล</h2>
            </Flex>
          </Header>
          <Layout className="main-content">
            {!screens.xl ? (
              <>
                <Row>
                  <Col span={24} style={{ background: "#FFF" }}>
                    <div style={{ padding: "14px" }}>
                      {/* Header */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "14px",
                        }}
                      >
                        <span
                          style={{
                            color: "#2E323A",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          ทั้งหมด ({consentList.length}) รายการ
                        </span>
                      </div>
                    </div>
                    {consentList ? (
                      <div style={{ padding: "14px" }}>
                        <ul
                          style={{
                            listStyle: "none",
                            padding: "0",
                            margin: "0",
                          }}
                        >
                          {consentList.map((item, index) => (
                            <li
                              key={item.id}
                              onClick={() => {
                                setSelect(item.id);
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "10px 0",
                                paddingLeft: "16px",
                                borderBottom:
                                  index !== consentList.length - 1
                                    ? "1px solid #D9D9D9"
                                    : "none",
                                cursor: "pointer",
                                background:
                                  item.id === select
                                    ? "#F5F5FF"
                                    : "transparent",
                                borderLeft:
                                  item.id === select
                                    ? "4px solid #683091"
                                    : "none",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    margin: "0",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color:
                                      item.id === select
                                        ? "#683091"
                                        : "#000000",
                                  }}
                                >
                                  {item.name}
                                </p>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "#22C55E",
                                    marginRight: "8px",
                                  }}
                                >
                                  {item.isPublished ? "เผยแพร่" : "ร่าง"}
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "#22C55E",
                                  }}
                                >
                                  {item.version}
                                </span>
                                <br />
                                {/* แสดงคำว่า 'ตั้งค่าเริ่มต้น' */}
                                {item.id === defaultId && (
                                  <span className="consent-badge">
                                    กำลังใช้งาน
                                  </span>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <Flex
                        vertical
                        justify="center"
                        align="center"
                        style={{ height: "80%" }}
                      >
                        <NoData />
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#667085",
                          }}
                        >
                          ไม่มีข้อมูล
                        </p>
                      </Flex>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={24}
                    style={{ paddingTop: "14px", background: "#FFF" }}
                  >
                    <Divider style={{ margin: "0" }} />
                    <UserListTable
                      data={{
                        selectedConsent: select,
                      }}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Sider
                  width={!screens.xl ? 300 : 402}
                  style={{
                    background: "#FFF",
                    height: "auto",
                  }}
                >
                  <div style={{ padding: "14px" }}>
                    {/* Header */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "14px",
                      }}
                    >
                      <p
                        style={{
                          margin: "0",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        ทั้งหมด ({consentList.length}) รายการ
                      </p>
                    </div>
                  </div>

                  <Divider style={{ margin: "0" }} />
                  {consentList ? (
                    <div style={{ padding: "14px" }}>
                      <ul
                        style={{ listStyle: "none", padding: "0", margin: "0" }}
                      >
                        {consentList.map((item, index) => (
                          <li
                            key={item.id}
                            onClick={() => {
                              setSelect(item.id);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px 0",
                              paddingLeft: "16px",
                              borderBottom:
                                index !== consentList.length - 1
                                  ? "1px solid #D9D9D9"
                                  : "none",
                              cursor: "pointer",
                              background:
                                item.id === select ? "#F5F5FF" : "transparent",
                              borderLeft:
                                item.id === select
                                  ? "4px solid #683091"
                                  : "none",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  margin: "0",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color:
                                    item.id === select ? "#683091" : "#000000",
                                }}
                              >
                                {item.name}
                              </p>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#22C55E",
                                  marginRight: "8px",
                                }}
                              >
                                {item.isPublished ? "เผยแพร่" : "ร่าง"}
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#22C55E",
                                }}
                              >
                                {item.version}
                              </span>
                              <br />
                              {/* แสดงคำว่า 'ตั้งค่าเริ่มต้น' */}
                              {item.id === defaultId && (
                                <span className="consent-badge">
                                  กำลังใช้งาน
                                </span>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <Flex
                      vertical
                      justify="center"
                      align="center"
                      style={{ height: "80%" }}
                    >
                      <NoData />
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#667085",
                        }}
                      >
                        ไม่มีข้อมูล
                      </p>
                    </Flex>
                  )}
                </Sider>
                <Content
                  style={{
                    marginLeft: "0",
                    overflowY: "auto",
                  }}
                >
                  <UserListTable
                    data={{
                      selectedConsent: select,
                    }}
                  />
                </Content>
              </>
            )}
          </Layout>
        </Layout>
      </div>

      <LoadingDialog isLoading={loading} />
    </>
  );
};

const UserListTable = (data) => {
  const [select, setSelect] = useState(null);
  const [paginatedData, setPaginatedData] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => pageSize * (currentPage - 1) + index + 1,
    },
    { title: "ชื่อ - นามสกุล", dataIndex: "fullName", key: "fullName" },
    { title: "อีเมล", dataIndex: "email", key: "email" },
    { title: "วันเวลา", dataIndex: "consentDateText", key: "consentDateText" },
  ];
  useEffect(() => {
    setSelect(data.data.selectedConsent);
  }, [data]);

  useEffect(() => {
    if (select) {
      fetchData(currentPage, pageSize, keywords);
    }
  }, [select, pageSize, currentPage]);

  const fetchData = async (page, size, filter) => {
    setLoading(true);
    try {
      var response = await ConsentService.userLists(select, {
        keywords: filter ?? keywords,
        "Pagination.Page": page ?? currentPage,
        "Pagination.PageSize": size ?? pageSize,
      });
      setPaginatedData(response.items);
      setPageSize(response.pagination.pageSize);
      setTotalCount(response.pagination.totalCount);
      setCurrentPage(response.pagination.page);
      setLoading(false);
    } catch (error) {
      ErrorMessageHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setKeywords(value);

    // ล้าง timeout เดิมก่อนจะตั้งใหม่
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        fetchData(1, pageSize, value);
      }, 1500)
    );
  };
  return (
    <div className="view-consent-body">
      <LoadingDialog isLoading={loading} />
      {select === null || select === undefined ? (
        <Flex
          vertical
          justify="center"
          align="center"
          style={{ height: "100%" }}
        >
          <NoData />
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#667085",
            }}
          >
            กรุณาเลือกคำยินยอมการใช้งานข้อมูลส่วนบุคคล
          </p>
        </Flex>
      ) : !paginatedData || paginatedData.length == 0 ? (
        <Flex
          vertical
          justify="center"
          align="center"
          style={{ height: "100%", marginTop: "70px" }}
        >
          <NoData />
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#667085",
            }}
          >
            ไม่มีข้อมูล
          </p>
        </Flex>
      ) : (
        <div
          key={select}
          style={{
            padding: "14px",
            background: "#FFF",
            margin: "14px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Row>
            <Col xl={10} md={12} xs={24}>
              <div className="search-box">
                <Input
                  placeholder="ค้นหา ชื่อ-นามสกุล, อีเมล..."
                  value={keywords}
                  onChange={handleSearchChange}
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={paginatedData}
            pagination={false}
            rowKey="id"
            scroll={{
              x: 1000,
            }}
          />

          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: 16 }}
            gutter={[16, 16]}
          >
            <Col>
              {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          > */}

              <span
                style={{
                  color: "#2E323A",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                ทั้งหมด {totalCount} รายการ
              </span>
            </Col>

            <Col>
              <Pagination
                className="custom-pagination"
                current={currentPage}
                pageSize={pageSize}
                total={totalCount}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                }}
              />
            </Col>
            <Col>
              {/* <select
                  value={pageSize}
                  onChange={(e) => {
                    const newPageSize = Number(e.target.value);
                    setPageSize(newPageSize);
                    setCurrentPage(1);
                  }}
                  style={{
                    padding: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  <option value="5">5 / หน้า</option>
                  <option value="10">10 / หน้า</option>
                  <option value="20">20 / หน้า</option>
                  <option value="50">50 / หน้า</option>
                </select> */}

              <Select
                value={pageSize}
                onChange={(value) => {
                  const newPageSize = Number(value);
                  setPageSize(newPageSize);
                  setCurrentPage(1);
                }}
                style={{
                  width: "108px",
                  height: "40px",
                  borderRadius: "10px",
                }}
              >
                <Option value={10}>
                  <span
                    style={{
                      color: "#2E323A",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    10 / หน้า
                  </span>
                </Option>
                <Option value={25}>
                  <span
                    style={{
                      color: "#2E323A",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    25 / หน้า
                  </span>
                </Option>
                <Option value={50}>
                  <span
                    style={{
                      color: "#2E323A",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    50 / หน้า
                  </span>
                </Option>
                <Option value={100}>
                  <span
                    style={{
                      color: "#2E323A",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    100 / หน้า
                  </span>
                </Option>
              </Select>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ViewConsent;
