import React, { useState, useEffect, useContext, useRef } from "react";
import "./DataSourceManagement.css";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Modal,
  Tooltip,
  Button,
  Divider,
  Flex,
  Space,
  Switch,
  message,
  InputNumber,
  Grid,
} from "antd";
import {
  PlusOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { AppContext } from "./DataSourceManagementDetail";
import { HTTP_STATUS_CODES } from "../../../../../constants";
// import { ReRouteService } from "../../../../../services/ReRouteService";

const { Option } = Select;
const { useBreakpoint } = Grid;

// const PATH_PATTERN =
//   /^\/([a-zA-Z0-9_-]+(?:\/[a-zA-Z0-9_-]+)*(?:\/\{[a-zA-Z0-9_-]+\})?(?:\/[a-zA-Z0-9_-]+)*(?:\/\{[a-zA-Z0-9_-]+\})*)*$/;
const PATH_PATTERN = /^\/(?!.*\/\/)[a-zA-Z0-9/_-]*(?<!\/)$/;

const DataSourceFormRouteConfig = ({
  isModalVisible,
  handleCancelModal,
  item,
  tags,
  createTagRender,
  setLoading,
}) => {
  const { setDetailList } = useContext(AppContext);

  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [upstreamPathTemplate, setUpstreamPathTemplate] = useState("");
  const [upstreamPathCurrent, setUpstreamPathCurrent] = useState("");
  const [selectVersion, setSelectVersion] = useState("");
  // const inputVersionRef = useRef(null);
  const [httpMethod, setHttpMethod] = useState("");
  const [selectRequestTypes, setSelectRequestTypes] = useState({});
  const [requestArrayTypes, setRequestArrayTypes] = useState({});
  const [selectResponseTypes, setSelectResponseTypes] = useState({});
  const [responseArrayTypes, setResponseArrayTypes] = useState({});

  useEffect(() => {
    if (isModalVisible) {
      handleSetFieldsValue(item);
      setLoading(false);
    }
  }, [isModalVisible]);

  // useEffect(() => {
  //   if (httpMethod !== "get") {
  //     const currentRequests = form.getFieldValue("requests") || [];
  //     if (currentRequests.length === 0) {
  //       form.setFieldsValue({
  //         requests: [
  //           {
  //             schemasProperties: [{}],
  //           },
  //         ],
  //       });
  //     }
  //   }
  // }, [httpMethod]);

  // useEffect(() => {
  //   if (upstreamPathTemplate) {
  //     const currentPathParameters = form.getFieldValue("pathParameters") || [];
  //     if (currentPathParameters.length === 0) {
  //       form.setFieldsValue({
  //         pathParameters: [{}],
  //       });
  //     }
  //   }
  // }, [upstreamPathTemplate]);

  const handleSetFieldsValue = (value) => {
    if (value.routeConfigJson) {
      const routeValue = JSON.parse(value.routeConfigJson);
      const swaggerValue = JSON.parse(value.swaggerConfigJson);
      const result = transformModel(swaggerValue.inputdoc[0]);

      // if (swaggerValue.inputdoc[0].method !== "get") {
      //   const initialRequestTypes =
      //     result.formattedRequests[0].schemasProperties.reduce(
      //       (acc, e, index) => {
      //         acc[index] = e.type;
      //         return acc;
      //       },
      //       {}
      //     );
      //   const initialRequestArrayTypes =
      //     result.formattedRequests[0].schemasProperties.reduce(
      //       (acc, e, index) => {
      //         if (e.type === "array") {
      //           acc[index] = e.array.type;
      //         }
      //         return acc;
      //       },
      //       {}
      //     );

      //   setSelectRequestTypes(initialRequestTypes);
      //   setRequestArrayTypes(initialRequestArrayTypes);
      // }

      // const initialResponseTypes = result.formattedResponses.reduce(
      //   (acc, e, index) => {
      //     acc[index] = e.schemasProperties.reduce((acc, e, index) => {
      //       acc[index] = e.type;
      //       return acc;
      //     }, {});
      //     return acc;
      //   },
      //   {}
      // );
      // const initialResponseArrayTypes = result.formattedResponses.reduce(
      //   (acc, e, index) => {
      //     acc[index] = e.schemasProperties.reduce((acc, e, index) => {
      //       if (e.type === "array") {
      //         acc[index] = e.array.type;
      //       }
      //       return acc;
      //     }, {});
      //     return acc;
      //   },
      //   {}
      // );

      const upstreamPath = routeValue.routes[0].upstreamPathTemplate;
      if (upstreamPath.includes("{") && upstreamPath.includes("}")) {
        setUpstreamPathTemplate(upstreamPath);
      }

      const basePathPattern = /^\/[^/]+/;
      const replaceUpstreamPath = upstreamPath.replace(basePathPattern, "");
      const versionNumber = swaggerValue.version.replace(/^v/, "");

      setUpstreamPathCurrent(replaceUpstreamPath);
      // setHttpMethod(routeValue.routes[0].upstreamHttpMethod[0]);
      setHttpMethod("post");
      setSelectVersion(swaggerValue.version);
      // setSelectResponseTypes(initialResponseTypes);
      // setResponseArrayTypes(initialResponseArrayTypes);

      form.setFieldsValue({
        ...routeValue.routes[0],
        upstreamPathTemplate: replaceUpstreamPath,
        // upstreamHttpMethod: routeValue.routes[0].upstreamHttpMethod[0],
        upstreamHttpMethod: "post",
        downstreamPathTemplate: "/private/explore/query",
        downstreamScheme: process.env.REACT_APP_DOWNSTREAM_ENDPOINT_SCHEME,
        downstreamHostAndPorts: [
          {
            host: process.env.REACT_APP_DOWNSTREAM_ENDPOINT_ADDRESS,
            port: process.env.REACT_APP_DOWNSTREAM_ENDPOINT_PORT,
          },
        ],
        summary: swaggerValue.inputdoc[0].summary,
        tag: swaggerValue.inputdoc[0].tag[0],
        version: versionNumber,
        tableName: routeValue.routes[0].tableName,
        queryLimit: routeValue.routes[0].queryLimit,
        requests: [
          {
            schemasName: result.formattedRequests?.schemasName,
            // tableName: routeValue.routes[0].tableName,
            // queryLimit: routeValue.routes[0].queryLimit,
          },
        ],
        responses: result.formattedResponses,
        queryParameters:
          swaggerValue.inputdoc.length > 0
            ? swaggerValue.inputdoc[0].QueryParameters
            : [],
        pathParameters:
          swaggerValue.inputdoc.length > 0
            ? swaggerValue.inputdoc[0].PathParameters
            : [],
      });
    } else {
      setSelectVersion("v1");

      form.setFieldsValue({
        version: 1,
        upstreamHttpMethod: "post",
        downstreamPathTemplate: "/private/explore/query",
        downstreamScheme: process.env.REACT_APP_DOWNSTREAM_ENDPOINT_SCHEME,
        downstreamHostAndPorts: [
          {
            host: process.env.REACT_APP_DOWNSTREAM_ENDPOINT_ADDRESS,
            port: process.env.REACT_APP_DOWNSTREAM_ENDPOINT_PORT,
          },
        ],
      });
    }
  };

  const handleCancel = () => {
    handleCancelModal();
    setTimeout(() => {
      form.resetFields();
      setUpstreamPathCurrent("");
      setUpstreamPathTemplate("");
      setHttpMethod("");
      setSelectVersion("");
      setSelectRequestTypes({});
      setRequestArrayTypes({});
      setSelectResponseTypes({});
      setResponseArrayTypes({});
    }, 200);
  };

  const onFinish = async (values) => {
    console.log(values);
    
    const routeConfigData = {
      routes: [
        {
          // routeName: values.routeName,
          routeName: "",
          routeType: "External",
          upstreamPathTemplate: `/v${values.version}${values.upstreamPathTemplate}`,
          upstreamHttpMethod: [values.upstreamHttpMethod],
          downstreamScheme: values.downstreamScheme,
          downstreamHostAndPorts: values.downstreamHostAndPorts,
          downstreamPathTemplate: values.downstreamPathTemplate,
          tableName: values.tableName,
          queryLimit: values.queryLimit,
          AuthenticationOptions: {
            AuthenticationProviderKey: "ALL_REALM",
            // AllowedScopes: [],
          },
        },
      ],
    };

    // let responsesFormat = {};

    // values.responses.forEach((response) => {
    //   responsesFormat[response.httpCode] = {
    //     description: response.description,
    //     content: {
    //       "application/json": {
    //         schema: {
    //           $ref: response.schemasName,
    //         },
    //       },
    //       "text/json": {
    //         schema: {
    //           $ref: response.schemasName,
    //         },
    //       },
    //       "application/*+json": {
    //         schema: {
    //           $ref: response.schemasName,
    //         },
    //       },
    //     },
    //   };
    // });

    // let requestSchema = [];
    // if (values.upstreamHttpMethod !== "get") {
    //   // requestSchema = await transformToSchemas(values.requests, "req");
    // }
    // const responseSchema = await transformToSchemas(values.responses, "res");
    // const responseSchema = [];
    // const combinedSchemas = requestSchema.concat(responseSchema);

    const requests = values.requests[0];

    const swaggerConfigData = {
      title: "MICE OPENDATA REST API",
      version: `v${values.version}`,
      inputdoc: [
        {
          path: `/api/v${values.version}${values.upstreamPathTemplate}`,
          method: values.upstreamHttpMethod,
          tag: [values.tag],
          summary: values.summary,
          requestBody:
            values.upstreamHttpMethod !== "get" ? requests.schemasName : "",
          // responses: responsesFormat,
          // responses: {
          //   200: {
          //     description: "Success",
          //     content: {
          //       "application/json": {
          //         schema: {
          //           $ref: `${requests.schemasName}Response`,
          //         },
          //       },
          //       "text/json": {
          //         schema: {
          //           $ref: `${requests.schemasName}Response`,
          //         },
          //       },
          //       "application/*+json": {
          //         schema: {
          //           $ref: `${requests.schemasName}Response`,
          //         },
          //       },
          //     },
          //   },
          // },
          responses: {},
          // schemas: combinedSchemas,
          schemas: [
            {
              Name: requests.schemasName,
              $ref: requests.schemasName,
              Type: "object",
              Properties: {
                // tableName: {
                //   Type: "string",
                //   Format: "",
                //   Nullable: false,
                // },
                limit: {
                  Type: "integer",
                  Format: "",
                  Nullable: false,
                },
              },
              AdditionalProperties: false,
            },
          ],
          QueryParameters: [],
          PathParameters: [],
          // QueryParameters: values.queryParameters.map((param) => {
          //   return {
          //     name: param.name,
          //     description: param.description,
          //     required: param.required,
          //     type: param.type,
          //   };
          // }),
          // PathParameters: upstreamPathTemplate
          //   ? values.pathParameters.map((param) => {
          //       return {
          //         name: param.name,
          //         description: param.description,
          //         required: true,
          //         // type: param.type,
          //         type: "string",
          //       };
          //     })
          //   : [],
        },
      ],
    };

    const routeConfigJsonData = JSON.stringify(routeConfigData);
    const swaggerConfigJsonData = JSON.stringify(swaggerConfigData);

    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            upstreamPath: `/v${values.version}${values.upstreamPathTemplate}`,
            upstreamHttpMethod: values.upstreamHttpMethod,
            routeConfigJson: routeConfigJsonData,
            swaggerConfigJson: swaggerConfigJsonData,
            apiVersion: `v${values.version}`,
          };
        }
        return detail;
      })
    );

    handleCancel();
  };

  const transformModel = (inputDoc) => {
    const { method, requestBody, responses, schemas } = inputDoc;

    const findSchemaByName = (name) =>
      schemas.find((schema) => schema.Name === name);

    const transformProperties = (properties) =>
      Object.entries(properties).map(([name, value]) => {
        if (value.Type === "array") {
          return {
            name,
            type: "array",
            array:
              value.Items.Type === "object"
                ? {
                    type: "object",
                    children: transformProperties(value.Items.Properties),
                    nullable: value.Items.Nullable,
                  }
                : { type: value.Items.Type, nullable: value.Items.Nullable },
          };
        } else if (value.Type === "object") {
          return {
            name,
            type: "object",
            children: transformProperties(value.Properties),
            nullable: value.Nullable,
          };
        } else {
          return {
            name,
            type: value.Type,
            nullable: value.Nullable,
          };
        }
      });

    const formattedResponses = [];
    // if (responses !== null || responses !== undefined) {
    //   formattedResponses = Object.entries(responses).map(
    //     ([httpCode, response]) => {
    //       const schemaRef = response.content["application/json"].schema.$ref;
    //       const schema = findSchemaByName(schemaRef);
    //       return {
    //         httpCode: Number(httpCode),
    //         description: response.description,
    //         schemasName: schema.Name,
    //         schemasProperties: transformProperties(schema.Properties),
    //       };
    //     }
    //   );
    // }

    // let formattedRequests = [];
    let formattedRequests;
    if (method !== "get") {
      const requestSchema = findSchemaByName(requestBody);
      // formattedRequests = [
      //   {
      //     schemasName: requestSchema.Name,

      //     tableName: requestSchema.Properties.
      //     queryLimit
      //     // schemasProperties: transformProperties(requestSchema.Properties),
      //   },
      // ];
      formattedRequests = {
        schemasName: requestSchema.Name,
      };
    }

    return { formattedResponses, formattedRequests };
  };

  const transformToSchemas = async (values, type) => {
    return values.map((items) => {
      const properties = {};

      items.schemasProperties.forEach((prop) => {
        if (prop.type === "object") {
          properties[prop.name] = {
            Type: "object",
            Properties: prop.children.reduce((acc, child) => {
              acc[child.name] = {
                Type: child.type,
                Format: "",
                Nullable: type !== "res" ? child.nullable : false,
              };
              return acc;
            }, {}),
            Nullable: prop.nullable,
          };
        } else if (prop.type === "array") {
          properties[prop.name] = {
            Type: "array",
            Items: {
              Type: prop.array.type,
              ...(prop.array.type === "object"
                ? {
                    Properties: prop.array.children.reduce((acc, child) => {
                      acc[child.name] = {
                        Type: child.type,
                        Format: "",
                        Nullable: type !== "res" ? child.nullable : false,
                      };
                      return acc;
                    }, {}),
                  }
                : {}),
              Nullable: type !== "res" ? prop.array.nullable : false,
            },
            Nullable: type !== "res" ? prop.nullable : false,
          };
        } else {
          properties[prop.name] = {
            Type: prop.type,
            Format: "",
            Nullable: type !== "res" ? prop.nullable : false,
          };
        }
      });

      return {
        Name: items.schemasName,
        $ref: items.schemasName,
        Type: "object",
        Properties: properties,
        // AdditionalProperties: items.schemasAdditionalProperties,
        AdditionalProperties: false,
      };
    });
  };

  const convertToSchemaName = (path) => {
    return (
      path
        .replace(/^\//, "")
        .split("/")
        .map((segment, index) =>
          index === 0
            ? segment
            : segment.charAt(0).toUpperCase() + segment.slice(1)
        )
        .join("") + "Schema"
    );
  };

  const handleRequestTypeChange = (value, index) => {
    setSelectRequestTypes((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const handleRequestArrayTypeChange = (value, index) => {
    setRequestArrayTypes((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const handleResponseTypeChange = (value, parentIndex, index) => {
    setSelectResponseTypes((prev) => ({
      ...prev,
      [parentIndex]: {
        ...(prev[parentIndex] || {}),
        [index]: value,
      },
    }));
  };

  const handleResponseArrayTypeChange = (value, parentIndex, index) => {
    setResponseArrayTypes((prev) => ({
      ...prev,
      [parentIndex]: {
        ...(prev[parentIndex] || {}),
        [index]: value,
      },
    }));
  };

  const handleRequestFieldClear = (index) => {
    setSelectRequestTypes((prev) => {
      const updatedTypes = { ...prev };
      delete updatedTypes[index];
      return updatedTypes;
    });

    setRequestArrayTypes((prev) => {
      const updatedArrayTypes = { ...prev };
      delete updatedArrayTypes[index];
      return updatedArrayTypes;
    });
  };

  const handleResponseFieldClear = (parentIndex, subIndex) => {
    setSelectResponseTypes((prev) => {
      const updatedTypes = { ...prev };
      if (updatedTypes[parentIndex]) {
        delete updatedTypes[parentIndex][subIndex];
        if (Object.keys(updatedTypes[parentIndex]).length === 0) {
          delete updatedTypes[parentIndex];
        }
      }
      return updatedTypes;
    });

    setResponseArrayTypes((prev) => {
      const updatedTypes = { ...prev };
      if (updatedTypes[parentIndex]) {
        delete updatedTypes[parentIndex][subIndex];
        if (Object.keys(updatedTypes[parentIndex]).length === 0) {
          delete updatedTypes[parentIndex];
        }
      }
      return updatedTypes;
    });
  };

  const validateName = (_, value) => {
    if (value && /\s/.test(value)) {
      return Promise.reject(new Error("ไม่อนุญาตให้มีช่องว่างระหว่างคำ!"));
    } else if (value && /[ก-๙]/.test(value)) {
      return Promise.reject(new Error("กรุณากรอกภาษาอังกฤษ!"));
    }
    return Promise.resolve();
  };

  const customLabel = (name, TooltipTitle) => {
    return (
      <div style={{ padding: "" }}>
        <span style={{ fontWeight: "", color: "#1D273C", marginRight: "5px" }}>
          {name}&nbsp;
        </span>
        <span style={{ fontWeight: "bold", color: "red" }}>*</span>
        {TooltipTitle && (
          <Tooltip title={TooltipTitle}>
            <InfoCircleOutlined style={{ color: "#0063A7", marginLeft: 8 }} />
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={
          <span
            style={{ color: "#2E323A", fontSize: "18px", fontWeight: "600" }}
          >
            ตั้งค่า API
          </span>
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        zIndex={2000}
        centered
      >
        <Divider
          style={{
            borderColor: "#d9d9d9",
          }}
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            downstreamHostAndPorts: [{}],
            // queryParameters: [{}],
            // pathParameters: [{}],
            // requestSchemasProperties: [{}],
            requests: [
              {
                // schemasProperties: [{}],
              },
            ],
            // responses: [
            //   {
            //     schemasProperties: [{}],
            //   },
            // ],
          }}
          onValuesChange={(changedValues, allValues) => {
            // setIsChanged(true);
          }}
          onFinishFailed={({ errorFields }) => {
            messageApi.open({
              type: "error",
              content: "กรุณากรอกข้อมูลให้สมบูรณ์",
            });
          }}
        >
          <div style={{ height: "70vh", overflowY: "scroll" }}>
            <div
              style={{
                padding: "8px",
                background: "#F8F8F9",
                borderRadius: "8px",
                display: "none",
              }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={customLabel("Route Name")}
                    name="routeName"
                    rules={[
                      {
                        required: false,
                        message: "จำเป็นต้องกรอกข้อมูล",
                      },
                    ]}
                    required={false}
                  >
                    <Input placeholder="" disabled={item?.isPreserved} />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            {/* <br /> */}
            <div
              style={{
                padding: "8px",
                background: "#F8F8F9",
                borderRadius: "8px",
              }}
            >
              {/* <Row>
                <span
                  style={{
                    color: "#2E323A",
                    fontSize: "18px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  API ที่เผยแพร่ให้ใช้งาน
                </span>
              </Row> */}
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={customLabel("ชื่อ URL (API ที่เผยแพร่ให้ใช้งาน)")}
                    name="upstreamPathTemplate"
                    rules={[
                      { required: true, message: "จำเป็นต้องกรอกข้อมูล" },
                      {
                        pattern: PATH_PATTERN,
                        message: "รูปแบบไม่ถูกต้อง",
                      },
                    ]}
                    required={false}
                  >
                    <Input
                      disabled={item?.isPreserved}
                      addonBefore={`/api/${selectVersion}`}
                      placeholder="/xxxx/xxx/xxxxxx"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (!PATH_PATTERN.test(inputValue)) {
                          setUpstreamPathCurrent("");
                          setUpstreamPathTemplate("");
                          return;
                        } else {
                          setUpstreamPathCurrent(inputValue);
                          let schemaName = convertToSchemaName(inputValue);

                          form.setFieldsValue({
                            requests: [
                              {
                                schemasName: schemaName,
                              },
                            ],
                          });
                        }

                        if (
                          inputValue.includes("{") &&
                          inputValue.includes("}") &&
                          PATH_PATTERN.test(inputValue)
                        ) {
                          setUpstreamPathTemplate(inputValue);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={customLabel("Http Method", "จำเป็นต้องกรอกข้อมูล")}
                    name="upstreamHttpMethod"
                    rules={[
                      { required: true, message: "จำเป็นต้องกรอกข้อมูล" },
                    ]}
                    required={false}
                    // noStyle
                    style={{ display: "none" }}
                  >
                    <Select
                      // mode="multiple"
                      placeholder=""
                      allowClear
                      onChange={(value) => {
                        setHttpMethod(value);
                      }}
                    >
                      <Option value="get">GET</Option>
                      <Option value="post">POST</Option>
                      <Option value="put">PUT</Option>
                      <Option value="patch">PATCH</Option>
                      <Option value="delete">DELETE</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="version"
                    label={customLabel("เวอร์ชั่นของ API")}
                    rules={[
                      {
                        required: true,
                        message: "จำเป็นต้องกรอกข้อมูล",
                      },
                    ]}
                    required={false}
                  >
                    <InputNumber
                      disabled={item?.isPreserved}
                      addonBefore={"v"}
                      min={1}
                      onChange={(value) => {
                        setSelectVersion(`v${value}`);
                      }}
                      style={{
                        width: screens.xs ? "100%" : "",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label={screens.xs ? "" : <></>}>
                    {selectVersion && (
                      <span>{`ตัวอย่าง: /api/${selectVersion}${upstreamPathCurrent}`}</span>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #d0d5dd",
                  display: "none",
                }}
              >
                <Form.List name="queryParameters">
                  {(fields, { add, remove }) => (
                    <>
                      <Flex justify="space-between">
                        <span
                          style={{
                            color: "#2E323A",
                            fontSize: "18px",
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Query Parameters
                        </span>
                        <Button
                          type="primary"
                          icon={
                            <PlusOutlined
                              style={{ color: "#2E323A", fontSize: "14px" }}
                            />
                          }
                          onClick={() => add()}
                          style={{
                            height: "40px",
                            color: "#2E323A",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          เพิ่ม
                        </Button>
                      </Flex>
                      {fields.map(({ key, name, ...restField }) => (
                        <>
                          <Row key={key} gutter={16}>
                            <Col span={11}>
                              <Form.Item
                                {...restField}
                                name={[name, "name"]}
                                label={customLabel(
                                  "Name",
                                  "จำเป็นต้องกรอกข้อมูล"
                                )}
                                rules={[
                                  {
                                    // required: true,
                                    required: false,
                                    message: "จำเป็นต้องกรอกข้อมูล",
                                  },
                                  {
                                    validator: validateName,
                                  },
                                  {
                                    validator: (_, value) => {
                                      if (value) {
                                        const queryParameters =
                                          form.getFieldValue(
                                            "queryParameters"
                                          ) || [];
                                        const isDuplicate =
                                          queryParameters.some(
                                            (item, index) =>
                                              item?.name === value &&
                                              index !== name
                                          );

                                        if (isDuplicate) {
                                          return Promise.reject(
                                            new Error("ไม่อนุญาตให้ใช้ชื่อซ้ำ!")
                                          );
                                        }
                                        return Promise.resolve();
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                                required={false}
                              >
                                <Input placeholder="" />
                              </Form.Item>
                            </Col>
                            <Col span={11}>
                              <Form.Item
                                {...restField}
                                name={[name, "description"]}
                                label={customLabel(
                                  "Description",
                                  "จำเป็นต้องกรอกข้อมูล"
                                )}
                                rules={[
                                  {
                                    // required: true,
                                    required: false,
                                    message: "จำเป็นต้องกรอกข้อมูล",
                                  },
                                ]}
                                required={false}
                              >
                                <Input placeholder="" />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item label={<></>}>
                                <Button
                                  icon={<DeleteOutlined />}
                                  onClick={() => {
                                    remove(name);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={6}>
                              <Form.Item
                                name={[name, "type"]}
                                label={customLabel("Type")}
                                rules={[
                                  {
                                    // required: true,
                                    required: false,
                                    message: "จำเป็นต้องกรอกข้อมูล",
                                  },
                                ]}
                                required={false}
                              >
                                <Select
                                  placeholder=""
                                  allowClear
                                  onChange={(value) => {}}
                                >
                                  <Option value="string">string</Option>
                                  <Option value="boolean">boolean</Option>
                                  <Option value="integer">integer</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col span={2}>
                              <Form.Item
                                name={[name, "required"]}
                                label={
                                  <span style={{ fontWeight: "bold" }}>
                                    required
                                  </span>
                                }
                                initialValue={false}
                              >
                                <Switch
                                  checkedChildren="True"
                                  unCheckedChildren="False"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ))}
                    </>
                  )}
                </Form.List>
              </div>

              {upstreamPathTemplate && (
                <>
                  <br />
                  <div
                    style={{
                      padding: "8px",
                      borderRadius: "8px",
                      border: "1px solid #d0d5dd",
                    }}
                  >
                    <Form.List name="pathParameters">
                      {(fields, { add, remove }) => (
                        <>
                          <Flex justify="space-between">
                            <span
                              style={{
                                color: "#2E323A",
                                fontSize: "18px",
                                fontWeight: "600",
                                // marginBottom: "10px",
                              }}
                            >
                              Path Parameters
                            </span>
                            <Button
                              type="primary"
                              icon={
                                <PlusOutlined
                                  style={{ color: "#2E323A", fontSize: "14px" }}
                                />
                              }
                              onClick={() => add()}
                              style={{
                                height: "40px",
                                color: "#2E323A",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              เพิ่ม
                            </Button>
                          </Flex>
                          <p>Upstream Path: {upstreamPathTemplate}</p>
                          {fields.map(({ key, name, ...restField }) => (
                            <Row key={key} gutter={16}>
                              <Col span={11}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "name"]}
                                  label={customLabel(
                                    "Name",
                                    "จำเป็นต้องกรอกข้อมูล"
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: "จำเป็นต้องกรอกข้อมูล",
                                    },
                                    {
                                      validator: validateName,
                                    },
                                    {
                                      validator: (_, value) => {
                                        if (value) {
                                          const pathParams =
                                            upstreamPathTemplate.match(
                                              /{([^}]+)}/g
                                            ) || [];
                                          const isValid = pathParams.some(
                                            (param) => {
                                              const paramName = param.replace(
                                                /[{}]/g,
                                                ""
                                              );
                                              return paramName === value;
                                            }
                                          );

                                          if (!isValid) {
                                            return Promise.reject(
                                              new Error(
                                                "ชื่อไม่สอดคล้องกับ Upstream Path!"
                                              )
                                            );
                                          }

                                          const pathParameters =
                                            form.getFieldValue(
                                              "pathParameters"
                                            ) || [];
                                          const isDuplicate =
                                            pathParameters.some(
                                              (item, index) =>
                                                item?.name === value &&
                                                index !== name
                                            );

                                          if (isDuplicate) {
                                            return Promise.reject(
                                              new Error(
                                                "ไม่อนุญาตให้ใช้ชื่อซ้ำ!"
                                              )
                                            );
                                          }
                                          return Promise.resolve();
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                  required={false}
                                >
                                  <Input placeholder="" />
                                </Form.Item>
                              </Col>
                              <Col span={11}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "description"]}
                                  label={customLabel(
                                    "Description",
                                    "จำเป็นต้องกรอกข้อมูล"
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: "จำเป็นต้องกรอกข้อมูล",
                                    },
                                  ]}
                                  required={false}
                                >
                                  <Input placeholder="" />
                                </Form.Item>
                              </Col>
                              <Col span={2}>
                                {fields.length > 1 && (
                                  <Form.Item label={<></>}>
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() => {
                                        remove(name);
                                      }}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </div>
                </>
              )}
            </div>

            <br />
            <div
              style={{
                padding: "8px",
                background: "#F8F8F9",
                borderRadius: "8px",
              }}
            >
              <Row>
                <span
                  style={{
                    color: "#2E323A",
                    fontSize: "18px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  แหล่งข้อมูลปลายทาง
                </span>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={<span style={{ fontWeight: "" }}>ชื่อ URL</span>}
                    name="downstreamPathTemplate"
                    rules={[
                      { required: true, message: "จำเป็นต้องกรอกข้อมูล" },
                      {
                        pattern: PATH_PATTERN,
                        message: "รูปแบบไม่ถูกต้อง",
                      },
                    ]}
                    required={false}
                  >
                    <Input
                      placeholder="/xxxx/xxx/xxxxxx"
                      value={"/private/explore/query"}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={
                      <span style={{ fontWeight: "" }}>รูปแบบโปรโตคอล</span>
                    }
                    name="downstreamScheme"
                    rules={[
                      { required: true, message: "จำเป็นต้องกรอกข้อมูล" },
                    ]}
                    required={false}
                  >
                    <Select placeholder="" allowClear value={"https"} disabled>
                      <Option value="http">HTTP</Option>
                      <Option value="https">HTTPS</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #d0d5dd",
                  display: "none",
                }}
              >
                <Row>
                  <span
                    style={{
                      color: "#2E323A",
                      fontSize: "18px",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                  >
                    Body Parameters
                  </span>
                </Row>
                {/* <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={customLabel("Table Name", "จำเป็นต้องกรอกข้อมูล")}
                      name="tableName"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "จำเป็นต้องกรอกข้อมูล",
                      //   },
                      //   {
                      //     validator: validateName,
                      //   },
                      // ]}
                      required={false}
                    >
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={customLabel("limit", "จำเป็นต้องกรอกข้อมูล")}
                      name="queryLimit"
                      // rules={[
                      //   { required: true, message: "จำเป็นต้องกรอกข้อมูล" },
                      // ]}
                      required={false}
                    >
                      <InputNumber placeholder="" min={1} max={100} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
              {/* <br /> */}
              <div
                style={{
                  padding: "0px",
                  // borderRadius: "8px",
                  // border: "1px solid #d0d5dd",
                }}
              >
                <Form.List name="downstreamHostAndPorts">
                  {(fields, { add, remove }) => (
                    <>
                      <Flex justify="space-between">
                        {/* <span
                          style={{
                            color: "#2E323A",
                            fontSize: "18px",
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Host and Post
                        </span> */}
                        {/* <Button
                        type="primary"
                        icon={
                          <PlusOutlined
                            style={{ color: "#2E323A", fontSize: "14px" }}
                          />
                        }
                        onClick={() => add()}
                        style={{
                          height: "40px",
                          color: "#2E323A",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        เพิ่ม
                      </Button> */}
                      </Flex>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} gutter={16}>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "host"]}
                              label={
                                <span style={{ fontWeight: "" }}>
                                  ที่อยู่เครื่องให้บริการ (รูปแบบ IPv4)
                                </span>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "จำเป็นต้องกรอกข้อมูล",
                                },
                              ]}
                              required={false}
                            >
                              <Input
                                placeholder=""
                                value={
                                  process.env
                                    .REACT_APP_DOWNSTREAM_ENDPOINT_ADDRESS
                                }
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "port"]}
                              label={
                                <span style={{ fontWeight: "" }}>พอร์ต</span>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "จำเป็นต้องกรอกข้อมูล",
                                },
                                {
                                  pattern: /^\d+$/,
                                  message: "กรุณากรอกตัวเลข!",
                                },
                              ]}
                              required={false}
                            >
                              <Input
                                placeholder=""
                                value={
                                  process.env.REACT_APP_DOWNSTREAM_ENDPOINT_PORT
                                }
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col span={2}>
                          {fields.length > 1 && (
                            <Form.Item label={<></>}>
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  remove(name);
                                }}
                              />
                            </Form.Item>
                          )}
                        </Col> */}
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </div>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={customLabel("ชื่อตารางข้อมูล")}
                    name="tableName"
                    rules={[
                      {
                        required: true,
                        message: "จำเป็นต้องกรอกข้อมูล",
                      },
                      {
                        validator: validateName,
                      },
                    ]}
                    required={false}
                  >
                    <Input
                      disabled={item?.isPreserved}
                      placeholder=""
                      // onChange={(e) => {
                      //   form.setFieldsValue({
                      //     tableName: e.target.value,
                      //   });
                      // }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label={customLabel("จำนวนข้อมูลสูงสุดที่ได้")}
                    name="queryLimit"
                    rules={[
                      {
                        required: true,
                        message: "จำเป็นต้องกรอกข้อมูล",
                      },
                    ]}
                    required={false}
                  >
                    <InputNumber
                      placeholder=""
                      min={1}
                      max={1000}
                      initialValue={100}
                      // onChange={(e) => {
                      //   form.setFieldsValue({
                      //     queryLimit: e,
                      //   });
                      // }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <br />
            <div
              style={{
                padding: "8px",
                background: "#F8F8F9",
                borderRadius: "8px",
              }}
            >
              <p
                style={{
                  color: "#2E323A",
                  fontSize: "18px",
                  fontWeight: "500",
                  margin: "0 0 20px 0",
                }}
              >
                เอกสารข้อมูล API
              </p>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="summary"
                    label={customLabel("คำอธิบายสั้นๆ")}
                    rules={[
                      {
                        required: true,
                        message: "จำเป็นต้องกรอกข้อมูล",
                      },
                    ]}
                    required={false}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      disabled={item?.isPreserved}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="tag"
                    label={customLabel("Tag (การจัดกลุ่ม API)")}
                    rules={[
                      {
                        required: true,
                        message: "จำเป็นต้องกรอกข้อมูล",
                      },
                    ]}
                    required={false}
                  >
                    <Select
                      disabled={item?.isPreserved}
                      tagRender={tags.length ? "" : createTagRender}
                      defaultValue={tags.length ? [] : ["New Tag"]}
                      onChange={() => {}}
                      options={tags?.map((tag) => ({
                        label: tag.name,
                        value: tag.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {httpMethod !== "get" && (
                <div
                  style={{
                    padding: "0px",
                    // borderRadius: "8px",
                    // border: "1px solid #d0d5dd",
                  }}
                >
                  <Form.List name="requests">
                    {(fields, { add, remove }) => (
                      <>
                        {/* <p
                          style={{
                            color: "#2E323A",
                            fontSize: "16px",
                            fontWeight: "500",
                            margin: "0 0 10px 0",
                          }}
                        >
                          Request (JSON format)
                        </p> */}

                        {fields.map(({ key, name, ...restField }) => (
                          <>
                            {/* <Flex
                              justify="space-between"
                              style={{ marginBottom: 20 }}
                            >
                              <div>
                                <span style={{ fontWeight: "bold" }}>
                                  Schemas
                                </span>
                                <Tooltip title="Schemas">
                                  <InfoCircleOutlined
                                    style={{ color: "#0063A7", marginLeft: 8 }}
                                  />
                                </Tooltip>
                              </div>
                            </Flex> */}

                            <Row gutter={16}>
                              {/* <Col span={12}>
                                <Form.Item
                                  name={[name, "schemasName"]}
                                  label={customLabel("Name")}
                                  rules={[
                                    {
                                      required: true,
                                      message: "จำเป็นต้องกรอกข้อมูล",
                                    },
                                    {
                                      validator: validateName,
                                    },
                                    {
                                      validator: (_, value) => {
                                        if (value) {
                                          const responses =
                                            form.getFieldValue("responses") ||
                                            [];
                                          const isDuplicate = responses.some(
                                            (item, index) =>
                                              item?.schemasName === value
                                          );

                                          if (isDuplicate) {
                                            return Promise.reject(
                                              new Error(
                                                "ไม่อนุญาตให้ใช้ชื่อซ้ำ!"
                                              )
                                            );
                                          }
                                          return Promise.resolve();
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                  required={false}
                                >
                                  <Input type="text" placeholder="" disabled />
                                </Form.Item>
                              </Col> */}

                              {/* <Col span={12}>
                                <Form.Item
                                  name={[name, "schemasType"]}
                                  label={
                                    <span style={{ fontWeight: "bold" }}>
                                      Type
                                    </span>
                                  }
                                >
                                  <Input
                                    placeholder="Object"
                                    value={"Object"}
                                    disabled
                                  />
                                </Form.Item>
                              </Col> */}
                            </Row>

                            <div
                              style={{
                                padding: "0px",
                                // borderRadius: "8px",
                                // border: "1px solid #d0d5dd",
                                // marginTop: "20px",
                              }}
                            >
                              {/* <Flex
                                justify="space-between"
                                style={{ marginBottom: 30 }}
                              >
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    Properties
                                  </span>
                                  <Tooltip title="Schemas">
                                    <InfoCircleOutlined
                                      style={{
                                        color: "#0063A7",
                                        marginLeft: 8,
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </Flex> */}
                              {/* <br /> */}
                              {/* <Row gutter={16}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                  <Form.Item
                                    label={customLabel(
                                      "ชื่อตารางข้อมูล"
                                    )}
                                    name={[name, "tableName"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "จำเป็นต้องกรอกข้อมูล",
                                      },
                                      {
                                        validator: validateName,
                                      },
                                    ]}
                                    required={false}
                                  >
                                    <Input
                                      disabled={item?.isPreserved}
                                      placeholder=""
                                      onChange={(e) => {
                                        form.setFieldsValue({
                                          tableName: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item
                                    label={customLabel(
                                      "จำนวนข้อมูลสูงสุดที่ได้",
                                    )}
                                    name={[name, "queryLimit"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "จำเป็นต้องกรอกข้อมูล",
                                      },
                                    ]}
                                    required={false}
                                  >
                                    <InputNumber
                                      placeholder=""
                                      min={1}
                                      max={100}
                                      onChange={(e) => {
                                        form.setFieldsValue({
                                          queryLimit: e,
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row> */}
                            </div>

                            {/* <div
                            style={{
                              padding: "15px",
                              borderRadius: "8px",
                              border: "1px solid #d0d5dd",
                              // marginTop: "20px",
                            }}
                          >
                            <Form.List name={[name, "schemasProperties"]}>
                              {(subFields, subOpt) => (
                                <>
                                  <Flex
                                    justify="space-between"
                                    style={{ marginBottom: 30 }}
                                  >
                                    <div>
                                      <span style={{ fontWeight: "bold" }}>
                                        Properties
                                      </span>
                                      <Tooltip title="Schemas">
                                        <InfoCircleOutlined
                                          style={{
                                            color: "#0063A7",
                                            marginLeft: 8,
                                          }}
                                        />
                                      </Tooltip>
                                    </div>
                                    <Button
                                      type="primary"
                                      icon={
                                        <PlusOutlined
                                          style={{
                                            color: "#2E323A",
                                            fontSize: "14px",
                                          }}
                                        />
                                      }
                                      onClick={() => {
                                        subOpt.add();
                                      }}
                                      style={{
                                        height: "40px",
                                        color: "#2E323A",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        display: "none",
                                      }}
                                    >
                                      เพิ่ม
                                    </Button>
                                  </Flex>
                                  {subFields.map((subField, index) => (
                                    <>
                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            label={customLabel(
                                              "Table Name",
                                              "จำเป็นต้องกรอกข้อมูล"
                                            )}
                                            name={[subField.name, "tableName"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "จำเป็นต้องกรอกข้อมูล",
                                              },
                                              {
                                                validator: validateName,
                                              },
                                            ]}
                                            required={false}
                                          >
                                            <Input placeholder="" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            label={customLabel(
                                              "limit",
                                              "จำเป็นต้องกรอกข้อมูล"
                                            )}
                                            name={[subField.name, "queryLimit"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "จำเป็นต้องกรอกข้อมูล",
                                              },
                                            ]}
                                            required={false}
                                          >
                                            <InputNumber
                                              placeholder=""
                                              min={1}
                                              max={100}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row key={subField.key} gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name={[subField.name, "name"]}
                                            label={customLabel("Name")}
                                            rules={[
                                              {
                                                required: true,
                                                message: "จำเป็นต้องกรอกข้อมูล",
                                              },
                                              {
                                                validator: validateName,
                                              },
                                              {
                                                validator: (_, value) => {
                                                  if (value) {
                                                    const requests =
                                                      form.getFieldValue(
                                                        "requests"
                                                      ) || [];
                                                    const isDuplicate =
                                                      requests.some((request) =>
                                                        (
                                                          request.schemasProperties ||
                                                          []
                                                        ).some(
                                                          (item, index) =>
                                                            item?.name ===
                                                              value &&
                                                            index !==
                                                              subField.name
                                                        )
                                                      );

                                                    if (isDuplicate) {
                                                      return Promise.reject(
                                                        new Error(
                                                          "ไม่อนุญาตให้ใช้ชื่อซ้ำ!"
                                                        )
                                                      );
                                                    }
                                                  }
                                                  return Promise.resolve();
                                                },
                                              },
                                            ]}
                                            required={false}
                                          >
                                            <Input type="text" placeholder="" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                          <Form.Item
                                            name={[subField.name, "type"]}
                                            label={customLabel("Type")}
                                            rules={[
                                              {
                                                required: true,
                                                message: "จำเป็นต้องกรอกข้อมูล",
                                              },
                                            ]}
                                            required={false}
                                          >
                                            <Select
                                              placeholder=""
                                              allowClear
                                              onChange={(value) => {
                                                handleRequestTypeChange(
                                                  value,
                                                  index
                                                );
                                              }}
                                            >
                                              <Option value="string">
                                                string
                                              </Option>
                                              <Option value="boolean">
                                                boolean
                                              </Option>
                                              <Option value="integer">
                                                integer
                                              </Option>
                                              <Option value="array">
                                                array
                                              </Option>
                                              <Option value="object">
                                                object
                                              </Option>
                                            </Select>
                                          </Form.Item>
                                        </Col>

                                        <Col span={3}>
                                          <Form.Item
                                            name={[subField.name, "nullable"]}
                                            label={
                                              <span
                                                style={{ fontWeight: "bold" }}
                                              >
                                                required
                                              </span>
                                            }
                                            initialValue={false}
                                          >
                                            <Switch
                                              checkedChildren="True"
                                              unCheckedChildren="False"
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                          {subFields.length > 1 && (
                                            <Form.Item label={<></>}>
                                              <Button
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                  subOpt.remove(subField.name);
                                                  handleRequestFieldClear(
                                                    index
                                                  );
                                                }}
                                              />
                                            </Form.Item>
                                          )}
                                        </Col>
                                      </Row>
                                      {selectRequestTypes[index] ===
                                        "object" && (
                                        <Form.List
                                          name={[subField.name, "children"]}
                                        >
                                          {(childFields, childOpt) => (
                                            <>
                                              {childFields.map((childField) => (
                                                <Row
                                                  key={childField.key}
                                                  gutter={16}
                                                >
                                                  <Col span={1}></Col>
                                                  <Col span={10}>
                                                    <Form.Item
                                                      name={[
                                                        childField.name,
                                                        "name",
                                                      ]}
                                                      label={customLabel(
                                                        "Name"
                                                      )}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "จำเป็นต้องกรอกข้อมูล",
                                                        },
                                                        {
                                                          validator:
                                                            validateName,
                                                        },
                                                        {
                                                          validator: (
                                                            _,
                                                            value
                                                          ) => {
                                                            if (value) {
                                                              const children =
                                                                form.getFieldValue(
                                                                  [
                                                                    "requests",
                                                                    name,
                                                                    "schemasProperties",
                                                                    subField.name,
                                                                    "children",
                                                                  ]
                                                                ) || [];

                                                              const isDuplicate =
                                                                children.some(
                                                                  (
                                                                    item,
                                                                    index
                                                                  ) =>
                                                                    item?.name ===
                                                                      value &&
                                                                    index !==
                                                                      childField.name
                                                                );
                                                              if (isDuplicate) {
                                                                return Promise.reject(
                                                                  new Error(
                                                                    "ไม่อนุญาตให้ใช้ชื่อซ้ำ!"
                                                                  )
                                                                );
                                                              }
                                                            }
                                                            return Promise.resolve();
                                                          },
                                                        },
                                                      ]}
                                                      required={false}
                                                    >
                                                      <Input
                                                        type="text"
                                                        placeholder=""
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={6}>
                                                    <Form.Item
                                                      name={[
                                                        childField.name,
                                                        "type",
                                                      ]}
                                                      label={customLabel(
                                                        "Type"
                                                      )}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "จำเป็นต้องกรอกข้อมูล",
                                                        },
                                                      ]}
                                                      required={false}
                                                    >
                                                      <Select
                                                        placeholder=""
                                                        allowClear
                                                      >
                                                        <Option value="string">
                                                          string
                                                        </Option>
                                                        <Option value="boolean">
                                                          boolean
                                                        </Option>
                                                        <Option value="integer">
                                                          integer
                                                        </Option>
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={3}>
                                                    <Form.Item
                                                      name={[
                                                        childField.name,
                                                        "nullable",
                                                      ]}
                                                      label={
                                                        <span
                                                          style={{
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          required
                                                        </span>
                                                      }
                                                      initialValue={false}
                                                    >
                                                      <Switch
                                                        checkedChildren="True"
                                                        unCheckedChildren="False"
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={1}>
                                                    <Form.Item label={<></>}>
                                                      <Button
                                                        icon={
                                                          <DeleteOutlined />
                                                        }
                                                        onClick={() =>
                                                          childOpt.remove(
                                                            childField.name
                                                          )
                                                        }
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                              ))}
                                              <Row gutter={16}>
                                                <Col span={1}></Col>
                                                <Col>
                                                  <Button
                                                    type="dashed"
                                                    icon={<PlusOutlined />}
                                                    onClick={() =>
                                                      childOpt.add()
                                                    }
                                                    style={{
                                                      marginBottom: 16,
                                                    }}
                                                  >
                                                    Add Field
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </>
                                          )}
                                        </Form.List>
                                      )}
                                      {selectRequestTypes[index] ===
                                        "array" && (
                                        <>
                                          <Row gutter={16}>
                                            <Col span={2}>
                                              <Form.Item label={<></>}>
                                                <span>Items</span>
                                              </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                              <Form.Item
                                                name={[
                                                  subField.name,
                                                  "array",
                                                  "type",
                                                ]}
                                                label={customLabel("Type")}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "จำเป็นต้องกรอกข้อมูล",
                                                  },
                                                ]}
                                                required={false}
                                              >
                                                <Select
                                                  placeholder=""
                                                  allowClear
                                                  onChange={(value) =>
                                                    handleRequestArrayTypeChange(
                                                      value,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <Option value="string">
                                                    string
                                                  </Option>
                                                  <Option value="boolean">
                                                    boolean
                                                  </Option>
                                                  <Option value="integer">
                                                    integer
                                                  </Option>
                                                  <Option value="object">
                                                    object
                                                  </Option>
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                            <Col span={3}>
                                              <Form.Item
                                                name={[
                                                  subField.name,
                                                  "array",
                                                  "nullable",
                                                ]}
                                                label={
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    required
                                                  </span>
                                                }
                                                initialValue={false}
                                              >
                                                <Switch
                                                  checkedChildren="True"
                                                  unCheckedChildren="False"
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                          {selectRequestTypes[index] ===
                                            "array" &&
                                            requestArrayTypes[index] ===
                                              "object" && (
                                              <Form.List
                                                name={[
                                                  subField.name,
                                                  "array",
                                                  "children",
                                                ]}
                                              >
                                                {(childFields, childOpt) => (
                                                  <>
                                                    {childFields.map(
                                                      (childField) => (
                                                        <Row
                                                          key={childField.key}
                                                          gutter={16}
                                                        >
                                                          <Col span={3}></Col>
                                                          <Col span={10}>
                                                            <Form.Item
                                                              name={[
                                                                childField.name,
                                                                "name",
                                                              ]}
                                                              label={customLabel(
                                                                "Name"
                                                              )}
                                                              rules={[
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "จำเป็นต้องกรอกข้อมูล",
                                                                },
                                                                {
                                                                  validator:
                                                                    validateName,
                                                                },
                                                                {
                                                                  validator: (
                                                                    _,
                                                                    value
                                                                  ) => {
                                                                    if (value) {
                                                                      const children =
                                                                        form.getFieldValue(
                                                                          [
                                                                            "requests",
                                                                            name,
                                                                            "schemasProperties",
                                                                            subField.name,
                                                                            "array",
                                                                            "children",
                                                                          ]
                                                                        ) || [];

                                                                      const isDuplicate =
                                                                        children.some(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) =>
                                                                            item?.name ===
                                                                              value &&
                                                                            index !==
                                                                              childField.name
                                                                        );
                                                                      if (
                                                                        isDuplicate
                                                                      ) {
                                                                        return Promise.reject(
                                                                          new Error(
                                                                            "ไม่อนุญาตให้ใช้ชื่อซ้ำ!"
                                                                          )
                                                                        );
                                                                      }
                                                                    }
                                                                    return Promise.resolve();
                                                                  },
                                                                },
                                                              ]}
                                                              required={false}
                                                            >
                                                              <Input
                                                                type="text"
                                                                placeholder=""
                                                              />
                                                            </Form.Item>
                                                          </Col>
                                                          <Col span={6}>
                                                            <Form.Item
                                                              name={[
                                                                childField.name,
                                                                "type",
                                                              ]}
                                                              label={customLabel(
                                                                "Type"
                                                              )}
                                                              rules={[
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "จำเป็นต้องกรอกข้อมูล",
                                                                },
                                                              ]}
                                                              required={false}
                                                            >
                                                              <Select
                                                                placeholder=""
                                                                allowClear
                                                              >
                                                                <Option value="string">
                                                                  string
                                                                </Option>
                                                                <Option value="boolean">
                                                                  boolean
                                                                </Option>
                                                                <Option value="integer">
                                                                  integer
                                                                </Option>
                                                              </Select>
                                                            </Form.Item>
                                                          </Col>
                                                          <Col span={3}>
                                                            <Form.Item
                                                              name={[
                                                                childField.name,
                                                                "nullable",
                                                              ]}
                                                              label={
                                                                <span
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  required
                                                                </span>
                                                              }
                                                              initialValue={
                                                                false
                                                              }
                                                            >
                                                              <Switch
                                                                checkedChildren="True"
                                                                unCheckedChildren="False"
                                                              />
                                                            </Form.Item>
                                                          </Col>
                                                          <Col span={1}>
                                                            <Form.Item
                                                              label={<></>}
                                                            >
                                                              <Button
                                                                icon={
                                                                  <DeleteOutlined />
                                                                }
                                                                onClick={() =>
                                                                  childOpt.remove(
                                                                    childField.name
                                                                  )
                                                                }
                                                              />
                                                            </Form.Item>
                                                          </Col>
                                                        </Row>
                                                      )
                                                    )}
                                                    <Row gutter={16}>
                                                      <Col span={3}></Col>
                                                      <Col>
                                                        <Button
                                                          type="dashed"
                                                          icon={
                                                            <PlusOutlined />
                                                          }
                                                          onClick={() =>
                                                            childOpt.add()
                                                          }
                                                          style={{
                                                            marginBottom: 16,
                                                          }}
                                                        >
                                                          Add Field
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </>
                                                )}
                                              </Form.List>
                                            )}
                                        </>
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </Form.List>
                          </div> */}
                          </>
                        ))}
                      </>
                    )}
                  </Form.List>
                </div>
              )}

              {/* <br /> */}
              <div
                style={{
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #d0d5dd",
                  display: "none",
                }}
              >
                <Form.List name="responses">
                  {(fields, { add, remove }) => (
                    <>
                      <Flex justify="space-between">
                        <span
                          style={{
                            color: "#2E323A",
                            fontSize: "18px",
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Responses (JSON format)
                        </span>
                        <Button
                          type="primary"
                          icon={
                            <PlusOutlined
                              style={{ color: "#2E323A", fontSize: "14px" }}
                            />
                          }
                          onClick={() => {
                            add({
                              schemasProperties: [{}],
                            });
                          }}
                          style={{
                            height: "40px",
                            color: "#2E323A",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          เพิ่ม
                        </Button>
                      </Flex>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          key={key}
                          style={{
                            padding: "15px",
                            borderRadius: "8px",
                            border: "1px solid #d0d5dd",
                            marginTop: "20px",
                          }}
                        >
                          <Row gutter={16}>
                            <Col span={11}>
                              <Form.Item
                                {...restField}
                                name={[name, "httpCode"]}
                                label={customLabel(
                                  "HTTP Code",
                                  "จำเป็นต้องกรอกข้อมูล"
                                )}
                                rules={[
                                  {
                                    // required: true,
                                    required: false,
                                    message: "จำเป็นต้องกรอกข้อมูล",
                                  },
                                  {
                                    validator: (_, value) => {
                                      if (value) {
                                        const responses =
                                          form.getFieldValue("responses") || [];
                                        const isDuplicate = responses.some(
                                          (item, index) =>
                                            item?.httpCode === value &&
                                            index !== name
                                        );

                                        if (isDuplicate) {
                                          return Promise.reject(
                                            new Error("HTTP Code ซ้ำกัน!")
                                          );
                                        }
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                                required={false}
                              >
                                <Select
                                  allowClear
                                  showSearch
                                  onChange={(e) => {
                                    const value = HTTP_STATUS_CODES.filter(
                                      (i) => i.httpCode === e
                                    );

                                    form.setFieldsValue({
                                      ["responses"]: {
                                        [name]: {
                                          description: value.length
                                            ? value[0].description
                                            : "",
                                        },
                                      },
                                    });
                                  }}
                                  options={HTTP_STATUS_CODES?.map((e) => ({
                                    label: e.httpCode,
                                    value: e.httpCode,
                                  }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={11}>
                              <Form.Item
                                {...restField}
                                name={[name, "description"]}
                                label={customLabel(
                                  "Description",
                                  "จำเป็นต้องกรอกข้อมูล"
                                )}
                                rules={[
                                  {
                                    // required: true,
                                    required: false,
                                    message: "จำเป็นต้องกรอกข้อมูล",
                                  },
                                ]}
                                required={false}
                              >
                                <Input type="text" placeholder="" />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              {fields.length > 1 && (
                                <Form.Item label={<></>}>
                                  <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                      remove(name);
                                    }}
                                  />
                                </Form.Item>
                              )}
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={22}>
                              <div
                                style={{
                                  padding: "15px",
                                  borderRadius: "8px",
                                  border: "1px solid #d0d5dd",
                                }}
                              >
                                <Flex
                                  justify="space-between"
                                  style={{ marginBottom: 20 }}
                                >
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>
                                      Schemas
                                    </span>
                                    <Tooltip title="Schemas">
                                      <InfoCircleOutlined
                                        style={{
                                          color: "#0063A7",
                                          marginLeft: 8,
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </Flex>

                                <Row gutter={16}>
                                  <Col span={12}>
                                    <Form.Item
                                      name={[name, "schemasName"]}
                                      label={customLabel("Name")}
                                      rules={[
                                        {
                                          // required: true,
                                          required: false,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                        {
                                          validator: validateName,
                                        },
                                        {
                                          validator: (_, value) => {
                                            if (value) {
                                              const requests =
                                                form.getFieldValue(
                                                  "requests"
                                                ) || [];
                                              const responses =
                                                form.getFieldValue(
                                                  "responses"
                                                ) || [];
                                              const isDuplicateReq =
                                                requests.some(
                                                  (item, index) =>
                                                    item?.schemasName === value
                                                );
                                              const isDuplicateRes =
                                                responses.some(
                                                  (item, index) =>
                                                    item?.schemasName ===
                                                      value && index !== name
                                                );

                                              if (
                                                isDuplicateRes ||
                                                isDuplicateReq
                                              ) {
                                                return Promise.reject(
                                                  new Error(
                                                    "ไม่อนุญาตให้ใช้ชื่อซ้ำ!"
                                                  )
                                                );
                                              }
                                              return Promise.resolve();
                                            }
                                            return Promise.resolve();
                                          },
                                        },
                                      ]}
                                      required={false}
                                    >
                                      <Input type="text" placeholder="" />
                                    </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                    <Form.Item
                                      name={[name, "schemasType"]}
                                      label={
                                        <span style={{ fontWeight: "bold" }}>
                                          Type
                                        </span>
                                      }
                                    >
                                      <Input
                                        placeholder="Object"
                                        value={"Object"}
                                        disabled
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <div
                                  style={{
                                    padding: "15px",
                                    borderRadius: "8px",
                                    border: "1px solid #d0d5dd",
                                    // marginTop: "20px",
                                  }}
                                >
                                  <Form.List name={[name, "schemasProperties"]}>
                                    {(subFields, subOpt) => (
                                      <>
                                        <Flex
                                          justify="space-between"
                                          style={{ marginBottom: 30 }}
                                        >
                                          <div>
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Properties
                                            </span>
                                            <Tooltip title="Schemas">
                                              <InfoCircleOutlined
                                                style={{
                                                  color: "#0063A7",
                                                  marginLeft: 8,
                                                }}
                                              />
                                            </Tooltip>
                                          </div>
                                          <Button
                                            type="primary"
                                            icon={
                                              <PlusOutlined
                                                style={{
                                                  color: "#2E323A",
                                                  fontSize: "14px",
                                                }}
                                              />
                                            }
                                            onClick={() => {
                                              subOpt.add();
                                            }}
                                            style={{
                                              height: "40px",
                                              color: "#2E323A",
                                              fontSize: "16px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            เพิ่ม
                                          </Button>
                                        </Flex>
                                        {subFields.map((subField, index) => (
                                          <>
                                            <Row key={subField.key} gutter={16}>
                                              <Col span={15}>
                                                <Form.Item
                                                  name={[subField.name, "name"]}
                                                  label={customLabel("Name")}
                                                  rules={[
                                                    {
                                                      // required: true,
                                                      required: false,
                                                      message:
                                                        "จำเป็นต้องกรอกข้อมูล",
                                                    },
                                                    {
                                                      validator: validateName,
                                                    },
                                                    {
                                                      validator: (_, value) => {
                                                        if (value) {
                                                          const responses =
                                                            form.getFieldValue(
                                                              "responses"
                                                            ) || [];
                                                          const isDuplicate =
                                                            responses.some(
                                                              (response) =>
                                                                (
                                                                  response.schemasProperties ||
                                                                  []
                                                                ).some(
                                                                  (
                                                                    item,
                                                                    index
                                                                  ) =>
                                                                    item?.name ===
                                                                      value &&
                                                                    index !==
                                                                      subField.name
                                                                )
                                                            );

                                                          if (isDuplicate) {
                                                            return Promise.reject(
                                                              new Error(
                                                                "ไม่อนุญาตให้ใช้ชื่อซ้ำ!"
                                                              )
                                                            );
                                                          }
                                                        }
                                                        return Promise.resolve();
                                                      },
                                                    },
                                                  ]}
                                                  required={false}
                                                >
                                                  <Input
                                                    type="text"
                                                    placeholder=""
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col span={6}>
                                                <Form.Item
                                                  name={[subField.name, "type"]}
                                                  label={customLabel("Type")}
                                                  rules={[
                                                    {
                                                      // required: true,
                                                      required: false,
                                                      message:
                                                        "จำเป็นต้องกรอกข้อมูล",
                                                    },
                                                  ]}
                                                  required={false}
                                                >
                                                  <Select
                                                    placeholder=""
                                                    allowClear
                                                    onChange={(value) => {
                                                      handleResponseTypeChange(
                                                        value,
                                                        name,
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    <Option value="string">
                                                      string
                                                    </Option>
                                                    <Option value="boolean">
                                                      boolean
                                                    </Option>
                                                    <Option value="integer">
                                                      integer
                                                    </Option>
                                                    <Option value="array">
                                                      array
                                                    </Option>
                                                    <Option value="object">
                                                      object
                                                    </Option>
                                                  </Select>
                                                </Form.Item>
                                              </Col>

                                              <Col span={1}>
                                                {subFields.length > 1 && (
                                                  <Form.Item label={<></>}>
                                                    <Button
                                                      icon={<DeleteOutlined />}
                                                      onClick={() => {
                                                        subOpt.remove(
                                                          subField.name
                                                        );
                                                        handleResponseFieldClear(
                                                          name,
                                                          index
                                                        );
                                                      }}
                                                    />
                                                  </Form.Item>
                                                )}
                                              </Col>
                                            </Row>
                                            {selectResponseTypes[name] &&
                                              selectResponseTypes[name][
                                                index
                                              ] === "object" && (
                                                <Form.List
                                                  name={[
                                                    subField.name,
                                                    "children",
                                                  ]}
                                                >
                                                  {(childFields, childOpt) => (
                                                    <>
                                                      {childFields.map(
                                                        (childField) => (
                                                          <Row
                                                            key={childField.key}
                                                            gutter={16}
                                                          >
                                                            <Col span={1}></Col>
                                                            <Col span={12}>
                                                              <Form.Item
                                                                name={[
                                                                  childField.name,
                                                                  "name",
                                                                ]}
                                                                label={customLabel(
                                                                  "Name"
                                                                )}
                                                                rules={[
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "จำเป็นต้องกรอกข้อมูล",
                                                                  },
                                                                  {
                                                                    validator:
                                                                      validateName,
                                                                  },
                                                                  {
                                                                    validator: (
                                                                      _,
                                                                      value
                                                                    ) => {
                                                                      if (
                                                                        value
                                                                      ) {
                                                                        const children =
                                                                          form.getFieldValue(
                                                                            [
                                                                              "responses",
                                                                              name,
                                                                              "schemasProperties",
                                                                              subField.name,
                                                                              "children",
                                                                            ]
                                                                          ) ||
                                                                          [];

                                                                        const isDuplicate =
                                                                          children.some(
                                                                            (
                                                                              item,
                                                                              index
                                                                            ) =>
                                                                              item?.name ===
                                                                                value &&
                                                                              index !==
                                                                                childField.name
                                                                          );
                                                                        if (
                                                                          isDuplicate
                                                                        ) {
                                                                          return Promise.reject(
                                                                            new Error(
                                                                              "ไม่อนุญาตให้ใช้ชื่อซ้ำ!"
                                                                            )
                                                                          );
                                                                        }
                                                                      }
                                                                      return Promise.resolve();
                                                                    },
                                                                  },
                                                                ]}
                                                                required={false}
                                                              >
                                                                <Input
                                                                  type="text"
                                                                  placeholder=""
                                                                />
                                                              </Form.Item>
                                                            </Col>
                                                            <Col span={6}>
                                                              <Form.Item
                                                                name={[
                                                                  childField.name,
                                                                  "type",
                                                                ]}
                                                                label={customLabel(
                                                                  "Type"
                                                                )}
                                                                rules={[
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "จำเป็นต้องกรอกข้อมูล",
                                                                  },
                                                                ]}
                                                                required={false}
                                                              >
                                                                <Select
                                                                  placeholder=""
                                                                  allowClear
                                                                >
                                                                  <Option value="string">
                                                                    string
                                                                  </Option>
                                                                  <Option value="boolean">
                                                                    boolean
                                                                  </Option>
                                                                  <Option value="integer">
                                                                    integer
                                                                  </Option>
                                                                </Select>
                                                              </Form.Item>
                                                            </Col>
                                                            <Col span={1}>
                                                              {/* {childFields.length > 1 && ( */}
                                                              <Form.Item
                                                                label={<></>}
                                                              >
                                                                <Button
                                                                  icon={
                                                                    <DeleteOutlined />
                                                                  }
                                                                  onClick={() =>
                                                                    childOpt.remove(
                                                                      childField.name
                                                                    )
                                                                  }
                                                                />
                                                              </Form.Item>
                                                              {/* )} */}
                                                            </Col>
                                                          </Row>
                                                        )
                                                      )}
                                                      <Row gutter={16}>
                                                        <Col span={1}></Col>
                                                        <Col>
                                                          <Button
                                                            type="dashed"
                                                            icon={
                                                              <PlusOutlined />
                                                            }
                                                            onClick={() =>
                                                              childOpt.add()
                                                            }
                                                            style={{
                                                              marginBottom: 16,
                                                            }}
                                                          >
                                                            Add Field
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </>
                                                  )}
                                                </Form.List>
                                              )}
                                            {selectResponseTypes[name] &&
                                              selectResponseTypes[name][
                                                index
                                              ] === "array" && (
                                                <>
                                                  <Row gutter={16}>
                                                    <Col span={2}>
                                                      <Form.Item label={<></>}>
                                                        <span>Items</span>
                                                      </Form.Item>
                                                    </Col>

                                                    <Col span={6}>
                                                      <Form.Item
                                                        name={[
                                                          subField.name,
                                                          "array",
                                                          "type",
                                                        ]}
                                                        label={customLabel(
                                                          "Type"
                                                        )}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message:
                                                              "จำเป็นต้องกรอกข้อมูล",
                                                          },
                                                        ]}
                                                        required={false}
                                                      >
                                                        <Select
                                                          placeholder=""
                                                          allowClear
                                                          onChange={(value) =>
                                                            handleResponseArrayTypeChange(
                                                              value,
                                                              name,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <Option value="string">
                                                            string
                                                          </Option>
                                                          <Option value="boolean">
                                                            boolean
                                                          </Option>
                                                          <Option value="integer">
                                                            integer
                                                          </Option>
                                                          <Option value="object">
                                                            object
                                                          </Option>
                                                        </Select>
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>
                                                  {selectResponseTypes[name] &&
                                                    selectResponseTypes[name][
                                                      index
                                                    ] === "array" &&
                                                    responseArrayTypes[name] &&
                                                    responseArrayTypes[name][
                                                      index
                                                    ] === "object" && (
                                                      <Form.List
                                                        name={[
                                                          subField.name,
                                                          "array",
                                                          "children",
                                                        ]}
                                                      >
                                                        {(
                                                          childFields,
                                                          childOpt
                                                        ) => (
                                                          <>
                                                            {childFields.map(
                                                              (childField) => (
                                                                <Row
                                                                  key={
                                                                    childField.key
                                                                  }
                                                                  gutter={16}
                                                                >
                                                                  <Col
                                                                    span={3}
                                                                  ></Col>
                                                                  <Col
                                                                    span={12}
                                                                  >
                                                                    <Form.Item
                                                                      name={[
                                                                        childField.name,
                                                                        "name",
                                                                      ]}
                                                                      label={customLabel(
                                                                        "Name"
                                                                      )}
                                                                      rules={[
                                                                        {
                                                                          required: true,
                                                                          message:
                                                                            "จำเป็นต้องกรอกข้อมูล",
                                                                        },
                                                                        {
                                                                          validator:
                                                                            validateName,
                                                                        },
                                                                        {
                                                                          validator:
                                                                            (
                                                                              _,
                                                                              value
                                                                            ) => {
                                                                              if (
                                                                                value
                                                                              ) {
                                                                                const children =
                                                                                  form.getFieldValue(
                                                                                    [
                                                                                      "responses",
                                                                                      name,
                                                                                      "schemasProperties",
                                                                                      subField.name,
                                                                                      "array",
                                                                                      "children",
                                                                                    ]
                                                                                  ) ||
                                                                                  [];

                                                                                const isDuplicate =
                                                                                  children.some(
                                                                                    (
                                                                                      item,
                                                                                      index
                                                                                    ) =>
                                                                                      item?.name ===
                                                                                        value &&
                                                                                      index !==
                                                                                        childField.name
                                                                                  );
                                                                                if (
                                                                                  isDuplicate
                                                                                ) {
                                                                                  return Promise.reject(
                                                                                    new Error(
                                                                                      "ไม่อนุญาตให้ใช้ชื่อซ้ำ!"
                                                                                    )
                                                                                  );
                                                                                }
                                                                              }
                                                                              return Promise.resolve();
                                                                            },
                                                                        },
                                                                      ]}
                                                                      required={
                                                                        false
                                                                      }
                                                                    >
                                                                      <Input
                                                                        type="text"
                                                                        placeholder=""
                                                                      />
                                                                    </Form.Item>
                                                                  </Col>
                                                                  <Col span={6}>
                                                                    <Form.Item
                                                                      name={[
                                                                        childField.name,
                                                                        "type",
                                                                      ]}
                                                                      label={customLabel(
                                                                        "Type"
                                                                      )}
                                                                      rules={[
                                                                        {
                                                                          required: true,
                                                                          message:
                                                                            "จำเป็นต้องกรอกข้อมูล",
                                                                        },
                                                                      ]}
                                                                      required={
                                                                        false
                                                                      }
                                                                    >
                                                                      <Select
                                                                        placeholder=""
                                                                        allowClear
                                                                      >
                                                                        <Option value="string">
                                                                          string
                                                                        </Option>
                                                                        <Option value="boolean">
                                                                          boolean
                                                                        </Option>
                                                                        <Option value="integer">
                                                                          integer
                                                                        </Option>
                                                                      </Select>
                                                                    </Form.Item>
                                                                  </Col>
                                                                  <Col span={1}>
                                                                    {/* {childFields.length > 1 && ( */}
                                                                    <Form.Item
                                                                      label={
                                                                        <></>
                                                                      }
                                                                    >
                                                                      <Button
                                                                        icon={
                                                                          <DeleteOutlined />
                                                                        }
                                                                        onClick={() =>
                                                                          childOpt.remove(
                                                                            childField.name
                                                                          )
                                                                        }
                                                                      />
                                                                    </Form.Item>
                                                                    {/* )} */}
                                                                  </Col>
                                                                </Row>
                                                              )
                                                            )}
                                                            <Row gutter={16}>
                                                              <Col
                                                                span={3}
                                                              ></Col>
                                                              <Col>
                                                                <Button
                                                                  type="dashed"
                                                                  icon={
                                                                    <PlusOutlined />
                                                                  }
                                                                  onClick={() =>
                                                                    childOpt.add()
                                                                  }
                                                                  style={{
                                                                    marginBottom: 16,
                                                                  }}
                                                                >
                                                                  Add Field
                                                                </Button>
                                                              </Col>
                                                            </Row>
                                                          </>
                                                        )}
                                                      </Form.List>
                                                    )}
                                                </>
                                              )}
                                          </>
                                        ))}
                                      </>
                                    )}
                                  </Form.List>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </>
                  )}
                </Form.List>
              </div>
            </div>
          </div>
          <br />
          <div style={{ textAlign: "right" }}>
            <Space>
              <Button
                onClick={() => {
                  handleCancel();
                }}
                style={{
                  width: "140px",
                  height: "40px",
                  borderRadius: "10px",
                }}
              >
                ยกเลิก
              </Button>
              <Button
                type="primary"
                onClick={() => form.submit()}
                style={{
                  width: "140px",
                  height: "40px",
                  borderRadius: "10px",
                }}
              >
                บันทึก
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DataSourceFormRouteConfig;
