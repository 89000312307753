import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Space,
  DatePicker,
  Flex,
  Button,
  Col,
  Row,
  Tag,
  Select,
  Radio,
  List,
  Divider,
} from "antd";
import "./InsightReport.css";
import { AiOutlineAppstore, AiOutlineBars } from "react-icons/ai";
import IconRightArrowViewDashboard from "../../../assets/icon/icon_right_arrow_view_dashboard.js";
import { InsightReportService } from "../../../services/InsightReportService";
import { InsightReportCategoryService } from "../../../services/InsightReportCategoryService";
import { InsightReportTagService } from "../../../services/InsightReportTagService";
import NoImg from "../../../assets/img/no_img.png";
import NoData from "../../../assets/img/no_data.js";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import { LoadingDialog, RangePickerLocale } from "../../../components";

const { RangePicker } = DatePicker;

const InsightReport = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dataTag, setDataTag] = useState([]);
  const [dataInsightType, setDataInsightType] = useState([]);
  const [dataInsight, setDataInsight] = useState([]);
  const [viewMode, setViewMode] = useState("card");
  const [isLoading, setIsLoading] = useState(0);
  const [selectedInsightType, setSelectedInsightType] = useState(["all"]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [cookies, setCookie] = useCookies(["insight-report-condition"]);
  const config = cookies["insight-report-condition"];
  // const tagId = searchParams.get("search");
  const location = useLocation();
  const { tagId } = location.state || {};

  useEffect(() => {
    if (tagId) {
      const tagIds = Array.isArray(tagId) ? tagId : [tagId];
      setSelectedTags(tagIds);
      const params = {
        InsightReportCategoryIds: selectedInsightType,
        TagIds: Array.isArray(tagId) ? tagId : [tagId],
        CreatedDateFrom: selectedDateRange[0],
        CreatedDateTo: selectedDateRange[1],
        Keywords: "",
        IsActivated: true,
      };
      console.log("params", params);

      InsightReportService.searchPublic(params)
        .then((response) => {
          console.log("response", response);
          setDataInsight(response.items);
        })
        .catch((error) => {
          console.error("Error in searchPublic:", error);
        });
    }
  }, [tagId]);

  const handleClick = (id, name) => {
    const Name = encodeURIComponent(name);
    navigate(`/insight-report/${Name}`);
    window.scrollTo(0, 0);
  };

  const setLoading = (visible) => {
    if (visible) {
      setIsLoading(isLoading + 1);
    } else {
      setIsLoading(isLoading - 1);
    }
  };

  useEffect(() => {
    const searchQuery = searchParams.get("search");
    //console.log('searchQuery:', searchQuery);
    if (!config && searchQuery && searchQuery.length > 0) {
      fetchDataInsight(searchQuery);
    }
  }, [searchParams]);

  const fetchDataInsight = async (updatedTags) => {
    try {
      setLoading(true);

      const params = {
        InsightReportCategoryIds: selectedInsightType,
        TagIds: updatedTags,
        CreatedDateFrom: selectedDateRange[0],
        CreatedDateTo: selectedDateRange[1],
        Keywords: "",
        IsActivated: true,
      };
      //console.log(JSON.stringify(params));
      if (localStorage.getItem("cookie") === "true") {
        setCookie("insight-report-condition", JSON.stringify(params), {
          path: "/",
        });
      }

      setTimeout(async () => {
        const response = await InsightReportService.searchPublic(params);
        setDataInsight(response.items);
      }, 150);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataTags = async () => {
    try {
      const response = await InsightReportTagService.get();
      setDataTag(response);
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
    }
  };

  const fetchDataInsightType = async () => {
    try {
      const response = await InsightReportCategoryService.search();

      // //console.log("API fetchDataInsightType Response:", response);

      const options = response.items.map((item) => ({
        value: item.id,
        label: item.name,
        color: "default",
      }));
      options.unshift({
        value: "all",
        label: "ประเภททั้งหมด",
        color: "default",
      });

      setDataInsightType(options);
      //console.log('config:', config);
      if (config && config.TagIds.length > 0) {
        setSelectedTags(config.TagIds);
      }
      if (config && config.InsightReportCategoryIds) {
        setSelectedInsightType(config.InsightReportCategoryIds);
      }
      if (config && config.CreatedDateFrom && config.CreatedDateTo) {
        setSelectedDateRange([config.CreatedDateFrom, config.CreatedDateTo]);
      }
    } catch (error) {
      //setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataTags().then(() => {
      fetchDataInsightType();
    });
  }, []);

  // useEffect(() => {
  //   fetchDataInsight();
  // }, [selectedInsightType, selectedTags, selectedDateRange]);

  /*useEffect(() => {
    fetchDataInsightType();
  }, []);*/

  const handleChangeType = async (selected) => {
    if (
      (selected.includes("all") && !selectedInsightType.includes("all")) ||
      selected.length == 0
    ) {
      setSelectedInsightType(["all"]);
    } else {
      const filteredValues = selected.filter((val) => val != "all");
      setSelectedInsightType(filteredValues);
    }
  };

  const tagRender = (props) => {
    const { label, value, color, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };

  const isSelectedTag = (key) => selectedTags.includes(key);
  const toggleButtonTag = (key, name) => {
    if (selectedTags.includes(key)) {
      // console.log("ว่าง");
      const updatedTags = []; // ตั้งค่าค่าว่าง
      setSelectedTags(updatedTags);
      // console.log("updatedTags", updatedTags);
      fetchDataInsight(updatedTags); // ส่ง updatedTags ที่เป็นค่าว่างไป
      navigate("/insight-reportTag");
    } else {
      const updatedTags = [key];
      setSelectedTags(updatedTags);
      fetchDataInsight(updatedTags); // ส่ง updatedTags ที่เป็น key เดียวไป
      navigate(`/insight-reportTag?tagName=${encodeURIComponent(name)}`);
    }
  };

  const toggleButtonTag2 = (key, name) => {
    if (selectedTags.length === 1 && selectedTags[0] === key) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    const updatedTags = [key];
    setSelectedTags(updatedTags);
    fetchDataInsight(updatedTags); // ส่ง updatedTags ที่เป็น key เดียวไป
    navigate(`/insight-reportTag?tagName=${encodeURIComponent(name)}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleChangeDateRange = (dates) => {
    let formattedDates = [];
    if (dates != null) {
      formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));
    }
    setSelectedDateRange(formattedDates);
  };

  return (
    <>
      <div className="insight-body">
        <div className="insight-container">
          <h2>รายงานวิเคราะห์ข้อมูลเชิงลึก (Insight Report)</h2>
          <div className="insight-tag">
            <Row>
              <Col xs={4} sm={3} md={2} lg={2} xl={2}>
                <h3>Tag : </h3>
              </Col>
              <Col
                sm={20}
                md={22}
                lg={22}
                xl={20}
                // flex={"20"}
                className="group-btn-tag"
              >
                <Flex wrap>
                  {dataTag?.map((tag) => (
                    <Button
                      key={tag.tagId}
                      onClick={() => toggleButtonTag(tag.tagId, tag.tagName)}
                      shape="round"
                      className={
                        isSelectedTag(tag.tagId)
                          ? "btn-tag-selected"
                          : "btn-tag"
                      }
                    >
                      {tag.tagName}
                    </Button>
                  ))}
                </Flex>
              </Col>
            </Row>
          </div>

          {/* <hr /> */}
          <Divider className="dashboard-divider" />

          <div className="insight-type">
            <Row className="insight-condition">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="group-type"
              >
                <h3>กรองโดย : </h3>
                <Select
                  size="large"
                  mode="multiple"
                  className="select-insight-type"
                  tagRender={tagRender}
                  defaultValue={["all"]}
                  onChange={handleChangeType}
                  value={selectedInsightType}
                  options={dataInsightType}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={10}
                xxl={10}
                className="group-type"
              >
                <RangePickerLocale
                  onChange={handleChangeDateRange}
                  value={
                    selectedDateRange.length > 0
                      ? [
                          dayjs(selectedDateRange[0]),
                          dayjs(selectedDateRange[1]),
                        ]
                      : null
                  }
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    fontSize: "14px",
                    marginLeft: "-15px",
                    marginRight: "-15px",
                  }}
                />
              </Col>
            </Row>
          </div>

          {/* <hr /> */}
          <Divider className="dashboard-divider" />

          {dataInsightType
            ?.filter(
              (item) =>
                item.value != "all" &&
                dataInsight.some(
                  (data) => data.insightReportCategoryId === item.value
                )
            )
            .map((itemType, index) => (
              <div key={index}>
                <div className="insight-content-head">
                  <Row justify={"space-between"} align={"middle"}>
                    <Col xs={14} sm={9} md={7} lg={14} xl={8}>
                      <h3 style={{ fontSize: 17 }}>{itemType.label}</h3>
                    </Col>
                    <Col
                      xs={10}
                      sm={15}
                      md={16}
                      lg={10}
                      xl={8}
                      className="btn-viewpage"
                      style={{ textAlign: "right" }}
                    >
                      {index == 0 && (
                        <Radio.Group
                          defaultValue="card"
                          buttonStyle="solid"
                          size="large"
                          optionType="button"
                          onChange={(e) => setViewMode(e.target.value)}
                        >
                          <Radio.Button value="card">
                            <AiOutlineAppstore />
                          </Radio.Button>
                          <Radio.Button value="list">
                            <AiOutlineBars />
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </Col>
                  </Row>
                </div>

                {viewMode === "card" && (
                  <div className="insight-content-content">
                    <Row justify="start" gutter={[16, 16]}>
                      {dataInsight
                        ?.filter(
                          (item) =>
                            item.insightReportCategoryId === itemType.value
                        )
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((item) => (
                          <Col sm={24} md={12} lg={8} xl={6} key={item.id}>
                            <div className="content-card-insight2">
                              <div>
                                <div
                                  style={{
                                    height: "175px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    className="content-image-insight"
                                    alt="example"
                                    src={item?.thumbnailSrc ?? `${NoImg}`}
                                    onError={(e) => {
                                      if (e.target.alt === "error") {
                                        //console.log("No img")
                                        e.target.src = NoImg;
                                      } else {
                                        e.target.src = NoImg;
                                      }
                                    }}
                                  />
                                </div>
                                <div style={{ padding: "24px 24px 0px 24px" }}>
                                  <Flex wrap gap="small">
                                    {item?.insightReportTags
                                      .slice(0, 3)
                                      .map((tag) => (
                                        <Tag
                                          key={tag.id}
                                          bordered={false}
                                          color={tag.tagColor}
                                          onClick={() =>
                                            toggleButtonTag2(
                                              tag.tagId,
                                              tag.tagName
                                            )
                                          }
                                          className="tag"
                                        >
                                          {tag.tagName}
                                        </Tag>
                                      ))}
                                  </Flex>

                                  <p className="content-card-title-dashboard">
                                    {item.name}
                                  </p>
                                  <p className="content-card-detail-dashboard">
                                    {item.shortDescription.length > 100
                                      ? item.shortDescription.substring(
                                          0,
                                          100
                                        ) + "..."
                                      : item.shortDescription}
                                  </p>

                                  <p
                                    style={{
                                      color: "#667085",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {item.createdDateFrom}
                                  </p>
                                </div>
                              </div>
                              <div style={{ padding: "0px 24px 24px 24px" }}>
                                <Flex
                                  justify="center"
                                  align="center"
                                  style={{
                                    marginTop: "-5px",
                                    padding: "10px 20px",
                                    backgroundColor: "#f0f5ff",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    color: "#2B7BF6",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                  }}
                                  onClick={() =>
                                    handleClick(item.id, item.name)
                                  }
                                >
                                  <p
                                    style={{
                                      margin: "0",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span style={{ marginRight: "8px" }}>
                                      ดูรายละเอียด
                                    </span>
                                    <IconRightArrowViewDashboard />
                                  </p>
                                </Flex>
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                )}
                {viewMode === "list" && (
                  <div
                    className="insight-hover-scroll"
                    style={{ overflowX: "auto", overflowY: "hidden" }}
                  >
                    <Space>
                      <List
                        itemLayout="vertical"
                        dataSource={dataInsight
                          .filter(
                            (item) =>
                              item.insightReportCategoryId === itemType.value
                          )
                          .sort((a, b) => a.sequence - b.sequence)}
                        renderItem={(item, index) => (
                          <>
                            <List.Item>
                              <Row
                                style={{
                                  alignItems: "center",
                                  // display: "flex",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  style={{ height: "120px", width: "150px" }}
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      minHeight: "120px",
                                      width: "100%",
                                      padding: "12px",
                                      borderRight:
                                        "1px solid rgba(5, 5, 5, 0.06)",
                                    }}
                                  >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      style={{ height: "100%" }}
                                    >
                                      <img
                                        alt="example"
                                        src={item?.thumbnailSrc ?? `${NoImg}`}
                                        onError={(e) => {
                                          if (e.target.alt === "error") {
                                            //console.log("No img")
                                            e.target.src = NoImg;
                                          } else {
                                            e.target.src = NoImg;
                                          }
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: "12px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </Flex>
                                  </div>
                                </Col>
                                <Col style={{ height: "auto", width: "340px" }}>
                                  <div
                                    style={{
                                      height: "100%",
                                      minHeight: "120px",
                                      width: "100%",
                                      padding: "0px 12px 0px 12px",
                                      borderRight:
                                        "1px solid rgba(5, 5, 5, 0.06)",
                                    }}
                                  >
                                    <Flex wrap gap="small">
                                      {item?.insightReportTags
                                        .slice(0, 3)
                                        .map((tag) => (
                                          <Tag
                                            key={tag.id}
                                            bordered={false}
                                            color={tag.tagColor}
                                            onClick={() =>
                                              toggleButtonTag2(
                                                tag.tagId,
                                                tag.tagName
                                              )
                                            }
                                            className="tag"
                                          >
                                            {tag.tagName}
                                          </Tag>
                                        ))}
                                    </Flex>
                                    <p
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        color: "#1D273C",
                                        margin: "10px 0px 10px 0px",
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "#667085",
                                      }}
                                    >
                                      {item.createdDateFrom}
                                    </p>
                                  </div>
                                </Col>
                                <Col style={{ height: "auto", width: "500px" }}>
                                  <div
                                    style={{
                                      height: "100%",
                                      minHeight: "120px",
                                      width: "100%",
                                      padding: "0px 12px 0px 12px",
                                      borderRight:
                                        "1px solid rgba(5, 5, 5, 0.06)",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#939CB0",
                                        margin: "0px 0px 10px 0px",
                                      }}
                                    >
                                      รายละเอียด
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#1D273C",
                                        margin: "0px 0px 0px 0px",
                                      }}
                                    >
                                      {item.shortDescription}
                                    </p>
                                  </div>
                                </Col>
                                <Col style={{ height: "auto", width: "200px" }}>
                                  <div
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      padding: "0px 12px 0px 12px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#f0f5ff",
                                        borderRadius: "10px",
                                        width: "100%",
                                        height: "auto",
                                        margin: "auto",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleClick(item.id, item.name)
                                      }
                                    >
                                      <Flex justify="center">
                                        <p
                                          style={{
                                            color: "#1677ff",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            textAlign: "center",
                                            marginRight: "10px",
                                          }}
                                        >
                                          ดูรายละเอียด
                                        </p>
                                        <IconRightArrowViewDashboard />
                                      </Flex>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </List.Item>
                          </>
                        )}
                      />
                    </Space>
                  </div>
                )}
              </div>
            ))}

          {dataInsight.length == 0 && (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ height: "50vh" }}
            >
              <NoData />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#667085",
                }}
              >
                ไม่มีข้อมูล
              </p>
            </Flex>
          )}
        </div>
      </div>
      <LoadingDialog isLoading={isLoading > 0} />
    </>
  );
};

export default InsightReport;
