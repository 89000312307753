import React from "react";
import { Button, Tooltip } from "antd";
import { AiOutlineDelete } from "react-icons/ai";

const DeleteButton = ({ onClick, isEditable = true, isVisible = true }) => {
  return (
    isVisible  && (
      <Tooltip title="ลบบัญชี">
        <Button
          className="custom-button-action"
          icon={
            <AiOutlineDelete
              style={{
                fontSize: "20px",
              }}
            />
          }
          disabled={isEditable === false}
          onClick={onClick}
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "8px",
          }}
        />
      </Tooltip>
    )
  );
};

export default DeleteButton;
