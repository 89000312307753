import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const RequisitionService = {
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/requisitions/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  search: async function (params, cancel = false) {
    const response = await api.request({
      url: `/requisitions`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  group: async function (params, cancel = false) {
    const response = await api.request({
      url: `/requisitions/group`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.test.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/requisitions`,
      method: "POST",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  approval: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/requisitions/${id}/approval`,
      method: "PATCH",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.approval.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/requisitions/${id}`,
      method: "DELETE",
      isSelfErrorHandler: true,
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(RequisitionService);
