import { useEffect, useState } from "react";
import { LoadingDialog } from "../../../../components";
import { ConfigurationService } from "../../../../services/ConfigurationService";
import "./Privacy.css";
import CustomFooter from "../../../../components/Footer/Footer";

const Terms = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
         ConfigurationService.get("terms-of-use").then((response) => {
             setData(response);
         }).catch((error) => {
             console.log(error);
         }).finally(() => {
             setIsLoading(false);
         });
    }, []);
    return (
        <>
        <div class="privacy-body">
            <h2>ข้อกำหนดและเงื่อนไขการให้บริการ</h2>
            <small>{data?.formattedUpdatedDate}</small>
            <div dangerouslySetInnerHTML={{ __html: data?.value }} />
            <LoadingDialog isLoading={isLoading} />
        </div>
        <CustomFooter />
        </>
    );
}
export default Terms;