import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ConfigurationService = {
  
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/configuration/key/$${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/configuration`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  update: async function (data, cancel = false) {
    const response = await api.request({
      url: `/configuration/key/$${data.key}`,
      method: "PATCH",
      data: data,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/configuration/key/$${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  
  
};

const cancelApiObject = defineCancelApiObject(ConfigurationService);
