import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./ManageConsent.css";
import {
  Layout,
  Flex,
  Button,
  Divider,
  Menu,
  Space,
  Card,
  Dropdown,
  notification,
  Grid,
  Col,
  Row,
} from "antd";
import {
  ConfirmDialog,
  LoadingDialog,
  DeleteDialog,
  EditButton,
} from "../../../../../components/index.js";
import NoData from "../../../../../assets/img/no_data.js";
import { AiOutlineEllipsis } from "react-icons/ai";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ClockIcon } from "@heroicons/react/24/outline";
import { ConsentService } from "../../../../../services/ConsentService.js";
import dayjs from "dayjs";
import ErrorMessageHandler from "../../../../../helpers/ErrorMessageHandler.js";

const { Header, Content, Sider } = Layout;
const { useBreakpoint } = Grid;

const MangeConsent = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  // const [dashboardCategory, setDashboardCategory] = useState();
  const passData = useLocation();
  let consentId = "";
  if (passData.state !== null && passData.state.consentId !== null) {
    consentId = passData.state.consentId;
  }
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [select, setSelect] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [consentList, setConsentList] = useState([]);
  const [defaultId, setDefaultId] = useState(null);
  const [ModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const latestConsent = consentList.reduce(
    (max, current) => {
      return current.version > max.version ? current : max;
    },
    { version: "0.0.0" }
  );

  const latestVersion = latestConsent?.version || "default-version";

  useEffect(() => {
    fetchAllConsent();
  }, []);

  useEffect(() => {
    if (consentId) {
      setSelect(consentId);
    }
  }, [consentId]);

  const fetchAllConsent = async () => {
    setLoading(true);
    try {
      const response = await ConsentService.getAll();
      setConsentList(response);
      response.map((item) => {
        if (item.isPrimary) {
          setDefaultId(item.id);
        }
      });
      if (response?.length !== 0) {
        setSelect(response[0]?.id);
        setPrevId(response[0]?.id);
      }
    } catch (error) {
      ErrorMessageHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const menuItem = (item) => (
    <Menu
      className="dropdown-menu"
      triggerSubMenuAction="click"
      items={[
        {
          key: "1",
          label: "ลบ",
          onClick: () => {
            setSelectedId(item.id);
            // setIsModalVisible(true);
            setIsModalDeleteOpen(true);
          },
        },
        item.isPublished
          ? {
              key: "2",
              label: "ตั้งค่าเริ่มต้น",
              onClick: () => {
                handleSetAsDefault(item.id);
              },
            }
          : null,
      ]}
    ></Menu>
  );
  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await ConsentService.delete(selectedId);
      notification.success({
        message: "ลบข้อมูลสำเร็จ",
        description: response.messageTh,
      });
      await fetchAllConsent();
    } catch (error) {
      ErrorMessageHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetAsDefault = async (itemId) => {
    setLoading(true);
    try {
      const response = await ConsentService.updateisPrimary({
        id: itemId,
        isPrimary: true,
      });
      notification.success({
        message: "บันทึกข้อมูลสำเร็จ",
        description: response.messageTh,
      });
      await fetchAllConsent();
    } catch (error) {
      ErrorMessageHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main-body">
        <Layout>
          <Header
            style={{
              height: "70px",
              background: "#ffffff",
              padding: 0,
            }}
          >
            <Flex
              justify="space-between"
              align="center"
              style={{ height: "100%" }}
            >
              <p style={{ fontSize: "20px", fontWeight: "600" }}>
                จัดการข้อมูลคำยินยอมการใช้งาน
              </p>
            </Flex>
          </Header>
          <Layout className="main-content">
            {!screens.xl ? (
              <>
                <Row>
                  <Col span={24} style={{ background: "#FFF" }}>
                    <div style={{ padding: "14px" }}>
                      {/* Header */}
                      <Flex
                        justify="space-between"
                        align="center"
                        wrap
                        gap="small"
                      >
                        <p
                          style={{
                            margin: "0",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          ทั้งหมด ({consentList.length}) รายการ
                        </p>
                        <Button
                          type="primary"
                          onClick={() =>
                            navigate(
                              {
                                pathname: `/management/manage-consent-detail`,
                              },
                              {
                                state: {
                                  catagoryId: select,
                                  latestVersion,
                                },
                              }
                            )
                          }
                          style={{
                            width: "120px",
                            height: "40px",
                            fontSize: "16px",
                            fontWeight: "500",
                            borderRadius: "10px",
                          }}
                        >
                          + สร้างฟอร์ม
                        </Button>
                      </Flex>
                    </div>

                    {consentList ? (
                      <div style={{ padding: "14px" }}>
                        <ul
                          style={{
                            listStyle: "none",
                            padding: "0",
                            margin: "0",
                          }}
                        >
                          {consentList.map((item, index) => (
                            <li
                              key={item.id}
                              onClick={() => {
                                setSelect(item.id);
                                setPrevId(item.id);
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "10px 0",
                                paddingLeft: "16px",
                                borderBottom:
                                  index !== consentList.length - 1
                                    ? "1px solid #D9D9D9"
                                    : "none",
                                cursor: "pointer",
                                background:
                                  item.id === select
                                    ? "#F5F5FF"
                                    : "transparent",
                                borderLeft:
                                  item.id === select
                                    ? "4px solid #683091"
                                    : "none",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    margin: "0",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color:
                                      item.id === select
                                        ? "#683091"
                                        : "#000000",
                                  }}
                                >
                                  {item.name}
                                </p>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "#22C55E",
                                    marginRight: "8px",
                                  }}
                                >
                                  {item.isPublished ? "เผยแพร่" : "ร่าง"}
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "#22C55E",
                                  }}
                                >
                                  {item.version}
                                </span>
                                <br />
                                {/* แสดงคำว่า 'ตั้งค่าเริ่มต้น' */}
                                {item.id === defaultId && (
                                  <span className="consent-badge">
                                    กำลังใช้งาน
                                  </span>
                                )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Dropdown
                                  trigger={["click"]}
                                  dropdownRender={(menu) => menuItem(item)}
                                >
                                  <Button
                                    style={{
                                      ontSize: "18px",
                                      color: "#000000",
                                      backgroundColor: "#FFFFFF",
                                      padding: "4px 8px",
                                      borderRadius: "4px",
                                      border: "1px solid #D9D9D9",
                                      transition:
                                        "background-color 0.3s ease, border-color 0.3s ease",
                                    }}
                                  >
                                    <AiOutlineEllipsis />
                                  </Button>
                                </Dropdown>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <Flex
                        vertical
                        justify="center"
                        align="center"
                        style={{ height: "80%" }}
                      >
                        <NoData />
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#667085",
                          }}
                        >
                          ไม่มีข้อมูล
                        </p>
                      </Flex>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={24}
                    style={{ paddingTop: "14px", background: "#FFF" }}
                  >
                    <Divider style={{ margin: "0" }} />
                    {select ? (
                      <ConsentBody
                        data={{
                          consentList: consentList,
                          select: select,
                        }}
                      />
                    ) : (
                      <Flex
                        vertical
                        justify="flex-start"
                        align="center"
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          paddingTop: "50px", // เพิ่มระยะห่างด้านบน
                        }}
                      >
                        <NoData
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                        />
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#667085",
                          }}
                        >
                          กรุณาเลือกคำยินยอมการใช้งานข้อมูลส่วนบุคคล
                        </p>
                      </Flex>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Sider
                  width={!screens.xl ? 300 : 402}
                  style={{
                    marginRight: "14px",
                    background: "#FFF",
                    height: "auto",
                  }}
                >
                  <div style={{ padding: "14px" }}>
                    {/* Header */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "14px",
                      }}
                    >
                      <p
                        style={{
                          margin: "0",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        ทั้งหมด ({consentList.length}) รายการ
                      </p>
                      {/* <Button
                        type="primary"
                        onClick={() =>
                          navigate(
                            {
                              pathname: `/management/manage-consent-detail`,
                            },
                            {
                              state: {
                                catagoryId: select,
                                latestVersion,
                              },
                            }
                          )
                        }
                        style={{
                          width: "120px",
                          height: "32px",
                          fontSize: "14px",
                          fontWeight: "500",
                          background: "#63E1E1",
                          borderRadius: "8px",
                        }}
                      > */}
                      <Button
                        onClick={() => {
                          navigate(
                            {
                              pathname: `/management/manage-consent-detail`,
                            },
                            {
                              state: {
                                catagoryId: select,
                                latestVersion,
                              },
                            }
                          );
                        }}
                        type="primary"
                        style={{
                          height: !screens.sm ? "30px" : "40px",
                          width: !screens.sm ? "100px" : "140px",
                          borderRadius: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          + สร้างฟอร์ม
                        </span>
                      </Button>
                    </div>
                  </div>

                  <Divider style={{ margin: "0" }} />
                  {consentList ? (
                    <div style={{ padding: "14px" }}>
                      <ul
                        style={{ listStyle: "none", padding: "0", margin: "0" }}
                      >
                        {consentList.map((item, index) => (
                          <li
                            key={item.id}
                            onClick={() => {
                              setSelect(item.id);
                              setPrevId(item.id);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px 0",
                              paddingLeft: "16px",
                              borderBottom:
                                index !== consentList.length - 1
                                  ? "1px solid #D9D9D9"
                                  : "none",
                              cursor: "pointer",
                              background:
                                item.id === select ? "#F5F5FF" : "transparent",
                              borderLeft:
                                item.id === select
                                  ? "4px solid #683091"
                                  : "none",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  margin: "0",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color:
                                    item.id === select ? "#683091" : "#000000",
                                }}
                              >
                                {item.name}
                              </p>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#22C55E",
                                  marginRight: "8px",
                                }}
                              >
                                {item.isPublished ? "เผยแพร่" : "ร่าง"}
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#22C55E",
                                }}
                              >
                                {item.version}
                              </span>
                              <br />
                              {/* แสดงคำว่า 'ตั้งค่าเริ่มต้น' */}
                              {item.id === defaultId && (
                                <span className="consent-badge">
                                  กำลังใช้งาน
                                </span>
                              )}
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Dropdown
                                trigger={["click"]}
                                dropdownRender={(menu) => menuItem(item)}
                              >
                                <Button
                                  style={{
                                    ontSize: "18px",
                                    color: "#000000",
                                    backgroundColor: "#FFFFFF",
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                    border: "1px solid #D9D9D9",
                                    transition:
                                      "background-color 0.3s ease, border-color 0.3s ease",
                                  }}
                                >
                                  <AiOutlineEllipsis />
                                </Button>
                              </Dropdown>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <Flex
                      vertical
                      justify="center"
                      align="center"
                      style={{ height: "100%" }}
                    >
                      <NoData />
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#667085",
                        }}
                      >
                        ไม่มีข้อมูล
                      </p>
                    </Flex>
                  )}
                </Sider>

                <Content
                  style={{
                    margin: "14px",
                    marginLeft: "0",
                    overflowY: "auto",
                  }}
                >
                  {select ? (
                    <ConsentBody
                      data={{
                        consentList: consentList,
                        select: select,
                      }}
                    />
                  ) : (
                    <Flex
                      vertical
                      justify="center"
                      align="center"
                      style={{ height: "100%" }}
                    >
                      <NoData />
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#667085",
                        }}
                      >
                        กรุณาเลือกคำยินยอมการใช้งานข้อมูลส่วนบุคคล
                      </p>
                    </Flex>
                  )}
                </Content>
              </>
            )}
          </Layout>
        </Layout>
      </div>
      <ConfirmDialog
        isModalOpen={isModalVisible}
        onConfirm={handleDelete}
        title="ยืนยันการลบข้อมูล"
        onCancel={() => setIsModalVisible(false)}
        description="คุณต้องการลบข้อมูลใช่หรือไม่"
        confirmText={"ลบ"}
        cancelText={"ยกเลิก"}
      />
      <DeleteDialog
        isModalOpen={ModalDeleteOpen}
        closable={false}
        title="ยืนยันการลบ"
        cancelText="ยกเลิก"
        confirmText="ลบ"
        onCancel={() => {
          setIsModalDeleteOpen(false);
        }}
        onConfirm={() => {
          handleDelete();
        }}
        description="คุณต้องการลบใช่หรือไม่?"
      />

      <LoadingDialog isLoading={loading} />
    </>
  );
};

const ConsentBody = (data) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [consentList, setConsentList] = useState([]);
  const [select, setSelect] = useState(null);

  useEffect(() => {
    setConsentList(data.data.consentList);
    setSelect(data.data.select);
  }, [data]);
  return (
    <>
      {!consentList || consentList.length === 0 ? (
        <Flex
          vertical
          justify="center"
          align="center"
          style={{ height: "100%" }}
        >
          <NoData />
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#667085",
            }}
          >
            ไม่มีข้อมูล
          </p>
        </Flex>
      ) : (
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          {consentList
            .filter((item) => item.id === select)
            .map((item) => (
              <div
                key={item.id}
                style={{
                  padding: "14px",
                  background: "#FFF",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Flex justify="space-between" align="center" wrap gap="small">
                  <span style={{ fontSize: "16px", fontWeight: "600" }}>
                    อัปเดตล่าสุด :{" "}
                    <span style={{ fontSize: "16px", fontWeight: "300" }}>
                      {dayjs(item.updatetedDate ?? item.createdDate).format(
                        "DD/MM/BBBB HH:mm:ss"
                      )}
                    </span>
                  </span>

                  {item.isEditable ? (
                    <EditButton
                      text={true}
                      onClick={() =>
                        navigate(
                          {
                            pathname: `/management/manage-consent-detail`,
                          },
                          {
                            state: {
                              catagoryId: select,
                              selectedData: item,
                            },
                          }
                        )
                      }
                    />
                  ) : (
                    "*ไม่สามารถแก้ไข เนื่องจากมีผู้ให้ความยินยอมใช้งานแล้ว"
                  )}
                </Flex>

                <Divider />

                <Flex justify="space-between" align="center" wrap gap="small">
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#1890FF",
                      margin: 0,
                    }}
                  >
                    <InfoCircleOutlined />{" "}
                    ข้อมูลชุดนี้จำเป็นต้องได้รับการยินยอม/รับทราบเท่านั้น
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#65707A",
                        textAlign: "right",
                        margin: "0 8px 0 0", // เพิ่ม margin ด้านขวา
                      }}
                    >
                      เวอร์ชั่น : {item.version}
                    </p>
                    {/* <button
                      style={{
                        width: "50px", // ปรับขนาด button ให้ใหญ่ขึ้น
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px solid #CBD5E1", // ขอบสีเทา
                        borderRadius: "12px", // ขอบมน
                        backgroundColor: "white",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // เพิ่มเงาให้เด่นชัดขึ้น
                        cursor: "pointer",
                      }}
                    >
                      <ClockIcon
                        style={{
                          width: "35px", // ปรับไอคอนให้ใหญ่ขึ้น
                          height: "35px",
                          color: "#65707A", // สีของไอคอน
                        }}
                      />
                    </button> */}
                  </div>
                </Flex>
                <br />
                <Card
                  bordered={false}
                  style={{
                    width: "100%",
                    minHeight: "400px",
                    textAlign: "center",
                    backgroundColor: "#F2EEF6",
                    overflowX: "hidden", // ป้องกันการทะลุ
                  }}
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            borderRadius: "50%",
                            backgroundColor: "#F64E60",
                          }}
                        ></div>
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            borderRadius: "50%",
                            backgroundColor: "#FCE697",
                          }}
                        ></div>
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            borderRadius: "50%",
                            backgroundColor: "#0BB783",
                          }}
                        ></div>
                      </div>
                    </div>
                  }
                  styles={{
                    header: {
                      backgroundColor: "#fcfcfc",
                      color: "#65707A",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 16px",
                    },
                    body: {
                      backgroundColor: "#F2EEF6",
                      color: "#65707A",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      padding: "16px",
                      overflowX: "hidden",
                    },
                  }}
                >
                  <div
                    style={{
                      overflowY: "auto",
                      padding: "0 16px",
                    }}
                  >
                    <Card
                      bordered={true}
                      style={{
                        width: "100%",
                        minWidth: "40vw",
                        backgroundColor: "#FFFFFF",
                        textAlign: "left",
                        padding: "16px",
                        borderTop: "8px solid #000000",
                      }}
                    >
                      <p
                        style={{
                          fontSize: !screens.lg ? "14px" : "16px",
                          fontWeight: "600",
                          color: "#212121",
                          margin: 0,
                        }}
                      >
                        {item.name}
                      </p>
                      <p
                        style={{
                          fontSize: !screens.lg ? "14px" : "16px",
                          fontWeight: "300",
                          color: "#212121",
                          margin: 0,
                        }}
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></p>
                    </Card>
                  </div>
                </Card>
              </div>
            ))}
        </Space>
      )}
    </>
  );
};

export default MangeConsent;
