import "./NotAllow.css";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Layout, Row } from "antd";
import { Footer } from "../../components";
import { Content } from "antd/es/layout/layout";


const NotAllow = () => {
  const [footerHeight, setFooterHeight] = useState(0);
    useEffect(() => {

    },[]);
  return (
    <div>
      <Layout className="layout">
        <Content className="content">
          <Layout>
            <Content>
            <div className="not-found-body" style={{minHeight:`calc(100vh - ${footerHeight}px)`}}>
              <div className="not-found-container" style={{minHeight:`calc(100vh - ${footerHeight}px)`}}>
                <h1 className="extra-large">401</h1>
                <h1>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</h1>
                <p>หน้าที่คุณกำลังเข้าถึงนี้ คุณไม่ได้รับอนุญาติให้เข้าถึง</p>
                <Row style={{width:"100%"}}>
                  <Col span={24}  style={{textAlign:"center"}}>
                    <Link to="/" style={{display:"inline-block"}}>
                      <Button type="default"  className="main-button">หน้าหลัก</Button>
                    </Link>
                  </Col>
                  
                </Row>
                </div>
             </div>
            </Content>
          </Layout>
        </Content>

        <Footer />
      </Layout>
    </div>
  );
};

export default NotAllow;
