import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
  >
    <rect width="78" height="78" rx="20" fill="#E7E7E7" />
    <path
      d="M42.0317 39L57.4126 20.666C57.6704 20.3613 57.4536 19.8984 57.0552 19.8984H52.3794C52.104 19.8984 51.8403 20.0215 51.6587 20.2324L38.9731 35.3555L26.2876 20.2324C26.1118 20.0215 25.8481 19.8984 25.5669 19.8984H20.8911C20.4927 19.8984 20.2759 20.3613 20.5337 20.666L35.9145 39L20.5337 57.334C20.4759 57.4019 20.4389 57.485 20.4269 57.5733C20.415 57.6616 20.4286 57.7516 20.4663 57.8324C20.5039 57.9132 20.5639 57.9815 20.6392 58.0292C20.7145 58.077 20.8019 58.1021 20.8911 58.1016H25.5669C25.8423 58.1016 26.1059 57.9785 26.2876 57.7676L38.9731 42.6445L51.6587 57.7676C51.8345 57.9785 52.0981 58.1016 52.3794 58.1016H57.0552C57.4536 58.1016 57.6704 57.6387 57.4126 57.334L42.0317 39Z"
      fill="#475467"
    />
  </svg>
);

const Icon_not_allow = (props) => <Icon component={icon} {...props} />;

export default Icon_not_allow;
