import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import th from "antd/es/date-picker/locale/th_TH";
import locale from "antd/locale/th_TH";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";

import "dayjs/locale/th";

dayjs.locale(locale);
dayjs.extend(buddhistEra);

const buddhistLocale = {
  ...th,
  lang: {
    ...th.lang,
    fieldDateFormat: "BBBB-MM-DD",
    fieldDateTimeFormat: "BBBB-MM-DD HH:mm:ss",
    yearFormat: "BBBB",
    cellYearFormat: "BBBB",
  },
};

const DatePickerLocale = ({ onChange, value, style }) => {
  return (
    // <ConfigProvider
    //   theme={{
    //     token: {
    //       colorPrimary: "#00b6a9",
    //     },
    //   }}
    // >
      <DatePicker
        locale={buddhistLocale}
        format={(value) => dayjs(value).format("DD/MM/BBBB")}
        placeholder="วัน/เดือน/ปี"
        inputReadOnly={true}
        size="large"
        onChange={onChange}
        value={value}
        style={style}
      />
    // </ConfigProvider>
  );
};

export default DatePickerLocale;
