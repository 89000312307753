import React, { useState, useEffect } from "react";
import {} from "react-router-dom";
import "./RequestApiManagement.css";
import { Col, Row, Table, Select, Pagination, Divider, Input, Tag } from "antd";
import { LoadingDialog, EditButton } from "../../../../../components";
import { AiOutlineSearch } from "react-icons/ai";
import RequestApiManagementDetail from "./RequestApiManagementDetail";
import { RequisitionService } from "../../../../../services/RequisitionService";

const { Option } = Select;

const RequestApiManagement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [select, setSelect] = useState();

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (keywords !== null) {
      const delayDebounce = setTimeout(() => {
        fetchData();
      }, 1000);

      return () => clearTimeout(delayDebounce);
    }
  }, [keywords]);

  const getParams = (params) => ({
    Keywords: keywords,
    "Pagination.PageSize": params.pagination?.pageSize,
    "Pagination.Page": params.pagination?.current,
  });

  const fetchData = async () => {
    try {
      setLoading(true);

      let params = getParams(tableParams);
      let response = await RequisitionService.group(params);

      setData(response.items);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.pagination.totalCount,
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setTableParams({
      pagination: {
        current: page,
        pageSize,
        total: tableParams.pagination?.total,
      },
    });

    if (pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handlePageSizeChange = (current, pageSize) => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize,
        total: tableParams.pagination?.total,
      },
    });
  };

  const columns = [
    // {
    //   title: <span className="api-columns-title">#</span>,
    //   dataIndex: "index",
    //   key: "index",
    //   width: "45px",
    //   // render: (text, record, index) => {
    //   //   // const current = tableParams.pagination?.current || 1;
    //   //   // const pageSize = tableParams.pagination?.pageSize || 10;
    //   //   // return (current - 1) * pageSize + index + 1;
    //   // },
    //   render: (text, record, index) => <span>{index + 1}</span>,
    // },
    Table.EXPAND_COLUMN,
    {
      title: <span className="api-columns-title">ชื่อ - นามสกุล</span>,
      dataIndex: "name",
      key: "name",
      render: (text, record, index) =>
        `${record.userFirstName} ${record.userLastName}`,
    },
    {
      title: <span className="api-columns-title">ชื่อบริษัท</span>,
      dataIndex: "companyName",
      key: "companyName",
      // width: "20%",
    },
    {
      title: <span className="api-columns-title">สถานะ</span>,
      dataIndex: "requisitionStatusId",
      key: "requisitionStatusId",
      render: (text, record, index) => {
        let textRender;
        switch (record.requisitionStatusId) {
          case "1":
            textRender = (
              <Tag color="processing" style={{ borderRadius: "12px" }}>
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  {record.requisitionStatusName}
                </span>
              </Tag>
            );
            break;
          case "2":
            textRender = (
              <Tag color="success" style={{ borderRadius: "12px" }}>
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  {record.requisitionStatusName}
                </span>
              </Tag>
            );
            break;
          case "3":
            textRender = (
              <Tag color="default" style={{ borderRadius: "12px" }}>
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  {record.requisitionStatusName}
                </span>
              </Tag>
            );
            break;
          default:
            break;
        }

        return textRender;
      },
    },
    {
      title: <span className="api-columns-title">จำนวน API ที่ขอใช้</span>,
      dataIndex: "dataSourceCount",
      key: "dataSourceCount",
      // width: "30%",
    },
    {
      title: <span className="api-columns-title">วันขอใช้ API ล่าสุด</span>,
      dataIndex: "formattedCreatedDate",
      key: "formattedCreatedDate",
      // width: "30%",
    },
    {
      title: <span className="api-columns-title">วันที่พิจารณา</span>,
      dataIndex: "formattedApprovalDate",
      key: "formattedApprovalDate",
      render: (text, record, index) => (
        <span>{record.formattedApprovalDate || "-"}</span>
      ),
    },
  ];

  const expandedColumns = [
    {
      title: <span className="role-columns-title">คำสั่ง</span>,
      key: "action",
      width: "50px",
      render: (_, record) => (
        <EditButton
          onClick={() => {
            setSelect(record.id);
          }}
        />
      ),
    },
    {
      title: <span className="api-columns-title">#</span>,
      dataIndex: "index",
      key: "index",
      width: "45px",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <span className="api-columns-title">จำนวน API ที่ขอใช้</span>,
      dataIndex: "dataSourceCount",
      key: "dataSourceCount",
      width: "100px",
    },
    {
      title: <span className="api-columns-title">สถานะ</span>,
      dataIndex: "requisitionStatusId",
      key: "requisitionStatusId",
      render: (text, record, index) => {
        let textRender;
        switch (record.requisitionStatusId) {
          case "1":
            textRender = (
              <Tag color="processing" style={{ borderRadius: "12px" }}>
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  {record.requisitionStatusName}
                </span>
              </Tag>
            );
            break;
          case "2":
            textRender = (
              <Tag color="success" style={{ borderRadius: "12px" }}>
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  {record.requisitionStatusName}
                </span>
              </Tag>
            );
            break;
          case "3":
            textRender = (
              <Tag color="default" style={{ borderRadius: "12px" }}>
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  {record.requisitionStatusName}
                </span>
              </Tag>
            );
            break;
          default:
            break;
        }

        return textRender;
      },
    },
    {
      title: <span className="api-columns-title">วันขอใช้ API ล่าสุด</span>,
      dataIndex: "formattedCreatedDate",
      key: "formattedCreatedDate",
      // width: "30%",
    },
    {
      title: <span className="api-columns-title">ผู้พิจารณา</span>,
      dataIndex: "approvalByName",
      key: "approvalByName",
      // width: "30%",
    },
    {
      title: <span className="api-columns-title">วันที่พิจารณา</span>,
      dataIndex: "formattedApprovalDate",
      key: "formattedApprovalDate",
      render: (text, record, index) => (
        <span>{record.formattedApprovalDate || "-"}</span>
      ),
    },
  ];

  const expandedRowRender = (record) => (
    <div
      style={{
        background: "#fff",
        borderRadius: "8px",
        border: "1px solid #d9d9d9",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #d9d9d9",
          background: "#F8F8F8",
          padding: "10px 20px 10px 20px",
        }}
      >
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#1C2538" }}>
          รายการขอใช้ API
        </span>
      </div>
      <div style={{ padding: "22px 20px 22px 20px" }}>
        <Table
          columns={expandedColumns}
          rowKey={(record) => record.id}
          dataSource={record.requisitions}
          pagination={false}
        />
      </div>
    </div>
  );

  return (
    <>
      {!select && (
        <div className="api-management-body">
          <div className="api-management-container main-body">
            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              จัดการคำร้องขอใช้ API
            </p>
            <Divider
              style={{ borderColor: "#d9d9d9", margin: "10px 0 10px 0" }}
            />
            <Input
              placeholder="ค้นหา ชื่อ นามสกุล ชื่อบริษัท"
              allowClear
              suffix={
                <AiOutlineSearch
                  style={{
                    color: "rgba(0,0,0,.45)",
                    cursor: "pointer",
                  }}
                />
              }
              onChange={(e) => setKeywords(e.target.value)}
              size="large"
              style={{
                margin: "14px 0 14px 0",
                borderRadius: "8px",
                maxWidth: "285px",
                width: "50%",
              }}
            />

            <Table
              style={{
                margin: "8px 0",
              }}
              columns={columns}
              rowKey={(record) => record.userId}
              dataSource={data}
              expandable={{
                expandedRowRender,
              }}
              pagination={false}
              onChange={handlePageChange}
              loading={loading}
              scroll={{
                x: 1000,
              }}
            />

            {data.length ? (
              <Row
                justify="space-between"
                align="middle"
                style={{ marginTop: 16 }}
                gutter={[16, 16]}
              >
                <Col>
                  <span
                    style={{
                      color: "#2E323A",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    ทั้งหมด {tableParams.pagination?.total} รายการ
                  </span>
                </Col>
                <Col>
                  <Pagination
                    style={{ pointerEvents: loading ? "none" : "auto" }}
                    current={tableParams.pagination?.current}
                    pageSize={tableParams.pagination?.pageSize}
                    total={tableParams.pagination?.total}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </Col>
                <Col>
                  <Select
                    value={tableParams.pagination?.pageSize}
                    onChange={(pageSize) =>
                      handlePageSizeChange(
                        tableParams.pagination?.current,
                        pageSize
                      )
                    }
                    style={{
                      width: "108px",
                      height: "40px",
                      borderRadius: "10px",
                    }}
                  >
                    <Option value={10}>
                      <span
                        style={{
                          color: "#2E323A",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        10 / หน้า
                      </span>
                    </Option>
                    <Option value={25}>
                      <span
                        style={{
                          color: "#2E323A",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        25 / หน้า
                      </span>
                    </Option>
                    <Option value={50}>
                      <span
                        style={{
                          color: "#2E323A",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        50 / หน้า
                      </span>
                    </Option>
                    <Option value={100}>
                      <span
                        style={{
                          color: "#2E323A",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        100 / หน้า
                      </span>
                    </Option>
                  </Select>
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
      )}

      {select && (
        <RequestApiManagementDetail select={select} setSelect={setSelect} />
      )}

      <LoadingDialog isLoading={loading} />
    </>
  );
};

export default RequestApiManagement;
