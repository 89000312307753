import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="45" height="46" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="44" height="44" rx="12.3571" fill="white"/>
<rect x="0.5" y="0.5" width="44" height="44" rx="12.3571" stroke="#00B6A9"/>
<path d="M30.1431 19.2592C30.1431 19.5869 30.028 19.8634 29.7979 20.0887L23.5949 26.2229C23.3125 26.5096 23.0092 26.6427 22.6431 26.6427C22.277 26.6427 21.9632 26.5096 21.6912 26.2331L15.4883 20.0887C15.2581 19.8634 15.1431 19.5869 15.1431 19.2592C15.1431 18.6038 15.6765 18.0713 16.346 18.0713C16.6807 18.0713 16.9841 18.2044 17.2351 18.44L22.6535 23.8265L28.0615 18.44C28.3021 18.2044 28.6054 18.0713 28.9401 18.0713C29.6096 18.0713 30.1431 18.6038 30.1431 19.2592Z" fill="#00B6A9"/>
</svg>









);

const Icon_Down_Admin = (props) => <Icon component={icon} {...props} />;

export default Icon_Down_Admin;

