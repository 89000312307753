import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConsentService } from '../../../services/ConsentService';
import './login.css'; // For custom styling
import { ConfirmDialog, LoadingDialog } from '../../../components';
import logo from './logo 1.png';
import {
    Row,
    Col,
    Button,
    Divider,
    Card,
    notification,
} from "antd";
import { handleLogout } from '../../../keycloak/KeycloakAuth';
import ErrorMessageHandler from '../../../helpers/ErrorMessageHandler';
const ConfirmConsent = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [consentData, setConsentData] = useState(null);
    const [showAcceptButton, setShowAcceptButton] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const consentBoxRef = useRef(null);
    useEffect(() => {
        // เริ่มโหลดข้อมูลเมื่อ component ถูก mount
        const fetchConsentData = async () => {
            setIsLoading(true);
            try {
                const data = await ConsentService.getCurrent();
                setConsentData(data);
            } catch (error) {
                console.error("Failed to fetch consent data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchConsentData();
    }, []);

    const formatDate = (date) => {
        if (!date) return 'ไม่ระบุวันที่';
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // ใช้เวลาแบบ 24 ชั่วโมง
        };
        return new Intl.DateTimeFormat('th-TH', options).format(new Date(date));
    };

    const consent = async () => {
        setIsLoading(true);
        try {
            await ConsentService.accept(consentData.id);
            if(localStorage.getItem('pageState')){
                var pageState = localStorage.getItem('pageState');
                localStorage.removeItem('pageState');
                navigate(pageState);
                return;
            }
            navigate('/');
        } catch (error) {
            ErrorMessageHandler(error);
        } finally {
            setIsLoading(false);
        }
    };
    const disagree = async () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false); // ปิด modal เมื่อกดปุ่ม
        handleLogout(); // ออกจากระบบ
    };

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = consentBoxRef.current;

        if (scrollHeight <= clientHeight || scrollTop + clientHeight >= scrollHeight - 5) {
            // กรณีที่เนื้อหาสั้นหรือเลื่อนได้ถึงปลายแล้ว
            setShowAcceptButton(true);
        } else {
            setShowAcceptButton(false);
        }
    };

    useEffect(() => {
        // ตรวจสอบเมื่อเนื้อหามีการโหลดเพื่อให้ปุ่มแสดงได้ทันทีถ้าเนื้อหาสั้น
        if (consentBoxRef.current) {
            const { scrollHeight, clientHeight } = consentBoxRef.current;
            setShowAcceptButton(scrollHeight <= clientHeight);
        }
    }, [consentData]); // เมื่อข้อมูลเปลี่ยน ให้ตรวจสอบใหม่

    return (
        <div className="login-container1">
            <Row>
                <Col span={18} md={18} xs={24}>
                    <Row>
                        <Col span={24} md={24} xs={0}>
                            <div className="login-bg"></div>
                            <div className="login-left">
                                <div className="logo">
                                    <img
                                        src={logo}
                                        alt="TCEB Logo"
                                        className="logo-image"
                                        onClick={() => navigate('/')}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                            <div className="copyright">
                                © 2567 สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)
                            </div>
                        </Col>
                        <Col span={24} xs={24} md={0}>
                            <div className="login-bg" style={{ position: 'fixed' }}></div>
                            <div className="logo" style={{ textAlign: "center", marginTop: "15px" }}>
                                <img
                                    src={logo}
                                    alt="TCEB Logo"
                                    className="logo-image"
                                    onClick={() => navigate('/')}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={6} md={6} xs={24} className="login-card-container">
                    <div className="center-v">
                        <div className="login-card2">
                            <Card
                                ref={consentBoxRef}
                                bordered={true}
                                style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    backgroundColor: "#FFFFFF",
                                    textAlign: "left",
                                    padding: "16px",
                                    borderTop: "8px solid #000000",
                                    maxHeight: "400px", // กำหนดความสูงสูงสุดที่คุณต้องการให้กับ Card
                                    overflowY: 'auto',  // เปิดใช้งานการเลื่อนในแนวตั้ง
                                }}
                                onScroll={handleScroll}
                            >
                                {!consentData ? (
                                    <p style={{ fontSize: "16px", fontWeight: "300", color: "#212121", margin: 0 }}>
                                        กำลังโหลดข้อมูล...
                                    </p>
                                ) : (
                                    <>
                                        <p
                                            style={{ fontSize: "16px", fontWeight: "300", color: "#212121", margin: 0 }}
                                            dangerouslySetInnerHTML={{ __html: consentData.content || "ไม่มีเนื้อหา" }}
                                        ></p>
                                    </>
                                )}
                            </Card>
                            <br />
                            <p>
                                อัปเดทล่าสุดวันที่ : {consentData && consentData.updatedDate ? consentData.updatedDateText : 'ไม่ระบุวันที่'}
                            </p>
                            <Divider />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Button type="default" style={{ flex: 1, marginRight: '5px' }} onClick={disagree}>
                                    ไม่ยอมรับคำยินยอม
                                </Button>
                                <Button
                                    onClick={consent}
                                    type="default"
                                    style={{
                                        flex: 1,
                                        marginLeft: '5px',
                                        backgroundColor: showAcceptButton ? '#63E1E1' : '', // ใช้สี 63E1E1 เมื่อปุ่มใช้งานได้
                                        borderColor: showAcceptButton ? '#63E1E1' : '', // ใช้สีขอบ 63E1E1 เมื่อปุ่มใช้งานได้
                                        color: showAcceptButton ? '#000000' : '', // เปลี่ยนสีข้อความเป็นสีขาวเมื่อปุ่มใช้งานได้
                                    }}
                                    disabled={!showAcceptButton} // ปรับให้ปุ่มถูกปิดใช้งานตามค่า showAcceptButton
                                >
                                    ยอมรับคำยินยอม
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <LoadingDialog isLoading={isLoading} />
            <ConfirmDialog 
                cancelText={"ยกเลิก"}
                confirmText={"ยืนยัน"}
                closable={false}
                type={"warning"}
                title={"ความยินยอมนี้มีความจำเป็นต่อระบบ ถ้าคุณไม่ให้ความยินยอม จะไม่สามารถใช้งานในระบบได้"}
                isModalOpen={isModalVisible}
                onConfirm={handleOk}
                onCancel={()=>setIsModalVisible(false)}
            />
        </div>
    );
};

export default ConfirmConsent;
