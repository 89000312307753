import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const OAuthService = {

  codeAuthorization: async function (code, isLocalHost, cancel = false) {
    const response = await api.request({
      url: `/oauth/code-authorization`,
      method: `POST`,
      data: {
        code: code,
        isLocalHost: isLocalHost,
      },
      signal: cancel ? cancelApiObject[this.codeAuthorization.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  refreshToken: async function (token, cancel = false) {
    if(!token){ return null; }
    try {
      const response = await api.request({
        url: `/oauth/refresh-token`,
        method: `POST`,
        data: {
          refreshToken: token,
        },
        signal: cancel ? cancelApiObject[this.refreshToken.name].handleRequestCancellation().signal : undefined,
      });

      return response.data;
    }catch(error){
      //do nothing
    }
    return null;
  },

  logout: async function (token, cancel = false) {
    try{
      await api.request({
        url: `/oauth/logout`,
        method: `POST`,
        data: {
          refreshToken: token,
        },
        signal: cancel ? cancelApiObject[this.logout.name].handleRequestCancellation().signal : undefined,
      })
    }catch(error){
      //do nothing
    }
  }
  
};

// defining the cancel API object for OAuthService
const cancelApiObject = defineCancelApiObject(OAuthService);
