import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Space,
  DatePicker,
  Flex,
  Button,
  Col,
  Row,
  Tag,
  Select,
  Radio,
  List,
  Divider,
  Tooltip,
} from "antd";
import "./InsightReport.css";
import { AiOutlineAppstore, AiOutlineBars } from "react-icons/ai";
import IconRightArrowViewDashboard from "../../../assets/icon/icon_right_arrow_view_dashboard.js";
import { InsightReportService } from "../../../services/InsightReportService";
import { InsightReportCategoryService } from "../../../services/InsightReportCategoryService";
import { InsightReportTagService } from "../../../services/InsightReportTagService";
import NoImg from "../../../assets/img/no_img.png";
import NoData from "../../../assets/img/no_data.js";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import { LoadingDialog, RangePickerLocale } from "../../../components";

const { RangePicker } = DatePicker;

const InsightReport = () => {
  const navigate = useNavigate();
  const [dataTag, setDataTag] = useState([]);
  const [dataInsightType, setDataInsightType] = useState([]);
  const [dataInsight, setDataInsight] = useState([]);
  const [viewMode, setViewMode] = useState("card");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInsightType, setSelectedInsightType] = useState(["all"]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [cookies, setCookie] = useCookies(["insight-report-condition"]);
  const config = cookies["insight-report-condition"];
  const view = cookies["insight-report-view"];
  var localDataTag = [];
  var localDataInsightType = [];
  var loadfirst = false;
  var existsSearch = null;
  var existParams = null;

  const handleClick = (id, urlSlug) => {
    navigate(`/insight-report/${encodeURIComponent(urlSlug)}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (existsSearch === window.location.search) {
      return;
    }
    const urlSearchParam = new URLSearchParams(window.location.search);
    var tag = urlSearchParam.get("tag");
    var cate = urlSearchParam.get("cate");
    if (selectedTags.length === 0 && !(tag === null || tag === "")) {
      return;
    }
    if (selectedInsightType.length === 0 && !(cate === null || cate === "")) {
      return;
    }

    existsSearch = window.location.search;
    fetchDataInsight();
  }, [window.location.search]);

  const fetchDataInsight = async () => {
    setIsLoading(true);
    try {      
      const urlSearchParam = new URLSearchParams(window.location.search);
      const search = urlSearchParam.get("search");
      const params = {
        InsightReportCategoryIds: selectedInsightType,
        TagIds: selectedTags,
        CreatedDateFrom: selectedDateRange[0],
        CreatedDateTo: selectedDateRange[1],
        IsActivated: true,
      };
      if (search) {
        params.Keywords = search;
      }
      if (existParams === JSON.stringify(params)) {
        setIsLoading(false);
        return;
      }
      existParams = JSON.stringify(params);
      setCookiesValue("insight-report-condition", JSON.stringify(params));
      const response = await InsightReportService.searchPublic(params);
      setDataInsight(response.items);
      setIsLoading(false);
    } catch (error) {
      console.error();
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataTags = async () => {
    try {
      const response = await InsightReportTagService.get();
      setDataTag(response);
      localDataTag = response;
    } catch (error) {
      console.error();
    } finally {
    }
  };

  const fetchDataInsightType = async () => {
    try {
      const response = await InsightReportCategoryService.search();
      const options = response.items.map((item) => ({
        value: item.id,
        label: item.name,
        color: "default",
      }));
      options.unshift({
        value: "all",
        label: "ประเภททั้งหมด",
        color: "default",
      });

      setDataInsightType(options);
      localDataInsightType = options;
    } catch (error) {
      //setLoading(false);
      console.error();
    }
  };

  useEffect(() => {
    if (loadfirst) {
      return;
    }
    if (view) {
      setViewMode(view ?? "card");
    } else {
      setViewMode("card");
    }
    fetchDataTags().then(() => {
      fetchDataInsightType().then(() => {
        const param = new URLSearchParams(window.location.search);
        var tag = param.get("tag");
        var cate = param.get("cate");
        if (config) {
          if (
            config.InsightReportCategoryIds &&
            config.InsightReportCategoryIds.length > 0
          ) {
            const cateNames = localDataInsightType
              .filter(
                (type) =>
                  config.InsightReportCategoryIds.includes(type.value) &&
                  type.value !== "all"
              )
              .map((type) => type.label)
              .join("|");
            const url = new URL(window.location.href);
            if (cateNames === "") {
              url.searchParams.delete("cate");
            } else {
              url.searchParams.set("cate", cateNames);
              cate = cateNames;
            }

            window.history.pushState({}, "", url);
          }
          if (config.TagIds && config.TagIds.length > 0) {
            const tags = localDataTag
              .filter((tag) => config.TagIds.includes(tag.tagId))
              .map((tag) => tag.tagName)
              .join("|");
            const url = new URL(window.location.href);
            if (tags !== "") {
              url.searchParams.set("tag", tags);
              window.history.pushState({}, "", url);
            }
          }
        }

        if (window.location.search) {
          var cateIds = [];
          var tagIds = [];

          if (cate) {
            const cates = cate.includes("|") ? cate.split("|") : [cate];
            localDataInsightType
              .filter((type) => cates.includes(type.label))
              .map((type) => {
                cateIds.push(type.value);
                return type;
              });
            if (cateIds.length === 0) {
              cateIds.push("all");
            }
          } else {
            cateIds.push("all");
          }
          if (tag) {
            const tags = tag.includes("|") ? tag.split("|") : [tag];
            localDataTag
              .filter((tag) => tags.includes(tag.tagName))
              .map((tag) => {
                tagIds.push(tag.tagId);
                return tag;
              });
          }
          setSelectedInsightType(cateIds);
          setSelectedTags(tagIds);
        } else {
          //fetchDataInsight();
        }
      });
    });
  }, []);

  useEffect(() => {
    const urlSearchParam = new URLSearchParams(window.location.search);
    var tag = urlSearchParam.get("tag");
    var cate = urlSearchParam.get("cate");
    if (selectedTags.length === 0 && !(tag === null || tag === "")) {
      return;
    }
    if (selectedInsightType.length === 0 && !(cate === null || cate === "")) {
      return;
    }
    fetchDataInsight();
  }, [selectedInsightType, selectedTags, selectedDateRange]);

  const handleChangeType = async (selected) => {
    if (
      (selected.includes("all") && !selectedInsightType.includes("all")) ||
      selected.length === 0
    ) {
      setSelectedInsightType(["all"]);
      const url = new URL(window.location.href);
      url.searchParams.delete("cate");
      window.history.pushState({}, "", url);
    } else {
      const filteredValues = selected.filter((val) => val !== "all");
      setSelectedInsightType(filteredValues);
      const url = new URL(window.location.href);
      const cate = dataInsightType
        .filter((type) => filteredValues.includes(type.value))
        .map((type) => type.label)
        .join("|");
      url.searchParams.set("cate", cate);
      window.history.pushState({}, "", url);
    }
  };

  const tagRender = (props) => {
    const { label, color, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };

  const isSelectedTag = (key) => selectedTags.includes(key);
  const toggleButtonTag = (key, tag) => {
    if (selectedTags.includes(key)) {
      //setSelectedTags([]);
      selectedTags.splice(selectedTags.indexOf(key), 1);
      const url = new URL(window.location.href);
      if (selectedTags.length === 0) {
        url.searchParams.delete("tag");
        window.history.pushState({}, "", url);

        setSelectedTags([]);
      } else {
        var tags = dataTag
          .filter((tag) => selectedTags.includes(tag.tagId))
          .map((tag) => tag.tagName)
          .join("|");
        url.searchParams.set("tag", tags);
        window.history.pushState({}, "", url);

        setSelectedTags([...selectedTags]);
      }
      fetchDataInsight();
    } else {
      selectedTags.push(key);

      const url = new URL(window.location.href);
      var tags = dataTag
        .filter((tag) => selectedTags.includes(tag.tagId))
        .map((tag) => tag.tagName)
        .join("|");
      url.searchParams.set("tag", tags);
      window.history.pushState({}, "", url);

      setSelectedTags([...selectedTags]);
      fetchDataInsight();
    }
  };

  const toggleButtonTag2 = (key, tag) => {
    if (selectedTags.length === 1 && selectedTags[0] === key) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    setSelectedTags([key]);
    const url = new URL(window.location.href);
    if (key) {
      url.searchParams.set("tag", tag);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      url.searchParams.delete("tag");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    window.history.pushState({}, "", url);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleChangeDateRange = (dates) => {
    
    let formattedDates = [];
    if (dates != null) {
      formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));
    }
    setSelectedDateRange(formattedDates);
  };

  const setCookiesValue = (key, value) => {
    if (localStorage.getItem("cookie") === "true") {
      setCookie(key, value, { path: "/" });
    }
  };

  const tagButtons = (item) => {
    const maxTag = 2;
    const tagCount = item?.insightReportTags.length;
    var renderTiems = item?.insightReportTags
      .sort((a, b) => {
        if (a.tagName === "เฉพาะสมาชิก" && b.tagName !== "เฉพาะสมาชิก") {
          return -1;
        }
        return a.sequence - b.sequence;
      })
      .slice(0, maxTag)
      .map((tag) => (
        <Tag
          key={item.id + tag.id}
          bordered={false}
          color={tag.tagColor}
          onClick={() => toggleButtonTag2(tag.tagId, tag.tagName)}
          className="tag"
        >
          {tag.tagName}
        </Tag>
      ));
    if (tagCount > maxTag) {
      renderTiems.push(
        <Tooltip
          placement="bottom"
          title={item?.insightReportTags
            .slice(maxTag)
            .map((tag) => tag.tagName)
            .join(", ")}
        >
          <Tag key={item.id + "more"} bordered={false} className="tag tag-more">
            {`+${tagCount - maxTag}`}
          </Tag>
        </Tooltip>
      );
    }
    return renderTiems;
  };

  return (
    <>
      <div className="insight-body">
        <div className="insight-container">
          <h2>รายงานวิเคราะห์ข้อมูลเชิงลึก (Insight Report)</h2>
          <div className="insight-tag">
            <Row>
              <Col xs={4} sm={3} md={2} lg={2} xl={2}>
                <h3>Tag : </h3>
              </Col>
              <Col
                sm={20}
                md={22}
                lg={22}
                xl={20}
                // flex={"20"}
                className="group-btn-tag"
              >
                <Flex wrap>
                  {dataTag
                    .sort((a, b) => {
                      if (
                        a.tagName === "เฉพาะสมาชิก" &&
                        b.tagName !== "เฉพาะสมาชิก"
                      ) {
                        return -1;
                      }
                      return a.sequence - b.sequence;
                    })
                    .map((tag) => (
                      <Button
                        key={tag.tagId}
                        onClick={() => toggleButtonTag(tag.tagId, tag.tagName)}
                        shape="round"
                        className={
                          isSelectedTag(tag.tagId)
                            ? "btn-tag-selected"
                            : "btn-tag"
                        }
                      >
                        {tag.tagName}
                      </Button>
                    ))}
                </Flex>
              </Col>
            </Row>
          </div>

          {/* <hr /> */}
          <Divider className="dashboard-divider" />

          <div className="insight-type">
            <Row className="insight-condition">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="group-type"
              >
                <h3>กรองโดย : </h3>
                <Select
                  size="large"
                  mode="multiple"
                  className="select-insight-type"
                  tagRender={tagRender}
                  defaultValue={["all"]}
                  onChange={handleChangeType}
                  value={selectedInsightType}
                  options={dataInsightType}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={10}
                xxl={10}
                className="group-type"
              >
                <RangePickerLocale
                  value={
                    selectedDateRange.length > 0
                      ? [
                          dayjs(selectedDateRange[0]),
                          dayjs(selectedDateRange[1]),
                        ]
                      : null
                  }
                  onChange={handleChangeDateRange}
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    fontSize: "14px",
                    marginLeft: "-15px",
                    marginRight: "-15px",
                  }}
                />
              </Col>
            </Row>
          </div>

          {/* <hr /> */}
          <Divider className="dashboard-divider" />

          {dataInsightType
            ?.filter(
              (item) =>
                item.value !== "all" &&
                dataInsight.some(
                  (data) => data.insightReportCategoryId === item.value
                )
            )
            .map((itemType, index) => (
              <div key={index}>
                <div className="insight-content-head">
                  <Row justify={"space-between"} align={"middle"}>
                    <Col xs={14} sm={9} md={7} lg={14} xl={8}>
                      <h3 style={{ fontSize: 17 }}>{itemType.label}</h3>
                    </Col>
                    <Col
                      xs={10}
                      sm={15}
                      md={16}
                      lg={10}
                      xl={8}
                      className="btn-viewpage"
                      style={{ textAlign: "right" }}
                    >
                      {index === 0 && (
                        <Radio.Group
                          defaultValue={viewMode}
                          buttonStyle="solid"
                          size="large"
                          optionType="button"
                          onChange={(e) => {
                            setCookiesValue(
                              "insight-report-view",
                              e.target.value
                            );
                            setViewMode(e.target.value);
                          }}
                        >
                          <Radio.Button value="card">
                            <AiOutlineAppstore />
                          </Radio.Button>
                          <Radio.Button value="list">
                            <AiOutlineBars />
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </Col>
                  </Row>
                </div>

                {viewMode === "card" && (
                  <div className="insight-content-content">
                    <Row justify="start" gutter={[16, 16]}>
                      {dataInsight
                        ?.filter(
                          (item) =>
                            item.insightReportCategoryId === itemType.value
                        )
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((item) => (
                          <Col sm={24} md={12} lg={8} xl={6} key={item.id}>
                            <div className="content-card-insight2">
                              <div>
                                <div
                                  style={{
                                    height: "175px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    className="content-image-insight"
                                    alt="example"
                                    src={item?.thumbnailSrc ?? `${NoImg}`}
                                    onError={(e) => {
                                      if (e.target.alt === "error") {
                                        e.target.src = NoImg;
                                      } else {
                                        e.target.src = NoImg;
                                      }
                                    }}
                                  />
                                </div>
                                <div style={{ padding: "24px 24px 0px 24px" }}>
                                  <Flex wrap gap="small">
                                    {tagButtons(item)}
                                  </Flex>

                                  <p className="content-card-title-dashboard">
                                    {item.name}
                                  </p>
                                  <p className="content-card-detail-dashboard">
                                    {item.shortDescription}
                                  </p>

                                  <p
                                    style={{
                                      color: "#667085",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {item.createdDateFrom}
                                  </p>
                                </div>
                              </div>
                              <div style={{ padding: "0px 24px 24px 24px" }}>
                                <Flex
                                  justify="center"
                                  align="center"
                                  style={{
                                    marginTop: "-5px",
                                    padding: "10px 20px",
                                    backgroundColor: "#f0f5ff",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    color: "#2B7BF6",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                  }}
                                  onClick={() =>
                                    handleClick(item.id, item.urlSlug)
                                  }
                                >
                                  <p
                                    style={{
                                      margin: "0",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span style={{ marginRight: "8px" }}>
                                      ดูรายละเอียด
                                    </span>
                                    <IconRightArrowViewDashboard />
                                  </p>
                                </Flex>
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                )}
                {viewMode === "list" && (
                  <div
                    className="insight-hover-scroll"
                    style={{ overflowX: "auto", overflowY: "hidden" }}
                  >
                    <Space>
                      <List
                        itemLayout="vertical"
                        dataSource={dataInsight
                          .filter(
                            (item) =>
                              item.insightReportCategoryId === itemType.value
                          )
                          .sort((a, b) => a.sequence - b.sequence)}
                        renderItem={(item, index) => (
                          <>
                            <List.Item>
                              <Row
                                style={{
                                  alignItems: "center",
                                  // display: "flex",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  style={{ height: "120px", width: "150px" }}
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      minHeight: "120px",
                                      width: "100%",
                                      padding: "12px",
                                      borderRight:
                                        "1px solid rgba(5, 5, 5, 0.06)",
                                    }}
                                  >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      style={{ height: "100%" }}
                                    >
                                      <img
                                        alt="example"
                                        src={item?.thumbnailSrc ?? `${NoImg}`}
                                        onError={(e) => {
                                          if (e.target.alt === "error") {
                                            e.target.src = NoImg;
                                          } else {
                                            e.target.src = NoImg;
                                          }
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: "12px",
                                          objectFit: "cover",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleClick(item.id, item.urlSlug)
                                        }
                                      />
                                    </Flex>
                                  </div>
                                </Col>
                                <Col style={{ height: "auto", width: "340px" }}>
                                  <div
                                    style={{
                                      height: "100%",
                                      minHeight: "120px",
                                      width: "100%",
                                      padding: "0px 12px 0px 12px",
                                      borderRight:
                                        "1px solid rgba(5, 5, 5, 0.06)",
                                    }}
                                  >
                                    <Flex wrap gap="small">
                                      {tagButtons(item)}
                                    </Flex>
                                    <p
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        color: "#1677FF",
                                        textDecoration: "underline",
                                        margin: "10px 0px 10px 0px",
                                        cursor: "pointer"
                                      }}
                                      onClick={() =>
                                        handleClick(item.id, item.urlSlug)
                                      }
                                    >
                                      {item.name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "#667085",
                                      }}
                                    >
                                      {item.createdDateFrom}
                                    </p>
                                  </div>
                                </Col>
                                <Col style={{ height: "auto", width: "500px" }}>
                                  <div
                                    style={{
                                      height: "100%",
                                      minHeight: "120px",
                                      width: "100%",
                                      padding: "0px 12px 0px 12px",
                                      borderRight:
                                        "1px solid rgba(5, 5, 5, 0.06)",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#939CB0",
                                        margin: "0px 0px 10px 0px",
                                      }}
                                    >
                                      รายละเอียด
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#1D273C",
                                        margin: "0px 0px 0px 0px",
                                      }}
                                    >
                                      {item.shortDescription}
                                    </p>
                                  </div>
                                </Col>
                                <Col style={{ height: "auto", width: "200px" }}>
                                  <div
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      padding: "0px 12px 0px 12px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#f0f5ff",
                                        borderRadius: "10px",
                                        width: "100%",
                                        height: "auto",
                                        margin: "auto",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleClick(item.id, item.urlSlug)
                                      }
                                    >
                                      <Flex justify="center">
                                        <p
                                          style={{
                                            color: "#1677ff",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            textAlign: "center",
                                            marginRight: "10px",
                                          }}
                                        >
                                          ดูรายละเอียด
                                        </p>
                                        <IconRightArrowViewDashboard />
                                      </Flex>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </List.Item>
                          </>
                        )}
                      />
                    </Space>
                  </div>
                )}
              </div>
            ))}

          {dataInsight.length === 0 && (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ height: "50vh" }}
            >
              <NoData />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#667085",
                }}
              >
                ไม่มีข้อมูล
              </p>
            </Flex>
          )}
        </div>

        <LoadingDialog isLoading={isLoading} />
      </div>

      <LoadingDialog isLoading={isLoading} />
    </>
  );
};

export default InsightReport;
