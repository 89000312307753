import "./DataCatalogDetail.css";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    Flex,
    Button,
    Col,
    Row,
    Tag,
    Dropdown,
    Menu,
    Input,
} from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { DataSourceService } from "../../../../services/DataSourceService.js";
import { LoadingDialog } from "../../../../components/index.js";

const DataCatalogDetail = () => {

    const navigate = useNavigate();
    const [dataCatalogData, setDataCatalogData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchDataCatalogDetailData()
    }, []);

    const fetchDataCatalogDetailData = async () => {
        setIsLoading(true);
        try {
            const title = decodeURI(window.location.pathname.split("/").pop());
            if (title) {
                const response = await DataSourceService.getByUrlSlug(title);
                setDataCatalogData(response.data);
                //console.log("response.data >> ",response.data);
            } else {
                console.error("No ID found in sessionStorage or URL");
            }
        } catch (err) {
        }finally{
            setIsLoading(false);
        }
    };

    const handleTagClick = (tagId , tagName) => {
        navigate(`/data-catalogTag?tagName=${encodeURIComponent(tagName)}`, {
            state: { tagId },
        });
    };

    const handleClickDownload = async (urlSlug,type) => {
        setIsLoading(true);
        try {
          const response = await DataSourceService.getDownloadByUrlSlug(urlSlug,type);
          //console.log("handleClickDownload >> response >> ",response)
          const blob = new Blob([response], { type: type }); 
          const downloadUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = `${urlSlug}.${type}`; 
          document.body.appendChild(a); 
          a.click(); 
          document.body.removeChild(a); 
          URL.revokeObjectURL(downloadUrl); 
    
    
        } catch (error) {
          console.error("Error fetching getDownloadByUrlSlug:", error);
        } finally {
            setIsLoading(false);
        }
      };
    


    const { dataSourceTags, dataSourceMetaDatas } = dataCatalogData;
    return (

        <>

            <div className="datacatalogdetail-body" >
                <div className="heading">
                    <div className="content-card-title">
                        
                        <ArrowLeftOutlined onClick={() => navigate(-1)} style={{
                            marginRight: "10px",
                            cursor: "pointer",
                        }} />{dataCatalogData?.name}
                    </div>
                </div>
                <div className="datacatalogdetail-container">
                    <div className="detail">

                        <Row gutter={[16, 8]}>
                            <>
                                <Col xs={24} >
                                    <div className="datacatalog-item-data">
                                        <Row justify={"space-between"} >
                                            <Col xs={24} sm={12} md={13} style={{ alignContent: "center" }}>
                                                <Row style={{ paddingBottom: 20 }}>
                                                    <span style={{ fontSize: "16px", fontWeight: "500" }}>{dataCatalogData?.name}</span>
                                                </Row>

                                                <Row style={{ paddingBottom: 20 }}>
                                                    <Input
                                                        prefix={<div className="http-prefix">POST</div>}
                                                        size="middle"
                                                            readOnly
                                                            value={"/api" + dataCatalogData?.upstreamPath}
                                                            placeholder="API สำหรับการขอ Access Token"
                                                        />
                                                </Row>

                                                <Row style={{ paddingBottom: 10 }}>
                                                    <Flex wrap gap="small">

                                                        {dataSourceTags && dataSourceTags.length > 0 ? (
                                                            dataSourceTags.map((tag) => (
                                                                <Tag
                                                                    key={tag.id}
                                                                    bordered={false}
                                                                    color={tag.tagColor}
                                                                    onClick={() => handleTagClick(tag.tagId,tag.tagName)}
                                                                    className="tag"
                                                                >
                                                                    {tag.tagName}
                                                                </Tag>
                                                            ))
                                                        ) : (
                                                            <p>No tags available.</p>
                                                        )}

                                                    </Flex>
                                                </Row>
                                                <Row>
                                                    <Flex wrap gap="small">
                                                        <span style={{ color: "#667085", marginBottom: 5 }}>อัพเดทล่าสุด : {dataCatalogData?.formattedUpdatedDate}</span>
                                                    </Flex>
                                                </Row>


                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={6} xl={5} xxl={5} style={{ alignContent: "start", textAlign: "end" }}>
                                                 <Dropdown overlay={
                                                    <Menu>
                                                        <Menu.Item key="1"><div onClick={(e)=>{handleClickDownload(dataCatalogData?.urlSlug,"json")}}>ดาวน์โหลด JSON</div></Menu.Item>
                                                        <Menu.Item key="2"><div onClick={(e)=>{handleClickDownload(dataCatalogData?.urlSlug,"csv")}}>ดาวน์โหลด CSV</div></Menu.Item>
                                                    </Menu>} 
                                                    
                                                    //trigger={['click']}
                                                    >
                                                    <Button
                                                        type="default"
                                                        className=""
                                                        style={{
                                                        border: "1px solid #1677FF",
                                                        color: "#1677FF",
                                                        borderRadius: "10px",
                                                        width: "100%"
                                                        }}
                                                    >
                                                        <UploadOutlined />
                                                        <span className="">ดาวน์โหลดข้อมูล</span>
                                                    </Button>

                                                </Dropdown>
                                            </Col>
                                        </Row>
                                        <Row justify={"space-between"} >
                                            <Col xs={24} style={{ alignContent: "start", textAlign: "left" }}>
                                                {/* <p>{dataCatalogData?.description}</p> */}
                                                <div dangerouslySetInnerHTML={{ __html: dataCatalogData?.description }} />
                                            </Col>
                                        </Row>

                                    </div>


                                </Col>
                            </>
                        </Row>
                    </div>
                    <div className="meta-data">
                        <h3>ข้อมูลเพิ่มเติม</h3>
                        <div className="meta-data-item">



                            {dataSourceMetaDatas && dataSourceMetaDatas.length > 0 ? (
                                dataSourceMetaDatas.map((data, index) => (
                                    <div className="meta-data-item-row">
                                        <Row gutter={[16]}>
                                            <Col xs={24} sm={12} md={8}>
                                                <div className="txt-thai">
                                                    {data?.fieldNameTH}
                                                </div>
                                                <div className="txt-eng">
                                                    {data?.fieldNameEN}
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={12} md={8}>
                                                <div className="lb-description">
                                                    JSON Attribute
                                                </div>
                                                <div className="txt-description">
                                                    {data?.dataSourceFieldName}
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={12} md={8}>
                                                <div className="lb-description">
                                                    รายละเอียด
                                                </div>
                                                <div className="txt-description">
                                                    {data?.description}
                                                </div>
                                            </Col>
                                        </Row>
                                        {!((index + 1) === dataSourceMetaDatas.length) && (
                                            <hr />
                                        )}

                                    </div>
                                ))
                            ) : (
                                <p>No Data</p>
                            )}





                        </div>


                    </div>
                </div>

            </div>
            <LoadingDialog isLoading={isLoading} />
        </>
    )


}

export default DataCatalogDetail;