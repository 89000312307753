import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Select, Checkbox, Modal } from 'antd';
import { ArrowLeftOutlined, PhoneOutlined, LockOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import logo from '../Login/logo 1.png';
import key from '../register/key.png'
import '../Login/login.css';
import './register.css';
import { KeycloakService } from '../../../services/KeycloakService';
import { LoadingDialog } from '../../../components';
const RegisterPage = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [captchaValue, setCaptchaValue] = useState('');
    const [email, setEmail] = useState('');
    const [visible, setVisible] = useState(false);
    const [isFieldVisible, setFieldVisible] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showOtherCategory, setShowOtherCategory] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง");
    const [isValid, setIsValid] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        specialChar: false,
    });



    const siteKey = process.env.REACT_APP_SITE_KEY_RECAPCHA;
    const secret = process.env.REACT_APP_SECRET_KEY_RECAPCHA;

    // โหลดสคริปต์ reCAPTCHA v3
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        document.head.appendChild(script);
    }, [siteKey]);

    const executeRecaptcha = async (action) => {
        return new Promise((resolve) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(siteKey, { action }).then((token) => {
                    resolve(token);
                });
            });
        });
    };


    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        // Validate password rules
        setIsValid({
            length: newPassword.length >= 8 && newPassword.length <= 20,
            uppercase: /[A-Z]/.test(newPassword),
            lowercase: /[a-z]/.test(newPassword),
            digit: /\d/.test(newPassword),
            specialChar: /[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|]/.test(newPassword),
        });
    };



    const toggleVisibility = () => {
        setVisible(!visible);
    };

    const onCaptchaChange = (value) => {
        setCaptchaValue(value); // อัพเดตค่า captchaValue เมื่อผู้ใช้ยืนยัน reCAPTCHA
    };

    const onFinish = async (values) => {
        setIsLoading(true);  // เริ่มการโหลด
        const token = await executeRecaptcha('login');

        if (token) {
            const recap = { secret, response: token };
            try {
                const responserecap = await KeycloakService.recap(recap);

                if (responserecap.success) {
                    const payload = {
                        username: values.email,
                        firstName: values.name,
                        lastName: values.surname,
                        email: values.email,
                        emailVerified: false,
                        enabled: true,
                        attributes: {
                            typeOfIndustries: [values.category],
                            company: [values.company],
                            position: [values.position],
                            tel: [values.phone],
                        },
                        credentials: [{ temporary: false, type: "password", value: values.password }],
                    };
                    try {
                        const response = await KeycloakService.register(payload);
                        //console.log("User registered successfully:", response.data);
                        setIsLoading(false);
                        setFieldVisible(false);
                    } catch (error) {
                        setErrorMessage(error.response?.data?.messageTh || "เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง");
                        setIsModalVisible(true);
                        setIsLoading(false);
                        console.error("Error while registering user:", error);
                    }
                } else {
                    setIsLoading(false);
                    setIsModalVisible(true);
                }
            } catch (error) {
                console.error("Error while verifying reCAPTCHA:", error);
                setErrorMessage("เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง");
                setIsLoading(false);
                setIsModalVisible(true);
            }
        } else {
            setErrorMessage("เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง");
            setIsModalVisible(true);
        }
        setIsLoading(false);  // สิ้นสุดการโหลด
        setEmail(values.email);
    };

    const handleOk = () => {
        setIsModalVisible(false); // ปิด modal เมื่อกดปุ่ม
    };



    const goBack = () => {
        navigate('/login');
    };

    const handleCategoryChange = (value) => {
        if (value === 'Other') {
            setShowOtherCategory(true);
        } else {
            setShowOtherCategory(false);
        }
    };

    return (
        <div className="login-container">

            <div className="login-left">
                <div className="logo">
                    <img
                        src={logo}
                        alt="TCEB Logo"
                        className="logo-image"
                        onClick={() => navigate('/')}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <span
                    style={{
                        fontSize: 12,
                        display: 'block',
                        textAlign: 'right',
                        paddingLeft: '11%', // เพิ่มการขยับไปทางขวา
                    }}
                >
                    © 2024 สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)
                </span>
            </div>

            {isFieldVisible && (
                <div className="login-right">
                    <div className="register-card">
                        <div className="header">
                            <ArrowLeftOutlined
                                style={{ marginRight: '8px', cursor: 'pointer' }}
                                onClick={goBack} // เพิ่มฟังก์ชัน goBack ที่จัดการการคลิก
                            />
                            <p className="welcome-text" style={{ display: 'inline-block', margin: 0 }}>
                                สมัครสมาชิก
                            </p>
                        </div>
                        <Form
                            form={form} // เชื่อมโยง form instance
                            name="register_form"
                            className="register-form"
                            onFinish={onFinish}
                            initialValues={{ remember: true }}
                        >
                            <Row gutter={8}> {/* ลด gutter */}
                                <Col span={12}>
                                    <Form.Item
                                        name="name"
                                        label={
                                            <span>
                                                ชื่อ <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        }
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                        required={false}
                                    >
                                        <Input placeholder="กรุณากรอกชื่อ" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="surname"
                                        label={
                                            <span>
                                                นามสกุล <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        }
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                        required={false}
                                    >
                                        <Input placeholder="กรุณากรอกนามสกุล" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8}> {/* ลด gutter */}
                                <Col span={12}>
                                    <Form.Item
                                        name="email"
                                        label={
                                            <span>
                                                อีเมล <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        }
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                        required={false}
                                    >
                                        <Input placeholder="อีเมล" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="phone"
                                        label={
                                            <span>
                                                เบอร์โทรศัพท์
                                            </span>
                                        }
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        // rules={[{ min: 10, message: 'ต้องมีความยาวอย่างน้อย 10 ตัวอักษร' }]}
                                        required={false}
                                    >
                                        <Input
                                            placeholder="080-000-0000"
                                            prefix={<PhoneOutlined style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8}> {/* ลด gutter */}
                                <Col span={12}>
                                    <Form.Item
                                        name="position"
                                        label={
                                            <span>
                                                ตำแหน่ง <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        }
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                        required={false}
                                    >
                                        <Input placeholder="ตำแหน่ง" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="category"
                                        label={
                                            <span>
                                                ประเภทงาน <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        }
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                        required={false}
                                    >
                                        <Select placeholder="Select" onChange={handleCategoryChange}>
                                            <Select.Option value="Academia">Academia</Select.Option>
                                            <Select.Option value="Asset Management and Private Equity">Asset Management and Private Equity</Select.Option>
                                            <Select.Option value="Consulting">Consulting</Select.Option>
                                            <Select.Option value="Corporate Finance and Accounting">Corporate Finance and Accounting</Select.Option>
                                            <Select.Option value="Customer Service">Customer Service</Select.Option>
                                            <Select.Option value="Data and Analytics">Data and Analytics</Select.Option>
                                            <Select.Option value="Diversity and Inclusion">Diversity and Inclusion</Select.Option>
                                            <Select.Option value="Event Management">Event Management</Select.Option>
                                            <Select.Option value="Government">Government</Select.Option>
                                            <Select.Option value="Human Resources">Human Resources</Select.Option>
                                            <Select.Option value="IT and Technology">IT and Technology</Select.Option>
                                            <Select.Option value="Legal">Legal</Select.Option>
                                            <Select.Option value="Logistics/Transportation/Supply Chain">Logistics/Transportation/Supply Chain</Select.Option>
                                            <Select.Option value="Manufacturing">Manufacturing</Select.Option>
                                            <Select.Option value="Marketing">Marketing</Select.Option>
                                            <Select.Option value="Media and Press">Media and Press</Select.Option>
                                            <Select.Option value="Operations">Operations</Select.Option>
                                            <Select.Option value="Procurement">Procurement</Select.Option>
                                            <Select.Option value="Research and Development/Innovation">Research and Development/Innovation</Select.Option>
                                            <Select.Option value="Risk">Risk</Select.Option>
                                            <Select.Option value="Sales">Sales</Select.Option>
                                            <Select.Option value="Strategy">Strategy</Select.Option>
                                            <Select.Option value="Sustainability">Sustainability</Select.Option>
                                            <Select.Option value="Transformation">Transformation</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {showOtherCategory && (
                                <Row gutter={8}>
                                    <Col span={12}>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="otherCategory"
                                            label="ประเภทงานอื่นๆ"
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                            required={false}
                                        >
                                            <Input placeholder="กรุณากรอกประเภทงานอื่นๆ" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}

                            <Row gutter={8}>
                                <Col span={12}>
                                    <Form.Item
                                        name="password"
                                        label={
                                            <span>
                                                รหัสผ่าน <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        }
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                        required={false}
                                        onChange={handlePasswordChange}
                                    >
                                        <Input.Password
                                            placeholder="กรุณากรอกรหัสผ่าน"
                                            prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="confirmPassword"
                                        label={
                                            <span>
                                                ยืนยันรหัสผ่าน <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        }
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        dependencies={['password']}
                                        rules={[
                                            { required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'));
                                                },
                                            }),
                                        ]}
                                        required={false}
                                    >
                                        <Input.Password
                                            placeholder="กรุณากรอกรหัสผ่าน"
                                            prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Password Validation Checklist */}
                            <div style={{ textAlign: 'left', marginBottom: '1px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircleIcon
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            color: isValid.length ? 'green' : 'gray',
                                        }}
                                    />
                                    <span style={{ color: isValid.length ? 'green' : 'gray', marginLeft: '8px' }}>ความยาวรหัสผ่านต้องมีอย่างน้อย 8 - 20 ตัวอักษร</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircleIcon
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            color: isValid.uppercase ? 'green' : 'gray',
                                        }}
                                    />
                                    <span style={{ color: isValid.uppercase ? 'green' : 'gray', marginLeft: '8px' }}>ต้องมีตัวอักษรพิมพ์ใหญ่</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircleIcon
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            color: isValid.lowercase ? 'green' : 'gray',
                                        }}
                                    />
                                    <span style={{ color: isValid.lowercase ? 'green' : 'gray', marginLeft: '8px' }}>ต้องมีตัวอักษรพิมพ์เล็ก</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircleIcon
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            color: isValid.digit ? 'green' : 'gray',
                                        }}
                                    />
                                    <span style={{ color: isValid.digit ? 'green' : 'gray', marginLeft: '8px' }}>ต้องมีหมายเลข (ตัวเลข)</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircleIcon
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            color: isValid.specialChar ? 'green' : 'gray',
                                        }}
                                    />
                                    <span style={{ color: isValid.specialChar ? 'green' : 'gray', marginLeft: '8px' }}>ต้องมีอักขระพิเศษ [*.!@#&amp;$%^&amp;(){ }[]:;&lt;&gt;,.?/~_+-=|]</span>
                                </div>
                            </div>


                            <Form.Item
                                name="terms"
                                valuePropName="checked"
                                rules={[{ required: true, message: 'กรุณายอมรับข้อกำหนดและเงื่อนไข' }]}
                                required={false}
                                style={{ marginBottom: '4px' }} // ปรับระยะห่างระหว่าง Form.Item
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox style={{ marginRight: '8px' }}>
                                        ข้าพเจ้ายอมรับและตกลงตาม <a href="">ข้อกำหนดและเงื่อนไขการให้บริการ</a>
                                    </Checkbox>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name="privacyPolicy"
                                valuePropName="checked"
                                rules={[{ required: true, message: 'กรุณายอมรับนโยบายความเป็นส่วนตัว' }]}
                                required={false}
                                style={{ marginBottom: '4px' }} // ปรับระยะห่างระหว่าง Form.Item
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Checkbox style={{ marginBottom: '0px', marginRight: '8px' }}>
                                        <span style={{ lineHeight: '1' }}>
                                            ข้าพเจ้ายอมรับ <a href="">นโยบายความเป็นส่วนตัว</a> และยินยอมให้ TCEB
                                        </span>
                                    </Checkbox>
                                    <span style={{ marginLeft: '24px', lineHeight: '1.5' }}>
                                        เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า
                                    </span>
                                    <span style={{ marginLeft: '24px', lineHeight: '1.5' }}>
                                        เพื่อการจัดการบัญชีผู้ใช้การรวบรวมสถิติการให้บริการ
                                    </span>
                                    <span style={{ marginLeft: '24px', lineHeight: '1.5' }}>
                                        การจัดกิจกรรม และการปฏิบัติตามพันธกิจของ TCEB
                                    </span>
                                </div>
                            </Form.Item>
                            <br />
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="register-form-button" disabled={!Object.values(isValid).every(Boolean)}>
                                    สมัครสมาชิก
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            )}
            {!isFieldVisible && (
                <div
                    className="login-right"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        textAlign: 'center',
                        backgroundColor: '#f0f2f5',
                    }}
                >
                    <div
                        className="register-card2">
                        <img
                            src={key}
                            alt="Key"
                            className="logo-image"
                            style={{ width: '60px', height: 'auto' }} // ปรับขนาดตามที่ต้องการ
                        />
                        <p
                            style={{
                                margin: '20px 0 0 0', // กำหนดระยะห่างด้านบนให้กับพารากราฟ
                                textAlign: 'center',
                                fontSize: '18px',
                                fontWeight: 'bold',
                            }}
                        >
                            อีเมลถูกส่งแล้ว
                        </p>
                        <span style={{ display: 'block', margin: '10px 0' }}> {/* ใช้ margin เพื่อเพิ่มระยะห่าง */}
                            รหัสยืนยันได้ถูกส่งไปที่ {email} กรุณากรอกรหัสยืนยันเพื่อทำการยืนยัน
                        </span>
                        <Button type="primary" htmlType="submit" className="register-form-button" onClick={goBack} >
                            รับทราบ
                        </Button>
                    </div>
                </div>
            )}
            <LoadingDialog visible={isLoading} />

            <Modal
                open={isModalVisible}
                onOk={handleOk}
                footer={null}
                closable={false} 
                centered 
                width={500} 
            >
                <div style={{ textAlign: 'center' }}>
                    <CloseCircleOutlined style={{ fontSize: '70px', color: '#E03A3A' }} /> {/* ลดขนาดไอคอน */}
                    <h2 style={{ marginTop: '16px' }}>ไม่สำเร็จ</h2>
                    <p style={{ margin: '16px 0', fontSize: '16px' }}>{errorMessage}</p> {/* แสดงข้อความ error */}
                    <Button type="primary" onClick={handleOk} style={{ backgroundColor: '#E03A3A', borderColor: '#E03A3A' }}>
                        ลองอีกครั้ง
                    </Button>
                </div>
            </Modal>

        </div>
    );
};

export default RegisterPage;

