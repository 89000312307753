import React, { useState } from "react";
import { Button } from "antd";
import afilate from "../../assets/icon/svg/affilate.svg";
import rightArrowButton from "../../assets/icon/svg/right_arrrow_button.svg";
import leftArrowButton from "../../assets/icon/svg/left_arrow_button.svg";
import "./AffilateButton.css";

const AffilateButton = ({ onClick, screenPosition = "50vh", visible = false }) => {
  const [showButton, setShowButton] = useState(visible);
  const handleHideButton = () => {
    setShowButton(!showButton);
  }
  return (
    <div className="affilate-button-container" style={{ 
        top: screenPosition,
        right: showButton ? "0" : "-200px",
      }}>
      <div className="overlay"></div>
      <Button className="button"
        onClick={handleHideButton}>
        <img src={showButton ? rightArrowButton : leftArrowButton} alt="Right Arrow Button"/>
      </Button>

      <img src={afilate} alt="Affilate Icon" className="image" />
      <p><a onClick={onClick} target="_black">สอบถามข้อมูลเพิ่มเติมที่ MICE</a></p>

    </div>
  );
};

export default AffilateButton;
