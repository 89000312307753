import React, { useState, useEffect } from "react";
import { useUnsavedChangesContext } from "../../../../../contexts";
import { useUnsavedChangesWarning } from "../../../../../hooks";
import "./RequestApiManagement.css";
import {
  Col,
  Row,
  Table,
  Button,
  Select,
  Flex,
  Divider,
  Form,
  Tag,
  Tooltip,
  Switch,
  InputNumber,
  Layout,
  Space,
  Modal,
  Input,
  message,
} from "antd";
import {
  SuccessDialog,
  FailedDialog,
  DiscardDialog,
  LoadingDialog,
} from "../../../../../components/index.js";
import { AiOutlineArrowLeft, AiOutlineCheck } from "react-icons/ai";
import IconNotAllow from "../../../../../assets/icon/icon_not_allow.js";
import IconSaveAllow from "../../../../../assets/icon/icon_save_allow.js";
import { RequisitionService } from "../../../../../services/RequisitionService";
import { UserService } from "../../../../../services/UserService";

const { Header } = Layout;
const { Option } = Select;
const { TextArea } = Input;

const RequestApiManagementDetail = ({ select, setSelect }) => {
  const { isDataModified, setIsDataModified } = useUnsavedChangesContext();
  const {
    handleUnsavedConfirm,
    handleUnsavedCancel,
    setIsUnsavedModalOpen,
    isUnsavedModalOpen,
  } = useUnsavedChangesWarning();

  const [form] = Form.useForm();
  const [formAllow] = Form.useForm();
  const [formNotAllow] = Form.useForm();
  const [dataById, setDataById] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [switchStates, setSwitchStates] = useState([]);
  const [rejectReason, setRejectReason] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const [isModalNotAllowOpen, setIsModalNotAllowOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (select) {
      fetchDataById();
      UserService.isEditable().then((isEditable) => {
        setIsEditable(isEditable);
      });
    }
  }, [select]);

  const fetchDataById = async () => {
    try {
      setLoading(true);

      let response = await RequisitionService.get(select);
      let switchStatesId = [];

      const data = {
        ...response,
        requisitionDetails:
          response.requisitionStatusId === "2"
            ? response.requisitionDetails
                .filter((item) => item.requisitionStatusId === "2")
                .map((item) => {
                  if (item?.enableRateLimiting) {
                    switchStatesId.push(item.id);
                  }
                  const match = item?.period?.match(/^(\d+)([a-zA-Z]+)$/);
                  return {
                    ...item,
                    period: match ? parseInt(match[1], 10) : "",
                    periodUnit: match ? match[2] : "",
                  };
                })
            : response.requisitionDetails.map((item) => {
                if (item?.enableRateLimiting) {
                  switchStatesId.push(item.id);
                }
                const match = item?.period?.match(/^(\d+)([a-zA-Z]+)$/);
                return {
                  ...item,
                  period: match ? parseInt(match[1], 10) : "",
                  periodUnit: match ? match[2] : "",
                };
              }),
      };

      setDataById(data);
      setSwitchStates(switchStatesId);
      setSelectedRowKeys([]);
      form.resetFields();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onFinish = async (values) => {
    setIsModalConfirmOpen(false);
    const rateLimits = values;
    let data = {};
    setLoading(true);
    try {
      if (dataById?.requisitionStatusId === "2") {
        data = {
          id: dataById?.id,
          requisitionStatusId: dataById?.requisitionStatusId,
          rejectReason: dataById?.rejectReason,
          requisitionDetails: dataById?.requisitionDetails?.map((item) => {
            const isCheck = selectedRowKeys.includes(item.id);
            const rateLimit = rateLimits.records[item.id];
            if (isCheck) {
              return {
                id: item.id,
                requisitionStatusId: "2",
                enableRateLimiting: rateLimit.enableRateLimiting || false,
                period: rateLimit.enableRateLimiting
                  ? `${rateLimit.period}${rateLimit.periodUnit}`
                  : "",
                periodTimeSpan: rateLimit.enableRateLimiting
                  ? rateLimit.periodTimeSpan
                  : 0,
                limit: rateLimit.enableRateLimiting ? rateLimit.limit : 0,
              };
            }
            return {
              id: item.id,
              requisitionStatusId: item?.requisitionStatusId,
              enableRateLimiting: item?.enableRateLimiting,
              period: `${rateLimit.period}${rateLimit.periodUnit}` || "",
              periodTimeSpan: item?.periodTimeSpan,
              limit: item?.limit,
            };
          }),
        };
      } else {
        data = {
          id: dataById?.id,
          requisitionStatusId: "2",
          rejectReason: rejectReason || "",
          requisitionDetails: dataById?.requisitionDetails?.map((item) => {
            const isCheck = selectedRowKeys.includes(item.id);
            if (isCheck) {
              const rateLimit = rateLimits.records[item.id];
              return {
                id: item.id,
                requisitionStatusId: "2",
                enableRateLimiting: rateLimit.enableRateLimiting || false,
                period: rateLimit.enableRateLimiting
                  ? `${rateLimit.period}${rateLimit.periodUnit}`
                  : "",
                periodTimeSpan: rateLimit.enableRateLimiting
                  ? rateLimit.periodTimeSpan
                  : 0,
                limit: rateLimit.enableRateLimiting ? rateLimit.limit : 0,
              };
            }
            return {
              id: item.id,
              requisitionStatusId: "3",
              enableRateLimiting: false,
              period: "",
              periodTimeSpan: 0,
              limit: 0,
            };
          }),
        };
      }

      let response = await RequisitionService.approval(dataById.id, data);
      setIsModalSuccessOpen(true);
      handleUnsavedConfirm();
      setTimeout(() => {
        setDataById();
        setRejectReason();
        setIsModalSuccessOpen(false);
        fetchDataById();
      }, 1000);
    } catch (error) {
      console.error();
      form.resetFields();
      formAllow.resetFields();
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  const onFinishAllow = (values) => {
    setRejectReason(values.rejectReason);
    form.submit();
  };

  const onFinishNotAllow = async (values) => {
    setIsModalNotAllowOpen(false);
    setLoading(true);
    try {
      const data = {
        id: dataById?.id,
        requisitionStatusId: "3",
        rejectReason: values.rejectReason,
        requisitionDetails: dataById?.requisitionDetails?.map((item) => ({
          id: item.id,
          requisitionStatusId: "3",
          enableRateLimiting: false,
          period: "",
          periodTimeSpan: 0,
          limit: 0,
        })),
      };

      let response = await RequisitionService.approval(dataById.id, data);
      setIsModalSuccessOpen(true);
      formNotAllow.resetFields();
      handleUnsavedConfirm();
      setTimeout(() => {
        setDataById();
        setIsModalSuccessOpen(false);
        fetchDataById();
      }, 1000);
    } catch (error) {
      console.error();
      formNotAllow.resetFields();
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchChange = (id, checked) => {
    // setIsDataModified(true);
    const updatedFields = checked
      ? {
          periodUnit: "s",
        }
      : {
          limit: "",
          period: "",
          periodUnit: "",
          periodTimeSpan: "",
        };

    form.setFieldsValue({
      records: {
        [id]: updatedFields,
      },
    });

    setSwitchStates((prev) => {
      if (checked) {
        return prev.includes(id) ? prev : [...prev, id];
      } else {
        return prev.filter((item) => item !== id);
      }
    });
  };

  const requestListColumns = [
    {
      title: <span className="api-columns-title">#</span>,
      dataIndex: "index",
      key: "index",
      width: "45px",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <span className="api-columns-title">ชื่อ API</span>,
      dataIndex: "dataSourceName",
      key: "dataSourceName",
      width: "180px",
    },
    // {
    //   title: <span className="api-columns-title">สถานะ</span>,
    //   dataIndex: "requisitionStatusName",
    //   key: "requisitionStatusName",
    //   width: "152px",
    //   render: (text, record, index) => {
    //     let textRender;
    //     switch (record.requisitionStatusId) {
    //       case "1":
    //         textRender = (
    //           <Tag color="processing" style={{ borderRadius: "12px" }}>
    //             <span style={{ fontSize: "12px", fontWeight: "600" }}>
    //               {record.requisitionStatusName}
    //             </span>
    //           </Tag>
    //         );
    //         break;
    //       case "2":
    //         textRender = (
    //           <Tag color="success" style={{ borderRadius: "12px" }}>
    //             <span style={{ fontSize: "12px", fontWeight: "600" }}>
    //               {record.requisitionStatusName}
    //             </span>
    //           </Tag>
    //         );
    //         break;
    //       case "3":
    //         textRender = (
    //           <Tag color="default" style={{ borderRadius: "12px" }}>
    //             <span style={{ fontSize: "12px", fontWeight: "600" }}>
    //               {record.requisitionStatusName}
    //             </span>
    //           </Tag>
    //         );
    //         break;
    //       default:
    //         break;
    //     }

    //     return textRender;
    //   },
    // },
    {
      title: <span className="api-columns-title">ประเภท</span>,
      dataIndex: "dataSourceCategoryName",
      key: "dataSourceCategoryName",
      width: "180px",
    },
    {
      title: <span className="api-columns-title">Tag</span>,
      dataIndex: "dataSourceTags",
      key: "dataSourceTags",
      width: "212px",
      render: (_, { dataSourceTags }) => {
        const visibleTags = dataSourceTags.slice(0, 2);
        const hiddenTagsCount = dataSourceTags.length - 2;

        return (
          <>
            {visibleTags.map((item) => (
              <Tag key={item.tagId} bordered={false} color={item.tagColor}>
                {item.tagName}
              </Tag>
            ))}
            {hiddenTagsCount > 0 && (
              <Tooltip
                title={dataSourceTags
                  .slice(2)
                  .map((item) => item.tagName)
                  .join(", ")}
              >
                <Tag key="more" color="#d9d9d9" style={{ cursor: "pointer" }}>
                  <span style={{ color: "#000" }}>+{hiddenTagsCount}</span>
                </Tag>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: <span className="api-columns-title">วันที่ขอใช้ API</span>,
      dataIndex: "formattedCreatedDate",
      key: "formattedCreatedDate",
      width: "135px",
    },
    dataById?.requisitionStatusId !== "3"
      ? {
          title: (
            <span className="api-columns-title">Enable Rate Limiting</span>
          ),
          dataIndex: "enableRateLimiting",
          key: "enableRateLimiting",
          width: "165px",
          render: (text, record, index) => (
            <Form.Item
              name={["records", record.id, "enableRateLimiting"]}
              initialValue={record.enableRateLimiting}
            >
              <Switch
                disabled={!isEditable}
                checkedChildren="True"
                unCheckedChildren="False"
                checked={
                  switchStates.includes(record.id) ?? record.enableRateLimiting
                }
                onChange={(checked) => handleSwitchChange(record.id, checked)}
              />
            </Form.Item>
          ),
        }
      : null,
    dataById?.requisitionStatusId !== "3"
      ? {
          title: (
            <span className="api-columns-title">
              Limit <span style={{ color: "red" }}>*</span>
            </span>
          ),
          dataIndex: "limit",
          key: "limit",
          width: "130px",
          render: (text, record, index) => (
            <Form.Item
              name={["records", record.id, "limit"]}
              initialValue={record.limit}
              rules={[
                {
                  required: switchStates.includes(record.id),
                  message: "จำเป็นต้องกรอกข้อมูล",
                },
              ]}
            >
              <InputNumber
                min={1}
                max={100}
                disabled={isEditable ? !switchStates.includes(record.id) : true}
                placeholder=""
                onChange={() => {
                  // setIsDataModified(true);
                }}
              />
            </Form.Item>
          ),
        }
      : null,
    dataById?.requisitionStatusId !== "3"
      ? {
          title: (
            <span className="api-columns-title">
              Period <span style={{ color: "red" }}>*</span>
            </span>
          ),
          dataIndex: "period",
          key: "period",
          width: "250px",
          render: (text, record, index) => (
            <Form.Item
              name={["records", record.id, "period"]}
              initialValue={record.period}
              rules={[
                {
                  required: switchStates.includes(record.id),
                  message: "จำเป็นต้องกรอกข้อมูล",
                },
              ]}
            >
              <InputNumber
                min={1}
                max={59}
                disabled={isEditable ? !switchStates.includes(record.id) : true}
                style={{ width: 160 }}
                placeholder=""
                onChange={() => {
                  // setIsDataModified(true);
                }}
                addonAfter={
                  <Form.Item
                    name={["records", record.id, "periodUnit"]}
                    initialValue={record.periodUnit}
                    rules={[
                      {
                        required: switchStates.includes(record.id),
                        message: "จำเป็นต้องกรอกข้อมูล",
                      },
                    ]}
                    noStyle
                  >
                    <Select
                      style={{ width: 80 }}
                      disabled={
                        isEditable ? !switchStates.includes(record.id) : true
                      }
                      onChange={() => {
                        // setIsDataModified(true);
                      }}
                    >
                      <Option value="s">วินาที</Option>
                      <Option value="m">นาที</Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
          ),
        }
      : null,
    dataById?.requisitionStatusId !== "3"
      ? {
          title: (
            <span className="api-columns-title">
              Period Time Span <span style={{ color: "red" }}>*</span>
            </span>
          ),
          dataIndex: "periodTimeSpan ",
          key: "periodTimeSpan",
          width: "210px",
          render: (text, record, index) => (
            <Form.Item
              name={["records", record.id, "periodTimeSpan"]}
              initialValue={record.periodTimeSpan}
              rules={[
                {
                  required: switchStates.includes(record.id),
                  message: "จำเป็นต้องกรอกข้อมูล",
                },
              ]}
            >
              <InputNumber
                min={1}
                max={59}
                disabled={isEditable ? !switchStates.includes(record.id) : true}
                addonAfter={<span>วินาที</span>}
                placeholder=""
                onChange={() => {
                  // setIsDataModified(true);
                }}
              />
            </Form.Item>
          ),
        }
      : null,
  ]?.filter(Boolean);

  return (
    <>
      {contextHolder}
      <Header
        style={{
          height: "70px",
          background: "#FFF",
          borderBottom: "1px solid #D0D5DD",
          padding: "0 30px",
        }}
      >
        <Flex
          gap="middle"
          justify="flex-start"
          align="center"
          style={{ height: "100%" }}
        >
          <AiOutlineArrowLeft
            style={{ fontSize: "24px", cursor: "pointer" }}
            onClick={() => {
              if (isDataModified) {
                setIsUnsavedModalOpen(true);
              } else {
                setSelect();
                setDataById();
              }
            }}
          />
          <p style={{ fontSize: "20px", fontWeight: "600" }}>
            จัดการคำร้องขอใช้ API
          </p>
        </Flex>
      </Header>
      <div className="api-management-body">
        <div className="api-management-container main-body">
          <h3>บัญชีของคุณ</h3>
          <Divider
            style={{ borderColor: "#d9d9d9", margin: "10px 0 10px 0" }}
          />
          <Row gutter={16}>
            <Col span={12} xs={24} md={24} lg={24} xl={12}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "0px",
                }}
              >
                ชื่อ - นามสกุล
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "10px 0px 10px 0px",
                  color: "#667085",
                }}
              >
                {dataById?.userFirstName && dataById?.userLastName
                  ? `${dataById.userFirstName} ${dataById.userLastName}`
                  : "-"}
              </p>
            </Col>
            <Col span={12} xs={24} md={24} lg={24} xl={12}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "0px",
                }}
              >
                อีเมล
              </p>
              <Flex justify="" align="center" wrap gap="small">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "10px 0px 10px 0px",
                    color: "#667085",
                  }}
                >
                  {dataById?.userEmail || "-"}
                </p>
                <Tag
                  color={dataById?.isUserEmailVerified ? "success" : "red"}
                  style={{ borderRadius: "12px", margin: "0px 0px 10px 0px" }}
                >
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {dataById?.isUserEmailVerified
                      ? "ตรวจสอบแล้ว"
                      : "ยังไม่ได้ยืนยันผู้ใช้งาน"}
                  </span>
                </Tag>
              </Flex>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} xs={24} md={24} lg={24} xl={12}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "0px",
                }}
              >
                ชื่อบริษัท
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "10px 0px 10px 0px",
                  color: "#667085",
                }}
              >
                {dataById?.companyName || "-"}
              </p>
            </Col>
            <Col span={12} xs={24} md={24} lg={24} xl={12}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "0px",
                }}
              >
                ที่อยู่ บริษัท
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "10px 0px 10px 0px",
                  color: "#667085",
                }}
              >
                {dataById?.formattedAddress || "-"}
              </p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} xs={24} md={24} lg={24} xl={12}>
              <p style={{ fontSize: "16px", fontWeight: "600", margin: "0px" }}>
                จุดประสงค์การใช้งาน
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "10px 0px 10px 0px",
                  color: "#667085",
                }}
              >
                {dataById?.requisitionReason || "-"}
              </p>
            </Col>

            {dataById?.approvalBy &&(
              <Col span={12} xs={24} md={24} lg={24} xl={12}>
                <p style={{ fontSize: "16px", fontWeight: "600", margin: "0px" }}>
                  ผู้พิจารณา
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "10px 0px 10px 0px",
                    color: "#667085",
                  }}
                >
                  {dataById?.approvalByName + " เมื่อ " +dataById?.formattedApprovalDate}
                </p>
              </Col>
            )}
            
          </Row>
         
        </div>

        <div
          className="api-management-container main-body"
          style={{ marginTop: "20px" }}
        >
          <Flex justify="space-between" align="center" wrap gap="small">
            <Space size="large">
              <h3>รายการขอใช้ API</h3>
              {dataById?.requisitionStatusId === "1" && (
                <Tag color="processing" style={{ borderRadius: "12px" }}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {dataById?.requisitionStatusName}
                  </span>
                </Tag>
              )}
              {dataById?.requisitionStatusId === "2" && (
                <Tag color="success" style={{ borderRadius: "12px" }}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {dataById?.requisitionStatusName}
                  </span>
                </Tag>
              )}
              {dataById?.requisitionStatusId === "3" && (
                <Tag color="default" style={{ borderRadius: "12px" }}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {dataById?.requisitionStatusName}
                  </span>
                </Tag>
              )}
            </Space>

            {isEditable && dataById?.requisitionStatusId === "1" && (
              <Button
                disabled={!selectedRowKeys.length}
                icon={<AiOutlineCheck />}
                onClick={() => setIsModalConfirmOpen(true)}
                style={{
                  color: selectedRowKeys.length ? "#fff" : "",
                  background: selectedRowKeys.length ? "#1AC487" : "",
                  borderRadius: "10px",
                  height: "40px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                ยินยอม
              </Button>
            )}
            {isEditable && dataById?.requisitionStatusId === "2" && (
              <Button
                disabled={!selectedRowKeys.length}
                icon={<AiOutlineCheck />}
                onClick={() => {
                  form.submit();
                }}
                style={{
                  color: selectedRowKeys.length ? "#000" : "",
                  background: selectedRowKeys.length ? "#63E1E1" : "",
                  borderRadius: "10px",
                  height: "40px",
                  width: "140px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                บันทึก
              </Button>
            )}
          </Flex>
          <Divider
            style={{ borderColor: "#d9d9d9", margin: "10px 0 10px 0" }}
          />
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            onFinishFailed={({ errorFields }) => {
              messageApi.open({
                type: "error",
                content: "กรุณากรอกข้อมูลให้สมบูรณ์",
              });
              setIsModalConfirmOpen(false);
            }}
          >
            {dataById && (
              <Table
                style={{
                  margin: "8px 0",
                }}
                rowSelection={
                  isEditable && dataById?.requisitionStatusId !== "3"
                    ? rowSelection
                    : null
                }
                columns={requestListColumns}
                rowKey={(record) => record.id}
                dataSource={dataById?.requisitionDetails}
                pagination={false}
                loading={loading}
                scroll={{
                  x: 1000,
                }}
              />
            )}
          </Form>
        </div>

        {isEditable && dataById?.requisitionStatusId !== "3" ? (
          <div
            className="api-management-container main-body"
            style={{ marginTop: "20px" }}
          >
            <Flex justify="space-between" align="center" wrap gap="small">
              <div>
                <h3>การไม่ยินยอมขอใช้ API</h3>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#667085",
                  }}
                >
                  เมื่อคุณไม่ยินยอมขอใช้ API ทั้งหมดนี้
                  ผู้ใช้งานจะไม่มีสามารถใช้ API เหล่านี้ได้ทั้งหมด
                </p>
              </div>
              <Button
                danger
                onClick={() => setIsModalNotAllowOpen(true)}
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                ไม่ยินยอม
              </Button>
            </Flex>
          </div>
        ) : (
          <div
            className="api-management-container main-body"
            style={{ marginTop: "20px", background: "#EBEBEB" }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                margin: "0px",
              }}
            >
              เหตุผลไม่ยินยอมขอใช้ API
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                margin: "0px",
                color: "#667085",
              }}
            >
              {dataById?.rejectReason}
            </p>
          </div>
        )}
      </div>

      <LoadingDialog isLoading={loading} />

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        title="สำเร็จ"
        detail="ยืนยันข้อมูลสำเร็จแล้ว"
        onCancel={() => {
          // setIsModalSuccessOpen(false);
        }}
        onAfterClose={() => {
          // setIsModalSuccessOpen(false);
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title=""
        detail={errorMessage?.messageTh}
        onCancel={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
        onAfterClose={() => {}}
        onConfirm={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
      />

      <DiscardDialog
        isModalOpen={isUnsavedModalOpen}
        closable={false}
        title="ยกเลิกการเปลี่ยนแปลง"
        cancelText="ยกเลิก"
        confirmText="ยืนยัน"
        onCancel={() => {
          handleUnsavedCancel();
        }}
        onConfirm={() => {
          handleUnsavedConfirm();
          setSelect();
          setDataById();
        }}
      />

      <Modal
        title=""
        open={isModalConfirmOpen}
        footer={null}
        closable={false}
        centered
        width={550}
        style={{
          borderRadius: "16px",
          overflow: "auto",
        }}
      >
        <Flex justify="center" align="center" vertical>
          <IconSaveAllow />
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 10,
            }}
          >
            ยืนยันการยินยอมขอใช้ API
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 0,
              color: "#475467",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            คุณต้องการยินยอมการใช้ API ใช่หรือไม่?
          </span>
        </Flex>
        <br />
        <div
          style={{
            background: "#F3F9FA",
            border: "1px solid #69B1FF",
            borderRadius: "6px",
            padding: "15px",
            marginBottom: "20px",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: "16px",
              fontWeight: "600",
              color: "#101828",
            }}
          >
            API ที่ขอใช้งาน
          </p>

          {dataById?.requisitionDetails
            .filter((item) => selectedRowKeys.includes(item.id))
            .map((item) => (
              <p
                key={item.id}
                style={{
                  margin: 0,
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#667085",
                }}
              >
                • {item.dataSourceName}
              </p>
            ))}
        </div>
        {dataById?.requisitionDetails.length !== selectedRowKeys.length && (
          <>
            <div
              style={{
                background: "#F3F3F3",
                border: "1px solid #BCC2CB",
                borderRadius: "6px",
                padding: "15px",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#101828",
                }}
              >
                API ที่ไม่ยินยอมขอใช้งาน
              </span>

              {dataById?.requisitionDetails
                .filter((item) => !selectedRowKeys.includes(item.id))
                .map((item) => (
                  <p
                    key={item.id}
                    style={{
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#667085",
                    }}
                  >
                    • {item.dataSourceName}
                  </p>
                ))}
            </div>
            <br />
            <Form form={formAllow} onFinish={onFinishAllow} layout="vertical">
              <Form.Item
                label={
                  <span style={{ fontSize: "16px", fontWeight: "600" }}>
                    เหตุผลไม่ยินยอมขอใช้ API{" "}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="rejectReason"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกรายละเอียด!",
                  },
                ]}
                required={false}
              >
                <TextArea rows={4} placeholder="รายละเอียด" />
              </Form.Item>
            </Form>
          </>
        )}
        <Flex justify="center" align="center" vertical>
          <Space style={{ marginTop: 20 }}>
            <Button
              type="primary"
              // ghost={true}
              style={{
                width: 135,
                height: 40,
                // border: "1px solid #D0D5DD",
                color: "#2E323A",
                backgroundColor: "#EBEBEB",
                fontWeight: 500,
                fontSize: 16,
              }}
              onClick={() => {
                formAllow.resetFields();
                setIsModalConfirmOpen(false);
              }}
            >
              ยกเลิก
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (
                  dataById?.requisitionDetails.length !== selectedRowKeys.length
                ) {
                  formAllow.submit();
                } else {
                  form.submit();
                }
              }}
              style={{
                width: 135,
                height: 40,
                backgroundColor: "#63E1E1",
                color: "#2E323A",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              ยืนยัน
            </Button>
          </Space>
        </Flex>
      </Modal>

      <Modal
        title=""
        open={isModalNotAllowOpen}
        footer={null}
        closable={false}
        centered
        width={550}
        style={{
          borderRadius: "16px",
          overflow: "auto",
        }}
      >
        <Flex justify="center" align="center" vertical>
          <IconNotAllow />
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 10,
            }}
          >
            ยืนยัน ไม่ยินยอมขอใช้ API ทั้งหมด
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 0,
              color: "#475467",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            คุณต้องการ ไม่ให้ยินยอมการใช้ API ใช่หรือไม่?
          </span>
        </Flex>
        <br />
        <Form form={formNotAllow} onFinish={onFinishNotAllow} layout="vertical">
          <Form.Item
            label={
              <span style={{ fontSize: "16px", fontWeight: "600" }}>
                เหตุผลไม่ยินยอมขอใช้ API <span style={{ color: "red" }}>*</span>
              </span>
            }
            name="rejectReason"
            rules={[
              {
                required: true,
                message: "กรุณากรอกรายละเอียด!",
              },
            ]}
            required={false}
          >
            <TextArea rows={4} placeholder="รายละเอียด" />
          </Form.Item>
        </Form>
        <Flex justify="center" align="center" vertical>
          <Space style={{ marginTop: 20 }}>
            <Button
              type="primary"
              // ghost={true}
              style={{
                width: 135,
                height: 40,
                // border: "1px solid #D0D5DD",
                color: "#2E323A",
                backgroundColor: "#EBEBEB",
                fontWeight: 500,
                fontSize: 16,
              }}
              onClick={() => {
                formNotAllow.resetFields();
                setIsModalNotAllowOpen(false);
              }}
            >
              ยกเลิก
            </Button>
            <Button
              type="primary"
              onClick={() => {
                formNotAllow.submit();
              }}
              style={{
                width: 135,
                height: 40,
                backgroundColor: "#63E1E1",
                color: "#2E323A",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              ยืนยัน
            </Button>
          </Space>
        </Flex>
      </Modal>
    </>
  );
};

export default RequestApiManagementDetail;
