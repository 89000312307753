import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUnsavedChangesContext } from "../../../../../contexts";
import { useUnsavedChangesWarning } from "../../../../../hooks";
import "./DashboardManagement.css";
import {
  Form,
  Input,
  Switch,
  Select,
  Row,
  Col,
  Flex,
  Tag,
  message,
  Button,
  Upload,
  Image,
} from "antd";
import { RightOutlined, PlusOutlined } from "@ant-design/icons";
import { AppContext } from "./DashboardManagementDetail";
import {
  SuccessDialog,
  FailedDialog,
  ConfirmDialog,
  PinButton,
} from "../../../../../components";
import { DashboardService } from "../../../../../services/DashboardService";
import { RoleService } from "../../../../../services/RoleService";
import { TagService } from "../../../../../services/TagService";
import { DataSourceService } from "../../../../../services/DataSourceService";
import { UserService } from "../../../../../services/UserService";
import { FileService } from "../../../../../services/FileService";

import IconUpAdmin from "../../../../../assets/icon/icon_up_admin";
import IconDownAdmin from "../../../../../assets/icon/icon_down_admin";
import IconBinAdmin from "../../../../../assets/icon/icon_bin_admin";
import IconUpAdminDisable from "../../../../../assets/icon/icon_up_admin_disable";
import IconDownAdminDisable from "../../../../../assets/icon/icon_down_admin_disable";
import NoData from "../../../../../assets/img/no_data.js";

import htmlEditButton from "quill-html-edit-button";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

Quill.register({
  "modules/htmlEditButton": htmlEditButton,
});

const modules = {
  toolbar: [
    { header: [1, 2, 3, 4, 5, 6, false] },
    "bold",
    "italic",
    "underline",
    "link",
    // { list: "bullet" },
    // { list: "ordered" },
  ],
  htmlEditButton: {
    debug: true,
    msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
    okText: "Ok", // Text to display in the OK button, default: Ok,
    cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
    buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
    prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
    editorModules: {}, // The default mod
  },
};

const { TextArea } = Input;
const IMAGE_ERROR =
  "https://archive.org/download/placeholder-image/placeholder-image.jpg";

const DashboardFormDetail = ({ categoryId, keyword }) => {
  const { detailList, setDetailList, isSubmit, setIsSubmit, setLoading } =
    useContext(AppContext);

  const { setIsDataModified } = useUnsavedChangesContext();
  const { handleUnsavedConfirm } = useUnsavedChangesWarning();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [roles, setRoles] = useState([]);
  const [tags, setTags] = useState([]);
  const [dashboardDataSources, setDashboardDataSources] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const categoryIdRef = useRef(null);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [fileLists, setFileLists] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    UserService.isEditable().then((res) => {
      setIsEditable(res);
      if (!res) {
        navigate(
          {
            pathname: `/management/dashboard-management`,
          },
          { state: { categoryId: categoryId } }
        );
      }
    });
  }, []);

  useEffect(() => {
    if (detailList.length) {
      if (categoryIdRef.current !== categoryId) {
        fetchTags();
        fetchRoles();
        fetchDashboardDataSources();
        categoryIdRef.current = categoryId;
      }
      initialValues(detailList);
    }
  }, [detailList]);

  useEffect(() => {
    if (isSubmit) {
      setIsModalConfirmOpen(true);
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const fetchRoles = async () => {
    try {
      const response = await RoleService.search();
      setRoles(response?.items);
    } catch (error) {
      console.error();
    }
  };

  const fetchTags = async () => {
    try {
      const response = await TagService.get();
      const sortedTags = response?.items.sort(
        (a, b) => a.sequence - b.sequence
      );
      setTags(sortedTags);
    } catch (error) {
      console.error();
    }
  };

  const fetchDashboardDataSources = async () => {
    try {
      const params = {
        isActivated: true,
      };
      const response = await DataSourceService.getIdName(params);
      setDashboardDataSources(response?.items);
    } catch (error) {
      console.error();
    }
  };

  const initialValues = async (data) => {
    const initialValues = data?.map((item) => ({
      ...item,
      dashboardTags: item.dashboardTags?.map((tag) => tag.tagId),
      dashboardPermissions: item.dashboardPermissions?.map(
        (role) => role.roleId
      ),
      dashboardDataSources: item.dashboardDataSources?.map(
        (sources) => sources.dataSourceId
      ),
    }));

    const initialFileLists = {};

    for (const [index, item] of (data || []).entries()) {
      const validFiles = [];

      for (const file of item.dashboardFiles || []) {
        if (file.fileType === "thumbnail") {
          const isValid = await checkFileUrl(file.fileSrc);
          validFiles.push(
            isValid
              ? {
                  uid: file.fileId,
                  url: file.fileSrc,
                }
              : {
                  uid: "error-image",
                  url: IMAGE_ERROR,
                }
          );
        }
      }

      initialFileLists[index] = validFiles;
    }

    setFileLists(initialFileLists);
    handleUpdateForm(initialValues);
  };

  const checkFileUrl = async (url) => {
    return new Promise((resolve) => {
      const img = document.createElement("img");

      img.onload = () => {
        resolve(true);
        img.remove();
      };

      img.onerror = () => {
        resolve(false);
        img.remove();
      };

      img.style.display = "none";
      img.src = url;
      document.body.appendChild(img);
    });
  };

  const handleUpdateForm = (data) => {
    form.setFieldsValue({ items: data });
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let data;
      if (!values || Object.keys(values).length === 0) {
        data = {
          dashboardCategoryId: categoryId,
          items: [],
        };
      } else {
        data = {
          dashboardCategoryId: categoryId,
          keywords: keyword || "",
          items: detailList.map((item) => {
            return {
              id: item.id,
              dashboardCategoryId: item.dashboardCategoryId,
              name: item.name,
              urlSlug: item.urlSlug,
              shortDescription: item.shortDescription,
              description: item.description,
              embedSrc: item.embedSrc,
              isPinned: item.isPinned,
              sequence: item.sequence,
              isActivated: item.isActivated,
              dashboardDataSources: item.dashboardDataSources?.map((item) => {
                return {
                  id: item.id,
                  dashboardId: item.dashboardId,
                  dataSourceId: item.dataSourceId,
                };
              }),
              dashboardFiles: item.dashboardFiles?.map((file) => {
                return {
                  id: file.id,
                  dashboardId: file.dashboardId,
                  fileId: file.fileId,
                  sequence: file.sequence,
                };
              }),
              dashboardPermissions: item.dashboardPermissions?.map((role) => {
                return {
                  id: role.id,
                  dashboardId: role.dashboardId,
                  roleId: role.roleId,
                };
              }),
              dashboardTags: item.dashboardTags?.map((tag) => {
                return {
                  id: tag.id,
                  dashboardId: tag.dashboardId,
                  tagId: tag.tagId,
                  sequence: tag.sequence,
                };
              }),
              insightReportDashboards: item.insightReportDashboards?.map(
                (tag) => {
                  return {
                    id: tag.id,
                    insightReportId: tag.insightReportId,
                    dashboardId: tag.dashboardId,
                  };
                }
              ),
            };
          }),
        };
      }

      const response = await DashboardService.saveBulk(data);
      setLoading(false);
      setIsModalSuccessOpen(true);
      handleUnsavedConfirm();
      setTimeout(() => {
        setIsModalSuccessOpen(false);
        navigate(
          {
            pathname: `/management/dashboard-management`,
          },
          { state: { categoryId: categoryId } }
        );
      }, 1000);
    } catch (error) {
      setLoading(false);
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    }
  };

  const moveItemDetail = (id, direction) => {
    setDetailList((prev) => {
      const items = prev;
      const index = items.findIndex((item) => item.id === id);

      if (index < 0) return prev;
      const swapIndex = index + direction;

      if (swapIndex < 0 || swapIndex >= items.length) return prev;

      [items[index].sequence, items[swapIndex].sequence] = [
        items[swapIndex].sequence,
        items[index].sequence,
      ];

      items.sort((a, b) => a.sequence - b.sequence);

      initialValues(items);
      setIsDataModified(true);

      return items;
    });
  };

  const handleChangeIsActivated = (isActivated, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            isActivated: isActivated,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeEmbedSrc = (embedSrc, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            embedSrc: embedSrc,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeTag = (selectedTagIds, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          const updatedTags = selectedTagIds.map((tagId) => {
            const tag = tags.find((tag) => tag.id === tagId);
            return {
              id:
                detail.dashboardTags?.find((t) => t.tagId === tagId)?.id || "",
              dashboardId: detail.id,
              tagId: tag.id,
              tagName: tag.name,
              tagColor: tag.color,
              sequence: tag.sequence,
            };
          });
          return {
            ...detail,
            dashboardTags: updatedTags,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeRole = (selectedRoleIds, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          const updatedRoles = selectedRoleIds.map((roleId) => {
            const role = roles.find((role) => role.id === roleId);
            return {
              id:
                detail.dashboardPermissions?.find((r) => r.roleId === roleId)
                  ?.id || "",
              roleId: role.id,
              roleName: role.name,
              dashboardId: detail.id,
            };
          });
          return {
            ...detail,
            dashboardPermissions: updatedRoles,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeSource = (selectedSourceIds, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          const updatedSources = selectedSourceIds.map((sourceId) => {
            const source = dashboardDataSources.find(
              (source) => source.id === sourceId
            );
            return {
              id:
                detail.dashboardDataSources?.find(
                  (s) => s.dataSourceId === sourceId
                )?.id || "",
              dashboardId: detail.id,
              dataSourceId: source.id,
            };
          });
          return {
            ...detail,
            dashboardDataSources: updatedSources,
          };
        }
        return detail;
      })
    );
  };

  const validateFileImage = async (file) => {
    const isImage = ["image/png", "image/jpeg"].includes(file.type);
    const isLessThan5MB = file.size / 1024 / 1024 < 5;

    if (!isImage) {
      message.error("ไฟล์ต้องเป็น .png, .jpg หรือ .jpeg เท่านั้น");
      return false;
    }

    if (!isLessThan5MB) {
      message.error("ไฟล์ต้องมีขนาดไม่เกิน 5 MB");
      return false;
    }

    return true;
  };

  const handleUploadImage = async (file, item, index) => {
    let isValidate = await validateFileImage(file);
    setLoading(isValidate);

    if (isValidate) {
      setIsDataModified(true);
      try {
        const response = await FileService.uploadFile(file, "thumbnail");
        setDetailList((prev) =>
          prev.map((detail) => {
            if (detail.id === item.id) {
              return {
                ...detail,
                thumbnailSrc: response.srcUrl,
                dashboardFiles: [
                  ...detail.dashboardFiles,
                  {
                    id: "",
                    dashboardId: detail.id,
                    fileId: response.id,
                    fileName: response.name,
                    fileRawName: response.rawName,
                    fileSrc: response.srcUrl,
                    fileType: response.fileType,
                    fileSequence: 0,
                  },
                ],
              };
            }
            return detail;
          })
        );

        setLoading(false);
      } catch (error) {
        console.error();
        // message.error("เกิดข้อผิดพลาด กรุณาลองอีกครั้ง");
        setLoading(false);
        setIsModalFailedOpen(true);
        setErrorMessage(error?.response?.data);
      }
    }
  };

  const handleRemoveImage = (thumbnailSrc, item, index) => {
    setIsDataModified(true);
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            thumbnailSrc: "",
            dashboardFiles: detail.dashboardFiles.filter(
              (file) => file.fileSrc !== thumbnailSrc
            ),
          };
        }
        return detail;
      })
    );
  };

  const handleChangeName = (name, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            name: name,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeUrlSlug = (urlSlug, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            urlSlug: urlSlug,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeShortDescription = (shortDescription, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            shortDescription: shortDescription,
          };
        }
        return detail;
      })
    );
  };

  const handleChangeDescription = (value, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            description: value,
          };
        }
        return detail;
      })
    );
  };

  const handleUpdatePinned = (isPinned, item) => {
    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            isPinned: isPinned,
          };
        }
        return detail;
      })
    );
  };

  const handleDelete = (item) => {
    setDetailList((prev) =>
      prev
        .filter((detail) => detail.id !== item.id)
        .map((item, index) => ({
          ...item,
          sequence: index + 1,
        }))
    );
    setIsDataModified(true);
  };

  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const getDetailLength = (name) => {
    const currentValue =
      form.getFieldValue(["items", name, "description"]) || "";
    const plainText = currentValue?.replace(/<[^>]+>/g, "");
    return plainText.length;
  };

  const createTagRender = (action) => (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        icon={<PlusOutlined style={{ color: "#00000073" }} />}
        color={"#F8F8F9"}
        onMouseDown={onPreventMouseDown}
        closable={false}
        onClick={() => {
          switch (action) {
            case "tag":
              navigate("/management/edit-tag");
              break;
            case "role":
              navigate("/management/role-management");
              break;
            case "source":
              navigate("/management/datasource-management");
              break;
          }
        }}
        style={{
          marginInlineEnd: 4,
          color: "#000000D9",
          fontSize: 12,
          fontWeight: 600,
          borderColor: "#BCC2CB",
          borderStyle: "dashed",
          cursor: "pointer",
        }}
      >
        {label}
      </Tag>
    );
  };

  const validateUrlSlug = (_, value) => {
    if (value && /\s/.test(value)) {
      return Promise.reject(
        new Error(`กรุณาใช้ "-" หรือ "_" แทนช่องว่างระหว่างคำ!`)
      );
    } else if (value && !/^[a-zA-Z0-9-_!@#$%^&*()]+$/.test(value)) {
      return Promise.reject(new Error(`กรุณากรอกเฉพาะภาษาอังกฤษ!`));
    }
    return Promise.resolve();
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        name="dynamic_form_complex"
        onFinish={onFinish}
        initialValues={{ items: [{}] }}
        onValuesChange={(changedValues, allValues) => {
          setIsDataModified(true);
        }}
        onFinishFailed={({ errorFields }) => {
          messageApi.open({
            type: "error",
            content: "กรุณากรอกข้อมูลให้สมบูรณ์",
          });
        }}
        layout="vertical"
        style={{ height: "100%" }}
      >
        {detailList?.length !== 0 ? (
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className="content-card-main-dashboard">
                    <Row
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginRight: "0px",
                      }}
                    >
                      <div style={{ width: "60px" }} span={2}>
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Row>
                            <Col>
                              <Row style={{ marginBottom: 15 }}>
                                {key === 0 || !isEditable ? (
                                  <IconUpAdminDisable />
                                ) : (
                                  <IconUpAdmin
                                    onClick={() => {
                                      setTimeout(() => {
                                        const item = form.getFieldValue([
                                          "items",
                                          name,
                                        ]);
                                        moveItemDetail(item.id, -1);
                                      }, 200);
                                    }}
                                  />
                                )}
                              </Row>
                              <Row>
                                {detailList.length === key + 1 ||
                                !isEditable ? (
                                  <IconDownAdminDisable />
                                ) : (
                                  <IconDownAdmin
                                    onClick={() => {
                                      setTimeout(() => {
                                        const item = form.getFieldValue([
                                          "items",
                                          name,
                                        ]);
                                        moveItemDetail(item.id, 1);
                                      }, 200);
                                    }}
                                  />
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div style={{ width: "90%" }} span={22}>
                        <div
                          style={{
                            width: "100%",
                            // height: "100%",
                            marginLeft: 0,
                          }}
                        >
                          <Col span={24}>
                            <Row style={{}}>
                              <Col span={1}>
                                <div className="border-left-dashboard"></div>
                              </Col>
                              <Col span={23}>
                                <Flex justify="end" align="center">
                                  <PinButton
                                    onClick={() => {
                                      const item = form.getFieldValue([
                                        "items",
                                        name,
                                      ]);
                                      handleUpdatePinned(!item?.isPinned, item);
                                    }}
                                    isPinned={
                                      form.getFieldValue(["items", name])
                                        ?.isPinned
                                    }
                                  />
                                  {!form.getFieldValue([
                                    "items",
                                    name,
                                    "isPreserved",
                                  ]) && (
                                    <IconBinAdmin
                                      onClick={() => {
                                        const item = form.getFieldValue([
                                          "items",
                                          name,
                                        ]);
                                        handleDelete(item);
                                      }}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    />
                                  )}
                                </Flex>
                                <Row>
                                  <div className="border-bottom"></div>
                                </Row>

                                <Form.Item
                                  {...restField}
                                  name={[name, "id"]}
                                  noStyle
                                >
                                  <Input type="hidden" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "dashboardCategoryId"]}
                                  noStyle
                                >
                                  <Input type="hidden" />
                                </Form.Item>

                                <Row style={{ marginLeft: 20 }}>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "dashboardCategoryName"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          ประเภทแดซบอร์ด
                                        </p>
                                      }
                                    >
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "500",
                                          color: "#667085",
                                        }}
                                      >
                                        {
                                          form.getFieldValue(["items", name])
                                            ?.dashboardCategoryName
                                        }
                                      </span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "isActivated"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">ใช้งาน</p>
                                      }
                                    >
                                      <Switch
                                        style={{
                                          marginTop: 10,
                                        }}
                                        defaultChecked
                                        onChange={(isActivated) => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleChangeIsActivated(
                                            isActivated,
                                            item
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row style={{ marginLeft: 20 }}>
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "embedSrc"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          URL แดชบอร์ดต้นทาง{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                        {
                                          pattern:
                                            /^https?:\/\/[^\s/$.?#].[^\s]*$/,
                                          message: "กรุณากรอก URL ให้ถูกต้อง",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Input
                                        onBlur={(e) => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleChangeEmbedSrc(
                                            e.target.value,
                                            item
                                          );
                                        }}
                                        placeholder="ชื่อแหล่งที่มาของข้อมูล"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={
                                        tags.length
                                          ? [name, "dashboardTags"]
                                          : ""
                                      }
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          Tag{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Select
                                        mode="multiple"
                                        // allowClear
                                        style={{ width: "100%" }}
                                        tagRender={
                                          tags.length
                                            ? ""
                                            : createTagRender("tag")
                                        }
                                        defaultValue={
                                          tags.length ? [] : ["New Tag"]
                                        }
                                        onChange={(selectedTagIds) => {
                                          if (tags.length) {
                                            const item = form.getFieldValue([
                                              "items",
                                              name,
                                            ]);
                                            handleChangeTag(
                                              selectedTagIds,
                                              item
                                            );
                                          }
                                        }}
                                        options={tags?.map((tag) => ({
                                          label: tag.name,
                                          value: tag.id,
                                        }))}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row style={{ marginLeft: 20 }}>
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={
                                        roles.length
                                          ? [name, "dashboardPermissions"]
                                          : ""
                                      }
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          เลือก Role ที่ต้องการแสดง{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        tagRender={
                                          roles.length
                                            ? ""
                                            : createTagRender("role")
                                        }
                                        defaultValue={
                                          roles.length ? [] : ["New Role"]
                                        }
                                        onChange={(selectedRoleIds) => {
                                          if (roles.length) {
                                            const item = form.getFieldValue([
                                              "items",
                                              name,
                                            ]);
                                            handleChangeRole(
                                              selectedRoleIds,
                                              item
                                            );
                                          }
                                        }}
                                        options={roles?.map((role) => ({
                                          label: role.nameTh,
                                          value: role.id,
                                        }))}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={
                                        dashboardDataSources.length
                                          ? [name, "dashboardDataSources"]
                                          : ""
                                      }
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          เลือกดาต้าแคตตาล็อกที่ต้องการเชื่อมกับแดชบอร์ด{" "}
                                          {/* <span
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          *
                                        </span> */}
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: false,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Select
                                        mode="multiple"
                                        // allowClear
                                        style={{ width: "100%" }}
                                        tagRender={
                                          dashboardDataSources.length
                                            ? ""
                                            : createTagRender("source")
                                        }
                                        defaultValue={
                                          dashboardDataSources.length
                                            ? []
                                            : ["New DataSources"]
                                        }
                                        onChange={(selectedSourceIds) => {
                                          if (tags.length) {
                                            const item = form.getFieldValue([
                                              "items",
                                              name,
                                            ]);
                                            handleChangeSource(
                                              selectedSourceIds,
                                              item
                                            );
                                          }
                                        }}
                                        options={dashboardDataSources?.map(
                                          (dashboardDataSource) => ({
                                            label: dashboardDataSource.name,
                                            value: dashboardDataSource.id,
                                          })
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "thumbnailSrc"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          อัปโหลดภาพ{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <span className="text-upload">
                                        *สามารถอัปโหลดไฟล์ได้ 1 ไฟล์/ครั้ง
                                        โดยแต่ละไฟล์ต้องมีขนาดไม่เกิน 5 MB
                                        (เฉพาะไฟล์ .png .jpg และ .jpeg เท่านั้น)
                                      </span>
                                      <p></p>
                                      <div
                                        style={{
                                          height: "110px",
                                        }}
                                      >
                                        <Upload
                                          listType="picture-card"
                                          maxCount={1}
                                          accept=".png,.jpg,.jpeg"
                                          fileList={fileLists[name] || []}
                                          onPreview={(file) => {
                                            handlePreview(file);
                                          }}
                                          customRequest={({
                                            file,
                                            onSuccess,
                                            onError,
                                          }) => {
                                            const item = form.getFieldValue([
                                              "items",
                                              name,
                                            ]);
                                            handleUploadImage(file, item, name);
                                          }}
                                          onRemove={(file) => {
                                            const item = form.getFieldValue([
                                              "items",
                                              name,
                                            ]);
                                            handleRemoveImage(
                                              item.thumbnailSrc,
                                              item,
                                              name
                                            );
                                          }}
                                        >
                                          {!form.getFieldValue([
                                            "items",
                                            name,
                                            "thumbnailSrc",
                                          ]) && (
                                            <button
                                              style={{
                                                border: 0,
                                                background: "none",
                                                margin: 0,
                                              }}
                                              type="button"
                                            >
                                              <PlusOutlined
                                                style={{ color: "#000000E0" }}
                                              />
                                              <div
                                                style={{
                                                  marginTop: 8,
                                                  color: "#D9D9D9",
                                                }}
                                              >
                                                อัปโหลด
                                              </div>
                                            </button>
                                          )}
                                        </Upload>
                                      </div>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "name"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          ชื่อแดซบอร์ด{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Input
                                        showCount
                                        maxLength={100}
                                        onBlur={(e) => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleChangeName(
                                            e.target.value,
                                            item
                                          );
                                        }}
                                        placeholder="ชื่อแดซบอร์ด"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "urlSlug"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          URL แดซบอร์ด{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                        {
                                          validator: validateUrlSlug,
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Input
                                        addonBefore={
                                          window.location.origin + "/dashboard/"
                                        }
                                        showCount
                                        maxLength={100}
                                        onBlur={(e) => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleChangeUrlSlug(
                                            e.target.value,
                                            item
                                          );
                                        }}
                                        placeholder="URL แดซบอร์ด"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "shortDescription"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          รายละเอียดโดยย่อ{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "จำเป็นต้องกรอกข้อมูล",
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <TextArea
                                        showCount
                                        maxLength={100}
                                        placeholder="รายละเอียดโดยย่อ"
                                        onBlur={(e) => {
                                          const item = form.getFieldValue([
                                            "items",
                                            name,
                                          ]);
                                          handleChangeShortDescription(
                                            e.target.value,
                                            item
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginLeft: 20,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "description"]}
                                      layout="vertical"
                                      label={
                                        <p className="label-admin">
                                          รายละเอียด{" "}
                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            *
                                          </span>
                                        </p>
                                      }
                                      colon={false}
                                      required={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: (
                                            <p
                                              style={{
                                                position: "relative",
                                                top: -38,
                                              }}
                                            >
                                              จำเป็นต้องกรอกข้อมูล
                                            </p>
                                          ),
                                        },
                                        {
                                          validator: (_, value) => {
                                            const plainText = value
                                              ? value.replace(/<[^>]+>/g, "")
                                              : "";
                                            if (plainText.length > 1000) {
                                              return Promise.reject(
                                                <p
                                                  style={{
                                                    position: "relative",
                                                    top: -38,
                                                  }}
                                                >
                                                  ข้อความต้องไม่เกิน 1000
                                                  ตัวอักษร
                                                </p>
                                              );
                                            }
                                            if (
                                              value &&
                                              plainText.length === 0
                                            ) {
                                              return Promise.reject(
                                                <p
                                                  style={{
                                                    position: "relative",
                                                    top: -38,
                                                  }}
                                                >
                                                  จำเป็นต้องกรอกข้อมูล
                                                </p>
                                              );
                                            }
                                            return Promise.resolve();
                                          },
                                        },
                                      ]}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      <Flex
                                        vertical={true}
                                        gap="small"
                                        justify="flex-end"
                                        align="flex-end"
                                      >
                                        <ReactQuill
                                          className="react-quill"
                                          theme="snow"
                                          value={form.getFieldValue([
                                            "items",
                                            name,
                                            "description",
                                          ])}
                                          modules={modules}
                                          style={{
                                            height: 145,
                                            width: "100%",
                                          }}
                                          onChange={(value) => {
                                            form.setFieldsValue({
                                              ["items"]: {
                                                [name]: {
                                                  description: value,
                                                },
                                              },
                                            });
                                            form.validateFields([
                                              ["items", name, "description"],
                                            ]);
                                            const item = form.getFieldValue([
                                              "items",
                                              name,
                                            ]);
                                            handleChangeDescription(
                                              value,
                                              item
                                            );
                                          }}
                                        />
                                        <span
                                          style={{
                                            color: "#9ca0a6",
                                            marginTop: 40,
                                            position: "relative",
                                          }}
                                        >
                                          {getDetailLength(name)}
                                          /1000
                                        </span>
                                      </Flex>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      </div>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        ) : (
          <Flex
            vertical
            justify="center"
            align="center"
            style={{
              height: "calc(100vh - 282px)",
              background: "#fff",
              borderRadius: "8px",
            }}
          >
            <NoData />
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#667085",
              }}
            >
              ไม่มีข้อมูล
            </p>
          </Flex>
        )}
      </Form>

      <Image
        wrapperStyle={{
          display: "none",
        }}
        src={previewImage}
        preview={{
          visible: previewOpen,
          onVisibleChange: (visible) => setPreviewOpen(visible),
          afterOpenChange: (visible) => !visible && setPreviewImage(""),
        }}
      />

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        title="สำเร็จ"
        detail="บันทึกข้อมูลสำเร็จ"
        onCancel={() => {}}
        onAfterClose={() => {}}
      />

      <ConfirmDialog
        isModalOpen={isModalConfirmOpen}
        closable={false}
        title="ยืนยันการบันทึก"
        description="คุณต้องการบันทึกใช่หรือไม่?"
        cancelText="ยกเลิก"
        confirmText="บันทึก"
        onCancel={() => {
          setIsModalConfirmOpen(false);
        }}
        onConfirm={() => {
          form.submit();
          setIsModalConfirmOpen(false);
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title=""
        detail={errorMessage?.messageTh}
        onCancel={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
        onAfterClose={() => {}}
        onConfirm={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
      />
    </>
  );
};

export default DashboardFormDetail;
