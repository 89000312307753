import React, { useEffect, useState } from 'react'
import "./ChangePassword.css";
import { Col, Form, Input, Row } from 'antd';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import CustomButton from '../../../../../components/Button/CustomButton';
import { UserService } from "../../../../../services/UserService";
import {FailedDialog, SuccessDialog, LoadingDialog, DiscardDialog} from '../../../../../components';
import { useUnsavedChangesContext } from "../../../../../contexts";
import { useUnsavedChangesWarning } from "../../../../../hooks";


const ChangePassword = () => {
    const { setIsDataModified } = useUnsavedChangesContext();
    const {
        handleUnsavedConfirm,
    } = useUnsavedChangesWarning();
    const [isLoading, setIsLoading] = useState(false);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [modalSuccessStr, setModalSuccessStr] = useState({
        title:"",
        detail:"",
    });
    const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
    const [modalFailedStr, setModalFailedStr] = useState({
        title:"",
        detail:"",
    });
    const [data, setData] = useState();
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [form] = Form.useForm();
    const [isValid, setIsValid] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        specialChar: false,
    });
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword:'',
      });

    useEffect(() => {
        if (!data) {
            fetchData();
        }
    },[data]);

    const fetchData = async () => {
        try {
            const response = await UserService.getMe();
            setData(response);
            //console.log("changepassword >> fetchData > response = ",response)
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e) => {
    // setIsDataModified(true);
    const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handlePasswordChange = (e) => {
        // setIsDataModified(true);
        const newPassword = e.target.value;
        // Validate password rules
        setIsValid({
            length: newPassword.length >= 8 && newPassword.length <= 20,
            uppercase: /[A-Z]/.test(newPassword),
            lowercase: /[a-z]/.test(newPassword),
            digit: /\d/.test(newPassword),
            specialChar: /[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|]/.test(newPassword),
        });
    };  

    const handleSave = async () => {
        setIsLoading(true);
        //console.log("handleSave ==>> formData = ",formData)
        try {
          let payload ={
            "email": data.email,
            "userId": data.id,
            "oldPassword": formData.oldPassword,
            "newPassword": formData.newPassword
          }
          //console.log("payload", payload);
          const response = await UserService.changePasswordUser(payload);

          //console.log("change-password updated successfully", response);
          setModalSuccessStr({
            title:"เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
            detail:'มีผลเมื่อคุณเข้าสู่ระบบครั้งถัดไป',
          });
          setIsModalSuccessOpen(true)
          setIsLoading(false);
          handleUnsavedConfirm();
          setTimeout(() => {
            setIsModalSuccessOpen(false);
          }, 3000);
          
        } catch (error) {
          console.error("Error updating change-password", error);
          console.error("Error updating change-password error.response.data.message", error.response.data.message);
          setModalFailedStr({
            title:"ไม่สำเร็จ",
            detail:error.response.data.message.messageTh,
          });
          setIsLoading(false);
          setIsModalFailedOpen(true);
          

        }
      };

  return (
    <>
    <div className="change-password-body main-body" >
      <h3>เปลี่ยนรหัสผ่าน</h3>
      <hr/>
      <div className='change-password-content'>
        <Row>
            <Col sm={24} md={16} lg={9} xl={8} xxl={6}>
                <Form
                    form={form}
                    name="change-password-form"
                    className="change-password-form"
                    layout='vertical'
                    
                    // initialValues={{ remember: true }}
                >
                    <Form.Item
                        name="itemOldPassword"
                        label={<span className='label'> รหัสผ่านเก่า</span>}
                        rules={[{ required: true, message: 'กรุณากรอกรหัสผ่านปัจจุบัน' }]}
                        required={false}
                    >
                        <Input.Password name='oldPassword' placeholder="รหัสผ่านเก่า" value={formData.oldPassword} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item
                        name="itemNewPassword"
                        label={<span className='label'> รหัสผ่านใหม่</span>}
                        rules={[{ required: true, message: 'กรุณากรอกรหัสผ่านใหม่' }]}
                        required={false}
                        onChange={handlePasswordChange}
                    >
                        <Input.Password name='newPassword' placeholder="รหัสผ่านใหม่" value={formData.oldPassword} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item
                        name="itemConfirmPassword"
                        label={<span className='label'> ยืนยันรหัสผ่านใหม่</span>}
                        rules={[{ required: true, message: 'กรุณากรอกรหัสผ่านใหม่' },
                                ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('itemNewPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'));
                                        },
                                    })
                                ]}
                        required={false}
                    >
                        <Input.Password name='confirmPassword' placeholder="ยืนยันรหัสผ่านใหม่" onChange={handleChange} />
                    </Form.Item>
                    
                    
                </Form>
                   
                
                    <div className='valid-checklist' >
                        <div style={{ display: 'flex', alignItems: 'center',alignItems:"flex-start" }}>
                            <span>
                            <CheckCircleIcon
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    color: isValid.length ? '#1ac487' : 'gray',
                                }}
                            />
                            </span>
                            
                            <span style={{ color: isValid.length ? '#1ac487' : 'gray', marginLeft: '8px' }}>ความยาวรหัสผ่านต้องมีอย่างน้อย 8 - 20 ตัวอักษร</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' ,alignItems:"flex-start" }}>
                            <span>
                            <CheckCircleIcon
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    color: isValid.uppercase ? '#1ac487' : 'gray',
                                    alignSelf:"center"
                                }}
                            />
                            </span>
                            
                            <span style={{ color: isValid.uppercase ? '#1ac487' : 'gray', marginLeft: '8px' }}>ต้องมีตัวอักษรพิมพ์ใหญ่</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' ,alignItems:"flex-start"}}>
                            <span>
                            <CheckCircleIcon
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    color: isValid.lowercase ? '#1ac487' : 'gray',
                                }}
                            />
                            </span>
                            
                            <span style={{ color: isValid.lowercase ? '#1ac487' : 'gray', marginLeft: '8px' }}>ต้องมีตัวอักษรพิมพ์เล็ก</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center',alignItems:"flex-start" }}>
                            <span>
                            <CheckCircleIcon
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    color: isValid.digit ? '#1ac487' : 'gray',
                                }}
                            />
                            </span>
                            <span style={{ color: isValid.digit ? '#1ac487' : 'gray', marginLeft: '8px' }}>ต้องมีหมายเลข (ตัวเลข)</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center',alignItems:"flex-start" }}>
                            <span>
                            <CheckCircleIcon
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    color: isValid.specialChar ? '#1ac487' : 'gray',
                                }}
                            />
                            </span>
                           
                            <span style={{ color: isValid.specialChar ? '#1ac487' : 'gray', marginLeft: '8px' }}>ต้องมีอักขระพิเศษ [*.!@#&amp;$%^&amp;(){ }[]:;&lt;&gt;,.?/~_+-=|]</span>
                        </div>
                    </div>


                <CustomButton type="primary" onClick={()=> handleSave() } width={"100%"} text={"เปลี่ยนรหัสผ่าน"} className="change-password-button" disabled={!Object.values(isValid).every(Boolean) || !formData.oldPassword || (formData.newPassword != formData.confirmPassword)} />
                    
                                
                            
            </Col>
        </Row>
      </div>
    </div>
    <LoadingDialog isLoading={isLoading} />
    <SuccessDialog
            isModalOpen={isModalSuccessOpen}
            closable={false}
            title={modalSuccessStr.title}
            detail={modalSuccessStr.detail}
            onCancel={() => {
            }}
            onAfterClose={() => {
                //handleLogout()
                //alert("Dialog dissmiss");
            }}
        />
      <FailedDialog 
            isModalOpen={isModalFailedOpen}
            closable={false}
            title={modalFailedStr.title}
            detail={modalFailedStr.detail}
            onCancel={() => {
                setIsModalFailedOpen(false);
            }}
            onAfterClose={() => {
                setIsModalFailedOpen(false);
            }}
            onConfirm={() => {
                setIsModalFailedOpen(false);
            }}
        />
  </>
  )
}

export default ChangePassword