import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Collapse,
  Button,
  Input,
  Modal,
  Flex,
  Form,
  Select,
  Grid,
} from "antd";
import "./AccountManagement.css";
import { FaUserCircle } from "react-icons/fa";
import { UserService } from "../../../../../services/UserService";
import CustomButton from "../../../../../components/Button/CustomButton";
import IconConWarningGray from "../../../../../assets/icon/icon_warning_gray_dialog";
import SuccessDialog from "../../../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../../../components/Dialog/FailedDialog";
import { handleLogout } from "../../../../../keycloak/KeycloakAuth";
import { LinkOutlined, UserOutlined } from "@ant-design/icons";
import {
  ConfirmDialog,
  LoadingDialog,
  DiscardDialog,
} from "../../../../../components";
import { useUnsavedChangesContext } from "../../../../../contexts";
import { useUnsavedChangesWarning } from "../../../../../hooks";

const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const AccountManagement = ({}) => {
  const { isDataModified, setIsDataModified } = useUnsavedChangesContext();
  const {
    handleUnsavedConfirm,
    handleUnsavedCancel,
    setIsUnsavedModalOpen,
    isUnsavedModalOpen,
  } = useUnsavedChangesWarning();
  const screens = useBreakpoint();
  const [isLoading, setIsLoading] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [existingJobFunctions, setExistingJobFunctions] = useState("");
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [modalSuccessStr, setModalSuccessStr] = useState({
    title: "",
    detail: "",
  });
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [modalFailedStr, setModalFailedStr] = useState({
    title: "",
    detail: "",
  });
  const [data, setData] = useState();
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [isConfirmUnLinkedModalOpen, setIsConfirmUnLinkedModalOpen] =
    useState(false);
  const [unlinkedIdp, setUnlinkedIdp] = useState(null);
  const [editMode, setEditMode] = useState(false); // State to toggle edit mode
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    position: "",
    jobFunction: "",
  });
  const [jobFunctions, setJobFunctions] = useState([]);
  const [confirmEmail, setConfirmEmail] = useState("");
  var localJobFunctions = [];
  const [errorMessage, setErrorMessage] = useState("");
  const [otherJobFunction, setOtherJobFunction] = useState("");

  useEffect(() => {
    fetchJobFunctions().then(() => {
      fetchData();
    });
  }, []);

  const fetchJobFunctions = async () => {
    try {
      const response = await UserService.getJob();
      setJobFunctions(response); // Set job functions from API response
      localJobFunctions = response;
      setFormData({ jobFunction: response[0] || "" }); // Default to first job function if available
    } catch (error) {
      console.error("Error fetching job functions:", error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await UserService.getMe(true);
      console.log("response", response);
      setData(response);
      setFormData({
        id: response?.id || "",
        firstName: response?.firstName || "",
        lastName: response?.lastName || "",
        email: response?.email || "",
        tel: response?.tel || "",
        position: response?.position || "",
        jobFunction: response?.jobFunction || "",
        userSources: response?.userSources || [],
        idp: response?.idp || "",
      });
      const jobFunction = response?.jobFunction || "";
      setExistingJobFunctions(jobFunction);
      // setIsOther(!localJobFunctions.includes(jobFunction));
    } catch (error) {
      console.error("เกิดข้อผิดพลาดขณะดึงข้อมูลผู้ใช้:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "jobFunction") {
      setIsOther(value === "Other"); // ตั้งค่า isOther เมื่อเลือก "Other"
      if (value !== "Other") {
        setOtherJobFunction(""); // ล้างค่าฟิลด์ otherJobFunction หากเปลี่ยนกลับไปเลือกตัวเลือกอื่น
      }
    }

    if (name === "otherJobFunction") {
      setOtherJobFunction(value); // เก็บค่าที่กรอกใน otherJobFunction
    }

    if (name === "tel") {
      const numericValue = value.replace(/[^0-9]/g, ""); // กรองเฉพาะตัวเลข
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDisconnect = async (source) => {
    setIsLoading(true);
    try {
      await UserService.disconnectUserSource(formData.id, source.provider);
      let userMe = await UserService.getMe();
      sessionStorage.setItem("userMe", JSON.stringify(userMe));
      setData(userMe);
      setIsModalSuccessOpen(true);
      setModalSuccessStr({
        title: "สำเร็จ",
        detail: "ยกเลิกการเชื่อมต่อบัญชีสำเร็จ",
      });
      setTimeout(() => {
        setIsModalSuccessOpen(false);
      }, 3000);
    } catch (error) {
      console.error("Error disconnecting user source", error);
      setIsModalFailedOpen(true);
      setModalFailedStr({
        title: "ไม่สำเร็จ",
        detail: error.response?.data?.message?.messageTh ?? error.message,
      });
      setTimeout(() => {
        setIsModalFailedOpen(false);
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (isOther && !otherJobFunction) {
        setErrorMessage("โปรดระบุข้อมูลนี้"); // แสดงข้อความข้อผิดพลาด
        setIsLoading(false);
        return; // หยุดการทำงานหากข้อมูลไม่ครบ
      }

      const jobFunction = isOther ? otherJobFunction : formData.jobFunction;
      let data = {
        id: formData.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        attributes: {
          typeOfIndustries: jobFunction ? [jobFunction] : [],
          company: [formData.company],
          position: [formData.position],
          tel: [formData.tel],
        },
      };
      console.log("ข้อมูลที่กำลังจะบันทึก:", data);
      await UserService.updateUser(data.id, data);
      let userMe = await UserService.getMe(true);
      sessionStorage.setItem("userMe", JSON.stringify(userMe));
      setEditMode(false);
      fetchData();

      setIsModalSuccessOpen(true);
      handleUnsavedConfirm();
      setModalSuccessStr({
        title: "สำเร็จ",
        detail: "บันทึกข้อมูลสำเร็จ",
      });
      setTimeout(() => {
        setIsModalSuccessOpen(false);
      }, 3000);
    } catch (error) {
      console.error("Error updating profile", error);
      setIsModalFailedOpen(true);
      setModalFailedStr({
        title: "ไม่สำเร็จ",
        detail: error.response?.data?.message?.messageTh || "เกิดข้อผิดพลาด",
      });
      setTimeout(() => {
        setIsModalFailedOpen(false);
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    ////console.log("handleDelete")
    setIsLoading(true);
    try {
      const response = await UserService.deleteUser(data.id);
      setModalSuccessStr({
        title: "สำเร็จ",
        detail: "ลบบัญชีของคุณสำเร็จแล้ว",
      });
      setIsModalSuccessOpen(true);
      setIsLoading(false);
      handleLogout();
      setTimeout(() => {
        setIsModalSuccessOpen(false);
        sessionStorage.clear();
        window.location.href = "/";
        //window.location.reload()
        //handleLogin()
      }, 3000);

      //console.log("Delete Profile successfully", response);
    } catch (error) {
      console.error("Error Delete profile", error);
      setModalFailedStr({
        title: "ไม่สำเร็จ",
        detail: error.response.data.message.messageTh,
      });
      setIsLoading(false);
      setIsModalFailedOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const clickDelete = () => {
    setIsConfirmDeleteModalOpen(true);
  };

  return (
    <div className="profile-body main-body">
      <p style={{ fontSize: "20px", fontWeight: "600" }}>บัญชีของคุณ</p>
      <div className="color-card">
        <Row>
          <Col xs={24} sm={5} lg={12}>
            <div className="color-card-img ">
              <FaUserCircle fontSize={116} className="img-icon" />
            </div>
          </Col>
          <Col xs={24} sm={19} lg={12}>
            {!screens.xs && (
              <div className="color-card-action">
                {editMode ? (
                  <div>
                    <Row gutter={8}>
                      <Col xs={24} sm={24} lg={24}>
                        <CustomButton
                          text={"ยกเลิก"}
                          height={40}
                          fontSize={16}
                          fontWeight={500}
                          borderRadius={10}
                          onClick={() => {
                            if (isDataModified) {
                              setIsUnsavedModalOpen(true);
                            } else {
                              setEditMode(false);
                              fetchData();
                            }
                          }}
                        />
                      
                        <CustomButton
                          type={"primary"}
                          text={"บันทึก"}
                          height={40}
                          fontSize={16}
                          fontWeight={500}
                          borderRadius={10}
                          onClick={() => handleSave()}
                        />
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    {!data?.type && (
                      <CustomButton
                        text={"แก้ไข"}
                        height={40}
                        fontSize={16}
                        fontWeight={500}
                        borderRadius={10}
                        onClick={() => setEditMode(true)}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </div>
      {screens.xs &&
        (editMode ? (
          <div >
            <Row>
              <Col xs={24} sm={24} lg={24} style={{ textAlign: "right" }}>
                <CustomButton
                  text={"ยกเลิก"}
                  onClick={async () => {
                    if (isDataModified) {
                      setIsUnsavedModalOpen(true);
                    } else {
                      setEditMode(false);
                    }
                  }}
                />
                <CustomButton
                  type={"primary"}
                  text={"บันทึก"}
                  onClick={() => handleSave()}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row>
              <Col  xs={24}  style={{ textAlign: "right" }}>
                {!data?.type && (
                  <CustomButton
                    text={"แก้ไข"}
                    onClick={() => setEditMode(true)}
                  />
                )}
              </Col>
            </Row>
          </div>
        ))}

      <Row>
        <Col xs={24} sm={24} lg={24}>
          <div className="profile-content">
            <Row gutter={24}>
              {editMode ? (
                <>
                  <Col xs={24} sm={12} lg={12}>
                    <h4>ชื่อ</h4>
                    <Input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <h4>นามสกุล</h4>
                    <Input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs={24} sm={24} lg={12}>
                    <h4>อีเมล์</h4>
                    <Input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      disabled
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={24} sm={12} lg={12}>
                    <h4>ชื่อ - นามสกุล</h4>
                    <p>{data?.fullName}</p>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <h4>อีเมล์</h4>
                    <p>{data?.email}</p>
                  </Col>
                </>
              )}
            </Row>
            <h4 style={{ marginTop: "0px", marginBottom: "10px" }}>
              แหล่งที่มาของบัญชี
            </h4>
            <ul style={{ listStyleType: "none", paddingLeft: 0, margin: 0 }}>
              {data?.userSources?.map((source, index) => (
                <li key={index} style={{ border: "transparent 1px solid" }}>
                  • {source.name}
                  {source.name.trim() !== process.env.REACT_APP_NAME &&
                    source.provider !== data?.idp && (
                      <button
                        style={{
                          color: "#1677FF",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "14px",
                          textDecoration: "underline",
                          padding: "0",
                          marginLeft: "0",
                          float: "right",
                        }}
                        onClick={() => {
                          setUnlinkedIdp(source);
                          setIsConfirmUnLinkedModalOpen(true);
                        }}
                      >
                        <LinkOutlined style={{ marginRight: "5px" }} />{" "}
                        ยกเลิกการเชื่อมต่อบัญชี
                      </button>
                    )}
                  {source.name.trim() === "TCEB SSO" &&
                    data?.idp === source.provider && (
                      <>
                        <button
                          style={{
                            color: "#1677FF",
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            textDecoration: "underline",
                            padding: "0",
                            marginLeft: "0",
                            float: "right",
                          }}
                          onClick={() => {
                            window.open(
                              `${process.env.REACT_APP_LOGIN_SSO_ISSUER}/account`
                            );
                          }}
                        >
                          <UserOutlined style={{ marginRight: "5px" }} />{" "}
                          แก้ไขข้อมูลผู้ใช้งาน
                        </button>
                      </>
                    )}
                </li>
              ))}
            </ul>

            <Row gutter={24}>
              <Col xs={24} sm={12} lg={12}>
                <h4>เบอร์โทรศัพท์</h4>
                {editMode ? (
                  <Input
                    type="text"
                    name="tel"
                    value={formData.tel}
                    onChange={handleChange}
                    maxLength={10}
                  />
                ) : (
                  <p style={{ lineHeight: "22px" }}>
                    {(formData.tel ?? "").length === 0
                      ? "ไม่ได้ระบุ"
                      : formData.tel ?? ""}
                  </p>
                )}
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <h4>ตำแหน่ง</h4>
                {editMode ? (
                  <Input
                    type="text"
                    name="position"
                    value={formData.position}
                    onChange={handleChange}
                  />
                ) : (
                  <p style={{ lineHeight: "22px" }}>
                    {(formData.position ?? "").length === 0
                      ? "ไม่ได้ระบุ"
                      : formData.position ?? ""}
                  </p>
                )}
              </Col>
            </Row>

            {editMode ? (
              <div>
                <Row gutter={24}>
                  <Col
                    xs={24}
                    sm={12}
                    lg={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <h4 style={{ marginBottom: "8px" }}>ประเภทงาน</h4>
                    <Select
                      name="jobFunction"
                      value={isOther ? "Other" : formData.jobFunction} // แสดง "Other" หาก isOther เป็น true
                      onChange={(value) =>
                        handleChange({ target: { name: "jobFunction", value } })
                      }
                      style={{ width: "100%" }}
                    >
                      {jobFunctions
                        .filter((jobFunction) => jobFunction !== "Other") // ลบ "Other" จาก jobFunctions ถ้ามีอยู่
                        .map((jobFunction, index) => (
                          <Select.Option key={index} value={jobFunction}>
                            {jobFunction}
                          </Select.Option>
                        ))}
                      <Select.Option key="other" value="Other">
                        Other
                      </Select.Option>
                    </Select>
                  </Col>
                  {isOther && (
                    <Col xs={24} sm={12} lg={12}>
                      <h4>
                        โปรดระบุ <span style={{ color: "red" }}>*</span>
                      </h4>
                      <Input
                        type="text"
                        name="otherJobFunction"
                        value={otherJobFunction}
                        onChange={(e) => {
                          handleChange(e); // จัดการค่าฟิลด์
                          setErrorMessage(""); // ล้างข้อความแจ้งเตือนเมื่อเริ่มกรอกข้อมูล
                        }}
                        style={{
                          borderColor: errorMessage ? "red" : undefined, // เปลี่ยนสีกรอบเป็นแดงถ้ามีข้อผิดพลาด
                        }}
                      />
                      {errorMessage && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorMessage}
                        </span>
                      )}
                    </Col>
                  )}
                </Row>
              </div>
            ) : (
              <>
                <h4>ประเภทงาน</h4>
                <p style={{ lineHeight: "22px" }}>
                  {formData.jobFunction === "Other" ? (
                    <>Other: {otherJobFunction || "ไม่ได้ระบุ"}</>
                  ) : (
                    formData.jobFunction || "ไม่ได้ระบุ"
                  )}
                </p>
              </>
            )}

            <br />
          </div>
        </Col>
      </Row>
      <div className="delete-card">
        <Row>
          <Col xs={24} sm={24} lg={24}>
            <div className="delete-card-content">
              <Collapse ghost expandIconPosition="end">
                <Panel
                  header={
                    <div>
                      <b>ลบบัญชีของคุณ</b>
                      <br />
                      การลบบัญชีจะทำให้คุณไม่สามารถเข้าถึงการตั้งค่าใดๆ ของคุณ
                      หรือลงชื่อเข้าใช้ {process.env.REACT_APP_NAME} ได้อีก
                      การกระทำนี้ไม่สามารถย้อนกลับได้
                      <br />
                      บัญชี {process.env.REACT_APP_NAME}{" "}
                      ของคุณสร้างขึ้นแล้วเมื่อ {data?.createdDateText ?? "-"}{" "}
                    </div>
                  }
                  key="1"
                >
                  <CustomButton
                    danger={true}
                    text={"ลบบัญชี"}
                    height={40}
                    fontSize={16}
                    fontWeight={500}
                    borderRadius={10}
                    onClick={() => {
                      clickDelete();
                    }}
                  />
                </Panel>
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        centered
        title=""
        open={isConfirmDeleteModalOpen}
        footer={null}
        closable={false}
        destroyOnClose={true}
        onCancel={() => {
          //onCancel();
        }}
        afterClose={(e) => {
          // onAfterClose();
        }}
      >
        <Flex justify="left" align="left" vertical>
          <IconConWarningGray
            style={{
              marginTop: 12,
            }}
          />
          <span
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
            {"คุณกำลังจะลบบัญชีของคุณ"}
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
              textAlign: "left",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `การลบบัญชีจะทำให้คุณไม่สามารถเข้าถึงการตั้งค่าใดๆ ของคุณ <br/> หรือลงชื่อเข้าใช้ ${process.env.REACT_APP_NAME} ได้อีก การกระทำนี้ไม่สามารถย้อนกลับได้`,
              }}
            />
            <hr style={{ marginTop: 20 }} />
          </span>
        </Flex>
        <Form
          //form={form}
          name="confirm-email-form"
          className="confirm-email-form"
          layout="vertical"

          // initialValues={{ remember: true }}
        >
          <Form.Item
            name="itemConfirmEmail"
            label={
              <span className="label" style={{ fontWeight: 600, fontSize: 16 }}>
                {" "}
                กรอกอีเมลของคุณเพื่อยืนยันการลบ
              </span>
            }
            rules={[{ required: true, message: "กรอกอีเมลของคุณเพื่อยืนยัน" }]}
            required={false}
          >
            <Input
              name="confirmEmail"
              placeholder="username@domain"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
            />
          </Form.Item>
        </Form>
        <Flex
          gap="middle"
          justify="center"
          align="center"
          vertical={false}
          style={{}}
        >
          <Button
            type="primary"
            onClick={() => {
              setIsConfirmDeleteModalOpen(false);
            }}
            style={{
              width: 150,
              height: 40,
              marginTop: 20,
              // marginRight: 10,
              backgroundColor: "#EBEBEB",
              // color: "#ffffff",
              // border: "2px solid #FF7F07",
            }}
            // type="primary"
          >
            ยกเลิก
          </Button>
          <Button
            type="primary"
            onClick={() => {
              //console.log("confirmEmail ==>",confirmEmail)
              //console.log("confirmEmail == data.email ==>",confirmEmail == data.email)
              if (confirmEmail == data.email) {
                handleDelete();
                setIsConfirmDeleteModalOpen(false);
              } else {
                setModalFailedStr({
                  title: "ผิดผลาด",
                  detail: "อีเมลของคุณไม่ถูกต้อง",
                });
                setIsModalFailedOpen(true);
              }
            }}
            style={{
              width: 150,
              height: 40,
              marginTop: 20,
              // marginRight: 10,
              backgroundColor: "#FF4D4F",
              color: "#ffffff",
              // border: "2px solid #FF7F07",
            }}
            // type="primary"
          >
            ยืนยัน
          </Button>
        </Flex>
      </Modal>
      <LoadingDialog isLoading={isLoading} />
      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title={modalFailedStr.title}
        detail={modalFailedStr.detail}
        onCancel={() => {
          setIsModalFailedOpen(false);
        }}
        onAfterClose={() => {
          setIsModalFailedOpen(false);
        }}
        onConfirm={() => {
          setIsModalFailedOpen(false);
        }}
      />

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        title={modalSuccessStr.title}
        detail={modalSuccessStr.detail}
        onCancel={() => {}}
        onAfterClose={() => {
          //handleLogout()
        }}
      />
      <ConfirmDialog
        title={"ยืนยันการยกเลิกเชื่อมต่อบัญชี"}
        description={`คุณต้องการยกเลิกการเชื่อมต่อ\nบัญชี ${
          unlinkedIdp ? unlinkedIdp.name : ""
        } หรือไม่`}
        confirmText={"ยืนยัน"}
        cancelText={"ยกเลิก"}
        isModalOpen={isConfirmUnLinkedModalOpen}
        onCancel={() => {
          setIsConfirmUnLinkedModalOpen(false);
        }}
        onConfirm={() => {
          handleDisconnect(unlinkedIdp);
          setIsConfirmUnLinkedModalOpen(false);
        }}
        onAfterClose={() => {
          setUnlinkedIdp(null);
        }}
      />

      <DiscardDialog
        isModalOpen={isUnsavedModalOpen}
        closable={false}
        title="ยกเลิกการเปลี่ยนแปลง"
        cancelText="ยกเลิก"
        confirmText="ยืนยัน"
        onCancel={() => {
          handleUnsavedCancel();
        }}
        onConfirm={() => {
          handleUnsavedConfirm();
          setEditMode(false);
        }}
      />
    </div>
  );
};

export default AccountManagement;
