import { useState } from "react";
import { useUnsavedChangesContext } from "../contexts";
import { useNavigate, useLocation } from "react-router-dom";
import { DiscardDialog } from "../components";
import { WARNING_PATH } from "../constants";

const useUnsavedChangesWarning = () => {
  const { isDataModified, setIsDataModified } = useUnsavedChangesContext();
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);
  const [nextPath, setNextPath] = useState(null);
  const [navigateState, setNavigateState] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path, state) => {    
    const isCheckPath = WARNING_PATH.includes(location.pathname);
    if (isDataModified && isCheckPath) {
      setIsUnsavedModalOpen(true);
      setNextPath(path);
      setNavigateState(state);
    } else {
      navigate(
        {
          pathname: path,
        },
        {
          state: state,
        }
      );
    }
  };

  const handleUnsavedConfirm = () => {
    setIsUnsavedModalOpen(false);
    setIsDataModified(false);
    navigate(
      {
        pathname: nextPath,
      },
      {
        state: navigateState,
      }
    );
  };

  const handleUnsavedCancel = () => {
    setIsUnsavedModalOpen(false);
    setNextPath(null);
    setNavigateState(null);
  };

  return {
    handleNavigation,
    handleUnsavedConfirm,
    handleUnsavedCancel,
    setIsUnsavedModalOpen,
    isUnsavedModalOpen,
    DiscardDialogComponent: (
      <DiscardDialog
        isModalOpen={isUnsavedModalOpen}
        closable={false}
        title="ยกเลิกการเปลี่ยนแปลง"
        cancelText="ยกเลิก"
        confirmText="ยืนยัน"
        onCancel={handleUnsavedCancel}
        onConfirm={handleUnsavedConfirm}
      />
    ),
  };
};

export default useUnsavedChangesWarning;
