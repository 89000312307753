import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import "../../../i18n/i18n.ts";
import {
  Flex,
  Space,
  Button,
  Modal,
  Row,
  Col,
  Tag,
  Carousel,
  Grid,
  Tooltip,
} from "antd";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import "../../../components/Card/CustomCard.css";
import IconFeedbackButtton from "../../../assets/icon/icon_feedback_button.js";
import IconCookieButtton from "../../../assets/icon/icon_cookie_button.js";
import IconCookieMini from "../../../assets/icon/icon_cookie_mini.js";
import ImageContent1 from "../../../assets/img/content1.png";
import ImageContent2 from "../../../assets/img/content2.png";
import ImageContent3 from "../../../assets/img/content3.png";
import ImageContent4 from "../../../assets/img/content4.png";
import IconRightArrowAll from "../../../assets/icon/icon_right_arrow_all.js";
import IconRightArrowViewDashboard from "../../../assets/icon/icon_right_arrow_view_dashboard.js";
import NoImg from "../../../assets/img/no_img.png";
import { useCookies } from "react-cookie";

import "dayjs/locale/th";

import { DashboardService } from "../../../services/DashboardService";
import { InsightReportService } from "../../../services/InsightReportService";
import SurveyPage from "../Feedback/Survey.js";
import NoAuthDialog from "../../../components/Dialog/NoAuthDialog.js";
import ErrorMessageHandler from "../../../helpers/ErrorMessageHandler.js";

const Home = () => {
  const { useBreakpoint } = Grid;
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const carouselRefDashboard = useRef(null);
  const carouselRefInsight = useRef(null);
  const [currentSlideDashboard, setCurrentSlideDashboard] = useState(0);
  const [currentSlideInsight, setCurrentSlideInsight] = useState(0);
  const [isNotAllow, setIsNotAllow] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  const [insightReportData, setInsightReportData] = useState();
  const [slideShowInsight, setSlideShowInsight] = useState();
  const [heightShowInsight, setHeightShowInsight] = useState();
  const [slideShowDashboard, setSlideShowDashboard] = useState();
  const [heightShowDashboard, setHeightShowDashboard] = useState();
  const [heightShowImgDashboard, setHeightShowImgDashboard] = useState();
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalCookieOpen, setIsModalCookieOpen] = useState(false);
  const [cookie, setCookie, removeCookie] = useCookies();
  const [cookieInsightReport, setCookieInsightReport] = useCookies([
    "insight-report-condition",
  ]);
  const [cookieDashboard, setCookieDashboard] = useCookies([
    "dashboard-condition",
  ]);

  const hasLeftItemDashboard = currentSlideDashboard > 0;
  const hasRightItemDashboard =
  currentSlideDashboard + slideShowDashboard < dashboardData?.length;

  const handleSlideDashboardChange = (current) => {
    setCurrentSlideDashboard(current);
  };

  const hasLeftItemInsight = currentSlideInsight > 0;
  const hasRightItemInsight =
  currentSlideInsight + slideShowInsight < insightReportData?.length;

  const handleSlideInsightChange = (current) => {
    setCurrentSlideInsight(current);
  };

  const webAdsContent = {
    topMsg: {
      title: "ศูนย์กลางข้อมูลเพื่อขับเคลื่อนอุตสาหกรรมไมซ์ไทย",
      detail:
        "ศูนย์รวมข้อมูลเชิงลึกและเครื่องมือวิเคราะห์ที่ทันสมัย มุ่งสร้างประโยชน์ให้ผู้ประกอบการไมซ์ไทยทุกระดับ ด้วยการแสดงผล และบทวิเคราะห์เชิงลึกที่ครอบคลุม พร้อมฐานข้อมูลที่อัปเดตอย่างต่อเนื่อง เพื่อให้คุณเข้าถึงข้อมูลที่จำเป็น ตัดสินใจได้แม่นยำ และพัฒนาธุรกิจสู่ความสำเร็จในระดับสากล",
    },
    msg1: {
      title: "แดชบอร์ดข้อมูลอัจฉริยะ",
      detail:
        "เครื่องมือวิเคราะห์ข้อมูลที่ออกแบบมาเพื่อผู้ประกอบการไมซ์โดยเฉพาะ ช่วยให้คุณตัดสินใจได้อย่างแม่นยำ",
    },
    msg2: {
      title: "บทวิเคราะห์เชิงลึก",
      detail:
        "วิเคราะห์แนวโน้มและโอกาสทางธุรกิจด้วยรายงานที่ครอบคลุมทุกมิติของอุตสาหกรรมไมซ์",
    },
    msg3: {
      title: "ฐานข้อมูลอุตสาหกรรม",
      detail:
        "ศูนย์รวมข้อมูลที่สำคัญสำหรับอุตสาหกรรมไมซ์ ที่ผ่านการตรวจสอบตามมาตรฐานการกำกับดูแลด้านหลักธรรมาภิบาลข้อมูล",
    },
    msg4: {
      title: "สร้างสรรค์งานไมซ์ด้วยข้อมูล",
      detail:
        "เครื่องมือวางแผนและออกแบบงานไมซ์ด้วยข้อมูลเชิงลึก ครอบคลุมการวิเคราะห์ตลาด พฤติกรรมผู้เข้าร่วมงาน และการคาดการณ์ศักยภาพของอุตสาหกรรม พร้อมแนวทางการจัดงานที่ประสบความสำเร็จ",
    },
  };

  useEffect(() => {
    if (!dashboardData) {
      fetchDashboardData();
    }
  }, [dashboardData]);

  useEffect(() => {
    if (!insightReportData) {
      fetchInsightReportData();
    }
  }, [insightReportData]);

  useEffect(() => {
    const initialWidth = window.innerWidth;
    if (initialWidth < 575) {
      setSlideShowInsight(1);
      setSlideShowDashboard(1);
      setHeightShowImgDashboard(200);
    } else if (initialWidth < 768) {
      setSlideShowInsight(2);
      setHeightShowInsight(500);
      setSlideShowDashboard(1);
      setHeightShowImgDashboard(220);
    } else if (initialWidth < 992) {
      setSlideShowInsight(2);
      setHeightShowInsight(570);
      setSlideShowDashboard(2);
      setHeightShowImgDashboard(220);
    } else if (initialWidth < 1200) {
      setSlideShowInsight(3);
      setHeightShowInsight(520);
      setSlideShowDashboard(2);
      setHeightShowDashboard(590);
      setHeightShowImgDashboard(300);
    } else {
      setSlideShowInsight(4);
      setHeightShowInsight(520);
      setSlideShowDashboard(2);
      setHeightShowDashboard(590);
      setHeightShowImgDashboard(300);
    }
  }, []);

  useEffect(() => {
    if (screens.xs) {
      setSlideShowInsight(1);
      setSlideShowDashboard(1);
      setHeightShowImgDashboard(200);
    }
    if (screens.sm) {
      setSlideShowInsight(2);
      setHeightShowInsight(570);
      setSlideShowDashboard(1);
      setHeightShowImgDashboard(220);
    }
    if (screens.md) {
      setSlideShowInsight(2);
      setHeightShowInsight(500);
      setSlideShowDashboard(2);
      setHeightShowImgDashboard(220);
    }
    if (screens.lg) {
      setSlideShowInsight(3);
      setHeightShowInsight(520);
      setSlideShowDashboard(2);
      setHeightShowDashboard(590);
      setHeightShowImgDashboard(300);
    }
    if (screens.xl) {
      setSlideShowInsight(4);
      setHeightShowInsight(520);
      setSlideShowDashboard(2);
      setHeightShowDashboard(590);
      setHeightShowImgDashboard(300);
    }
    if (localStorage.getItem("cookie") === null) {
      setIsModalCookieOpen(true);
    }
    // if (screens.xxl) setSlideShowInsight(4);
  }, [screens]);

  const fetchDashboardData = async () => {
    try {
      const params = {
        IsPinned: true,
        IsActivated: true,
      };

      const response = await DashboardService.getPublic(params);
      setDashboardData(response.items);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInsightReportData = async () => {
    try {
      const params = {
        IsPinned: true,
        IsActivated: true,
      };

      const response = await InsightReportService.getPublic(params);
      setInsightReportData(response.items);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickDashBoard = (id, urlSlug) => {
    const Name = encodeURIComponent(urlSlug);
    DashboardService.validatePermission(urlSlug)
      .then((allow) => {
        if (allow) {
          navigate(`/dashboard/${Name}`);
          window.scrollTo(0, 0);
        } else {
          var hasAccessToken = sessionStorage.getItem("accessToken") !== null;
          if (!hasAccessToken) {
            localStorage.setItem("pageState", `/dashboard/${Name}`);
            navigate("/login");
          } else {
            setIsNotAllow(true);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          if (sessionStorage.getItem("accessToken") === null) {
            localStorage.setItem(
              "pageState",
              `/dashboard/${encodeURIComponent(urlSlug)}`
            );
            navigate("/login");
            return;
          }
          setIsNotAllow(true);
        } else {
          ErrorMessageHandler(error);
        }
      });
  };

  const handleClickInsight = (id, urlSlug) => {
    navigate(`/insight-report/${encodeURIComponent(urlSlug)}`);
  };

  const consentCookie = () => {
    localStorage.setItem("cookie", "true");
    setIsModalCookieOpen(false);
  };
  const declineCookie = () => {
    localStorage.setItem("cookie", "false");
    setIsModalCookieOpen(false);
    removeCookie("dashboard-condition", { path: "/" });
    removeCookie("insight-report-condition", { path: "/" });
    removeCookie("data-catalog-condition", { path: "/" });
    removeCookie("dashboard-view", { path: "/" });
    removeCookie("insight-report-view", { path: "/" });
    removeCookie("data-catalog-view", { path: "/" });
  };

  const surveyModalAction = () => {
    setIsModalFormOpen(false);
  };

  const goDashboard = (tagId, tagName) => {
    if (localStorage.getItem("cookie") === "true") {
      if (cookieDashboard["dashboard-condition"] !== undefined) {
        var condition = cookieDashboard["dashboard-condition"];
        condition = {};
        condition.TagIds = tagId;
        setCookiesValue("dashboard", "dashboard-condition", condition);
      }
    }
    navigate(`/dashboard?tag=${encodeURIComponent(tagName)}`, {
      state: { tagId },
    });
  };

  const goInsight = (tagId, tagName) => {
    if (localStorage.getItem("cookie") === "true") {
      if (cookieInsightReport["insight-report-condition"] !== undefined) {
        var condition = cookieInsightReport["insight-report-condition"];
        condition = {};
        condition.TagIds = tagId;
        setCookiesValue(
          "insight-report",
          "insight-report-condition",
          condition
        );
      }
    }
    navigate(`/insight-report?tag=${encodeURIComponent(tagName)}`, {
      state: { tagId },
    });
  };

  const setCookiesValue = (group, key, value) => {
    if (localStorage.getItem("cookie") === "true") {
      if (group === "dashboard") {
        setCookieDashboard(key, value, { path: "/" });
      } else if (group === "insight-report") {
        setCookieInsightReport(key, value, { path: "/" });
      }
    }
  };

  const tagInsightButtons = (item) => {
    const maxTag = 2;
    const tagCount = item?.insightReportTags.length;
    var renderTiems = item?.insightReportTags
      .sort((a, b) => {
        if (a.tagName === "เฉพาะสมาชิก" && b.tagName !== "เฉพาะสมาชิก") {
          return -1;
        }
        return a.sequence - b.sequence;
      })
      .slice(0, maxTag)
      .map((tag) => (
        <Tag
          key={item.id + tag.id}
          bordered={false}
          color={tag.tagColor}
          onClick={() => goInsight(tag.tagId, tag.tagName)}
          className="tag"
        >
          {tag.tagName}
        </Tag>
      ));
    if (tagCount > maxTag) {
      renderTiems.push(
        <Tooltip
          placement="bottom"
          title={item?.insightReportTags
            .slice(maxTag)
            .map((tag) => tag.tagName)
            .join(", ")}
        >
          <Tag key={item.id + "more"} bordered={false} className="tag tag-more">
            {`+${tagCount - maxTag}`}
          </Tag>
        </Tooltip>
      );
    }
    return renderTiems;
  };
  const tagDashboardButtons = (item) => {
    const maxTag = 5;
    const tagCount = item?.dashboardTags.length;
    var renderTiems = item?.dashboardTags
      .sort((a, b) => {
        if (a.tagName === "เฉพาะสมาชิก" && b.tagName !== "เฉพาะสมาชิก") {
          return -1;
        }
        return a.sequence - b.sequence;
      })
      .slice(0, maxTag)
      .map((tag) => (
        <Tag
          key={item.id + tag.id}
          bordered={false}
          color={tag.tagColor}
          onClick={() => goDashboard(tag.tagId, tag.tagName)}
          className="tag"
        >
          {tag.tagName}
        </Tag>
      ));
    if (tagCount > maxTag) {
      renderTiems.push(
        <Tooltip
          placement="bottom"
          title={item?.dashboardTags
            .slice(maxTag)
            .map((tag) => tag.tagName)
            .join(", ")}
        >
          <Tag key={item.id + "more"} bordered={false} className="tag tag-more">
            {`+${tagCount - maxTag}`}
          </Tag>
        </Tooltip>
      );
    }
    return renderTiems;
  };

  return (
    <>
      <div className="home-body">
        {isModalCookieOpen ? (
          <div className="cookie-layout">
            <Flex>
              <IconCookieMini style={{ marginLeft: 20, marginTop: 10 }} />{" "}
              <p className="cookie-title">เว็บไซต์นี้มีการใช้งานคุกกี้</p>
            </Flex>
            <p className="cookie-des">
              เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพ
              และประสบการณ์ที่ดีในการใช้งานเว็บไซต์
              เมื่อคุณกดยอมรับเราจะสามารถเลือกแสดงสิ่งที่น่าสนใจสำหรับคุณได้โดยเฉพาะ
            </p>
            <Space>
              <Button
                type="defualt"
                onClick={consentCookie}
                className={
                  localStorage.getItem("cookie") === "false"
                    ? "cookie-decline"
                    : "cookie-consent"
                }
              >
                ยอมรับ
              </Button>
              <Button
                type="defualt"
                onClick={declineCookie}
                className={
                  localStorage.getItem("cookie") === "false"
                    ? "cookie-consent"
                    : "cookie-decline"
                }
              >
                ปฎิเสธ
              </Button>
            </Space>
          </div>
        ) : null}

        <div className="home-container">
          <p className="content-head">{webAdsContent.topMsg.title}</p>
          <p className="content-head-detail">{webAdsContent.topMsg.detail}</p>

          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
              <div className="content-card">
                <Flex>
                  <img
                    className="content-image"
                    alt="example"
                    src={ImageContent1}
                  />
                  <Flex vertical="false" justify="center">
                    <div style={{ padding: "8px" }}>
                      <p className="content-card-title">
                        {webAdsContent.msg1.title}
                      </p>
                      <p className="content-card-detail">
                        {webAdsContent.msg1.detail}
                      </p>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Col>
            <Col xs={24} md={24} xl={12} xxl={12}>
              <div className="content-card">
                <Flex>
                  <img
                    className="content-image"
                    alt="example"
                    src={ImageContent2}
                  />
                  <Flex vertical="false" justify="center">
                    <div style={{ padding: "8px" }}>
                      <p className="content-card-title">
                        {webAdsContent.msg2.title}
                      </p>
                      <p className="content-card-detail">
                        {webAdsContent.msg2.detail}
                      </p>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Col>
            <Col xs={24} md={24} xl={12} xxl={12}>
              <div className="content-card">
                <Flex>
                  <img
                    className="content-image"
                    alt="example"
                    src={ImageContent3}
                  />
                  <Flex vertical="false" justify="center">
                    <div style={{ padding: "8px" }}>
                      <p className="content-card-title">
                        {webAdsContent.msg3.title}
                      </p>
                      <p className="content-card-detail">
                        {webAdsContent.msg3.detail}
                      </p>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Col>
            <Col xs={24} md={24} xl={12} xxl={12}>
              <div className="content-card">
                <Flex>
                  <img
                    className="content-image"
                    alt="example"
                    src={ImageContent4}
                  />
                  <Flex vertical="false" justify="center">
                    <div style={{ padding: "8px" }}>
                      <p className="content-card-title">
                        {webAdsContent.msg4.title}
                      </p>
                      <p className="content-card-detail">
                        {webAdsContent.msg4.detail}
                      </p>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {dashboardData && dashboardData.length > 0 && (
        <div className="container-dashboard">
          <div className="content-dashboard">
            <p className="content-head-dashboard">แดชบอร์ด</p>
            <p className="content-head-detail-dashboard">
              เราได้นำเสนอแดชบอร์ดที่เกี่ยวข้องกับอุตสาหกรรม MICE
              ที่สามารถช่วยให้คุณเข้าใจข้อมูลและข่าวสารที่สำคัญได้ง่ายขึ้น
            </p>
            <Flex justify="space-between" align="center">
              <Space>
                {(dashboardData &&
                  dashboardData?.length > 2 &&
                  slideShowDashboard !== 1) ||
                (slideShowDashboard === 1 && dashboardData?.length > 1) ? (
                  <>
                    <Button
                      className="arrow-left-home"
                      onClick={() => carouselRefDashboard.current.prev()}
                      style={{
                        backgroundColor: hasLeftItemDashboard ? "#8059d9" : "#eee8fb",
                        color: hasLeftItemDashboard ? "white" : "#ad85d5",
                        border: "none",
                      }}
                    >
                      <HiOutlineArrowLeft />
                    </Button>
                    <Button
                      className="arrow-right-home"
                      onClick={() => carouselRefDashboard.current.next()}
                      style={{
                        backgroundColor: hasRightItemDashboard ? "#8059d9" : "#eee8fb",
                        color: hasRightItemDashboard ? "white" : "#ad85d5",
                        border: "none",
                      }}
                    >
                      <HiOutlineArrowRight />
                    </Button>
                  </>
                ) : (
                  <div></div>
                )}
              </Space>

              <Flex
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                <p className="see-all">ดูทั้งหมด</p>
                <IconRightArrowAll />
              </Flex>
            </Flex>
          </div>

          <div className="container-card-dashboard">
            <Carousel
              ref={carouselRefDashboard}
              slidesToShow={slideShowDashboard}
              slidesToScroll={slideShowDashboard}
              infinite={false}
              dots={false}
              centerMode={slideShowDashboard === 1}
              beforeChange={(_, next) => handleSlideDashboardChange(next)}
              centerPadding="40px"
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              {dashboardData
                ?.sort((a, b) => a.sequence - b.sequence)
                .map((item, index) => (
                  <div key={item.id}>
                    {item && (
                      <div style={{ margin: "8px 8px 20px 8px" }}>
                        <div
                          className="content-card-dashboard"
                          style={{
                            height: screens.xs ? "" : heightShowDashboard,
                          }}
                        >
                          <div>
                            <div
                              style={{
                                height: heightShowImgDashboard,
                                overflow: "hidden",
                                marginBottom: "20px",
                              }}
                            >
                              <img
                                className="content-image-dashboard"
                                alt="example"
                                src={item?.thumbnailSrc ?? `${NoImg}`}
                                onError={(e) => {
                                  e.target.src = NoImg;
                                }}
                              />
                            </div>
                            <div>
                              <Flex wrap gap="small">
                                {tagDashboardButtons(item)}
                              </Flex>
                            </div>
                            <div>
                              <p className="content-card-title-dashboard">
                                {item.name}
                              </p>
                              <p className="content-card-detail-dashboard">
                                {item.shortDescription}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingLeft: screens.xl ? "80px" : "",
                              paddingRight: screens.xl ? "80px" : "",
                            }}
                          >
                            <Flex
                              justify="center"
                              align="center"
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#f0f5ff",
                                borderRadius: "10px",
                                cursor: "pointer",
                                color: "#2B7BF6",
                                fontWeight: "bold",
                                fontSize: "14px",
                                lineHeight: "1.5",
                              }}
                              onClick={() =>
                                handleClickDashBoard(item.id, item.urlSlug)
                              }
                            >
                              <p
                                style={{
                                  margin: "0",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginRight: "8px" }}>
                                  ดูรายละเอียด
                                </span>
                                <IconRightArrowViewDashboard />
                              </p>
                            </Flex>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      )}

      {insightReportData && insightReportData.length > 0 && (
        <div className="container-insight-report">
          <div className="content-insight-report">
            <p className="content-head-dashboard">
              รายงานวิเคราะห์ข้อมูลเชิงลึก (Insight Report)
            </p>
            <Flex justify="space-between" align="center">
              <Space>
                {(insightReportData &&
                  insightReportData?.length > 4 &&
                  slideShowInsight === 4) ||
                (slideShowInsight === 3 && insightReportData?.length > 3) ||
                (slideShowInsight === 2 && insightReportData?.length > 2) ||
                (slideShowInsight === 1 && insightReportData?.length > 1) ? (
                  <>
                    <Button
                      className="arrow-left-home"
                      onClick={() => carouselRefInsight.current.prev()}
                      style={{
                        backgroundColor: hasLeftItemInsight ? "#8059d9" : "#eee8fb",
                        color: hasLeftItemInsight ? "white" : "#ad85d5",
                        border: "none",
                      }}
                    >
                      <HiOutlineArrowLeft />
                    </Button>
                    <Button
                      className="arrow-right-home"
                      onClick={() => carouselRefInsight.current.next()}
                      style={{
                        backgroundColor: hasRightItemInsight ? "#8059d9" : "#eee8fb",
                        color: hasRightItemInsight ? "white" : "#ad85d5",
                        border: "none",
                      }}
                    >
                      <HiOutlineArrowRight />
                    </Button>
                  </>
                ) : (
                  <div></div>
                )}
              </Space>
              <Flex
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/insight-report");
                }}
              >
                <p className="see-all">ดูทั้งหมด</p>
                <IconRightArrowAll />
              </Flex>
            </Flex>
          </div>

          <div className="container-card-insight-report">
            {insightReportData.length < 4 && !screens.xs ? (
              <Row gutter={[16, 0]}>
                {insightReportData
                  ?.sort((a, b) => a.sequence - b.sequence)
                  .map((item, index) => (
                    <Col
                      key={item.id}
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={6}
                      xxl={6}
                    >
                      {item && (
                        <div>
                          <div
                            key={index}
                            className="content-card-insight-report"
                            style={{ height: heightShowInsight }}
                          >
                            <div>
                              <div
                                style={{
                                  height: "175px",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  className="content-image-insight-report"
                                  alt="example"
                                  src={item?.thumbnailSrc ?? `${NoImg}`}
                                  onError={(e) => {
                                    e.target.src = NoImg;
                                  }}
                                />
                              </div>
                              <div style={{ padding: "24px 24px 0px 24px" }}>
                                <Flex wrap gap="small">
                                  {tagInsightButtons(item)}
                                </Flex>
                                <p className="content-card-title-dashboard">
                                  {item?.name}
                                </p>
                                <p className="content-card-detail-insight-report">
                                  {item?.shortDescription}
                                </p>
                                <p className="insight-report-date">
                                  {item?.createdDateFrom}
                                </p>
                              </div>
                            </div>
                            <div style={{ padding: "0px 24px 24px 24px" }}>
                              <Flex
                                justify="flex-start"
                                onClick={() =>
                                  handleClickInsight(item.id, item.urlSlug)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <p className="content-tag-dashboard-button-text">
                                  อ่านต่อ
                                </p>
                                <IconRightArrowViewDashboard />
                              </Flex>
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                  ))}
              </Row>
            ) : (
              <Carousel
                ref={carouselRefInsight}
                slidesToShow={slideShowInsight}
                slidesToScroll={slideShowInsight}
                infinite={false}
                dots={false}
                centerMode={slideShowInsight === 1}
                beforeChange={(_, next) => handleSlideInsightChange(next)}
                centerPadding="40px"
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                {insightReportData
                  ?.sort((a, b) => a.sequence - b.sequence)
                  .map((item, index) => (
                    <div key={item.id}>
                      {item && (
                        <div style={{ margin: "8px 8px 8px 8px" }}>
                          <div
                            key={index}
                            className="content-card-insight-report"
                            style={{ height: heightShowInsight }}
                          >
                            <div>
                              <div
                                style={{
                                  height: "175px",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  className="content-image-insight-report"
                                  alt="example"
                                  src={item?.thumbnailSrc ?? `${NoImg}`}
                                  onError={(e) => {
                                    e.target.src = NoImg;
                                  }}
                                />
                              </div>
                              <div style={{ padding: "24px 24px 0px 24px" }}>
                                <Flex wrap gap="small">
                                  {tagInsightButtons(item)}
                                </Flex>
                                <p className="content-card-title-dashboard">
                                  {item?.name}
                                </p>
                                <p className="content-card-detail-insight-report">
                                  {item?.shortDescription}
                                </p>
                                <p className="insight-report-date">
                                  {item?.createdDateFrom}
                                </p>
                              </div>
                            </div>
                            <div style={{ padding: "0px 24px 24px 24px" }}>
                              <Flex
                                justify="flex-start"
                                onClick={() =>
                                  handleClickInsight(item.id, item.urlSlug)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <p className="content-tag-dashboard-button-text">
                                  อ่านต่อ
                                </p>
                                <IconRightArrowViewDashboard />
                              </Flex>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </Carousel>
            )}
          </div>
        </div>
      )}

      <IconFeedbackButtton
        className="home-feedback"
        onClick={() => setIsModalFormOpen(true)}
      />
      <IconCookieButtton
        className="home-cookie"
        onClick={() => setIsModalCookieOpen(true)}
      />

      <Modal
        title={<p>Feedback</p>}
        open={isModalFormOpen}
        footer={null}
        closable={true}
        destroyOnClose={true}
        style={{ top: 20, minWidth: "60%" }}
        onCancel={() => setIsModalFormOpen(false)}
        zIndex={2000}
        className="feedback-modal"
      >
        <SurveyPage modalOpenState={surveyModalAction} />
      </Modal>
      <NoAuthDialog
        isModalOpen={isNotAllow}
        onCancel={() => {
          setIsNotAllow(false);
        }}
        onAfterClose={() => {
          setIsNotAllow(false);
        }}
        onConfirm={() => {
          setIsNotAllow(false);
        }}
      />
    </>
  );
};

export default Home;
