import "./RequestApiNew.css";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    Form,
    Flex,
    Button,
    Col,
    Row,
    Tag,
    Select,
    Divider,
    Input,
    Table,
    Checkbox,
} from "antd";

import NoData from "../../../../assets/img/no_data.js";
import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { UserService } from "../../../../services/UserService.js";
import TextArea from "antd/es/input/TextArea.js";
import { AiOutlineLink } from "react-icons/ai";
import { DataSourceService } from "../../../../services/DataSourceService.js";
import { RequisitionService } from "../../../../services/RequisitionService.js";
import { ConfirmDialog, FailedDialog } from "../../../../components/index.js";
import { LoadingDialog } from "../../../../components";


const { Option } = Select;

const RequestApiNew = () => {

    const navigate = useNavigate();
    const [showSelectContainer, setShowSelectContainer] = useState(true);
    const [showInputContainer, setShowInputContainer] = useState(false);
    const [showSuccessContainer, setShowSuccessContainer] = useState(false);

    const [dataUserMe, setDataUserMe] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [dataAfterSave, setDataAfterSave] = useState({});
    const [provinceList, setProvinceList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [subDistrictList, setSubDistrictList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedDataSourceList, setSelectedDataSourceList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [isModalComfirmOpen, setIsModalComfirmOpen] = useState(false);
    const [isModalFailOpen, setIsModalFailOpen] = useState(false);
    var userMe;

    const [formData, setFormData] = useState({
        companyName: "",
        address1: "",
        address2: "",
        provinceId: "",
        districtId: "",
        subdistrictId: "",
        requisitionReason: "",
        domain: "",
        zipcode: "",
        requisitionDetails: [],
        chkAgree: false,
    });

    const toggleContainers = () => {
        setShowSelectContainer(false)
        setShowInputContainer(true);
    };
    const toggleContainersBack = () => {
        setShowSelectContainer(true)
        setShowInputContainer(false);
    };

    const [selectionType, setSelectionType] = useState('checkbox');

    const fetchProvinces = async () => {
        try {
            const response = await UserService.getProvinces();
            //console.log('fetchProvinces >>  response:', response);
            setProvinceList(response)
            console.log('userMe', userMe)
            if (userMe.provinceId) {
                handleChangeProvince(userMe.provinceId);
            }

        } catch (error) {
            console.error('Error fetching job functions:', error);
        }
    };


    const fetchDataSource = async () => {
        try {
            const responseUserMe = dataUserMe;
            const params = {
                TagIds: [],
                // LastCreatedDate: selectedDate,
                // Keywords: keywords,
                IsActivated: true,
                ExcludeUserId: responseUserMe?.id
            };



            //setTimeout(async () => {
            const response = await DataSourceService.searchPublic(params);
            //setDataSource(response.items);
            setDataSource(
                response.items.map((item, index) => ({
                    key: item.id,
                    name: item.name,
                    description: item.description,
                    urlSlug:item.urlSlug,
                    formattedUpdatedDate: item.formattedUpdatedDate,
                }))
            )
            //console.log("fetchDataSource >> response.items",response.items)
            //}, 150); 
        } catch (error) {

            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!dataUserMe) return;
        UserService.getRequisitionsStatusByUserId(dataUserMe?.id).then((data) => {
            fetchDataSource();
        });
    }, [dataUserMe]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            if (dataUserMe !== null) return;

            const responseUserMe = await UserService.getMe(true);
            userMe = responseUserMe;
            setDataUserMe(responseUserMe);

            const requestApiStatus = await UserService.getRequisitionsStatusByUserId(responseUserMe?.id);
            if (requestApiStatus) {
                if(requestApiStatus?.hasRequisition === true){
                    navigate(`/request-api`);
                    return;
                }
            }


            
            if (userMe.provinceId) {
                setFormData({
                    ...formData,
                    companyName: userMe.company,
                    address1: userMe.address,
                    provinceId: userMe.provinceId,
                    districtId: userMe.districtId,
                    subdistrictId: userMe.subDistrictId,
                    zipcode: userMe.zipCode,
                    domain: userMe.domain
                });

                const provinceList = await UserService.getProvinces();
                setProvinceList(provinceList);
                const districtList = await UserService.getDistrictsByProvinceId(userMe.provinceId);
                setDistrictList(districtList);
                const subDistrictList = await UserService.getSubDistrictsByDistrictsId(userMe.districtId);
                setSubDistrictList(subDistrictList);

            } else {
                setFormData({
                    ...formData,
                    companyName: userMe.company,
                    address1: userMe.address
                });
                fetchProvinces();
            }




        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    };


    const columns = [
        {
            title: 'รายการ API',
            //dataIndex: 'name',
            render: (record) =>
                <>
                    <div className="requestapinew-item-data">
                        <Row justify={"space-between"} >
                            <Col xs={24} sm={12} md={14} style={{ alignContent: "center" }}>
                                <Row style={{ paddingBottom: 10 }}>
                                    <span className="name" onClick={() => navigate(`/data-catalog/${record.urlSlug}`)}>{record.name}</span>
                                </Row>

                                <Row style={{ paddingBottom: 5 }}>

                                </Row>
                                <Row>
                                    <Flex wrap gap="small">
                                        <span style={{ color: "#667085", marginBottom: 5 }}>อัพเดทล่าสุด : {record.formattedUpdatedDate}</span>
                                    </Flex>
                                </Row>


                            </Col>
                            <Col xs={24} sm={8} md={8} lg={6} xl={5} xxl={5} style={{ alignContent: "start", textAlign: "end" }}>

                            </Col>
                        </Row>
                        <Row justify={"space-between"} >
                            <Col xs={24} style={{ alignContent: "start", textAlign: "left" }}>
                                {/* <p>{dataCatalogData?.description}</p> */}
                                <div dangerouslySetInnerHTML={{ __html: record.description }} />
                            </Col>
                        </Row>

                    </div>
                </>,
        },


    ]


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

    };

    const rowSelection = {
        selectedDataSourceList,
        onChange: (selectedRowKeys, selectedRows) => {
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedDataSourceList(selectedRowKeys);
            setFormData({
                ...formData,
                requisitionDetails: selectedRows?.map((item, index) => ({
                    dataSourceId: item.key,
                }))
            });
        },
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };


    const handleChangeProvince = async (id) => {
        try {
            const response = await UserService.getDistrictsByProvinceId(id);
            //console.log('getDistrictsByProvinceId >>  response:', response);
            setDistrictList(response)
            setSubDistrictList([])
            setFormData({
                ...formData,
                provinceId: id,
                districtId: '',
                subdistrictId: '',
                zipcode: ''

            });

        } catch (error) {
            console.error('Error fetching job functions:', error);
        }
    };
    const handleChangeDistrict = async (id) => {
        try {
            const response = await UserService.getSubDistrictsByDistrictsId(id);
            setSubDistrictList(response)
            setFormData({
                ...formData,
                districtId: id,
                subdistrictId: '',
                zipcode: ''
            });
        } catch (error) {
            console.error('Error fetching job functions:', error);
        }
    };
    const handleChangeSubDistrict = async (id) => {
        try {

            let valObject = subDistrictList.filter((item) => item.id == id)

            if (valObject.length > 0) {
                setFormData({
                    ...formData,
                    subdistrictId: id,
                    zipcode: valObject[0].zipCode
                });
            }

        } catch (error) {
            console.error('Error fetching job functions:', error);
        }
    };

    const handleClickSave = () => {
        setIsModalComfirmOpen(true);
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {

            let dataSave = {
                companyName: formData.companyName,
                address1: formData.address1,
                //address2: formData.address2,
                provinceId: formData.provinceId == "" ? null : formData.provinceId,
                districtId: formData.districtId == "" ? null : formData.districtId,
                subdistrictId: formData.subdistrictId == "" ? null : formData.subdistrictId,
                requisitionReason: formData.requisitionReason,
                domain: formData.domain,
                zipcode: formData.zipcode.toString(),
                requisitionDetails: formData.requisitionDetails,
            }

            const response = await RequisitionService.create(dataSave);
            console.log("setDataAfterSave >> response >> ", response)
            setDataAfterSave(response)
            setShowSuccessContainer(true)
            setShowInputContainer(false);
        } catch (error) {

        }finally{
            setIsLoading(false);
        }

    };

    return (

        <>

            <div className="requestapinew-body" >
                <div className="heading">
                    <div className="content-card-title">
                        <ArrowLeftOutlined className="arrow-back" onClick={() =>  showInputContainer ? toggleContainersBack() : navigate(`/data-catalog`)} />  <span style={{ marginLeft: 10 }}>ขอใช้ API</span>
                    </div>
                </div>
                {showSelectContainer && (
                    <div className="requestapinew-container">
                        <div className="detail">
                            <Row>
                                <Col xs={12} sm={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                    <div style={{ fontSize: 18, fontWeight: 600 }}>รายการขอใช้ API</div>
                                </Col>
                                <Col xs={12} sm={12} style={{ alignContent: "center", textAlign: "right" }}>
                                    {formData.requisitionDetails.length > 0 && (
                                        <Button type={"primary"} onClick={toggleContainers}>ถัดไป <ArrowRightOutlined /></Button>
                                    )}
                                </Col>
                            </Row>
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />



                            <Row gutter={[16, 8]}>
                                <Col xs={24}>
                                    <div>
                                        <Table
                                            rowSelection={{
                                                type: selectionType,
                                                ...rowSelection,
                                            }}
                                            columns={columns}
                                            dataSource={dataSource}
                                            pagination={false}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}

                {showInputContainer && (
                    <>
                        <div className="requestapinew-input-container">
                            <div className="detail">
                                <Row>
                                
                                    <Col xs={12} sm={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        <div style={{ fontSize: 18, fontWeight: 600 }}>รายการขอใช้ API</div>
                                    </Col>
                                    {/* <Col xs={12} sm={12} style={{ alignContent: "center", textAlign: "right" }}>
                                        <Button type={"primary"} onClick={toggleContainersBack}><ArrowLeftOutlined /> ก่อนหน้า</Button>
                                    </Col> */}

                                </Row>

                                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                                <Row gutter={[16, 8]}>
                                    <Col xs={24} md={20} lg={15} xl={14}>
                                        <div className="form-requestapinew">
                                            <div className="box-color">
                                                <p className="name">
                                                    {dataUserMe?.fullName}
                                                </p>
                                                <span className="email">
                                                    {dataUserMe?.email}
                                                </span>
                                            </div>
                                            <div className="box-input">
                                                <Form
                                                    layout="vertical"
                                                    onFinish={handleClickSave}
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Form.Item label="ชื่อบริษัท" name="companyName" initialValue={formData.companyName}
                                                                rules={[{ required: true, message: "กรุณากรอกชื่อบริษัท" }]}
                                                            >
                                                                <Input placeholder="ชื่อบริษัท" name="companyName" onChange={handleChange} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <p style={{ fontSize: 16, fontWeight: 600 }}>ที่อยู่บริษัท</p>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <Form.Item label="จังหวัด">
                                                                <Select
                                                                    placeholder="Select"
                                                                    value={formData?.provinceId}
                                                                    name="provinceId"
                                                                    onChange={(value) => {
                                                                        handleChange({ target: { name: 'provinceId', value } })
                                                                        handleChangeProvince(value);
                                                                    }}
                                                                >
                                                                    <Option value="" disabled>{"Select"}</Option>
                                                                    {provinceList?.map((item) => (
                                                                        <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <Form.Item label="อำเภอ/เขต">
                                                                <Select
                                                                    placeholder="Select"
                                                                    value={formData?.districtId}
                                                                    name="districtId"
                                                                    onChange={(value) => {
                                                                        handleChange({ target: { name: 'districtId', value } })
                                                                        handleChangeDistrict(value);
                                                                    }}
                                                                >
                                                                    <Option value="" disabled>{"Select"}</Option>
                                                                    {districtList?.map((item) => (
                                                                        <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <Form.Item label="ตำบล/แขวง">
                                                                <Select
                                                                    value={formData?.subdistrictId}
                                                                    name="subdistrictId"
                                                                    onChange={(value) => {
                                                                        //handleChange({ target: { name: 'subdistrictId', value } })
                                                                        handleChangeSubDistrict(value);
                                                                    }}
                                                                >
                                                                    <Option value="" disabled>{"Select"}</Option>
                                                                    {subDistrictList?.map((item) => (
                                                                        <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <Form.Item label="รหัสไปรณีย์">
                                                                <Input placeholder="รหัสไปรณีย์" name="zipcode" value={formData?.zipcode} onChange={handleChange} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Form.Item label="รายละเอียดที่อยู่" >
                                                                <TextArea placeholder="รายละเอียด" name="address1" value={formData?.address1} onChange={handleChange} autoSize={{ minRows: 3, maxRows: 5 }} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Form.Item label="จุดประสงค์การขอใช้ API" name="requisitionReason" rules={[{ required: true, message: "กรุณากรอกจุดประสงค์การขอใช้ API" }]}>
                                                                <TextArea placeholder="รายละเอียด" name="requisitionReason" value={formData?.requisitionReason} onChange={handleChange} autoSize={{ minRows: 3, maxRows: 5 }} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Form.Item label="โดเมน ที่เอาไปใช้งาน" name="domain"
                                                                rules={[
                                                                    { required: true, message: "กรุณากรอกที่อยู่โดเมน" },
                                                                    {
                                                                        pattern: /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}.*$/,
                                                                        message: "กรุณากรอก URL ที่เริ่มต้นด้วย https://",
                                                                    },
                                                                ]}
                                                                initialValue={formData?.domain}>
                                                                <Input placeholder="โดเมน ที่เอาไปใช้งาน" name="domain" value={formData?.domain} onChange={handleChange} prefix={<AiOutlineLink />} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Form.Item required name="agreement"
                                                                valuePropName="checked"

                                                                rules={[
                                                                    {
                                                                        validator: (_, value) =>
                                                                            value ? Promise.resolve() : Promise.reject(new Error("กรุณายินยอมข้อกำหนดและเงื่อนไขการให้บริการ")),
                                                                    },
                                                                ]}
                                                            >
                                                                <Checkbox name="agreement" >ข้าพเจ้ายอมรับและตกลงตาม <a href="/terms-and-condition" target="_blank" rel="noopener noreferrer"><u>ข้อกำหนดและเงื่อนไขการให้บริการ</u></a></Checkbox>
                                                            </Form.Item>

                                                        </Col>


                                                    </Row>

                                                    <Row>
                                                        <Col xs={24} md={12} style={{ textAlign: "left" }}>
                                                            <Form.Item>
                                                                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>ขอใช้ API</Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                )}

                {showSuccessContainer && (
                    <>
                        <div className="requestapinew-success-container">
                            <div className="detail">
                                <Row>
                                    <Col xs={24} sm={24} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        <div style={{ fontSize: 20, fontWeight: 600 }}>ขอใช้ API
                                            <Tag style={{ marginLeft: 20 }} color="volcano">อยู่ระหว่างพิจารณาอนุมัติการใช้งาน</Tag>
                                        </div>
                                    </Col>

                                </Row>

                                <Divider style={{ marginTop: 15, marginBottom: 20 }} />

                                <Row gutter={[16, 8]}>
                                    <Col xs={24} md={24} lg={15} xl={14}>
                                        <div className="form-requestapinew">
                                            <div className="box-name">
                                                <div className="name">
                                                    {dataUserMe?.fullName}
                                                </div>
                                                <div className="email">
                                                    {dataUserMe?.email}
                                                </div>
                                                <div className="email">
                                                    <span>{dataAfterSave?.companyName} , {dataAfterSave?.domain}</span>
                                                </div>
                                            </div>
                                            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                                            <div className="box-color">
                                                <p className="name">
                                                    คุณได้ลงทะเบียนสำเร็จแล้ว
                                                </p>
                                                <div className="email">
                                                    คุณได้ลงทะเบียนสำเร็จแล้ว เราได้รับข้อมูลของคุณแล้ว หากได้รับการอนุมัติ
                                                    รหัสผู้ใช้งาน (Client ID) และรหัสลับ (Client Secret) จะถูกส่งไปยังอีเมลของคุณ และจะถูกแสดง อยู่ใน บัญชีของคุณ
                                                </div>
                                                <div className="email" style={{ marginTop: 20 }}>
                                                    อีเมลของคุณคือ  "{dataUserMe?.email}"
                                                </div>
                                            </div>


                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                )}

                <LoadingDialog isLoading={isLoading} />


                <ConfirmDialog
                    isModalOpen={isModalComfirmOpen}
                    closable={false}
                    title="ยืนยันการขอใช้ API"
                    cancelText="ยกเลิก"
                    confirmText="บันทึก"
                    type={"requestApi"}
                    onCancel={() => {
                        setIsModalComfirmOpen(false);
                    }}
                    onConfirm={() => {
                        handleSave();
                    }}
                    description="คุณต้องการขอใช้ API ใช่หรือไม่"
                />

                <FailedDialog
                    isModalOpen={isModalFailOpen}
                    closable={false}
                    title="ไม่สำเร็จ"
                    detail={errorMessage}
                    onCancel={() => {
                        setIsModalFailOpen(false);
                    }}
                    onAfterClose={() => {
                        setIsModalFailOpen(false);
                    }}
                    onConfirm={() => {
                        setIsModalFailOpen(false);
                    }}
                />
            </div>
        </>
    )


}

export default RequestApiNew;