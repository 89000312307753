import { Divider, notification } from "antd";

const ErrorMessageHandler = (error) => {

  var errorCode = `${error.response?.data?.errorCode || error.response?.status || error.code || error.name || ''}`;
  if(!window.showingError) window.showingError = {};
  if(window.showingError[errorCode] === true) return;
  window.showingError[errorCode] = true;
  notification.error({
    message: (
      <div>
        <b>เกิดข้อผิดพลาด</b>
      </div>
    ),
    description: (
      <div>
        <p style={{fontSize: 14}}><b style={{fontSize: 12}}>[{errorCode}]</b><br/>{error.response?.data?.messageTh || error.message}</p>
        <Divider style={{margin: "5px 0"}} />
        <p style={{fontSize: 12, color: "#444",marginBottom: "-10px"}}>หากต้องการความช่วยเหลือ กรุณาติดต่อผู้ดูแลระบบ</p>
      </div>
    ),
    code: errorCode,
    duration: 8,
    pauseOnHover: true,
    placement: 'top',
    onClose: () => {
      window.showingError[errorCode] = false;
    }
  });
};

export default ErrorMessageHandler;
