import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
  >
    <rect width="78" height="78" rx="20" fill="#EFF6FD" />
    <path
      d="M39.2344 48.4336C39.2344 48.6914 39.4336 48.9023 39.6738 48.9023H50.5137C50.7539 48.9023 50.9531 48.6914 50.9531 48.4336V45.6211C50.9531 45.3633 50.7539 45.1523 50.5137 45.1523H39.6738C39.4336 45.1523 39.2344 45.3633 39.2344 45.6211V48.4336ZM27.8145 48.791L39.0645 39.3574C39.2871 39.1699 39.2871 38.8242 39.0645 38.6367L27.8145 29.209C27.7465 29.1512 27.6635 29.1142 27.5751 29.1022C27.4868 29.0903 27.3969 29.1039 27.3161 29.1416C27.2352 29.1792 27.1669 29.2392 27.1192 29.3145C27.0715 29.3898 27.0464 29.4773 27.0469 29.5664V33.2402C27.0469 33.3809 27.1055 33.5098 27.2168 33.5977L33.6504 39L27.2168 44.4023C27.1644 44.4462 27.1221 44.5008 27.0928 44.5625C27.0635 44.6242 27.0478 44.6915 27.0469 44.7598V48.4336C27.0469 48.832 27.5098 49.0488 27.8145 48.791ZM60.5625 15.5625H17.4375C16.4004 15.5625 15.5625 16.4004 15.5625 17.4375V60.5625C15.5625 61.5996 16.4004 62.4375 17.4375 62.4375H60.5625C61.5996 62.4375 62.4375 61.5996 62.4375 60.5625V17.4375C62.4375 16.4004 61.5996 15.5625 60.5625 15.5625ZM58.2188 58.2188H19.7812V19.7812H58.2188V58.2188Z"
      fill="#1677FF"
    />
  </svg>
);

const Icon_save_allow = (props) => <Icon component={icon} {...props} />;

export default Icon_save_allow;
