import "./DataCatalog.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  Space,
  DatePicker,
  Flex,
  Button,
  Col,
  Row,
  Tag,
  Grid,
  Menu,
  Input,
  Dropdown,
} from "antd";
import { LiaReadme } from "react-icons/lia";
import TabPane from "antd/es/tabs/TabPane";
import NoData from "../../../assets/img/no_data.png";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { DataSourceService } from "../../../services/DataSourceService.js";
import { DataSourceCategoryService } from "../../../services/DataSourceCategoryService.js";
import { DataSourceTagService } from "../../../services/DataSourceTagService";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import { UserService } from "../../../services/UserService.js";
import { LoadingDialog, DatePickerLocale } from "../../../components/index.js";
import { useLocation } from "react-router-dom";
import { TbApi } from "react-icons/tb";
const { useBreakpoint } = Grid;

const DataCatalog = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [dataTag, setDataTag] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceCategory, setDataSourceCategory] = useState([
    {
      id: "-",
      name: "ไม่มีข้อมูล",
    },
  ]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [existsSearch, setExistsSearch] = useState(null);
  const [cookies, setCookie] = useCookies(["data-catalog-condition"]);
  const config = cookies["data-catalog-condition"];
  var localDataTag = [];
  var existParams = null;
  const location = useLocation();
  const { dataSourceIds } = location.state || {};
  const [currentDataSourceIds, setCurrentDataSourceIds] = useState(
    dataSourceIds || []
  );

  const handleClickDownload = async (urlSlug, type) => {
    setIsLoading(true);
    try {
      const response = await DataSourceService.getDownloadByUrlSlug(
        urlSlug,
        type
      );
      //console.log("handleClickDownload >> response >> ",response)
      const blob = new Blob([response], { type: type });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `${urlSlug}.${type}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error("Error fetching getDownloadByUrlSlug:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataTags = async () => {
    try {
      const response = await DataSourceTagService.get();
      setDataTag(response);
      localDataTag = response;
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
    }
  };
  const fetchDataSource = async () => {
    setIsLoading(true);
    try {
      const params = {
        TagIds: selectedTags,
        LastCreatedDate: selectedDate,
        Keywords: keywords,
        IsActivated: true,
      };
      if (localStorage.getItem("cookie") === "true") {
        setCookie("data-catalog-condition", JSON.stringify(params), {
          path: "/",
        });
      }
      if (existParams === JSON.stringify(params)) {
        return;
      }
      existParams = JSON.stringify(params);
      const data = await DataSourceService.searchPublic(params);
      if (data && data.items) {
        let filteredData = data.items;
        if (currentDataSourceIds && currentDataSourceIds.length > 0) {
          filteredData = data.items.filter((item) =>
            currentDataSourceIds.includes(item.id)
          );
        }
        setDataSource(filteredData);
        setIsLoading(false);
        setCurrentDataSourceIds(undefined);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataSourceCategory = async () => {
    try {
      const params = {
        //IsActivated: true,
      };
      const response = await DataSourceCategoryService.search(params);
      if (response?.items?.length === 0) {
        response.items = [
          {
            id: "-",
            name: "ไม่มีข้อมูล",
          },
        ];
      }
      setDataSourceCategory(response.items);
    } catch (error) {
      //setLoading(false);
      console.error(error);
    }
  };

  const tagRender = (props) => {
    const { label, value, color, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDataTags().then(() => {
      fetchDataSourceCategory().then(() => {
        if (config) {
          if (config.TagIds && config.TagIds.length > 0) {
            const tags = localDataTag
              .filter((tag) => config.TagIds.includes(tag.tagId))
              .map((tag) => tag.tagName)
              .join("|");
            const url = new URL(window.location.href);
            if (tags === "") {
              url.searchParams.delete("tag");
            } else {
              url.searchParams.set("tag", tags);
              tag = tags;
            }
            window.history.pushState({}, "", url);
          }
        }
        const param = new URLSearchParams(window.location.search);
        var tag = param.get("tag");
        var search = param.get("search");

        if (window.location.search) {
          var tagIds = [];
          if (tag) {
            const tags = tag.includes("|") ? tag.split("|") : [tag];
            localDataTag
              .filter((tag) => tags.includes(tag.tagName))
              .map((tag) => {
                tagIds.push(tag.tagId);
              });
          }
          if (search) {
            setExistsSearch(search);
            setKeywords(search);
          }
          setSelectedTags(tagIds);
        }
      });
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchDataSource();
  }, [selectedTags, selectedDate, keywords]);

  const headTaps = screens.lg ? (
    <div className="tabs-header">ประเภทระบบฐานข้อมูล</div>
  ) : (
    ""
  );

  const isSelectedTag = (key) => selectedTags.includes(key);
  const toggleButtonTag = (key, tag) => {
    if (selectedTags.includes(key)) {
      //setSelectedTags([]);
      selectedTags.splice(selectedTags.indexOf(key), 1);
      const url = new URL(window.location.href);
      if (selectedTags.length === 0) {
        url.searchParams.delete("tag");
        window.history.pushState({}, "", url);
        setSelectedTags([]);
      } else {
        var tags = dataTag
          .filter((tag) => selectedTags.includes(tag.tagId))
          .map((tag) => tag.tagName)
          .join("|");
        url.searchParams.set("tag", tags);
        window.history.pushState({}, "", url);
        setSelectedTags([...selectedTags]);
      }
      //fetchDataInsight();
    } else {
      selectedTags.push(key);

      const url = new URL(window.location.href);
      var tags = dataTag
        .filter((tag) => selectedTags.includes(tag.tagId))
        .map((tag) => tag.tagName)
        .join("|");
      url.searchParams.set("tag", tags);
      window.history.pushState({}, "", url);
      setSelectedTags([...selectedTags]);
      //fetchDataInsight();
    }
  };

  const toggleButtonTag2 = (key, tag) => {
    if (selectedTags.length === 1 && selectedTags[0] === key) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    setSelectedTags([key]);
    const url = new URL(window.location.href);
    if (key) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      url.searchParams.set("tag", tag);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      url.searchParams.delete("tag");
    }
    window.history.pushState({}, "", url);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleChangeDate = (dates, datesStr) => {
    let dateStr = "";
    if (dates != null) {
      dateStr = dates.format("YYYY-MM-DD");
    }
    setSelectedDate(dateStr);
  };

  const setLoading = (visible) => {
    if (visible) {
      setIsLoading(isLoading + 1);
    } else {
      setIsLoading(isLoading - 1);
    }
  };
  const handleClickOpenRequestApiPage = async () => {
    setLoading(true);
    if (sessionStorage.getItem("accessToken") === null) {
      localStorage.setItem("pageState", "/request-api");
      navigate("/login");
      return;
    }
    const responseUserMe = await UserService.getMe();
    UserService.getRequisitionsStatusByUserId(responseUserMe?.id).then(
      (data) => {
        if (data.isApproved) {
          navigate(`/request-api`);
        } else {
          navigate(`/new-request-api`);
        }
      }
    );
  };

  let handleInputSearchTimeout;
  const handleInputSearch = (value) => {
    setExistsSearch(value);
    if (handleInputSearchTimeout) clearTimeout(handleInputSearchTimeout);
    handleInputSearchTimeout = setTimeout(() => {
      setKeywords(value);
      const url = new URL(window.location.href);
      if (value) {
        url.searchParams.set("search", value);
      } else {
        url.searchParams.delete("search");
      }
      window.history.pushState({}, "", url);
      handleInputSearchTimeout = null;
    }, 1500);
  };

  return (
    <>
      <div className="datacatalog-body">
        <div className="datacatalog-content">
          <Tabs
            defaultActiveKey="1"
            tabPosition={screens.lg ? "left" : "top"}
            style={{
              height: "max",
              marginTop: screens.lg ? "0px" : "-25px",
            }}
            tabBarExtraContent={{ left: headTaps }}
          >
            {dataSourceCategory.map((itemCategory, index) => (
              <TabPane tab={itemCategory.name} key={itemCategory.id}>
                <>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        padding: "20px",
                        paddingLeft: "30px",
                        background: "#FFF",
                        borderRadius: "8px",
                      }}
                    >
                      {/* <Flex justify="space-between" align="center" style={{ paddingBottom: 20 }}> */}
                      <Row style={{ paddingBottom: 15 }}>
                        <Col xs={24} sm={12} md={14} lg={15}>
                          <span style={{ fontSize: "18px", fontWeight: "600" }}>
                            {" "}
                            ดาต้าแค็ตตาล๊อค{" "}
                          </span>
                        </Col>
                        <Col
                          xs={24}
                          sm={12}
                          md={10}
                          lg={9}
                          style={{
                            textAlign: "right",
                            paddingTop: !screens.md ? "15px" : "0px",
                          }}
                        >
                          <Row gutter={16}>
                            <Col span={12}>
                              <Button
                                type={""}
                                style={{
                                  fontWeight: 500,
                                  width: "100%",
                                  borderRadius: 10,
                                }}
                                onClick={() =>
                                  window.open(
                                    process.env.REACT_APP_API_DOC_URL,
                                    "_blank"
                                  )
                                }
                              >
                                <TbApi />
                                API Doc
                              </Button>
                            </Col>
                            <Col span={12}>
                              <Button
                                type={"primary"}
                                style={{ fontWeight: 500, width: "100%" }}
                                onClick={() => handleClickOpenRequestApiPage()}
                              >
                                <LiaReadme />
                                ขอใช้ API
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {/* <Flex justify="space-between" align="center" style={{ paddingBottom: 20 }}>
                              
                            </Flex> */}

                      {/* </Flex> */}
                      <hr />
                      <div className="datacatalog-tag">
                        <Row>
                          <Col xs={4} sm={3} md={4} lg={2} xl={2}>
                            <h3>Tag : </h3>
                          </Col>
                          <Col
                            sm={20}
                            md={20}
                            lg={22}
                            xl={20}
                            // flex={"20"}
                            className="group-btn-tag"
                            style={{ alignContent: "center" }}
                          >
                            <Flex wrap>
                              {dataTag?.map((tag) => (
                                <Button
                                  shape="round"
                                  key={tag.tagId}
                                  className={
                                    isSelectedTag(tag.tagId)
                                      ? "btn-tag-selected"
                                      : "btn-tag"
                                  }
                                  onClick={() =>
                                    toggleButtonTag(tag.tagId, tag.tagName)
                                  }
                                >
                                  {tag.tagName}
                                </Button>
                              ))}
                            </Flex>
                          </Col>
                        </Row>
                      </div>
                      <hr />

                      <div className="datacatalog-search">
                        <Row gutter={[16, 8]}>
                          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
                            <h3>กรองโดย : </h3>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={9}
                            lg={7}
                            xl={5}
                            xxl={5}
                            className="group-type"
                            style={{ alignContent: "center" }}
                          >
                            <DatePickerLocale
                              onChange={handleChangeDate}
                              value={selectedDate ? dayjs(selectedDate) : null}
                              style={{
                                width: "100%",
                                borderRadius: "8px",
                                fontSize: "14px",
                                marginLeft: "-15px",
                                marginRight: "-15px",
                              }}
                            />
                          </Col>
                          {/* <Col xs={24} sm={24} md={10} lg={5} xl={5} xxl={5}
                                        className="group-type"
                                        style={{alignContent:"center"}}
                                        >
                                            <Select
                                                size="large"
                                                mode="multiple"
                                                tagRender={tagRender}
                                                defaultValue={["all"]}
                                                //onChange={handleChangeType}
                                                //value={selectedInsightType}
                                                style={{
                                                width: "100%",
                                                }}
                                                options={dataSourceCategory}
                                            />
                                        </Col> */}
                          <Col
                            xs={24}
                            sm={24}
                            md={11}
                            lg={10}
                            xl={11}
                            xxl={11}
                            className="group-type"
                            style={{ alignContent: "center" }}
                          >
                            <Input
                              size="large"
                              //value={queryStr}
                              placeholder="ค้นหาข้อมูล..."
                              //onPressEnter={(e) => handleInputSearch(e.target.value)}
                              value={existsSearch}
                              onChange={(e) =>
                                handleInputSearch(e.target.value)
                              }
                              suffix={
                                <SearchOutlined
                                  // onClick={() => handleSearch()}
                                  style={{
                                    color: "rgba(0,0,0,.45)",
                                  }}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <div className="datacatalog-item">
                        <Row gutter={[16, 8]}>
                          {dataSource
                            ?.filter(
                              (item) =>
                                item.dataSourceCategoryId === itemCategory.id
                            )
                            //.sort((a, b) => a.sequence - b.sequence)
                            .map((item) => (
                              <Col xs={24} key={item.id}>
                                <div className="datacatalog-item-data">
                                  <Row justify={"space-between"}>
                                    <Col
                                      xs={24}
                                      sm={12}
                                      md={14}
                                      style={{ alignContent: "center" }}
                                    >
                                      <Row style={{ paddingBottom: 10 }}>
                                        <span
                                          className="link"
                                          onClick={() =>
                                            navigate(
                                              `/data-catalog/${item?.urlSlug}`
                                            )
                                          }
                                        >
                                          {item?.name}
                                        </span>
                                      </Row>

                                      <Row style={{ paddingBottom: 5 }}>
                                        <Flex wrap gap="small">
                                          {item?.dataSourceTags
                                            .slice(0, 3)
                                            .map((tag) => (
                                              <Tag
                                                key={tag.id}
                                                bordered={false}
                                                color={tag.tagColor}
                                                onClick={() =>
                                                  toggleButtonTag2(
                                                    tag.tagId,
                                                    tag.tagName
                                                  )
                                                }
                                                className="tag"
                                              >
                                                {tag.tagName}
                                              </Tag>
                                            ))}
                                        </Flex>
                                      </Row>
                                      <Row>
                                        <Flex wrap gap="small">
                                          <span
                                            style={{
                                              color: "#667085",
                                              marginBottom: 5,
                                            }}
                                          >
                                            อัพเดทล่าสุด :{" "}
                                            {item?.formattedUpdatedDate}
                                          </span>
                                        </Flex>
                                      </Row>
                                    </Col>
                                    <Col
                                      xs={24}
                                      sm={8}
                                      md={8}
                                      lg={6}
                                      xl={5}
                                      xxl={5}
                                      style={{
                                        alignContent: "start",
                                        textAlign: "end",
                                      }}
                                    >
                                      <Dropdown
                                        overlay={
                                          <Menu>
                                            <Menu.Item key="1">
                                              <div
                                                onClick={(e) => {
                                                  handleClickDownload(
                                                    item?.urlSlug,
                                                    "json"
                                                  );
                                                }}
                                              >
                                                ดาวน์โหลด JSON
                                              </div>
                                            </Menu.Item>
                                            <Menu.Item key="2">
                                              <div
                                                onClick={(e) => {
                                                  handleClickDownload(
                                                    item?.urlSlug,
                                                    "csv"
                                                  );
                                                }}
                                              >
                                                ดาวน์โหลด CSV
                                              </div>
                                            </Menu.Item>
                                          </Menu>
                                        }

                                        //trigger={['click']}
                                      >
                                        <Button
                                          type="default"
                                          className=""
                                          style={{
                                            border: "1px solid #1677FF",
                                            color: "#1677FF",
                                            borderRadius: "10px",
                                            width: "100%",
                                          }}
                                        >
                                          <UploadOutlined />
                                          <span className="">
                                            ดาวน์โหลดข้อมูล
                                          </span>
                                        </Button>
                                      </Dropdown>
                                    </Col>
                                  </Row>
                                  <Row justify={"space-between"}>
                                    <Col
                                      xs={24}
                                      style={{
                                        alignContent: "start",
                                        textAlign: "left",
                                      }}
                                    >
                                      {/* <p>{item.description}</p> */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </div>

                                <hr />
                              </Col>
                            ))}

                          {dataSource?.filter(
                            (item) =>
                              item.dataSourceCategoryId === itemCategory.id
                          ).length === 0 && (
                            <Col xs={24}>
                              <Flex
                                vertical
                                justify="center"
                                align="center"
                                style={{ height: "50vh" }}
                              >
                                <img src={`${NoData}`} />
                                <p
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#667085",
                                  }}
                                >
                                  ไม่มีข้อมูล
                                </p>
                              </Flex>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </Space>
                </>
              </TabPane>
            ))}
          </Tabs>

          
        </div>
        {/* <Radio.Group
            onChange={handleModeChange}
            value={mode}
            style={{
            marginBottom: 8,
            }}
        >
            <Radio.Button value="top">Horizontal</Radio.Button>
            <Radio.Button value="left">Vertical</Radio.Button>
        </Radio.Group> */}

        
      </div>
      <LoadingDialog isLoading={isLoading} />
    </>
  );
};

export default DataCatalog;
