import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const KeycloakService = {
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `Keycloak/users/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  roles: async function (params, cancel = false) {
    const response = await api.request({
      url: `Keycloak/roles`,
      method: "GET",
      params: params,
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  search: async function (params, cancel = false) {
    const response = await api.request({
      url: `Keycloak/users`,
      method: "GET",
      params: params,
      signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  create: async function (data, cancel = false) {
    await api.request({
      url: `Keycloak/users`,
      method: "POST",
      data: data,
      signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
    });
  },

  update: async function (id, data, cancel = false) {
    await api.request({
      url: `Keycloak/users/${id}`,
      method: "PUT",
      data: data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
  },

  delete: async function (id, cancel = false) {
    await api.request({
      url: `Keycloak/users/${id}`,
      method: "DELETE",
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    });
  },

  login: async function (credentials) {
    try {
      const response = await api.request({
        url: '/oauth/login',
        method: 'POST',
        isSelfErrorHandler: true,
        data: {
          username: credentials.username,
          password: credentials.password
        },
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  
  loginsso: async function (credentials) {
    try {
      const response = await api.request({
        url: '/oauth/login',
        method: 'POST',
        data: {
          username: credentials.username,
          password: credentials.password
        },
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },


  register: async function (payload) {
    try {
      
      const response = await api.request({
        url: '/users/register-user-keycloak',
        method: 'POST',
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
        isSelfErrorHandler: true
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },
  resendVerification: async function (token) {
    try {
      const response = await api.request({
        url: '/users/register-user-keycloak?token=' + token,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      //console.log('Response data:', response.data);
      return response;
    } catch (error) {
      console.error('Resend Verification failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  registerGoogle: async function (id, payload) {
    try {
      const response = await api.request({
        url: `/users?id=${id}`, 
        method: 'PUT',
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },
  

  registersso: async function (payload) {
    try {
      const response = await api.request({
        url: '/users/register-user-keycloak',
        method: 'POST',
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  recap: async function (recap) {
    try {
      const response = await api.request({
        url: '/oauth/google-recaptcha',
        method: 'POST',
        data: recap,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  verifyEmail: async function (token) {
    try {
      // ใช้ backticks เพื่อให้ `${token}` ทำงานได้
      const response = await api.request({
        url: `/users/verify-by-token?token=${token}`, // ใช้ backticks สำหรับ string interpolation
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      //console.log('Response data:', response);
      return response;
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  forgotPassWord: async function (token) {
    try {
      // ใช้ backticks เพื่อให้ `${token}` ทำงานได้
      const response = await api.request({
        url: `/users/verify-by-token?token=${token}`, // ใช้ backticks สำหรับ string interpolation
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  sendEmailForgot: async function (email) {
    try {
      const response = await api.request({
        url: `/users/send-email-forgot-password?email=${email}`, // ใช้ backticks สำหรับ string interpolation
        method: 'POST',
        isSelfErrorHandler: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  sendEmailForgotsso: async function (email) {
    try {
      // ใช้ backticks เพื่อให้ `${token}` ทำงานได้
      const response = await api.request({
        url: `/users/send-email-forgot-password?email=${email}`, // ใช้ backticks สำหรับ string interpolation
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  sendResetPassword: async function (token, password) {
    try {
      const response = await api.request({
        url: `/users/reset-password?token=${token}&password=${password}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Password reset failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },


  loginVerifyCodeGoogle: async function (code) {
    try {
      // ยังสามารถส่งคำขอ API ได้ตามปกติ
      const response = await api.request({
        url: '/oauth/code-authorization',
        method: 'POST',
        data: code,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  loginVerifyCodeGoogleGetme: async function (accessToken) {
    try {
      const response = await api.request({
        url: '/users/me',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      //console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Fetching user data failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
  





}

const cancelApiObject = defineCancelApiObject(KeycloakService);