import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Select, Checkbox, Modal, Grid } from 'antd';
import { ArrowLeftOutlined, PhoneOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import logo from '../../Login/logo 1.png';
import '../../Login/login.css';
import '../../register/register.css';
import { KeycloakService } from '../../../../services/KeycloakService';
import { LoadingDialog } from '../../../../components';
import { UserService } from '../../../../services/UserService';
import { ConsentService } from '../../../../services/ConsentService';

const { useBreakpoint } = Grid;


const RegisterGooglePage = () => {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [showOtherCategory, setShowOtherCategory] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const { userData } = location.state || {};
    const { responsCodegoogle } = location.state || {};
    const [isModalVisible, setIsModalVisible] = useState(false);

    //console.log("Received user data:", userData);
    //console.log("responsCodegoogle data:", responsCodegoogle);
    const initialValues = {
        name: userData?.firstName || '',
        surname: userData?.lastName || '',
        email: userData?.email || '',
    };

    const siteKey = process.env.REACT_APP_SITE_KEY_RECAPCHA;
    const secret = process.env.REACT_APP_SECRET_KEY_RECAPCHA;

    // โหลดสคริปต์ reCAPTCHA v3
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        document.head.appendChild(script);
    }, [siteKey]);

    const executeRecaptcha = async (action) => {
        return new Promise((resolve) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(siteKey, { action }).then((token) => {
                    resolve(token);
                });
            });
        });
    };

    const onFinish = async (values) => {
        setIsLoading(true);  // เริ่มการโหลด
        const token = await executeRecaptcha('login');

        if (token) {
            const recap = { secret, response: token };
            try {
                const responserecap = await KeycloakService.recap(recap);

                if (responserecap.success) {
                    const payload = {
                        id: userData.id,
                        keycloakUserId: userData.keycloakUserId,
                        firstName: values.name,
                        lastName: values.surname,
                        email: values.email,
                        createdTimestamp: Date.now(),
                        attributes: {
                            typeOfIndustries: [values.category],
                            company: [values.company],
                            position: [values.position],
                            tel: [values.phone],
                        },
                    };
                    //console.log("payload:", payload );
                    try {
                        const response = await KeycloakService.registerGoogle(userData.id,payload);
                        //console.log("User registered successfully:", response.data);
                        setIsLoading(false);
                        sessionStorage.setItem("accessToken", responsCodegoogle.accessToken);
                        sessionStorage.setItem("refreshToken", responsCodegoogle.refreshToken);

                        await UserService.getPermissions();
                        var hasConsent = await ConsentService.validate();
                        if (hasConsent) {
                            if(localStorage.getItem('pageState')){
                                var pageState = localStorage.getItem('pageState');
                                localStorage.removeItem('pageState');
                                navigate(pageState);
                                return;
                            }
                            navigate('/');
                        } else {
                            navigate('/confirm-consent');
                        }

                        // setFieldVisible(false);
                    } catch (error) {
                        setIsModalVisible(true);
                        setIsLoading(false);
                        console.error("Error while registering user:", error);
                    }
                } else { 
                    setIsLoading(false);
                    setIsModalVisible(true);
                }
            } catch (error) {
                console.error("Error while verifying reCAPTCHA:", error);
                setIsLoading(false);
                setIsModalVisible(true);
            }
        } else {
            setIsModalVisible(true);
        }
        setIsLoading(false);  // สิ้นสุดการโหลด
    };


    const handleOk = () => {
        setIsModalVisible(false); // ปิด modal เมื่อกดปุ่ม
    };



    const goBack = () => {
        navigate('/login');
    };

    const handleCategoryChange = (value) => {
        if (value === 'Other') {
            setShowOtherCategory(true);
        } else {
            setShowOtherCategory(false);
        }
    };

    return (
        <div className="login-container1">
            <Row>
                <Col span={18} md={18} xs={24}>
                
                <Row>
                    <Col span={24} md={24} xs={0}>
                    <div className="login-bg"></div>
                    <div className='login-left'>
                        <div className="logo">
                        <img
                            src={logo}
                            alt="TCEB Logo"
                            className="logo-image"
                            onClick={() => navigate('/')}
                            style={{ cursor: 'pointer' }}
                        />
                        </div>
                    </div>
                    <div className="copyright">
                        © 2567 สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)
                    </div>
                    </Col>
                    <Col span={24} xs={24} md={0}>
                    <div className="login-bg" style={{position:'fixed'}}></div>
                    <div className="logo" style={{textAlign:"center", marginTop:"15px"}}>
                        <img
                        src={logo}
                        alt="TCEB Logo"
                        className="logo-image"
                        onClick={() => navigate('/')}
                        style={{ cursor: 'pointer' }}
                        />
                    </div>
                    </Col>
                </Row>
                </Col>
                <Col span={6} md={6} xs={24}>
                    <div className="center-v">
                    
                            <div className="login-card" style={screens.xs || document.body.clientWidth <= 767 ? {marginLeft: "auto", marginRight: "auto", marginTop: "15px"} : { marginLeft: "-230px" }}>
                                <div className="header">
                                    <ArrowLeftOutlined
                                        style={{ marginRight: '8px', cursor: 'pointer' }}
                                        onClick={goBack} // เพิ่มฟังก์ชัน goBack ที่จัดการการคลิก
                                    />
                                    <p className="welcome-text" style={{ display: 'inline-block', margin: 0 }}>
                                        สมัครสมาชิก
                                    </p>
                                </div>
                                <Form
                                    form={form}
                                    name="register_form"
                                    initialValues={initialValues} // Set initial values here
                                    onFinish={onFinish} // Add form submission logic

                                >
                                    <Row gutter={8}> {/* ลด gutter */}
                                        <Col span={12}>
                                            <Form.Item
                                                name="name"
                                                label={
                                                    <span>
                                                        ชื่อ <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                            >
                                                <Input placeholder="กรุณากรอกชื่อ" />
                                            </Form.Item>
                                        </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="surname"
                                            label={
                                                <span>
                                                    นามสกุล <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                            required={false}
                                        >
                                            <Input placeholder="กรุณากรอกนามสกุล" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8}> {/* ลด gutter */}
                                    <Col span={12}>
                                        <Form.Item
                                            name="email"
                                            label={
                                                <span>
                                                    อีเมล <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                            required={false}
                                        >
                                            <Input placeholder="อีเมล" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="phone"
                                            label={
                                                <span>
                                                    เบอร์โทรศัพท์
                                                </span>
                                            }
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            // rules={[{ min: 10, message: 'ต้องมีความยาวอย่างน้อย 10 ตัวอักษร' }]}
                                            required={false}
                                        >
                                            <Input
                                                placeholder="080-000-0000"
                                                prefix={<PhoneOutlined style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8}> {/* ลด gutter */}
                                    <Col span={12}>
                                        <Form.Item
                                            name="position"
                                            label={
                                                <span>
                                                    ตำแหน่ง <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                            required={false}
                                        >
                                            <Input placeholder="ตำแหน่ง" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="category"
                                            label={
                                                <span>
                                                    ประเภทงาน <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                            required={false}
                                        >
                                            <Select placeholder="Select" onChange={handleCategoryChange}>
                                                <Select.Option value="Academia">Academia</Select.Option>
                                                <Select.Option value="Asset Management and Private Equity">Asset Management and Private Equity</Select.Option>
                                                <Select.Option value="Consulting">Consulting</Select.Option>
                                                <Select.Option value="Corporate Finance and Accounting">Corporate Finance and Accounting</Select.Option>
                                                <Select.Option value="Customer Service">Customer Service</Select.Option>
                                                <Select.Option value="Data and Analytics">Data and Analytics</Select.Option>
                                                <Select.Option value="Diversity and Inclusion">Diversity and Inclusion</Select.Option>
                                                <Select.Option value="Event Management">Event Management</Select.Option>
                                                <Select.Option value="Government">Government</Select.Option>
                                                <Select.Option value="Human Resources">Human Resources</Select.Option>
                                                <Select.Option value="IT and Technology">IT and Technology</Select.Option>
                                                <Select.Option value="Legal">Legal</Select.Option>
                                                <Select.Option value="Logistics/Transportation/Supply Chain">Logistics/Transportation/Supply Chain</Select.Option>
                                                <Select.Option value="Manufacturing">Manufacturing</Select.Option>
                                                <Select.Option value="Marketing">Marketing</Select.Option>
                                                <Select.Option value="Media and Press">Media and Press</Select.Option>
                                                <Select.Option value="Operations">Operations</Select.Option>
                                                <Select.Option value="Procurement">Procurement</Select.Option>
                                                <Select.Option value="Research and Development/Innovation">Research and Development/Innovation</Select.Option>
                                                <Select.Option value="Risk">Risk</Select.Option>
                                                <Select.Option value="Sales">Sales</Select.Option>
                                                <Select.Option value="Strategy">Strategy</Select.Option>
                                                <Select.Option value="Sustainability">Sustainability</Select.Option>
                                                <Select.Option value="Transformation">Transformation</Select.Option>
                                                <Select.Option value="Other">Other</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {showOtherCategory && (
                                    <Row gutter={8}>
                                        <Col span={12}>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="otherCategory"
                                                label="ประเภทงานอื่นๆ"
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                                required={false}
                                            >
                                                <Input placeholder="กรุณากรอกประเภทงานอื่นๆ" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}


                                <Form.Item
                                    name="terms"
                                    valuePropName="checked"
                                    rules={[{ required: true, message: 'กรุณายอมรับข้อกำหนดและเงื่อนไข' }]}
                                    required={false}
                                    style={{ marginBottom: '4px' }} // ปรับระยะห่างระหว่าง Form.Item
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox style={{ marginRight: '8px' }}>
                                            ข้าพเจ้ายอมรับและตกลงตาม <a href="">ข้อกำหนดและเงื่อนไขการให้บริการ</a>
                                        </Checkbox>
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name="privacyPolicy"
                                    valuePropName="checked"
                                    rules={[{ required: true, message: 'กรุณายอมรับนโยบายความเป็นส่วนตัว' }]}
                                    required={false}
                                    style={{ marginBottom: '4px' }} // ปรับระยะห่างระหว่าง Form.Item
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Checkbox style={{ marginBottom: '0px', marginRight: '8px' }}>
                                            <span style={{ lineHeight: '1' }}>
                                                ข้าพเจ้ายอมรับ <a href="">นโยบายความเป็นส่วนตัว</a> และยินยอมให้ TCEB
                                            </span>
                                        </Checkbox>
                                        <span style={{ marginLeft: '24px', lineHeight: '1.5' }}>
                                            เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า
                                        </span>
                                        <span style={{ marginLeft: '24px', lineHeight: '1.5' }}>
                                            เพื่อการจัดการบัญชีผู้ใช้การรวบรวมสถิติการให้บริการ
                                        </span>
                                        <span style={{ marginLeft: '24px', lineHeight: '1.5' }}>
                                            การจัดกิจกรรม และการปฏิบัติตามพันธกิจของ TCEB
                                        </span>
                                    </div>
                                </Form.Item>
                                <br />
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="register-form-button">
                                        สมัครสมาชิก
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
            <LoadingDialog isLoading={isLoading} />
            <Modal
                open={isModalVisible}
                onOk={handleOk}
                footer={null} // ไม่มีปุ่ม Footer
                closable={false} // ไม่สามารถปิด Modal ด้วยการกดปุ่มปิด
                centered // จัด Modal ให้อยู่กลางหน้าจอ
                width={300} // กำหนดความกว้างของ Modal
            >
                <div style={{ textAlign: 'center' }}>
                    <CloseCircleOutlined style={{ fontSize: '40px', color: '#E03A3A' }} /> {/* ลดขนาดไอคอน */}
                    <h2 style={{ marginTop: '16px' }}>ไม่สำเร็จ</h2>
                    <p style={{ margin: '16px 0', fontSize: '14px' }}>เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง</p> {/* ลดขนาดข้อความ */}
                    <Button type="primary" onClick={handleOk} style={{ backgroundColor: '#E03A3A', borderColor: '#E03A3A' }}>
                        ลองอีกครั้ง
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default RegisterGooglePage;

