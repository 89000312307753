import React from "react";
import FailedDialog from "./FailedDialog";


const NoAuthDialog = ({
  isModalOpen,
  onCancel,
  onAfterClose,
  onConfirm,
}) => {
  return (
    <>
      <FailedDialog
        isModalOpen={isModalOpen}
        closable={false}
        title={"ไม่สามารถเข้าถึงหน้านี้ได้"}
        detail={"ขออภัยคุณไม่มีสิทธิ์เข้าถึงหน้านี้"}
        onCancel={onCancel}
        onAfterClose={onAfterClose}
        onConfirm={onConfirm}
        closeButtonTitle={"ตกลง"}
        
    />
    </>
  );
};

export default NoAuthDialog;
