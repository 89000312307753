import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const UserDataSourcePermissionService = {
    
    revoke: async function (data, cancel = false) {
        const response = await api.request({
            url: `/user-data-source-permissions/revoke`,
            method: "DELETE",
            data:data,
            signal: cancel ? cancelApiObject[this.revoke.name].handleRequestCancellation().signal : undefined,
        });

        return response;
    },
}

const cancelApiObject = defineCancelApiObject(UserDataSourcePermissionService);