import React from "react";
import { Button, Modal, Flex, Row, Col } from "antd";
import IconSuccessBin from "../../assets/icon/icon_success_bin";
import IconSuccessDefault from "../../assets/icon/icon_success_default";

const SuccessDialog = ({
  isModalOpen,
  closable,
  title,
  detail,
  onCancel,
  onAfterClose,
  type,
}) => {
  return (
    <>
      <Modal
        title=""
        mask={false}
        open={isModalOpen}
        footer={null}
        closable={closable}
        destroyOnClose={true}
        onCancel={onCancel}
        afterClose={(e) => {
          onAfterClose();
        }}
        width={450}
        style={{
          top: 20,
          borderRadius: "18px",
          height: 85,
          overflow: "hidden",
          border: "1px solid #EBEBEB",
          boxShadow: "0px 4px 20px 10px #95E1B933"
        }}
        zIndex={2000}
        modalRender={(modal) => {
          return React.cloneElement(modal, {
            style: {
              ...modal.props.style,
              ...{ borderRadius: 0, padding: 0 },
            },
          });
        }}
      >
        <Row>
          <Col>
            {type !== "bin" ? <IconSuccessDefault style={{
              width: 86,
              height: 86,
            }}/> : <IconSuccessBin  style={{
              width: 86,
              height: 86,
            }}/>}
          </Col>
          <Col>
            <Flex align="center" style={{ height: "100%" }}>
              <div style={{ marginLeft: "20px" }}>
                <p
                  style={{
                    margin: 0,
                    color: "#101828",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {title}
                </p>
                <p
                  style={{
                    margin: 0,
                    color: "#667085",
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  {detail}
                </p>
              </div>
            </Flex>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SuccessDialog;
