import React, { useEffect, useState } from "react";
import "./ManagementLayout.css";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useUnsavedChangesWarning } from "../../../hooks";
import { Grid, Layout, Menu } from "antd";
import { LockOutlined, AuditOutlined } from "@ant-design/icons";
import { FaRegUserCircle, FaAlignLeft, FaRegCheckCircle } from "react-icons/fa";
import {
  AiOutlineTeam,
  AiOutlineCopy,
  AiOutlineSolution,
  AiOutlineTags,
  AiOutlineAppstoreAdd,
  AiOutlineContainer,
  AiOutlineCode,
  AiOutlineControl,
} from "react-icons/ai";
import { UserService } from "../../../services/UserService";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const ManagementLayout = () => {
  const { handleNavigation, DiscardDialogComponent } =
    useUnsavedChangesWarning();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [permissions, setPermissions] = useState([]);
  const [adminPermission, setAdminPermission] = useState(false);
  const screens = useBreakpoint();
  const [menuItems, setMenuItems] = useState([]);

  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];

  const fetchUserPermission = async () => {
    if (window.permissions) {
      let userPermission = await UserService.getPermissions();
      let permissions = userPermission.filter((p) => p.isViewable);
      window.permissions = permissions;
    }
    let permissions = window.permissions.map((p) => p.routePath);
    setAdminPermission(permissions.length > 0);
    let userMe = JSON.parse(sessionStorage.getItem("userMe"));
    if (userMe && userMe.idp === process.env.REACT_APP_NAME) {
      permissions = insert(permissions, 0, "/management/change-password");
    }
    permissions = insert(permissions, 0, "/management/consent-setting");
    permissions = insert(permissions, 0, "/management/request-api");
    permissions = insert(permissions, 0, "/management/account-management");
    setPermissions(permissions);
    if (
      !permissions.includes(currentPath) &&
      !currentPath.includes(currentPath.replace("-detail", ""))
    ) {
      navigate("/");
      return;
    }
  };

  useEffect(() => {
    fetchUserPermission();
  }, [currentPath]);

  useEffect(() => {
    if (!window.permissions) return;
    let items = [];
    const menuLabel = {};
    let addAdminGroupLabel = "/management/consent-setting";
    window.permissions.map((p) => {
      menuLabel[p.routePath] = p.pageName;
    });
    menuLabel["/management/account-management"] = "บัญชีของคุณ";
    menuLabel["/management/request-api"] = "ขอใช้ API";
    menuLabel["/management/consent-setting"] = "ตั้งค่าความยินยอมของคุณ";
    let userMe = JSON.parse(sessionStorage.getItem("userMe"));
    if (userMe && userMe.idp === process.env.REACT_APP_NAME) {
      menuLabel["/management/change-password"] = "เปลี่ยนรหัสผ่าน";
      addAdminGroupLabel = "/management/change-password";
    }

    let addConsentGroup = false;
    permissions.map((p, item) => {
      if (p.includes("/management")) {
        if (
          p === "/management/manage-consent" ||
          p === "/management/view-consent"
        ) {
          if (
            !addConsentGroup &&
            (p === "/management/manage-consent" ||
              p === "/management/view-consent")
          ) {
            addConsentGroup = true;
            items.push({
              key: "sub1",
              icon: <FaRegCheckCircle />,
              label: "จัดการข้อมูลคำยินยอมการใช้งาน",
              disabled: true,
              children: [
                {
                  key: "/management/manage-consent",
                  label: "สร้างข้อมูลคำยินยอมใช้งาน",
                },
                {
                  key: "/management/view-consent",
                  label: "การให้ความยินยอมการใช้งานข้อมูล",
                },
              ],
            });
          }
        } else {
          items.push({
            key: p,
            icon:
              p === "/management/account-management" ? (
                <FaRegUserCircle />
              ) : p === "/management/request-api" ? (
                <AiOutlineCode />
              ) : p === "/management/consent-setting" ? (
                <AiOutlineControl />
              ) : p === "/management/change-password" ? (
                <LockOutlined />
              ) : p === "/management/manage-user" ? (
                <AiOutlineTeam />
              ) : p === "/management/feedback" ? (
                <FaAlignLeft />
              ) : p === "/management/terms-and-conditions" ? (
                <AuditOutlined />
              ) : p === "/management/manage-consent" ? (
                <FaRegCheckCircle />
              ) : p === "/management/view-consent" ? (
                <FaRegCheckCircle />
              ) : p === "/management/role-management" ? (
                <AiOutlineSolution />
              ) : p === "/management/edit-tag" ? (
                <AiOutlineTags />
              ) : p === "/management/dashboard-management" ? (
                <AiOutlineAppstoreAdd />
              ) : p === "/management/insight-report-management" ? (
                <AiOutlineContainer />
              ) : p === "/management/datasource-management" ? (
                <AiOutlineCopy />
              ) : p === "/management/datasource-request" ? (
                <AiOutlineCode />
              ) : null,
            label: menuLabel[p],
          });
          if (adminPermission && p === addAdminGroupLabel) {
            items.push({
              key: "labelAdmin",
              label: <span style={{ fontWeight: "600" }}>ผู้ดูแลระบบ</span>,
              disabled: true,
            });
          }
        }
      }
    });
    setMenuItems(items);
  }, [permissions]);

  return (
    <Layout>
      {DiscardDialogComponent}
      <Sider
        style={{
          textAlign: "center",
          lineHeight: "120px",
          color: "#fff",
          backgroundColor: "rgba(255, 255, 255, 1)",
        }}
        className="admin-menu"
        width={
          !screens.md ||
          (window.screen.width < 850 &&
            window.screen.height < window.screen.width)
            ? 0
            : 350
        }
      >
        <div className="menu-container">
          <Menu
            mode="inline"
            defaultSelectedKeys={currentPath}
            openKeys={["sub1"]}
            onClick={(e) => {
              handleNavigation(e.key);
            }}
            items={menuItems}
          ></Menu>
        </div>
      </Sider>
      <Layout
        className="content-body"
        style={{
          marginLeft:
            !screens.md ||
            (window.screen.width < 850 &&
              window.screen.height < window.screen.width)
              ? 0
              : 350,
        }}
      >
        <div>
          <Outlet />
        </div>
      </Layout>
    </Layout>
  );
};

export default ManagementLayout;
