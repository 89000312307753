import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, Modal, Col, Row, Select, Checkbox, Grid } from 'antd';
import { MailOutlined, LockOutlined, EyeOutlined, EyeInvisibleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ArrowLeftOutlined, PhoneOutlined } from '@ant-design/icons';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import logo from './logo 1.png';
import google from './google.png';
import logo2 from './logo 2.png';
import { useNavigate } from 'react-router-dom';
import { KeycloakService } from '../../../services/KeycloakService';
import './login.css'; // For custom styling
import key from '../../Main/register/key.png';
import { UserService } from '../../../services/UserService';
import { LoadingDialog, FailedDialog } from '../../../components';
import { ConsentService } from '../../../services/ConsentService';

const { useBreakpoint } = Grid;

const LoginPage = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  
  const registerName = Form.useWatch('name', form);
  const registerSurname = Form.useWatch('surname', form);
  const registerEmail = Form.useWatch('email', form);
  const registerPosition = Form.useWatch('position', form);
  const registerCategory = Form.useWatch('category', form);
  const registerTerms = Form.useWatch('terms', form);
  const registerConsent = Form.useWatch('privacyPolicy', form);
  const registerPassword = Form.useWatch('password', form);
  const registerConfirmPassword = Form.useWatch('confirmPassword', form);

  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleConsent, setIsModalVisibleConsent] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const consentBoxRef = useRef(null);
  const [showAcceptButton, setShowAcceptButton] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [showOtherCategory, setShowOtherCategory] = useState(false);
  const [isAcceptConsent, setIsAcceptConsent] = useState(false);
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [data, setData] = useState({});
  const [email, setEmail] = useState('');
  const [jobFunction, setJobFuntion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState({
      length: false,
      uppercase: false,
      lowercase: false,
      digit: false,
      specialChar: false,
  });
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);

  const siteKey = process.env.REACT_APP_SITE_KEY_RECAPCHA;
  const secret = process.env.REACT_APP_SECRET_KEY_RECAPCHA;
  const loginGoogle = process.env.REACT_APP_LOGIN_GOOGLE;
  const loginSSO = process.env.REACT_APP_LOGIN_SSO;

  
  useEffect(() => {
    UserService.getJobFunction().then((jobFunction) => {
      setJobFuntion(jobFunction)
    });
  }, []);
  
  // โหลดสคริปต์ reCAPTCHA v3
  useEffect(() => {
    //console.log(siteKey)
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    document.head.appendChild(script);
  }, [siteKey]);

  

  const verifyingRecaptcha = async () => {
    return new Promise(async (resolve, reject) => {
      const token = await executeRecaptcha('login');
      setIsLoading(true);
      if (token) {
          const recap = {
              secret: secret,
              response: token
          };
          try {
              const responserecap = await KeycloakService.recap(recap);
              if (responserecap.success == true) {
                  //console.log("reCAPTCHA success");
                  resolve(true);
              }
          } catch (error) {
              setIsLoading(false);
              setIsModalFailedOpen(true);
              setErrorMessage("ไม่สามารถตรวจสอบ reCAPTCHA");
              resolve(false);
          }
      }else{
          setIsLoading(false);
          setIsModalFailedOpen(true);
          setErrorMessage('ไม่สามารถยืนยัน reCAPTCHA ได้');
          resolve(false);
      }
    });
  };

  const executeRecaptcha = async (action) => {
    return new Promise((resolve) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(siteKey, { action }).then((token) => {
          resolve(token);
        });
      });
    });
  };
  const handlePasswordChange = (e) => {
      const newPassword = e.target.value;
      // Validate password rules
      setIsValid({
          length: newPassword.length >= 8 && newPassword.length <= 20,
          uppercase: /[A-Z]/.test(newPassword),
          lowercase: /[a-z]/.test(newPassword),
          digit: /\d/.test(newPassword),
          specialChar: /[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|]/.test(newPassword),
      });
  };
  const toggleVisibility = () => {
    setVisible(!visible); // เปลี่ยนสถานะเปิด/ปิด
  };

  const onFinish = async (values) => {

    verifyingRecaptcha().then(async(result) => {
      if (result) {
        try {
          setIsLoading(true);
          const response = await KeycloakService.login(values);
          //console.log('Login successful:', response);
          setIsLoading(false);
          setData(response);
          sessionStorage.setItem("accessToken", response.accessToken);
          sessionStorage.setItem("refreshToken", response.refreshToken);
          await UserService.getPermissions();

          var hasConsent = await ConsentService.validate();
          if (hasConsent) {
            if(localStorage.getItem('pageState')){
              var pageState = localStorage.getItem('pageState');
              localStorage.removeItem('pageState');
              navigate(pageState);
              return;
            }
            navigate('/');
          } else {
            navigate('/confirm-consent');
          }
        } catch (error) {
          setIsLoading(false);
          // console.error();
          setIsModalFailedOpen(true);
          setErrorMessage(error?.response?.data?.messageTh);
        }
      }
    });
  };

  const onRegisterFinish = async (values) => {
    setEmail(values.email);
    verifyingRecaptcha().then(async (result) => {
      if (result) {
        const payload = {
          username: values.email,
          firstName: values.name,
          lastName: values.surname,
          email: values.email,
          emailVerified: false,
          enabled: true,
          attributes: {
              typeOfIndustries: [values.category === 'Other' ? values.otherCategory : values.category],
              company: [values.company],
              position: [values.position],
              tel: [values.phone],
          },
          credentials: [{ temporary: false, type: "password", value: values.password }],
        };
        try {
            setIsLoading(true);
            const response = await KeycloakService.register(payload);
            //console.log("User registered successfully:", response.data);
            setIsLoading(false);
            setIsRegisterSuccess(true);
        } catch (error) {
            setErrorMessage(error.response?.data?.messageTh);
            setIsModalFailedOpen(true);
            // setIsModalVisible(true);
            setIsLoading(false);
        }
      }
    });
    
    
  };

  const handleOk = () => {
    setIsModalVisible(false); // ปิด modal เมื่อกดปุ่ม
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = consentBoxRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 5) { // -5 เพื่อป้องกันการเลื่อนเกิน
      setShowAcceptButton(true);
    } else {
      setShowAcceptButton(false);
    }
  };

  const consent = () => {
    // setIsModalVisibleConsent(true);
    setIsModalFailedOpen(true);
  };

  const handleGoogleLogin = () => {
    window.location.href = loginGoogle;
    // window.location.href = 'https://keycloak.ccs-service.online/realms/tceb-web-portal/protocol/openid-connect/auth?client_id=tceb-web-portal-client&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fverifyemail&kc_idp_hint=google&response_mode=fragment&response_type=code&scope=openid';
  };

  const handleLoginSSO = () => {
    window.location.href = loginSSO;
  }
  const handleCategoryChange = (value) => {
    if (value === 'Other') {
        setShowOtherCategory(true);
    } else {
        setShowOtherCategory(false);
    }
  };

  const checkFormValidation = () => {
    if(isAcceptConsent && isAcceptTerms && isValid.digit && isValid.length && isValid.lowercase && isValid.specialChar && isValid.uppercase 
        && registerName && registerSurname && registerEmail && registerPosition && registerCategory && registerTerms && registerConsent
        && registerPassword && registerConfirmPassword && registerPassword == registerConfirmPassword){
        return false;
    }
    return true;
}

  return (

    <div className="login-container1">
    
      <Row>
        <Col span={18} md={18} xs={24}>
          
          <Row>
            <Col span={24} md={24} xs={0}>
              <div className="login-bg" style={{position:'fixed', width: screens.md ? '75%' : '100%'}}></div>
              <div className='login-left'>
                <div className="logo">
                  <img
                    src={logo}
                    alt="TCEB Logo"
                    className="logo-image"
                    onClick={() => navigate('/')}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
              <div className="copyright">
                © 2567 สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)
              </div>
            </Col>
            <Col span={24} xs={24} md={0}>
            <div className="login-bg" style={{position:'fixed'}}></div>
              <div className="logo" style={{textAlign:"center", marginTop:"15px"}}>
                <img
                  src={logo}
                  alt="TCEB Logo"
                  className="logo-image"
                  onClick={() => navigate('/')}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={6} md={6} xs={24}>
          <div className="center-v">
          {!isLoggedIn && (
            isRegister ? 
            isRegisterSuccess ? (
              <div className="login-card">
                        <center>
                        <img
                            src={key}
                            alt="key"
                            className="logo-image"
                            style={{ width: '100px', height: 'auto' }}
                        />
                        </center>
                        <p
                            style={{
                                margin: '20px 0 0 0', // กำหนดระยะห่างด้านบนให้กับพารากราฟ
                                textAlign: 'center',
                                fontSize: '18px',
                                fontWeight: 'bold',
                            }}
                        >
                            อีเมลถูกส่งแล้ว
                        </p>
                        <span style={{ display: 'block', margin: '10px 0', textAlign:"center" }}> {/* ใช้ margin เพื่อเพิ่มระยะห่าง */}
                            ระบบได้ทำการส่งอีเมลยืนยันตัวตนไปที่ <b>{email}</b> กรุณา คลิกลิงก์ในอีเมลเพื่อยืนยันตัวตน
                        </span>
                        <Button type="primary" htmlType="submit" className="register-form-button" onClick={()=>{
                          setIsRegisterSuccess(false);
                          setIsRegister(false);
                          form.resetFields();
                          }} >
                            รับทราบ
                        </Button>
                    </div>
 
              ):
            (
              <div className="login-card">
                  <div className="header">
                      <ArrowLeftOutlined
                          style={{ marginRight: '8px', cursor: 'pointer' }}
                          onClick={()=>{setIsRegister(false)}} // เพิ่มฟังก์ชัน goBack ที่จัดการการคลิก
                      />
                      <p className="welcome-text" style={{ display: 'inline-block', margin: 0 }}>
                          สมัครสมาชิก
                      </p>
                  </div>
                  <Form
                      form={form} // เชื่อมโยง form instance
                      name="register_form"
                      className="register-form"
                      onFinish={onRegisterFinish}
                      initialValues={{ remember: true }}
                  >
                      <Row gutter={8}> {/* ลด gutter */}
                          <Col span={12}>
                              <Form.Item
                                  name="name"
                                  label={
                                      <span>
                                          ชื่อ <span style={{ color: 'red' }}>*</span>
                                      </span>
                                  }
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                  required={false}
                              >
                                  <Input placeholder="กรุณากรอกชื่อ" />
                              </Form.Item>
                          </Col>

                          <Col span={12}>
                              <Form.Item
                                  name="surname"
                                  label={
                                      <span>
                                          นามสกุล <span style={{ color: 'red' }}>*</span>
                                      </span>
                                  }
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                  required={false}
                              >
                                  <Input placeholder="กรุณากรอกนามสกุล" />
                              </Form.Item>
                          </Col>
                      </Row>

                      <Row gutter={8}> {/* ลด gutter */}
                          <Col span={12}>
                              <Form.Item
                                  name="email"
                                  label={
                                      <span>
                                          อีเมล <span style={{ color: 'red' }}>*</span>
                                      </span>
                                  }
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                  required={false}
                              >
                                  <Input placeholder="อีเมล" />
                              </Form.Item>
                          </Col>

                          <Col span={12}>
                              <Form.Item
                                  name="phone"
                                  label={
                                      <span>
                                          เบอร์โทรศัพท์
                                      </span>
                                  }
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  // rules={[{ min: 10, message: 'ต้องมีความยาวอย่างน้อย 10 ตัวอักษร' }]}
                                  required={false}
                              >
                                  <Input
                                      placeholder="080-000-0000"
                                      prefix={<PhoneOutlined style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
                                  />
                              </Form.Item>
                          </Col>
                      </Row>

                      <Row gutter={8}> {/* ลด gutter */}
                          <Col span={12}>
                              <Form.Item
                                  name="position"
                                  label={
                                      <span>
                                          ตำแหน่ง <span style={{ color: 'red' }}>*</span>
                                      </span>
                                  }
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                  required={false}
                              >
                                  <Input placeholder="ตำแหน่ง" />
                              </Form.Item>
                          </Col>

                          <Col span={12}>
                              <Form.Item
                                  name="category"
                                  label={
                                      <span>
                                          ประเภทงาน <span style={{ color: 'red' }}>*</span>
                                      </span>
                                  }
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                  required={false}
                              >
                                  <Select placeholder="Select" onChange={handleCategoryChange}>
                                      {jobFunction.map((item) => (
                                          <Select.Option key={item} value={item}>{item}</Select.Option>
                                      ))}
                                  </Select>
                              </Form.Item>
                          </Col>
                      </Row>

                      {showOtherCategory && (
                          <Row gutter={8}>
                              <Col span={12}>
                              </Col>
                              <Col span={12}>
                                  <Form.Item
                                      name="otherCategory"
                                      label="ประเภทงานอื่นๆ"
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                      required={false}
                                  >
                                      <Input placeholder="กรุณากรอกประเภทงานอื่นๆ" />
                                  </Form.Item>
                              </Col>
                          </Row>
                      )}

                      <Row gutter={8}>
                          <Col span={12}>
                              <Form.Item
                                  name="password"
                                  label={
                                      <span>
                                          รหัสผ่าน <span style={{ color: 'red' }}>*</span>
                                      </span>
                                  }
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                                  required={false}
                                  onChange={handlePasswordChange}
                              >
                                  <Input.Password
                                      placeholder="กรุณากรอกรหัสผ่าน"
                                      prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
                                  />
                              </Form.Item>
                          </Col>

                          <Col span={12}>
                              <Form.Item
                                  name="confirmPassword"
                                  label={
                                      <span>
                                          ยืนยันรหัสผ่าน <span style={{ color: 'red' }}>*</span>
                                      </span>
                                  }
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  dependencies={['password']}
                                  rules={[
                                      { required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' },
                                      ({ getFieldValue }) => ({
                                          validator(_, value) {
                                              if (!value || getFieldValue('password') === value) {
                                                  return Promise.resolve();
                                              }
                                              return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'));
                                          },
                                      }),
                                  ]}
                                  required={false}
                              >
                                  <Input.Password
                                      placeholder="กรุณากรอกรหัสผ่าน"
                                      prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
                                  />
                              </Form.Item>
                          </Col>
                      </Row>

                      {/* Password Validation Checklist */}
                      <div style={{ textAlign: 'left', marginBottom: '1px' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                              <CheckCircleIcon
                                  style={{
                                      width: '20px',
                                      height: '20px',
                                      color: isValid.length ? '#1AC487' : 'gray',
                                  }}
                              />
                              <span style={{ color: isValid.length ? '#1AC487' : 'gray', marginLeft: '8px' }}>ความยาวรหัสผ่านต้องมีอย่างน้อย 8 - 20 ตัวอักษร</span>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                              <CheckCircleIcon
                                  style={{
                                      width: '20px',
                                      height: '20px',
                                      color: isValid.uppercase ? '#1AC487' : 'gray',
                                  }}
                              />
                              <span style={{ color: isValid.uppercase ? '#1AC487' : 'gray', marginLeft: '8px' }}>ต้องมีตัวอักษรพิมพ์ใหญ่</span>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                              <CheckCircleIcon
                                  style={{
                                      width: '20px',
                                      height: '20px',
                                      color: isValid.lowercase ? '#1AC487' : 'gray',
                                  }}
                              />
                              <span style={{ color: isValid.lowercase ? '#1AC487' : 'gray', marginLeft: '8px' }}>ต้องมีตัวอักษรพิมพ์เล็ก</span>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                              <CheckCircleIcon
                                  style={{
                                      width: '20px',
                                      height: '20px',
                                      color: isValid.digit ? '#1AC487' : 'gray',
                                  }}
                              />
                              <span style={{ color: isValid.digit ? '#1AC487' : 'gray', marginLeft: '8px' }}>ต้องมีหมายเลข (ตัวเลข)</span>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                              <CheckCircleIcon
                                  style={{
                                      width: '20px',
                                      height: '20px',
                                      color: isValid.specialChar ? '#1AC487' : 'gray',
                                  }}
                              />
                              <span style={{ color: isValid.specialChar ? '#1AC487' : 'gray', marginLeft: '8px' }}>ต้องมีอักขระพิเศษ [*.!@#&amp;$%^&amp;(){ }[]:;&lt;&gt;,.?/~_+-=|]</span>
                          </div>
                      </div>


                      <Form.Item
                          name="terms"
                          valuePropName="checked"
                          rules={[{ required: true, message: 'กรุณายอมรับข้อกำหนดและเงื่อนไข' }]}
                          required={false}
                          style={{ marginBottom: '4px' }} // ปรับระยะห่างระหว่าง Form.Item
                      >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Checkbox style={{ marginRight: '8px' }} onChange={e => setIsAcceptTerms(e.target.checked)}>
                                  ข้าพเจ้ายอมรับและตกลงตาม <a href={`/terms-and-condition`} target='_black'>ข้อกำหนดและเงื่อนไขการให้บริการ</a>
                              </Checkbox>
                          </div>
                      </Form.Item>

                      <Form.Item
                          name="privacyPolicy"
                          valuePropName="checked"
                          rules={[{ required: true, message: 'กรุณายอมรับนโยบายความเป็นส่วนตัว' }]}
                          required={false}
                          style={{ marginBottom: '4px' }} // ปรับระยะห่างระหว่าง Form.Item
                      >
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <Checkbox style={{ marginBottom: '0px', marginRight: '8px' }} onChange={e => setIsAcceptConsent(e.target.checked)}>
                                  <span style={{ lineHeight: '1' }}>
                                      ข้าพเจ้ายอมรับ <a href={`/privacy`} target='_black'>นโยบายความเป็นส่วนตัว</a> และยินยอมให้ TCEB
                                  </span>
                              </Checkbox>
                              <span style={{ marginLeft: '24px', lineHeight: '1.5' }}>
                                  เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า
                              </span>
                              <span style={{ marginLeft: '24px', lineHeight: '1.5' }}>
                                  เพื่อการจัดการบัญชีผู้ใช้การรวบรวมสถิติการให้บริการ
                              </span>
                              <span style={{ marginLeft: '24px', lineHeight: '1.5' }}>
                                  การจัดกิจกรรม และการปฏิบัติตามพันธกิจของ TCEB
                              </span>
                          </div>
                      </Form.Item>
                      <br />
                      <Form.Item style={{marginBottom:"10px"}}>
                          <Button type="primary" htmlType="submit" className="register-form-button" disabled={checkFormValidation()}>
                              สมัครสมาชิก
                          </Button>
                      </Form.Item>
                  </Form>
              </div>
            ):(
              <div className="login-card"
                style={screens.xs || document.body.clientWidth <= 767 ? {marginLeft: "auto", marginRight: "auto", marginTop: "15px"} : { marginLeft: "-230px" }}
              >
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between', 
                  alignItems: 'center'
                }}>
                  <p className="welcome-text" style={{ margin: 0 }}>ยินดีต้อนรับ !</p>
                  <Button
                    type="default"
                    className="home-button"
                    onClick={() => {
                      navigate('/');
                      window.location.reload();
                    }}
                    style={{
                      backgroundColor: '#F0F5FF',
                      borderColor: '#F0F5FF',
                      color: '#0958D9',
                    }}
                  >
                    กลับไปหน้าหลัก
                  </Button>
                </div>

                <p className="description-text">เข้าสู่ระบบ/ลงทะเบียน</p>

                {/* แสดงข้อความ error หากมี */}
                {errorMessage && (
                  <div style={{
                    color: '#E03A3A',
                    border: '1px solid #E03A3A',
                    padding: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#FEE2E2',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '16px'
                  }}>
                    <CloseCircleOutlined style={{ fontSize: '18px', marginRight: '8px' }} />
                    <span>{errorMessage}</span>
                  </div>
                )}

                {/* ฟอร์มเข้าสู่ระบบ */}
                <Form
                  name="login_form"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <label style={{ fontWeight: 700 }}>อีเมล</label>
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'กรุณากรอกอีเมล' }]}
                    style={{ marginBottom: '16px' }}
                  >
                    <Input prefix={<MailOutlined style={{ color: 'rgba(0, 0, 0, 0.5)', minHeight: '30px' }} />} placeholder="อีเมล" />
                  </Form.Item>

                  <label style={{ fontWeight: 700 }}>รหัสผ่าน</label>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
                    style={{ marginBottom: '16px' }}
                  >
                    <Input
                      prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.5)', minHeight: '30px' }} />}
                      type={visible ? 'text' : 'password'}
                      placeholder="รหัสผ่าน"
                      suffix={visible ? (
                        <EyeInvisibleOutlined onClick={toggleVisibility} style={{ cursor: 'pointer' }} />
                      ) : (
                        <EyeOutlined onClick={toggleVisibility} style={{ cursor: 'pointer' }} />
                      )}
                    />
                  </Form.Item>

                  <Form.Item style={{ marginBottom: '16px' }}>
                    <a className="login-form-forgot" href="/forgot">
                      ลืมรหัสผ่าน ?
                    </a>
                  </Form.Item>

                  <Form.Item style={{ marginBottom: '16px' }}>
                    <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%', minHeight: '40px', display: 'flex', alignItems: 'center' }}>
                      เข้าสู่ระบบ
                    </Button>
                  </Form.Item>

                  <Button className="tceb-login-button" style={{ width: '100%', display: 'flex', minHeight: '40px', alignItems: 'center', marginBottom: '16px' }}
                    onClick={handleLoginSSO}>
                    <img src={logo2} alt="TCEB Logo" style={{ marginRight: '8px', width: '20px', height: '20px' }} />
                    เข้าสู่ระบบด้วย TCEB SSO
                  </Button>

                  <Button className="google-login-button" style={{ width: '100%', display: 'flex', minHeight: '40px', alignItems: 'center', marginBottom: '16px' }}
                    onClick={handleGoogleLogin}
                  >
                    <img src={google} alt="Google Logo" style={{ marginRight: '8px', width: '20px', height: '20px' }} />
                    เข้าสู่ระบบด้วย Google
                  </Button>

                  <div className="separator" style={{ marginBottom: '16px' }}>หรือ</div>

                  <Form.Item style={{ marginBottom: '16px' }}>
                    <Button
                      type="default"
                      className="register-button"
                      onClick={() => { setIsRegister(true) }}
                    >
                      ลงทะเบียน
                    </Button>
                  </Form.Item>
                </Form>
              </div>   
            )
          )}
          </div>   
        </Col>
      </Row>
      {
        isLoggedIn && (
          <div className="login-right">
            <div className="login-card">
              <div
                ref={consentBoxRef}
                style={{ maxHeight: '300px', overflowY: 'scroll', padding: '10px', border: '1px solid #ccc' }}
                onScroll={handleScroll}
              >
                <h3>ข้อมูลคำยินยอมการใช้งาน</h3>
                <p>[ชื่อบริษัท]</p>
                <p>(“บริษัท) ให้ความสำคัญกับความเป็นส่วนตัวของท่านบริษัทขอความยินยอมจากท่าน...</p>
                <h3>ความยินยอมให้เรานำเสนอผลิตภัณฑ์ และบริการ</h3>
                <p>เพื่อให้ท่านไม่พลาดสิทธิพิเศษ ข่าวสาร ข้อเสนอผลิตภัณฑ์และบริการ...</p>
                <p><strong>หมายเหตุ</strong>: การให้หรือไม่ให้ความยินยอมไม่มีผลต่อการใช้ผลิตภัณฑ์...</p>
              </div>
              <br />
              อัปเดทล่าสุดวันที่ : 04/09/2024 11:23
              <hr />
              <br />
              {/* แสดงปุ่มยอมรับและไม่ยอมรับ */}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Button type="default" style={{ flex: 1, marginRight: '5px' }} onClick={consent}>
                  ไม่ยอมรับคำยินยอม
                </Button>
                <Button
                  type="default"
                  style={{
                    flex: 1,
                    marginLeft: '5px',
                    backgroundColor: showAcceptButton ? '#63E1E1' : '', // ใช้สี 63E1E1 เมื่อปุ่มใช้งานได้
                    borderColor: showAcceptButton ? '#63E1E1' : '', // ใช้สีขอบ 63E1E1 เมื่อปุ่มใช้งานได้
                    color: showAcceptButton ? '#000000' : '', // เปลี่ยนสีข้อความเป็นสีขาวเมื่อปุ่มใช้งานได้
                  }}
                  disabled={!showAcceptButton} // ปรับให้ปุ่มถูกปิดใช้งานตามค่า showAcceptButton
                >
                  ยอมรับคำยินยอม
                </Button>
              </div>
            </div>
          </div>
        )
      }
      <LoadingDialog isLoading={isLoading} />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title=""
        detail={errorMessage}
        onCancel={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
        onAfterClose={() => {}}
        onConfirm={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
      />

      {/* <Modal
        open={isModalVisibleConsent}
        onOk={handleOk}
        footer={null} // ไม่มีปุ่ม Footer
        closable={false} // ไม่สามารถปิด Modal ด้วยการกดปุ่มปิด
        centered // จัด Modal ให้อยู่กลางหน้าจอ
        width={300} // กำหนดความกว้างของ Modal
      >
        <div style={{ textAlign: 'center' }}>
          <CloseCircleOutlined style={{ fontSize: '40px', color: '#E03A3A' }} />
          <h2 style={{ marginTop: '16px' }}>ไม่สำเร็จ</h2>
          <p style={{ margin: '16px 0', fontSize: '14px' }}>เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง</p> 
          <Button type="primary" onClick={handleOk} style={{ backgroundColor: '#E03A3A', borderColor: '#E03A3A' }}>
            ยกเลิก
          </Button>
          <Button type="primary" onClick={handleOk} style={{ backgroundColor: '#E03A3A', borderColor: '#E03A3A', }}>
            ยืนยัน
          </Button>
        </div>
      </Modal> */}


      {/* <Modal
        open={isModalVisible}
        onOk={handleOk}
        footer={null} // ไม่มีปุ่ม Footer
        closable={false} // ไม่สามารถปิด Modal ด้วยการกดปุ่มปิด
        centered // จัด Modal ให้อยู่กลางหน้าจอ
        width={300} // กำหนดความกว้างของ Modal
      >
        <div style={{ textAlign: 'center' }}>
          <CloseCircleOutlined style={{ fontSize: '40px', color: '#E03A3A' }} />
          <h2 style={{ marginTop: '16px' }}>ไม่สำเร็จ</h2>
          <p style={{ margin: '16px 0', fontSize: '14px' }}>{errorMessage}</p>
          <Button type="primary" onClick={handleOk} style={{ backgroundColor: '#E03A3A', borderColor: '#E03A3A' }}>
            ลองอีกครั้ง
          </Button>
        </div>
      </Modal> */}
    </div >
  );
};

export default LoginPage;
