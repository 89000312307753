import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  createContext,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUnsavedChangesContext } from "../../../../../contexts";
import { useUnsavedChangesWarning } from "../../../../../hooks";
import "./InsightReportManagement.css";
import {
  Layout,
  Flex,
  Button,
  Divider,
  Input,
  Menu,
  Space,
  message,
  Form,
  Row,
  Col,
  Spin,
  Grid,
  Dropdown,
} from "antd";
import {
  DeleteDialog,
  DiscardDialog,
  LoadingDialog,
  FailedDialog,
} from "../../../../../components";
import { MenuFoldOutlined } from "@ant-design/icons";
import {
  AiOutlineSearch,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlinePlusCircle,
  AiOutlineEdit,
  AiOutlineCaretUp,
  AiOutlineCaretDown,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import NoData from "../../../../../assets/img/no_data.js";
import InsightReportFormDetail from "./InsightReportFormDetail";
import { InsightReportService } from "../../../../../services/InsightReportService";
import { InsightReportCategoryService } from "../../../../../services/InsightReportCategoryService";
import { UserService } from "../../../../../services/UserService.js";
import { v4 as uuidv4 } from "uuid";

export const AppContext = createContext(null);
const { useBreakpoint } = Grid;
const { Header, Content, Sider } = Layout;

const InsightReportManagementDetail = () => {
  const { isDataModified, setIsDataModified } = useUnsavedChangesContext();
  const {
    handleNavigation,
    handleUnsavedConfirm,
    handleUnsavedCancel,
    setIsUnsavedModalOpen,
    isUnsavedModalOpen,
  } = useUnsavedChangesWarning();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [formEditMenu] = Form.useForm();
  const [select, setSelect] = useState();
  const [addCategory, setAddCategory] = useState(false);
  const [detailList, setDetailList] = useStateCallback([]);
  const [insightCategory, setInsightCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [selectEdit, setSelectEdit] = useState();
  const [selectDelete, setSelectDelete] = useState();
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  // const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const [targetId, setTargetId] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();
  const [selectMenuItem, setSelectMenuItem] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const contentRef = useRef(null);
  const dataRef = useRef(null);

  const passData = useLocation();
  let categoryId = "-";
  if (passData.state !== null && passData.state.categoryId !== null) {
    categoryId = passData.state.categoryId;
  }
  let tmpSelectedMenu = null;
  if (passData.state !== null && passData.state.selectedMenu !== null) {
    tmpSelectedMenu = passData.state.selectedMenu;
  }
  const [prevId, setPrevId] = useState();
  // const [isChanged, setIsChanged] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  const [currentMenu, setCurrentMenu] = useState(passData?.state?.selectedMenu?.name);
  let isFirstSetData = true;

  useEffect(() => {
    if (isFirstSetData) {
      setSelect(categoryId);
      setPrevId(categoryId);
      setSelectedMenu(tmpSelectedMenu);
      isFirstSetData = false;
      UserService.isEditable().then((res) => {
        setIsEditable(res);
      });
    }
  }, []);

  useEffect(() => {
    if (select !== undefined) {
      fetchData();
    }
  }, [select]);

  useEffect(() => {
    if (keyword) {
      const delayDebounce = setTimeout(() => {
        if (select) {
          fetchData();
        }
      }, 1000);

      return () => clearTimeout(delayDebounce);
    } else if (select) {
      fetchData();
    }
  }, [keyword]);

  function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(state);
    }, []); // keep object reference stable, exactly like `useState`

    useEffect(() => {
      // cb.current is `null` on initial render,
      // so we only invoke callback on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);

    return [state, setStateCallback];
  }

  function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(state);
    }, []); // keep object reference stable, exactly like `useState`

    useEffect(() => {
      // cb.current is `null` on initial render,
      // so we only invoke callback on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);

    return [state, setStateCallback];
  }

  useEffect(() => {
    if (!insightCategory) {
      fetchInsightCategory();
    }
  }, [insightCategory]);

  const fetchData = async () => {
    try {
      if (select === undefined) {
        return;
      }

      setLoading(true);
      const params = {
        InsightReportCategoryIds: select,
        Keywords: keyword,
      };

      const response = await InsightReportService.search(params);

      let updatedList = response.items?.sort((a, b) => a.sequence - b.sequence);

      setDetailList(updatedList);

      dataRef.current = updatedList;

      const delayPerItem = 600;
      const totalDelay = response?.items?.length * delayPerItem;
      
      setTimeout(() => {
        setLoading(false);
      }, totalDelay);

    } catch (error) {
      setLoading(false);
      console.error();
    }
  };

  const fetchInsightCategory = async () => {
    try {
      setLoading(true);

      const params = {
        // Keywords: ,
      };

      const response = await InsightReportCategoryService.search(params);
      setInsightCategory(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error();
    }
  };

  const handleSearchInsight = () => {
    fetchData();
  };

  const handleCreateCategory = async () => {
    setLoading(true);
    const data = [
      {
        id: "",
        name: addCategory,
        sequence: insightCategory?.pagination?.totalCount + 1,
      },
    ];
    try {
      await InsightReportCategoryService.create(data);
      setAddCategory("");
      setIsAddCategory(false);
      fetchInsightCategory();
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
      console.error();
    }
  };

  const handleUpdateCategory = async () => {
    setLoading(true);
    const data = {
      name: selectEdit?.name,
      sequence: selectEdit?.sequence,
      isActivated: selectEdit?.isActivated,
    };

    try {
      await InsightReportCategoryService.update(selectEdit.id, data);
      setSelectEdit();
      fetchInsightCategory();
      setLoading(false);
    } catch (error) {
      console.error();
      setLoading(false);
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    }
  };

  const handleUpdateCategorySequence = async (items) => {
    try {
      setLoading(true);
      await InsightReportCategoryService.create(items);
      setAddCategory("");
      setIsAddCategory(false);
      setIsDuplicate(false);
      fetchInsightCategory();
      setLoading(false);
    } catch (error) {
      console.error();
      setLoading(false);
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      setLoading(true);
      await InsightReportCategoryService.delete(selectDelete.id);
      setSelectDelete();
      setIsModalDeleteOpen(false);
      setIsDuplicate(false);

      const updatedCategories = insightCategory.items
        .filter((item) => item.id !== selectDelete.id)
        .map((item, index) => ({
          ...item,
          sequence: index + 1,
        }));

      handleUpdateCategorySequence(updatedCategories);
      setSelect();
      setLoading(false);
    } catch (error) {
      console.error();
      setLoading(false);
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
    }
  };

  const moveItem = (id, direction) => {
    setInsightCategory((prev) => {
      const items = [...prev.items];
      const index = items.findIndex((item) => item.id === id);

      if (index < 0) return prev;
      const swapIndex = index + direction;

      if (swapIndex < 0 || swapIndex >= items.length) return prev;

      [items[index].sequence, items[swapIndex].sequence] = [
        items[swapIndex].sequence,
        items[index].sequence,
      ];

      items.sort((a, b) => a.sequence - b.sequence);

      handleUpdateCategorySequence(items);

      return { ...prev, items };
    });
  };

  function handleClick(e, menu, menuName) {
    if (isDataModified) {
      setIsUnsavedModalOpen(true);
      setTargetId(menu);
      setCurrentMenu(menuName);
      e.domEvent.preventDefault();
      e.domEvent.stopPropagation();
    } else {
      setCurrentMenu(menuName);
      setSelect(menu);
      setPrevId(menu);
    }
  }

  // detail
  const handleAddDetail = () => {
    setLoading(true);
    setIsDataModified(true);

    const updatedDetailList = [
      ...detailList.map((detail, index) => ({
        ...detail,
        sequence: index + 1,
      })),
      {
        id: uuidv4(),
        insightReportCategoryId: select,
        insightReportCategoryName: currentMenu,
        name: "",
        urlSlug: "",
        shortDescription: "",
        description: "",
        embedSrc: "",
        thumbnailSrc: "",
        contentImageSrc: "",
        insightReportFileRawName: "",
        isPinned: false,
        sequence: detailList.length + 1,
        isActivated: false,
        insightReportDashboards: [],
        insightReportFiles: [],
        insightReportPermissions: [],
        insightReportTags: [],
      },
    ];

    setDetailList(updatedDetailList, () => {
      const delayPerItem = 600;
      const totalDelay = updatedDetailList.length * delayPerItem;
      setTimeout(() => {
        setLoading(false);
        messageApi.open({
          type: "success",
          content: "เพิ่มข้อมูลแล้ว",
        });
        setTimeout(() => {
          if (contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
          }
        }, 100);
      }, totalDelay);
    });
  };

  return (
    <AppContext.Provider
      value={{
        detailList,
        setDetailList,
        isSubmit,
        setIsSubmit,
        setLoading,
      }}
    >
      {contextHolder}
      <div className="insight-report-management-body">
        <Layout>
          <Header
            style={{
              height: "70px",
              background: "#FFF",
              borderBottom: "1px solid #D0D5DD",
            }}
          >
            <Flex
              gap="middle"
              justify="flex-start"
              align="center"
              style={{ height: "100%" }}
            >
              <AiOutlineArrowLeft
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={() => {
                  handleNavigation(`/management/insight-report-management`, {
                    categoryId: select,
                    selectedMenu: selectedMenu,
                  });
                }}
              />
              <p style={{ margin: "0", fontSize: "18px", fontWeight: "600" }}>
                เพิ่ม/แก้ไขข้อมูลรายงานวิเคราะห์
              </p>
            </Flex>
          </Header>
          <Layout>
            <Sider
              width={!screens.xl ? 0 : 302}
              style={{
                margin: !screens.xl ? "7px" : "14px",
                background: "#FFF",
                borderRadius: "8px",
                height: "calc(100vh - 200px)",
                overflowY: "hidden",
              }}
            >
              <div style={{ padding: "14px" }}>
                <p style={{ margin: "0", fontSize: "16px", fontWeight: "600" }}>
                  เลือกประเภทรายงานวิเคราะห์
                </p>
                <Input
                  placeholder="ค้นหา ชื่อรายงานวิเคราะห์..."
                  allowClear
                  suffix={
                    <AiOutlineSearch
                      onClick={() => handleSearchInsight()}
                      style={{
                        color: "rgba(0,0,0,.45)",
                        cursor: "pointer",
                      }}
                    />
                  }
                  onChange={(e) => setKeyword(e.target.value)}
                  onPressEnter={() => handleSearchInsight()}
                  size="large"
                  style={{ marginTop: "14px", borderRadius: "8px" }}
                />
              </div>
              <Divider style={{ margin: "0", borderColor: "#D0D5DD" }} />
              <div style={{ padding: "14px" }}>
                {isEditable && (
                  <Button
                    type="dashed"
                    icon={<AiOutlinePlusCircle style={{ fontSize: "16px" }} />}
                    onClick={() => {
                      setIsAddCategory(true);
                      setSelectEdit();
                    }}
                    style={{
                      color: "#1677FF",
                      borderColor: "#1677FF",
                      width: "100%",
                      height: "40px",
                      borderRadius: "10px",
                    }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: "500" }}>
                      เพิ่มประเภท
                    </span>
                  </Button>
                )}
                {isAddCategory && (
                  <div
                    style={{
                      background: "#EFEFEF",
                      width: "272px",
                      height: "44px",
                      padding: "6px ",
                      borderRadius: "8px",
                      marginTop: "14px",
                    }}
                  >
                    <Flex align="center">
                      <Form>
                        <Row>
                          <Col>
                            <Form.Item
                              name="input"
                              rules={[
                                {
                                  validator: (_, value) => {
                                    if (value) {
                                      const isCheck =
                                        insightCategory?.items.some(
                                          (item) =>
                                            item?.name.toLowerCase() ===
                                            value.toLowerCase()
                                        );
                                      setIsDuplicate(isCheck);
                                      if (isCheck) {
                                        return Promise.reject(
                                          new Error("มีชื่อประเภทแล้ว!")
                                        );
                                      }
                                    }
                                  },
                                },
                              ]}
                            >
                              <Input
                                showCount
                                maxLength={20}
                                placeholder="ชื่อประเภท"
                                onChange={(e) => setAddCategory(e.target.value)}
                                // onPressEnter={() => handleCreateCategory()}
                                style={{
                                  borderRadius: "6px",
                                  width: "200px",
                                  height: "32px",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Space
                              size="small"
                              style={{
                                marginLeft: "8px",
                                marginTop: "8px",
                              }}
                            >
                              <AiOutlineCheck
                                onClick={() => {
                                  if (!isDuplicate && addCategory)
                                    handleCreateCategory();
                                }}
                                style={{
                                  color:
                                    !isDuplicate && addCategory
                                      ? "#00B6A9"
                                      : "#D9D9D9",
                                  fontSize: "16px",
                                  cursor:
                                    !isDuplicate && addCategory
                                      ? "pointer"
                                      : "not-allowed",
                                }}
                              />
                              <AiOutlineClose
                                onClick={() => {
                                  setIsDuplicate(false);
                                  setIsAddCategory(false);
                                  setAddCategory("");
                                }}
                                style={{
                                  color: "#FF4D4F",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                              />
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </Flex>
                  </div>
                )}
              </div>

              {!isAddCategory &&
              insightCategory &&
              insightCategory?.items?.length !== 0 ? (
                <div
                  style={{
                    padding: "14px",
                    height: "calc(100% - 180px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#999 #f5f5f5",
                    scrollbarTrackColor: "#f5f5f5",
                  }}
                >
                  <Menu
                    // mode="inline"
                    defaultSelectedKeys={["1"]}
                    selectedKeys={prevId}
                    style={{
                      height: "100%",
                    }}
                  >
                    {insightCategory?.items
                      ?.sort((a, b) => a.sequence - b.sequence)
                      .map((item, index) => (
                        <Menu.Item
                          key={item.id}
                          id={item.id}
                          // onClick={() => setSelect(item.id)}
                          onClick={(e) => {
                            handleClick(e, item.id, item.name);
                          }}
                          style={{
                            padding: 0,
                            marginBottom:
                              isDuplicate && selectEdit?.id === item.id
                                ? "20px"
                                : "0px",
                          }}
                        >
                          {selectEdit?.id === item.id ? (
                            <div
                              style={{
                                background: "#EFEFEF",
                                width: "272px",
                                height: "44px",
                                padding: "6px ",
                                borderRadius: "8px",
                                marginBottom: "10px",
                              }}
                            >
                              <Flex align="center">
                                <Form form={formEditMenu}>
                                  <Row>
                                    <Col>
                                      <Form.Item
                                        name="editmenu"
                                        rules={[
                                          {
                                            validator: (_, value) => {
                                              if (value) {
                                                const isCheck =
                                                  insightCategory?.items.some(
                                                    (item) =>
                                                      item?.name.toLowerCase() ===
                                                        value.toLowerCase() &&
                                                      item.id !== selectEdit.id
                                                  );
                                                setIsDuplicate(isCheck);
                                                if (isCheck) {
                                                  return Promise.reject(
                                                    new Error(
                                                      "มีชื่อประเภทแล้ว"
                                                    )
                                                  );
                                                }
                                              }
                                            },
                                          },
                                        ]}
                                      >
                                        <Input
                                          showCount
                                          maxLength={20}
                                          placeholder="ชื่อประเภท"
                                          onChange={(e) =>
                                            setSelectEdit({
                                              ...selectEdit,
                                              name: e.target.value,
                                            })
                                          }
                                          style={{
                                            borderRadius: "6px",
                                            width: "200px",
                                            height: "32px",
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Space
                                        size="small"
                                        style={{
                                          marginLeft: "8px",
                                          marginTop: "8px",
                                        }}
                                      >
                                        <AiOutlineCheck
                                          onClick={() => {
                                            if (!isDuplicate && selectEdit.name)
                                              handleUpdateCategory();
                                          }}
                                          style={{
                                            color:
                                              !isDuplicate && selectEdit.name
                                                ? "#00B6A9"
                                                : "#D9D9D9",
                                            fontSize: "16px",
                                            cursor:
                                              !isDuplicate && selectEdit.name
                                                ? "pointer"
                                                : "not-allowed",
                                          }}
                                        />
                                        <AiOutlineClose
                                          onClick={() => {
                                            setSelectEdit();
                                            setIsDuplicate(false);
                                          }}
                                          style={{
                                            color: "#FF4D4F",
                                            fontSize: "16px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Space>
                                    </Col>
                                  </Row>
                                </Form>
                              </Flex>
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "250px",
                                height: "100%",
                                position: "relative",
                                top: "-5px",
                                margin: "8px",
                              }}
                            >
                              <Flex
                                justify="space-between"
                                align="center"
                                style={{ height: "100%" }}
                              >
                                <Space>
                                  <Flex vertical>
                                    <AiOutlineCaretUp
                                      onClick={() => moveItem(item.id, -1)}
                                      style={{
                                        color: "#667085",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <AiOutlineCaretDown
                                      onClick={() => moveItem(item.id, 1)}
                                      style={{
                                        color: "#667085",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Flex>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item.name} ({item?.count || 0})
                                  </span>
                                </Space>
                                <Flex
                                  gap="small"
                                  style={{ marginRight: "10px" }}
                                >
                                  <AiOutlineEdit
                                    onClick={() => {
                                      setIsAddCategory(false);
                                      setAddCategory("");
                                      setSelectEdit(item);
                                      formEditMenu.setFieldsValue({
                                        editmenu: item?.name,
                                      });
                                    }}
                                    style={{
                                      fontSize: "16px",
                                      color: "#667085",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <AiOutlineDelete
                                    onClick={() => {
                                      setIsAddCategory(false);
                                      setAddCategory("");
                                      setSelectEdit();
                                      setSelectDelete(item);
                                      if (!item?.count) {
                                        setIsModalDeleteOpen(true);
                                      }
                                    }}
                                    style={{
                                      fontSize: "16px",
                                      color: !item?.count
                                        ? "#667085"
                                        : "#E0E4EA",
                                      cursor: !item?.count
                                        ? "pointer"
                                        : "not-allowed",
                                    }}
                                  />
                                </Flex>
                              </Flex>
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                  </Menu>
                </div>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ height: "70%" }}
                >
                  {!isAddCategory && (
                    <>
                      <NoData />
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#667085",
                        }}
                      >
                        ไม่มีข้อมูล
                      </p>
                    </>
                  )}
                </Flex>
              )}
            </Sider>

            <Content
              // ref={contentRef}
              style={{
                margin: "14px",
                marginLeft: "0",
              }}
            >
              {!screens.xl && (
                <div
                  className="custom-dropdown"
                  style={{ marginBottom: "10px" }}
                >
                  <Dropdown
                    placement="bottomRight"
                    trigger={["click"]}
                    // overlayStyle={{ zIndex: 2000 }}
                    style={{ height: "40px" }}
                    dropdownRender={(menu) => (
                      <Menu
                        className="dropdown-menu"
                        theme="light"
                        mode="vertical"
                        style={{
                          width: 262,
                          minHeight: 100,
                          padding: "23px 12px 23px 12px",
                          // marginTop: "10px",
                          // marginRight: "-10px",
                          fontSize: "16px",
                        }}
                        triggerSubMenuAction="click"
                      >
                        <Menu.Item
                          key="0"
                          style={{ fontWeight: 500 }}
                          onClick={() => {
                            setIsAddCategory(true);
                            setSelectEdit();
                          }}
                        >
                          <AiOutlinePlusCircle style={{ marginRight: 10 }} />{" "}
                          เพิ่มประเภท
                        </Menu.Item>
                        <Divider
                          className="dropdown-divider"
                          style={{ borderColor: "#D0D5DD" }}
                        />
                        {insightCategory &&
                          insightCategory?.items?.length !== 0 &&
                          insightCategory?.items
                            ?.sort((a, b) => a.sequence - b.sequence)
                            .map((item, index) => (
                              <Menu.Item
                                key={item.id}
                                onClick={() => {
                                  if (isDataModified) {
                                    setIsUnsavedModalOpen(true);
                                    setTargetId(item.id);
                                    setSelectMenuItem(item);
                                  } else {
                                    setSelect(item.id);
                                    setPrevId(item.id);
                                    setSelectedMenu(item);
                                  }
                                }}
                                style={{
                                  paddingLeft: "14px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {item.name} ({item?.count ?? 0})
                                </span>
                              </Menu.Item>
                            ))}
                      </Menu>
                    )}
                  >
                    <Button
                      type="default"
                      className="dropdown-button"
                      htmlType="button"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        fontWeight: 500,
                        fontSize: "16px",
                        height: "40px",
                      }}
                    >
                      <MenuFoldOutlined />
                      {selectedMenu
                        ? `${selectedMenu.name} (${selectedMenu.count})`
                        : `ประเภทรายงานวิเคราะห์ (${
                            insightCategory?.items?.length ?? 0
                          })`}
                    </Button>
                  </Dropdown>
                  {isAddCategory && (
                    <div
                      style={{
                        background: "#EFEFEF",
                        width: "100%",
                        height: "44px",
                        padding: "6px ",
                        borderRadius: "8px",
                        marginTop: "14px",
                      }}
                    >
                      <Flex align="center">
                        <Form>
                          <Row>
                            <Col>
                              <Form.Item
                                name="input"
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      if (value) {
                                        const isCheck =
                                          insightCategory?.items.some(
                                            (item) =>
                                              item?.name.toLowerCase() ===
                                              value.toLowerCase()
                                          );
                                        setIsDuplicate(isCheck);
                                        if (isCheck) {
                                          return Promise.reject(
                                            new Error("มีชื่อประเภทแล้ว!")
                                          );
                                        }
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Input
                                  showCount
                                  maxLength={20}
                                  placeholder="ชื่อประเภท"
                                  onChange={(e) =>
                                    setAddCategory(e.target.value)
                                  }
                                  // onPressEnter={() => handleCreateCategory()}
                                  style={{
                                    borderRadius: "6px",
                                    width: "100%",
                                    height: "32px",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Space
                                size="small"
                                style={{
                                  marginLeft: "8px",
                                  marginTop: "8px",
                                }}
                              >
                                <AiOutlineCheck
                                  onClick={() => {
                                    if (!isDuplicate && addCategory)
                                      handleCreateCategory();
                                  }}
                                  style={{
                                    color:
                                      !isDuplicate && addCategory
                                        ? "#00B6A9"
                                        : "#D9D9D9",
                                    fontSize: "16px",
                                    cursor:
                                      !isDuplicate && addCategory
                                        ? "pointer"
                                        : "not-allowed",
                                  }}
                                />
                                <AiOutlineClose
                                  onClick={() => {
                                    setIsDuplicate(false);
                                    setIsAddCategory(false);
                                    setAddCategory("");
                                  }}
                                  style={{
                                    color: "#FF4D4F",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Space>
                            </Col>
                          </Row>
                        </Form>
                      </Flex>
                    </div>
                  )}
                </div>
              )}
              {select && (!keyword || (keyword && detailList.length !== 0)) ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      background: "#fff",
                      borderRadius: "8px",
                      padding: "14px",
                      position: "sticky",
                      top: "0px",
                      zIndex: "2",
                      marginBottom: "14px",
                    }}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      style={{ height: "100%" }}
                      wrap
                      gap="small"
                    >
                      <p
                        style={{
                          margin: "0",
                          fontSize: !screens.xl ? "14px" : "16px",
                          fontWeight: "600",
                          display: !screens.xl ? "none" : "block",
                        }}
                      >
                        รายละเอียด
                      </p>

                      <Space>
                        {isEditable && (
                          <Button
                            onClick={handleAddDetail}
                            type="dashed"
                            icon={
                              <AiOutlinePlusCircle
                                style={{
                                  fontSize: !screens.xl ? "14px" : "16px",
                                }}
                              />
                            }
                            style={{
                              color: "#1677FF",
                              borderColor: "#1677FF",
                              height: !screens.xl ? "30px" : "40px",
                              width: !screens.xl ? "100px" : "115px",
                              borderRadius: "10px",
                              margin: "0",
                            }}
                          >
                            <span
                              style={{
                                fontSize: !screens.xl ? "14px" : "16px",
                                fontWeight: "500",
                              }}
                            >
                              เพิ่มใหม่
                            </span>
                          </Button>
                        )}
                        <Divider
                          type="vertical"
                          style={{
                            display: !screens.xl ? "none" : "block",
                            height: "36px",
                            borderColor: "#D0D5DD",
                          }}
                        />
                        <Button
                          type="primary"
                          onClick={() => {
                            handleNavigation(
                              `/management/insight-report-management`,
                              {
                                categoryId: select,
                                selectedMenu: selectedMenu,
                              }
                            );
                          }}
                          style={{
                            background: "#EBEBEB",
                            height: !screens.xl ? "30px" : "40px",
                            width: !screens.xl ? "100px" : "115px",
                            borderRadius: "10px",
                            margin: !screens.xl ? "0" : "0 10px 0 0",
                          }}
                        >
                          <span
                            style={{
                              fontSize: !screens.xl ? "14px" : "16px",
                              fontWeight: "500",
                            }}
                          >
                            ยกเลิก
                          </span>
                        </Button>
                        {isEditable && (
                          <Button
                            onClick={() => {
                              setIsSubmit(true);
                            }}
                            type="primary"
                            style={{
                              height: !screens.xl ? "30px" : "40px",
                              width: !screens.xl ? "100px" : "115px",
                              borderRadius: "10px",
                              margin: !screens.xl ? "0" : "0 10px 0 0",
                            }}
                            disabled={
                              detailList?.length === 0 &&
                              dataRef.current !== null &&
                              dataRef.current?.length === 0
                            }
                          >
                            <span
                              style={{
                                fontSize: !screens.xl ? "14px" : "16px",
                                fontWeight: "500",
                              }}
                            >
                              บันทึก
                            </span>
                          </Button>
                        )}
                      </Space>
                    </Flex>
                  </div>
                  <div
                    ref={contentRef}
                    style={{
                      overflowY: "auto",
                      overflowX: "hidden",
                      height: "calc(100vh - 282px)",
                      scrollBehavior: "smooth",
                      scrollbarWidth: "thin",
                      scrollbarColor: "#999 #f5f5f5",
                      scrollbarTrackColor: "#f5f5f5",
                      borderRadius: "8px",
                    }}
                  >
                    <InsightReportFormDetail
                      categoryId={select}
                      keyword={keyword}
                    />
                  </div>
                </>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{
                    height: "calc(100vh - 200px)",
                    background: "#fff",
                    borderRadius: "8px",
                  }}
                >
                  <NoData />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#667085",
                    }}
                  >
                    {!keyword
                      ? "กรุณาเลือกประเภทก่อนเพิ่มข้อมูล"
                      : !select
                      ? "กรุณาเลือกประเภทก่อนเพิ่มข้อมูล"
                      : "ไม่มีข้อมูลที่ค้นหา"}
                  </p>
                </Flex>
              )}
            </Content>
          </Layout>
        </Layout>

        <LoadingDialog isLoading={loading} />

        <DiscardDialog
          isModalOpen={isUnsavedModalOpen}
          closable={false}
          title="ยกเลิกการเปลี่ยนแปลง"
          cancelText="ยกเลิก"
          confirmText="ยืนยัน"
          onCancel={() => {
            handleUnsavedCancel();
            setSelectMenuItem();
          }}
          onConfirm={() => {
            handleUnsavedConfirm();
            // let element = document.getElementById(targetId);
            // element.click();
            setSelect(targetId);
            setPrevId(targetId);
            setSelectedMenu(selectMenuItem);
            setTimeout(() => {
              setSelectMenuItem();
            }, 200);
          }}
        />

        <FailedDialog
          isModalOpen={isModalFailedOpen}
          closable={false}
          title=""
          detail={errorMessage?.messageTh}
          onCancel={() => {
            setIsModalFailedOpen(false);
            setErrorMessage("");
          }}
          onAfterClose={() => {}}
          onConfirm={() => {
            setIsModalFailedOpen(false);
            setErrorMessage("");
          }}
        />

        <DeleteDialog
          isModalOpen={isModalDeleteOpen}
          closable={false}
          title="ยืนยันการลบ"
          description="คุณต้องการลบใช่หรือไม่?"
          cancelText="ยกเลิก"
          confirmText="ลบ"
          onCancel={() => {
            setSelectDelete();
            setIsModalDeleteOpen(false);
          }}
          onConfirm={() => {
            handleDeleteCategory();
          }}
        />
      </div>
    </AppContext.Provider>
  );
};

export default InsightReportManagementDetail;
