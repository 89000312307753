import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"
import CryptoJS from "crypto-js";


export const UserService = {

  getJob: async function (params, cancel = false) {
    const response = await api.request({
      url: `/users/job-functions`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },
  getMe: async function (callFromService, cancel = false) {
    if (sessionStorage.getItem("userMe") && callFromService !== true) {
      return JSON.parse(sessionStorage.getItem("userMe"));
    }
    const response = await api.request({
      url: `/users/me`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/Keycloak-users/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },
  getJobFunction: async function (cancel = false) {
    const response = await api.request({
      url: `/users/job-functions`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getJobFunction.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  search: async function (requestObject, cancel = false) {
    let params = "?Keywords=" +
      requestObject.keywords +
      "&Pagination.Page=" +
      requestObject.page +
      "&Pagination.PageSize=" +
      requestObject.pageSize +
      "&Pagination.SortBy=" +
      requestObject.sortBy +
      "&Pagination.SortDestination=" +
      requestObject.sortDirection
    if (requestObject.enabled !== undefined) {
      params += "&enabled=" +
        requestObject.enabled
    }
    // requestObject.enabled !== null ? 
    // "&enabled=" +
    // requestObject.enabled  : ""+
    const response = await api.request({
      url: `/Keycloak-users` +
        params,
      method: "GET",
      // params: params,
      signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/Keycloak-users`,
      method: "POST",
      data: data,
      signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },

  update: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/Keycloak-users/${id}`,
      method: "PUT",
      data: data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },

  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/Keycloak-users/${id}`,
      method: "DELETE",
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },

  updateUser: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/users?id=${id}`,
      method: "PUT",
      data: data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },

  changePasswordUser: async function (data, cancel = false) {
    const response = await api.request({
      url: `/users/change-password`,
      method: "PUT",
      data: data,
      isSelfErrorHandler: true,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },
  adminResetPasswordUser: async function (data, cancel = false) {
    const response = await api.request({
      url: `/users/admin-reset-password`,
      method: "PUT",
      data: data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },


  deleteUser: async function (id, cancel = false) {
    const response = await api.request({
      url: `/users/${id}`,
      method: "DELETE",
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },

  deleteUserMuti: async function (ids, cancel = false) {
    const response = await api.request({
      url: "/users/bulk",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json", // ระบุประเภทของข้อมูลที่ส่ง
      },
      data: ids,  // เปลี่ยนจาก body เป็น data
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },
  sendEmailMuti: async function (ids, cancel = false) {
    if (!Array.isArray(ids) || ids.some((id) => typeof id !== "string")) {
      console.error("Invalid input: ids must be an array of strings.", ids);
      throw new Error("Invalid input format.");
    }
    const response = await api.request({
      url: "/users/register-user-keycloak/bulk",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      data: ids, 
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
  
    return response;
  },  
  getAllUsers: async function (page, pageSize, filters, cancel = false) {
    try {
      const params = {
        'Pagination.Page': page,  // ส่งหมายเลขหน้า
        'Pagination.PageSize': pageSize,  // ส่งจำนวนข้อมูลที่จะแสดงต่อหน้า
      };

      if (filters && filters.Keywords) {
        params['Keywords'] = filters.Keywords;
      }

      if (filters && typeof filters.IsVerifiedEmail !== 'undefined') {
        params['IsVerifiedEmail'] = filters.IsVerifiedEmail;
      }
      if (filters && filters.type) {
        params['UserSources'] = filters.type; 
      }
      if (filters && filters.roles && filters.roles.length > 0) {
        params['Roles'] = filters.roles.join(',');
      }

      
      const response = await api.request({
        url: '/users/all-users',
        method: 'GET',
        params: params,
        signal: cancel ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal : undefined,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error; 
    }
  },

  getUserById: async function (id, cancel = false) {

    const response = await api.request({
      url: `/users/${id}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal : undefined,
    });
    //console.log("aaaaaaaaaaaaaasdasdadd", response);
    return response.data;
  },
  disconnectUserSource: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/users/disconnect-user-source/${id}`,
      method: "DELETE",
      params: {
        idpName: data
      },
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },
  getPermissions: async function (cancel = false) {

    const response = await api.request({
      url: `/users/permissions`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    if (response.data.length > 0) {
      const permissions = response.data.filter((p) => p.isViewable);
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      let secSpec = CryptoJS.enc.Utf8.parse(secretKey);
      let ivSpec = CryptoJS.enc.Utf8.parse(secretKey);
      secSpec = CryptoJS.lib.WordArray.create(secSpec.words.slice(0, 16 / 4));
      ivSpec = CryptoJS.lib.WordArray.create(ivSpec.words.slice(0, 16 / 4));
      const permissionString = CryptoJS.AES.encrypt(JSON.stringify(permissions), secSpec, { iv: ivSpec }).toString();
      sessionStorage.setItem("permissions", permissionString);
      window.permissions = permissions;
      return permissions;
    } else {
      return response.data;
    }

  },
  getPermissionFromSession: function (cancel = false) {
    if (sessionStorage.getItem('permissions') === null) {
      return [];
    }
    try {
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      let secSpec = CryptoJS.enc.Utf8.parse(secretKey);
      let ivSpec = CryptoJS.enc.Utf8.parse(secretKey);
      secSpec = CryptoJS.lib.WordArray.create(secSpec.words.slice(0, 16 / 4));
      ivSpec = CryptoJS.lib.WordArray.create(ivSpec.words.slice(0, 16 / 4));
      const permissionString = CryptoJS.AES.decrypt(sessionStorage.getItem('permissions'), secSpec, { iv: ivSpec }).toString(CryptoJS.enc.Utf8);
      const permission = JSON.parse(permissionString);
      window.permissions = permission;
      return permission;
    } catch (error) {
      console.error('error', error);
    }
    return [];
  },
  isEditable: async function () {
    if (window.permissions === undefined) {
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      let secSpec = CryptoJS.enc.Utf8.parse(secretKey);
      let ivSpec = CryptoJS.enc.Utf8.parse(secretKey);
      secSpec = CryptoJS.lib.WordArray.create(secSpec.words.slice(0, 16 / 4));
      ivSpec = CryptoJS.lib.WordArray.create(ivSpec.words.slice(0, 16 / 4));
      if (window.permissions === undefined) {
        if (sessionStorage.getItem('permissions') != null) {
          var fetchPermission = await this.getPermissions();
          const permissionString = CryptoJS.AES.encrypt(JSON.stringify(fetchPermission), secSpec, { iv: ivSpec }).toString();
          const permssion = JSON.parse(permissionString);
          sessionStorage.setItem('permissions', permissionString);
          window.permissions = permssion;
        } else {
          const permissionString = CryptoJS.AES.decrypt(sessionStorage.getItem('permissions'), secSpec, { iv: ivSpec }).toString(CryptoJS.enc.Utf8);
          const permssion = JSON.parse(permissionString);
          window.permissions = permssion;
        }
      }

      const permissionString = CryptoJS.AES.decrypt(sessionStorage.getItem('permissions'), secSpec, { iv: ivSpec }).toString(CryptoJS.enc.Utf8);
      const permssion = JSON.parse(permissionString);
      window.permissions = permssion;
    }

    const currentPermission = (window.permissions || []).filter((p) => (p.routePath === window.pathname || (p.routePath + '-detail' === window.pathname && p.isEditable === true)));
    return currentPermission.length > 0 && currentPermission[0].isEditable;
  },

  getProvinces: async function (cancel = false) {
    const response = await api.request({
      url: `/users/provinces`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getProvinces.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },
  getDistrictsByProvinceId: async function (provinceId, cancel = false) {
    const response = await api.request({
      url: `users/districts/${provinceId}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getDistrictsByProvinceId.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },
  getSubDistrictsByDistrictsId: async function (districtsId, cancel = false) {
    const response = await api.request({
      url: `users/sub-districts/${districtsId}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getSubDistrictsByDistrictsId.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },
  getRequisitionsStatusByUserId: async function (userId, cancel = false) {
    const response = await api.request({
      url: `users/${userId}/requisitions/status`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getRequisitionsStatusByUserId.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },
  getApiUserByUserId: async function (userId, cancel = false) {
    const response = await api.request({
      url: `users/api-user/${userId}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getApiUserByUserId.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },
  updateApiUserByUserId: async function (userId, data, cancel = false) {
    const response = await api.request({
      url: `users/api-user/${userId}`,
      method: "PATCH",
      data: data,
      signal: cancel ? cancelApiObject[this.updateApiUserByUserId.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },

}

const cancelApiObject = defineCancelApiObject(UserService);