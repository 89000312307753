import "./TradeAssociation.css";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  Space,
  Flex,
  Col,
  Row,
  Grid,
  Input,
  Divider 
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import NoData from "../../../assets/img/no_data.js";
import { SearchOutlined } from "@ant-design/icons";
import { DataSourceService } from "../../../services/DataSourceService.js";
import { LoadingDialog } from "../../../components/index.js";

const { useBreakpoint } = Grid;

const TradeAssociation = () => {
    const screens = useBreakpoint();
    const [dataAssociations, setDataAssociations] = useState([]);
    const [dataAssociationItemList, setDataAssociationItemList] = useState([]);
    const [keywords, setKeywords] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    const headTaps =screens.lg ? (<div className="tabs-header">กลุ่มสมาคมการค้า</div>):("") ;


    const fetchDataAssociation = async () => {
        setIsLoading(true);
        try {
            const response = await DataSourceService.getAssociationList();
            setDataAssociations(response.data);
            setDataAssociationItemList(response.data.flatMap((item) => item.contacts));
        } catch (error) {
          console.error(error);
        }finally{
          setIsLoading(false);
        }   
    };


    let handleInputSearchTimeout;
    const handleInputSearch = (value) => {
        
        if (handleInputSearchTimeout) clearTimeout(handleInputSearchTimeout);
        handleInputSearchTimeout = setTimeout(() => {
            
            let data = dataAssociations.flatMap((item) => item.contacts)
            if(value){
                let filtered = data.filter(contact => 
                    contact.thaiName.includes(value) ||
                    contact.englishName.toLowerCase().includes(value.toLowerCase()) ||
                    contact.address.includes(value) ||
                    contact.telephone.includes(value)
                );
                setDataAssociationItemList(filtered)
            }else{
                setDataAssociationItemList(data)
            }
        }, 600); 
        setKeywords(value);
    };


    useEffect(() => {
        fetchDataAssociation();
    }, []);

    return (
        <>
           <LoadingDialog isLoading={isLoading} />
           <div className="association-body" >
                <div className="association-content">
                <Tabs
                    defaultActiveKey="1"
                    tabPosition={screens.lg ? ("left"):("top")}
                    style={{
                        height: "max",
                        marginTop: screens.lg? '0px' : '-25px',
                    }}
                    tabBarExtraContent={{left: headTaps}}
                    >
                    {dataAssociations?.map((group, index) => (
                        <TabPane tab={group?.groupTitle} key={group?.groupTitle}>
                        <>
                            <Space
                            direction="vertical"
                            size="middle"
                            style={{
                                display: "flex",
                                
                            }}
                            >
                            
                                <div
                                    style={{
                                    padding: "20px",
                                    paddingLeft:"30px",
                                    background: "#FFF",
                                    borderRadius: "8px",
                                    
                                    }}
                                >
                                        <Row justify="space-between" align="center" style={{paddingBottom:10,alignItems:"center"}}>
                                            <Col xs={24} md={14} xl={15}>
                                                <span style={{ fontSize: "18px", fontWeight: "600" }}> สมาคมการค้า </span>
                                            </Col>
                                            <hr />
                                            <Col xs={24} md={10} xl={9}>
                                                <Input
                                                    size="large"
                                                    value={keywords}
                                                    placeholder="ค้นหาข้อมูล..."
                                                    //onPressEnter={(e) => handleInputSearch(e.target.value)}
                                                    onChange={(e) => handleInputSearch(e.target.value)}
                                                    suffix={
                                                        <SearchOutlined
                                                            // onClick={() => handleSearch()}
                                                            style={{
                                                            color: "rgba(0,0,0,.45)",
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <hr />

                                        <div className="association-group-name">
                                            <p>{`${group.groupTitle} (${dataAssociationItemList?.filter((item) => item.title === group?.groupTitle).length})`}</p>
                                        </div>
                                        <div className="association-item">
                                            {dataAssociationItemList
                                                ?.filter(
                                                (item) => item.title === group?.groupTitle
                                                ).map((item, index) => (
                                                        <div key={item.thaiName}>
                                                            <Row gutter={[16,16]}>
                                                                <Col xs={24} md={7} lg={7} xl={8}>
                                                                    <div style={{fontWeight:600,marginBottom:10}}>{`${(index+1)}.  ${item.thaiName}`}{}</div>
                                                                    <div style={{color:"#667085"}}>{item.englishName}</div>
                                                                </Col>
                                                                {screens.md &&(<Divider type="vertical" style={{ height: "auto",borderColor:"#D0D5DD"}} />)}
                                                                <Col xs={24}md={8} lg={8} xl={8}>
                                                                    <div style={{color:"#939CB0",fontWeight:600,marginBottom:10}}>ที่อยู่</div>
                                                                    <div >{`${item.address}`}</div>
                                                                    
                                                                </Col>
                                                                {screens.md &&(<Divider type="vertical" style={{ height: "auto",borderColor:"#D0D5DD"}} />)}
                                                                <Col xs={24} md={6} lg={7} xl={6} xxl={7}>
                                                                    <div style={{color:"#939CB0",fontWeight:600,marginBottom:10}}>ติดต่อ</div>
                                                                    <div >{`${item.telephone}`}</div>
                                                                </Col>
                                                            </Row>
                                                            <Divider type="horizontal" style={{borderColor:"#D0D5DD"}}/>
                                                        </div>
                                                ))}

                                            {dataAssociationItemList
                                                ?.filter((item) => item.title === group?.groupTitle).length === 0 && (
                                                        <>
                                                        <Row gutter={[16,16]}>
                                                                    <Col xs={24} >
                                                                <Flex
                                                                vertical
                                                                justify="center"
                                                                align="center"
                                                                style={{ height: "90vh" }}
                                                                >
                                                                <NoData />
                                                                <p
                                                                    style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "600",
                                                                    color: "#667085",
                                                                    }}
                                                                >
                                                                    ไม่มีข้อมูล
                                                                </p>
                                                            </Flex>
                                                        </Col>
                                                        </Row>
                                                        </>
                                                )}
                                        
                                        </div>

    
                                </div>
                            </Space>
                        
                        </>
                        </TabPane>
                    ))}
                   
                </Tabs>
                </div>
            {/* <Radio.Group
                onChange={handleModeChange}
                value={mode}
                style={{
                marginBottom: 8,
                }}
            >
                <Radio.Button value="top">Horizontal</Radio.Button>
                <Radio.Button value="left">Vertical</Radio.Button>
            </Radio.Group> */}
           
    
            </div>
            
        </>
        )
   
    
}

export default TradeAssociation;