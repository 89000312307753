import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const FileService = {
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/files/${id}`,
      method: "GET",
      responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  uploadFile: async function (file, fileType, cancel = false) {
    const formData = new FormData();
    formData.append("File", file); 
    formData.append("FileType", fileType); 
    try {
      const response = await api.request({
        url: `/files`,
        method: "POST",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        isSelfErrorHandler: true,
        signal: cancel
          ? cancelApiObject[this.uploadFile.name]?.handleRequestCancellation().signal
          : undefined,
      });

      return response.data;
    } catch (error) {
      // if (error.response) {
      //   console.error("Error response:", error.response.data);  // ข้อความจากเซิร์ฟเวอร์
      // }
      throw error; 
    }
  },
  listFiles: async function (fileType, cancel = false) {
    try {
      // การส่งคำขอ API
      const response = await api.request({
        url: `/files/lists`,
        method: "POST",
        data: fileType,  // ส่ง FormData
        signal: cancel
          ? cancelApiObject[this.listFiles.name]?.handleRequestCancellation().signal
          : undefined,
      });

      return response.data; // ส่งคืนข้อมูลที่ได้จาก API
    } catch (error) {
      // แสดงข้อผิดพลาดในการอัปโหลด
      console.error("File upload error:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);  // ข้อความจากเซิร์ฟเวอร์
      }
      throw error;  // หลีกเลี่ยงการให้แอปพลิเคชันค้าง
    }
  },
};

const cancelApiObject = defineCancelApiObject(FileService);
