import { useLocation } from "react-router-dom";


const DefaultRoute = ({ children = [] }) => {

  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  };

  const location = useLocation();
  window.pathname = location.pathname;
  if(!localStorage.getItem('sessionId')) {
    localStorage.setItem('sessionId', uuidv4());
  }
  window.sessionId = localStorage.getItem('sessionId');
  var reCAPTCHARoute = [
    '/login',
    '/register'
  ];
  var reCAPTCHABadged =  document.querySelector('.grecaptcha-badge');
  if(reCAPTCHABadged) {
    if(reCAPTCHARoute.includes(location.pathname)) {
      reCAPTCHABadged.style.display = '';
    }else{
      reCAPTCHABadged.style.display = 'none';
    }
  }
  return children;
};

export default DefaultRoute;
