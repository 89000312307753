import React from "react";
import { Button, Tooltip } from "antd";
import { AiOutlineEdit } from "react-icons/ai";

const EditButton = ({ onClick, text, isEditable = true, isVisible = true }) => {
  return (
    isVisible && (
    <Tooltip title={text ? "" : "แก้ไข"}>
      <Button
        className={text ? "" : "custom-button-action"}
        icon={
          <AiOutlineEdit
            style={{
              fontSize: "20px",
            }}
          />
        }
        disabled={isEditable === false}
        onClick={onClick}
        style={{
          width: text ? "" : "32px",
          height: text ? "40px" : "32px",
          borderRadius: text ? "10px" : "8px",
          color: text ? "#00B6A9" : "",
          borderColor: text ? "#00B6A9" : "",
        }}
      >
        {text ? "แก้ไข" : ""}
      </Button>
    </Tooltip>
    )
  );
};

export default EditButton;
