import React, { useState, useEffect, useRef } from "react";
import {
  Tabs,
  Typography,
  Card,
  notification,
  Button,
  Divider,
  Grid,
} from "antd";
import { ConfigurationService } from "../../../../../services/ConfigurationService";
import { LoadingDialog } from "../../../../../components";
import "./terms-privacy.css"; // อ้างอิงไฟล์ CSS
import ReactQuill from "react-quill";
import { EditOutlined } from "@ant-design/icons";
import { name } from "dayjs/locale/th";
import { UserService } from "../../../../../services/UserService";
import ErrorMessageHandler from "../../../../../helpers/ErrorMessageHandler";
import { DiscardDialog } from "../../../../../components";
import { useUnsavedChangesContext } from "../../../../../contexts";
import { useUnsavedChangesWarning } from "../../../../../hooks";

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const TermsPrivacyManagement = () => {
  const { isDataModified, setIsDataModified } = useUnsavedChangesContext();
  const {
    handleUnsavedConfirm,
    handleUnsavedCancel,
    setIsUnsavedModalOpen,
    isUnsavedModalOpen,
  } = useUnsavedChangesWarning();
  const screens = useBreakpoint();
  const [activeTab, setActiveTab] = useState("privacy");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tabPosition, setTabPosition] = useState("left");
  const [isEditable, setIsEditable] = useState(false);
  const [privacyData, setPrivacyData] = useState({
    key: "privacy",
    name: "นโยบายความเป็นส่วนตัว",
    value: "",
    formattedUpdatedDate: "",
  });
  const [termsData, setTermsData] = useState({
    key: "terms-of-use",
    name: "ข้อกำหนดและเงื่อนไขการให้บริการ",
    value: "",
    formattedUpdatedDate: "",
  });
  const quillRef = useRef(null);
  const modules = {
    toolbar: [
      { header: [1, 2, 3, 4, 5, 6, false] },
      "bold",
      "italic",
      "underline",
      "link",
      // { list: "bullet" },
      // { list: "ordered" },
    ],
    htmlEditButton: {
      debug: true,
      msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
      okText: "Ok", // Text to display in the OK button, default: Ok,
      cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
      buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
      buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
      syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
      prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
      editorModules: {}, // The default mod
    },
  };

  useEffect(() => {
    fetchData();
    UserService.isEditable().then((res) => {
      setIsEditable(res);
    });
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const privacy = await ConfigurationService.get("privacy");
      const terms = await ConfigurationService.get("terms-of-use");
      setPrivacyData(privacy);
      setTermsData(terms);
    } catch (error) {
      if (error.response.status !== 404) {
        ErrorMessageHandler(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnPrivacyChange = (value) => {
    // setIsDataModified(true);
    // Trim whitespace and check for empty content or default placeholder
    const cleanedValue = value.trim() === "<p><br></p>" ? "" : value;

    //if (cleanedValue.length <= 15000) {
    setPrivacyData((prevData) => ({ ...prevData, value: cleanedValue }));
    //}
  };
  const handleOnTermsChange = (value) => {
    // setIsDataModified(true);
    // Trim whitespace and check for empty content or default placeholder
    const cleanedValue = value.trim() === "<p><br></p>" ? "" : value;

    //if (cleanedValue.length <= 15000) {
    setTermsData((prevData) => ({ ...prevData, value: cleanedValue }));
    //}
  };

  const handleOk = async () => {
    try {
      setIsLoading(true);
      if (privacyData.id) {
        await ConfigurationService.update(privacyData);
      } else {
        await ConfigurationService.create(privacyData);
      }
      if (termsData.id) {
        await ConfigurationService.update(termsData);
      } else {
        await ConfigurationService.create(termsData);
      }
      notification.success({
        message: "บันทึกข้อมูลสำเร็จ",
      });
    } catch (error) {
      ErrorMessageHandler(error);
    } finally {
      setIsLoading(false);
      setIsEditing(false);
      fetchData();
      handleUnsavedConfirm();
    }
  };

  return (
    <div className="privacy-terms-settings-body main-body">
      <p style={{ fontSize: "20px", fontWeight: "600" }}>
        นโยบายความเป็นส่วนตัว/ข้อกำหนดและเงื่อนไขการให้บริการ
      </p>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        {isEditable &&
          (!isEditing ? (
            <Button
              onClick={() => {
                setIsEditing(!isEditing);
              }}
              type="primary"
              style={{
                height: !screens.sm ? "30px" : "40px",
                width: !screens.sm ? "100px" : "140px",
                border: "2px solid #00B6A9",
                background: "transparent",
                borderRadius: "10px",
                color: "#00B6A9",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                <EditOutlined />
                แก้ไข
              </span>
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                onClick={async () => {
                  if (isDataModified) {
                    setIsUnsavedModalOpen(true);
                  } else {
                    setIsEditing(!isEditing);
                  }
                }}
                style={{
                  width: "120px",
                  height: "32px",
                  fontSize: "14px",
                  fontWeight: "500",
                  background: "transparent",
                  border: "2px solid #00B6A9",
                  borderRadius: "8px",
                  color: "#00B6A9",
                }}
              >
                ยกเลิก
              </Button>
              <Button
                type="primary"
                onClick={handleOk}
                style={{
                  width: "120px",
                  height: "32px",
                  fontSize: "14px",
                  fontWeight: "500",
                  background: "#00B6A9",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                }}
              >
                บันทึก
              </Button>
            </>
          ))}
      </div>
      <Tabs
        tabPosition={!screens.lg ? "top" : tabPosition}
        activeKey={activeTab}
        onChange={setActiveTab}
      >
        <TabPane
          key={"privacy"}
          tab={
            <div>
              <span style={{ color: "#673AB7" }}>นโยบายความเป็นส่วนตัว</span>
            </div>
          }
        >
          {isEditing ? (
            <ReactQuill
              ref={quillRef}
              theme="snow"
              modules={modules}
              value={privacyData?.value}
              style={{
                height: "calc(100% - 160px)",
                width: "100%",
                marginBottom: 30,
              }}
              onChange={handleOnPrivacyChange}
            />
          ) : (
            <ContentPreview value={privacyData?.value} />
          )}
          <br />
          <Paragraph>
            <strong>อัปเดทล่าสุดวันที่ : </strong>{" "}
            {privacyData?.formattedUpdatedDate}
          </Paragraph>
          <Divider style={{ margin: "0" }} />
          <br />
        </TabPane>
        <TabPane
          key={"terms-of-use"}
          tab={
            <div>
              <span style={{ color: "#673AB7" }}>
                ข้อกำหนดและเงื่อนไขการให้บริการ
              </span>
            </div>
          }
        >
          {isEditing ? (
            <ReactQuill
              ref={quillRef}
              theme="snow"
              modules={modules}
              value={termsData?.value}
              style={{
                height: "calc(100% - 160px)",
                width: "100%",
                marginBottom: 30,
              }}
              onChange={handleOnTermsChange}
            />
          ) : (
            <ContentPreview value={termsData?.value} />
          )}
          <br />
          <Paragraph>
            <strong>อัปเดทล่าสุดวันที่ :</strong>{" "}
            {termsData?.formattedUpdatedDate}
          </Paragraph>
          <Divider style={{ margin: "0" }} />
          <br />
        </TabPane>
      </Tabs>

      <LoadingDialog isLoading={isLoading} />

      <DiscardDialog
        isModalOpen={isUnsavedModalOpen}
        closable={false}
        title="ยกเลิกการเปลี่ยนแปลง"
        cancelText="ยกเลิก"
        confirmText="ยืนยัน"
        onCancel={() => {
          handleUnsavedCancel();
        }}
        onConfirm={async () => {
          handleUnsavedConfirm();
          setIsEditing(false);
          await fetchData();
        }}
      />
    </div>
  );
};

const ContentPreview = ({ value }) => {
  return (
    <>
      <Card
        bordered={false}
        style={{
          width: "100%",

          textAlign: "center",
          backgroundColor: "#F2EEF6",
          overflowX: "hidden", // ป้องกันการทะลุ
          overflowY: "auto", // ให้เนื้อหาสามารถเลื่อนภายใน
        }}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", gap: "8px" }}>
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: "#F64E60",
                }}
              ></div>
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: "#FCE697",
                }}
              ></div>
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: "#0BB783",
                }}
              ></div>
            </div>
          </div>
        }
        styles={{
          header: {
            backgroundColor: "#fcfcfc",
            color: "#65707A",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 16px",
          },
          body: {
            backgroundColor: "#F2EEF6",
            color: "#65707A",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px", // เพิ่ม padding เพื่อให้มีพื้นที่ห่าง
            overflowX: "hidden", // ให้เนื้อหาสามารถเลื่อนภายใน
          },
        }}
      >
        <div
          style={{
            overflowY: "auto",

            padding: "0 16px", // เพิ่ม padding ด้านซ้ายขวา
          }}
        >
          <Card
            bordered={true}
            style={{
              width: "100%",
              minWidth: "40vw",
              backgroundColor: "#FFFFFF",
              textAlign: "left",
              padding: "16px",
              borderTop: "8px solid #000000",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "300",
                color: "#212121",
                margin: 0,
              }}
              dangerouslySetInnerHTML={{ __html: value }}
            ></p>
          </Card>
        </div>
      </Card>
    </>
  );
};

export default TermsPrivacyManagement;
