import React, { useState, useEffect, useRef  } from "react";
import { useUnsavedChangesContext } from "../../../../../contexts";
import { useUnsavedChangesWarning } from "../../../../../hooks";
import {
  Space,
  Tag,
  Table,
  Input,
  Button,
  Select,
  message,
  Divider,
  Flex,
} from "antd";
import "./TagManagement.css";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { AiOutlineSearch } from "react-icons/ai";
import { TagService } from "../../../../../services/TagService";
import { UserService } from "../../../../../services/UserService";
import {
  DeleteDialog,
  SuccessDialog,
  FailedDialog,
  ConfirmDialog,
  DiscardDialog,
  LoadingDialog,
} from "../../../../../components";

const { Option } = Select;

const TagManagement = () => {
  const { isDataModified, setIsDataModified } = useUnsavedChangesContext();
  const {
    handleUnsavedConfirm,
    handleUnsavedCancel,
    setIsUnsavedModalOpen,
    isUnsavedModalOpen,
  } = useUnsavedChangesWarning();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalSuccessOpen2, setIsModalSuccessOpen2] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [showFirstTable, setShowFirstTable] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const tableContainerRef = useRef(null);


  const fetchTags = async () => {
    try {
      setLoading(true);
      const response = await TagService.get();

      if (!response || !Array.isArray(response.items)) {
        throw new Error("Response format is invalid or items are missing.");
      }

      const formattedData = response.items.map((item, index) => ({
        key: item.id || index.toString(),
        index: item.sequence ?? index + 1,
        name: item.name || "Default",
        color: item.color || "default",
        isActivated: item.isActivated ?? false,
        tagExample: item.name || "Default",
      }));

      const sortedData = formattedData.sort((a, b) => a.index - b.index);

      setData(sortedData);
    } catch (error) {
      console.error();
      message.error("เกิดข้อผิดพลาดในการโหลดข้อมูล");
    } finally {
      setLoading(false);
    }
  };

  const fetchTagsSearch = async (keyword = "") => {
    try {
      setLoading(true);
      const response = await TagService.getSearch(keyword);
      if (!response || !Array.isArray(response.items)) {
        throw new Error("Response format is invalid or items are missing.");
      }
      const formattedData = response.items.map((item, index) => ({
        key: item.id || index.toString(),
        index: item.sequence ?? index + 1,
        name: item.name || "Default",
        color: item.color || "default",
        isActivated: item.isActivated ?? false,
        tagExample: item.name || "Default",
      }));

      setData(formattedData);
    } catch (error) {
      console.error();
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
  };

  useEffect(() => {
    fetchTags();
    UserService.isEditable().then((res) => {
      setIsEditable(res);
    });
  }, []);

  useEffect(() => {
    if (searchKeyword) {
      const delayDebounce = setTimeout(() => {
        fetchTagsSearch(searchKeyword);
      }, 1000);

      return () => clearTimeout(delayDebounce);
    } else {
      fetchTags();
    }
  }, [searchKeyword]);

  const handleColorChange = (color, key) => {
    // setIsDataModified(true);
    const newData = data.map((item) => {
      if (item.key === key) {
        return { ...item, color };
      }
      return item;
    });
    setData(newData);
  };

  const handleNameChange = (value, key) => {
    // setIsDataModified(true);
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, name: value, tagExample: value } : item
    );
    setData(updatedData);
  };

  const handleAddTag = () => {
    const newIndex = data.length + 1;
    const newTag = {
      key: `temp-${newIndex}`,
      index: newIndex,
      name: "New Tag",
      color: "default",
      tagExample: "New Tag",
      isTemporary: true,
    };
    setData([...data, newTag]);

    // Scroll to the bottom after adding the tag
    setTimeout(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 100);
  };

  const handleDeleteTag = (key) => {
    const tagToDelete = data.find((item) => item.key === key);

    if (tagToDelete.isTemporary) {
      // ลบ Temporary Tag ออกจาก state โดยตรง
      const filteredData = data.filter((item) => item.key !== key);
      setData(filteredData);
    } else {
      // เปิด Modal สำหรับการลบจาก backend
      setSelectedKey(key);
      setIsModalDeleteOpen(true);
    }
  };

  const handleSaveButton = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      const tagToDelete = data.find((item) => item.key === selectedKey);

      if (!tagToDelete) {
        message.error("ไม่พบ Tag ที่ต้องการลบ");
        return;
      }
      const response = await TagService.delete(tagToDelete.key);
      if (response.status === 200) {
        setIsModalSuccessOpen(true);
        const filteredData = data.filter((item) => item.key !== selectedKey);
        setData(filteredData);
        setTimeout(() => {
          setIsModalSuccessOpen(false);
        }, 3000);
        setShowFirstTable(false);
      } else {
        setIsModalFailedOpen(true);
      }
      setLoading(false);
    } catch (error) {
      setIsModalFailedOpen(true);
      setErrorMessage(error?.response?.data);
      setShowFirstTable(false);
    } finally {
      setIsModalDeleteOpen(false);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const colorUsage = {};

    data.forEach((item) => {
      colorUsage[item.color] = true;
    });

    const jsonData = data.map((item) => ({
      id: item.key && item.key.length < 2 ? "" : item.key || "",
      name: item.name || "Unknown",
      color: item.color || "DefaultColor",
      sequence: item.index,
    }));

    try {
      const response = await TagService.Createdata(jsonData);
      setIsModalSuccessOpen2(true);
      fetchTags();
      setShowFirstTable(false);
      handleUnsavedConfirm();
      setLoading(false);
      setTimeout(() => {
        setIsModalSuccessOpen2(false);
      }, 3000);
    } catch (error) {
      setErrorMessage(error?.response?.data);
      setIsModalFailedOpen(true);
      setLoading(false);
    }
  };

  const handleswitch = () => {
    setShowFirstTable(true);
  };

  // const handleCancel = () => {
  //   const filteredData = data.filter((item) => !item.isTemporary);
  //   setData(filteredData);
  //   setShowFirstTable(false);
  // };

  const handleCancel = async () => {
    try {
      await fetchTags();
      setShowFirstTable(false); // ปิดการแสดงตาราง
    } catch (error) {
      console.error("Error during cancel:", error);
    }
  };

  const handleClick = () => {
    const filteredData = data.filter((item) => !item.isTemporary);
    setData(filteredData);
    setShowFirstTable(false);
  };

  const columns = [
    {
      title: "คำสั่ง",
      key: "action",
      width: "10%",
      // sorter: (a, b) => a.index - b.index,
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="default"
            disabled={!isEditable}
            icon={<DeleteOutlined />}
            style={{ padding: "5px 10px" }}
            onClick={() => handleDeleteTag(record.key)} // รองรับ Temporary Tags
          />
        </Space>
      ),
    },
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "10%",
      sorter: (a, b) => a.index - b.index, // เพิ่มฟังก์ชันเรียงลำดับ
    },
    {
      title: "ชื่อ Tag",
      dataIndex: "name",
      key: "name",
      width: "30%",
      sorter: (a, b) => a.index - b.index, // ไม่ให้สามารถเรียงลำดับได้
      render: (text, record) => (
        <Input
          value={text}
          maxLength={20} // จำกัดไม่ให้เกิน 20 ตัวอักษร
          onChange={(e) => handleNameChange(e.target.value, record.key)}
          suffix={
            <span style={{ fontSize: "12px", color: "#888" }}>
              {text.length} / 20
            </span>
          }
        />
      ),
    },
    {
      title: "สี Tag",
      dataIndex: "color",
      key: "color",
      width: "20%",
      render: (color, record) => (
        <Select
          defaultValue={color}
          style={{ width: 100 }}
          onChange={(newColor) => handleColorChange(newColor, record.key)}
        >
          <Option value="default">Default</Option>
          <Option value="magenta">Magenta</Option>
          <Option value="green">Green</Option>
          <Option value="red">Red</Option>
          <Option value="volcano">Volcano</Option>
          <Option value="gold">Gold</Option>
          <Option value="cyan">Cyan</Option>
          <Option value="blue">Blue</Option>
          <Option value="purple">Purple</Option>
        </Select>
      ),
    },
    {
      title: "ตัวอย่าง Tag",
      dataIndex: "tagExample",
      key: "tagExample",
      width: "30%",
      sorter: false, // ไม่ให้สามารถเรียงลำดับได้
      sorter: (a, b) => a.index - b.index,
      render: (tagExample, record) => (
        <Tag
          color={record.color}
          bordered={false}
          style={{
            borderRadius: "8px",
            padding: "6px",
          }}
        >
          {tagExample}
        </Tag>
      ),
    },
  ];

  const columns2 = [
    {
      title: <span className="role-columns-title">คำสั่ง</span>,
      key: "action",
      width: "10%",
      // sorter: (a, b) => a.index - b.index,
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="default"
            disabled={!isEditable}
            icon={<DeleteOutlined />}
            style={{ padding: "5px 10px" }}
            onClick={() => handleDeleteTag(record.key)} // ส่ง key ไป
          />
        </Space>
      ),
    },
    {
      title: <span className="role-columns-title">#</span>,
      dataIndex: "index",
      key: "index",
      width: "10%",
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: <span className="role-columns-title">ชื่อ Tag</span>,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.index - b.index,
      render: (text) => (
        <span style={{ color: "#000", cursor: "not-allowed" }}>{text}</span>
      ),
    },
    {
      title: <span className="role-columns-title">ตัวอย่าง Tag</span>,
      dataIndex: "tagExample",
      key: "tagExample",
      width: "30%",
      sorter: (a, b) => a.index - b.index,
      render: (tagExample, record) => (
        <Tag
          color={record.color}
          bordered={false}
          style={{
            borderRadius: "8px",
            padding: "6px",
          }}
        >
          {tagExample}
        </Tag>
      ),
    },
  ];

  return (
    <div className="tag-body main-body">
      {!showFirstTable && (
        <div className="tag-content">
          <Flex justify="space-between" align="center" wrap gap="small">
            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              จัดการประเภท Tag
            </p>
            <div>
              {isEditable && (
                <Button
                  type="primary"
                  style={{
                    borderRadius: "10px",
                    // width: "",
                    height: "40px",
                    color: "#2E323A",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={handleswitch}
                >
                  + เพิ่ม/แก้ไข Tag
                </Button>
              )}
            </div>
          </Flex>

          <Divider className="tag-divider" />
          <Input
            placeholder="ค้นหาชื่อ Tag"
            value={searchKeyword}
            allowClear
            size="large"
            suffix={
              <AiOutlineSearch
                style={{
                  color: "rgba(0,0,0,.45)",
                  cursor: "pointer",
                }}
              />
            }
            onChange={handleSearchChange}
            style={{
              margin: "14px 0 14px 0",
              borderRadius: "8px",
              maxWidth: "285px",
              width: "50%",
            }}
          />
          <Table
            style={{
              margin: "8px 0",
            }}
            columns={columns2}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={false}
            loading={loading}
            scroll={{
              x: 500,
            }}
          />
        </div>
      )}
      {showFirstTable && (
        <div className="tag-content" ref={tableContainerRef}>
          <Flex justify="space-between" align="center" wrap gap="small">
            <Space>
              <ArrowLeftOutlined
                style={{ cursor: "pointer", fontSize: "20px" }} // เพิ่ม cursor pointer
                onClick={() => {
                  // if (isDataModified) {
                  //   setIsUnsavedModalOpen(true);
                  // } else {
                  //   handleClick();
                  // }
                  handleCancel();
                }} // เมื่อคลิกจะเรียก handleClick
              />
              <h2>เพิ่ม/แก้ไข Tag</h2>
            </Space>
            <Space>
              <Button
                type="primary"
                style={{
                  backgroundColor: "#EBEBEB",
                  borderRadius: "10px",
                  // width: "",
                  height: "40px",
                  color: "#2E323A",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  // if (isDataModified) {
                  //   setIsUnsavedModalOpen(true);
                  // } else {
                  //   handleCancel();
                  // }
                  handleCancel();
                }}
              >
                ยกเลิก
              </Button>

              <Button
                type="primary"
                style={{
                  borderRadius: "10px",
                  // width: "",
                  height: "40px",
                  color: "#2E323A",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                onClick={handleSaveButton}
              >
                บันทึก
              </Button>
            </Space>
          </Flex>
          <Divider className="tag-divider" />
          <Flex justify="space-between" align="center" wrap gap="small">
            <h3>รายการ Tag</h3>
            <Button
              icon={<PlusOutlined />}
              style={{
                width: "96px",
                height: "40px",
                padding: "0px 22px",
                borderRadius: "10px",
                borderColor: "#00B6A9",
                color: "#00B6A9",
              }}
              ghost
              onClick={handleAddTag}
            >
              เพิ่ม
            </Button>
          </Flex>

          <Table
            style={{
              margin: "8px 0",
            }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={false}
            loading={loading}
            scroll={{
              x: 800,
            }}
          />
        </div>
      )}

      <LoadingDialog isLoading={loading} />

      <DeleteDialog
        isModalOpen={isModalDeleteOpen}
        closable={false}
        title="ยืนยันการลบ"
        cancelText="ยกเลิก"
        confirmText="ลบ"
        onCancel={() => {
          setIsModalDeleteOpen(false);
        }}
        onConfirm={() => {
          handleConfirmDelete();
        }}
        description="คุณต้องการลบใช่หรือไม่?"
      />
      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        type="bin"
        title="สำเร็จ"
        detail="ลบข้อมูลสำเร็จ"
        onCancel={() => {
          setIsModalSuccessOpen(false);
        }}
        onAfterClose={() => {
          setTimeout(() => {
            setIsModalSuccessOpen(false);
          }, 3000);
        }}
      />
      <SuccessDialog
        isModalOpen={isModalSuccessOpen2}
        closable={false}
        title="สำเร็จ"
        detail="บันทึกข้อมูลสำเร็จ"
        onCancel={() => {
          setIsModalSuccessOpen2(false);
        }}
        onAfterClose={() => {
          setTimeout(() => {
            setIsModalSuccessOpen2(false);
          }, 3000);
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title=""
        detail={errorMessage?.messageTh}
        onCancel={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
        onAfterClose={() => { }}
        onConfirm={() => {
          setIsModalFailedOpen(false);
          setErrorMessage("");
        }}
      />

      <ConfirmDialog
        isModalOpen={isModalOpen}
        closable={false}
        title="ยืนยันการบันทึก"
        cancelText="ยกเลิก"
        confirmText="บันทึก"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={() => {
          handleSave();
        }}
        description="คุณต้องการบันทึกใช่หรือไม่?"
      />

      <DiscardDialog
        isModalOpen={isUnsavedModalOpen}
        closable={false}
        title="ยกเลิกการเปลี่ยนแปลง"
        cancelText="ยกเลิก"
        confirmText="ยืนยัน"
        onCancel={() => {
          handleUnsavedCancel();
        }}
        onConfirm={() => {
          handleUnsavedConfirm();
          handleClick();
          handleCancel();
        }}
      />
    </div>
  );
};

export default TagManagement;
