import React, { useState } from "react";
import "./InsightReportManagement.css";
import { Layout, Tabs, Grid } from "antd";
import InsightReportManagement from "./InsightReportManagement";
import InsightReportDownloadAnalysis from "./InsightReportDownloadAnalysis";

const { Header } = Layout;
const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const InsightReportManagementNav = () => {
  const [tabKey, setTabKey] = useState("2");
  const screens = useBreakpoint();

  const handleTabChange = (key) => {
    setTabKey(key);
  };
  return (
    <div className="insight-report-management-body">
      <Layout>
        <Header
          style={{
            height: "70px",
            background: "#FFF",
            borderBottom: "1px solid #D0D5DD",
          }}
        >
          <div className="tab-container" style={{ width: "100%", overflow: "auto"  }}>
            <Tabs
              defaultActiveKey={tabKey}
              onChange={handleTabChange}
              tabBarStyle={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <TabPane
                tab={
                  <p
                    style={{
                      margin: "0px",
                      marginBottom: screens.xs ? "" : "6px",
                      fontWeight: "600",
                      fontSize: screens.xs ? "12px" : "16px",
                    }}
                  >
                    ข้อมูลดาวน์โหลดรายงานวิเคราะห์
                  </p>
                }
                key="1"
              />
              <TabPane
                tab={
                  <p
                    style={{
                      margin: "0px",
                      marginBottom: screens.xs ? "" : "6px",
                      fontWeight: "600",
                      fontSize: screens.xs ? "12px" : "16px",
                    }}
                  >
                    รายการข้อมูลรายงานวิเคราะห์
                  </p>
                }
                key="2"
              />
            </Tabs>
          </div>
        </Header>

        {tabKey === "1" && <InsightReportDownloadAnalysis />}
        {tabKey === "2" && <InsightReportManagement />}
      </Layout>
    </div>
  );
};

export default InsightReportManagementNav;
