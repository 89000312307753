import React, { createContext, useContext, useState } from "react";

const UnsavedChangesContext = createContext();

export const UnsavedChangesProvider = ({ children }) => {
  const [isDataModified, setIsDataModified] = useState(false);

  return (
    <UnsavedChangesContext.Provider value={{ isDataModified, setIsDataModified }}>
      {children}
    </UnsavedChangesContext.Provider>
  );
};

export const useUnsavedChangesContext = () => {
  return useContext(UnsavedChangesContext);
};
